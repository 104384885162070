import React, { useContext, useEffect, useRef, useState } from 'react';
import { Tag, Row, Col, Button, Input, Select, Divider, notification, Tooltip } from 'antd';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';

import * as Components from '../../index';

import './CaseReportListComponent.scss';
import moment from 'moment';
import 'moment/locale/es';

import { AlertOutlined, AlertTwoTone, AppstoreOutlined, DeliveredProcedureOutlined, FilePdfOutlined, InfoCircleOutlined, LoadingOutlined, PrinterOutlined, ProfileOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CaseReports from '../../../models/CaseReports';
import { CaseReportStatus } from '../../../models/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Request from '../../../models/Request';
import { useHistory, useParams } from "react-router-dom";
import { LoginContext } from '../../../hooks/context/userContext';
import { ADMIN_ROLE, DOCTOR_ROLE, PATHOLOGIST_ROLE } from '../../../utils/Constants';
import { processFile } from '../../../utils/ProcessFile';
import { getAgeInfo } from '../../../utils/AgeUtils';



const { Option } = Select;


interface CaseReportListComponentProps{
    onlyArchived?:boolean,
    showHeaderButtons?: boolean,
    filter?: string,
}


const CaseReportListComponent = (props: CaseReportListComponentProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const history = useHistory();
    let loginContext = useContext(LoginContext);

    const {onlyArchived, showHeaderButtons} = props;
    const { paramsFilter } = useParams<any>();
    const [filter, setFilter] = useState<string>(paramsFilter);


    
    const tableListRef = useRef()

    const [selected, setSelected] = useState<CaseReports>();
    const [showEdition, setShowEdition] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [lastRequests, setLastRequests] = useState<Request[]>([]);
    const [lastRequestsNumber, setLastRequestsNumber] = useState<number>(40);
    const [pdfData, setPdfData] = useState<any>();
    const [showPdf, setShowPdf] = useState<boolean>(false);
    const [columns, setColumns] = useState<any[]>([]);


    const getStatusTag = (status: CaseReportStatus, customStyles?: any) : JSX.Element => {
        let result : JSX.Element = <></>;

        switch(status){
            case CaseReportStatus.Finished:
                result = <Tag className="case-report-status-tag" color="magenta" style={customStyles}>{status}</Tag>
                break;
            case CaseReportStatus.Processing:
                result = <Tag className="case-report-status-tag" color="gold" style={customStyles}>{status}</Tag>
                break
            case CaseReportStatus.Received:
                result = <Tag className="case-report-status-tag" color="green" style={customStyles}>{status}</Tag>
                break
            case CaseReportStatus.Empty:
                result = <Tag className="case-report-status-tag" color="purple" style={customStyles}>{status}</Tag>
                break;
        }

        return result;
    }

    const viewDetail = (element: CaseReports) : void => {
        history.push(`/case-report/${element.id}`);
    }

    const getReceptionStatusTag = (status: number, observations: string) : JSX.Element => {
        let result : JSX.Element = <></>;
        let color : string = "";
        let text : string = "";

        switch(status){
            case 1:
                color = "#f8ac59";
                text = "Pendiente de autorización";
                break;
            case 2:
                color = "#ed5565";
                text = "Caso con incidencia";
                break;
            case 3:
                color = "#ed7555";
                text = "Rechazado por aseguradora";
                break;
        }

        if (status > 0){
            if (observations && observations.length > 0){
                result = <Tag className={`reception-status-tag`} color={color}><Tooltip title={observations} placement='top'>{text} {observations && observations.length > 0 ? <QuestionCircleOutlined /> : ''}</Tooltip></Tag>
    
            }else{
                result = <Tag className={`reception-status-tag`} color={color}>{text}</Tag>
    
            }
        }

        return result;
    }

    const renderColumn = (element: CaseReports, children: any, addIcon: boolean = false) : any => {
        const isUrgent : boolean = element.keywords !== undefined && element.keywords.length > 0;
        const isDeleted : boolean = element.deleteLogic;


        const finalChildren = isUrgent && addIcon ? <>{children} <AlertTwoTone className="icon-urgent" twoToneColor="red"  /></> : children; 

        return {
            props: {
                className:`${isDeleted ? 'case-report-deleted' : (isUrgent ? 'case-report-urgent' : '')}`,
            },
            children: finalChildren,
        };
    }
     
    const getSpecialWords = (element: CaseReports) : JSX.Element[] => {
        let specialWords : JSX.Element[] = [];

            if (element.keywords && element.keywords.length>0){
                let words : string = '';
                element.keywords.forEach((word: string, i: number)=>{
                    
                    words = words + word + (element.keywords && i < element.keywords.length-1 ? ', ' : '');
                    //specialWords.push(<Tag color="#f50">#{word}</Tag>);
                });

                if (element.status == "finalizado"){
                    specialWords.push(<Tooltip placement="bottom" title={`${words}`}>
                                        <Tag color="red" style={{cursor:"pointer", fontWeight:"700"}}>Caso Preferente <QuestionCircleOutlined /></Tag>
                                  </Tooltip>);
                }
                
            };

        return specialWords;
    }


    const getPreviousCaseReports = (element: CaseReports) :JSX.Element => {
        var previousCaseReport : JSX.Element = <></>;

            if (element.patientCaseReportsCounter.length > 1){
                let numbers : string = "";
                element.patientCaseReportsCounter.forEach((e, i) => {

                    if (e != element.caseReportNum){
                        const separator : string = numbers.length === 0 || i === 0 ? "" : ", ";
                        numbers = `${numbers}${separator}${e}`;
                    }
                })

                let title : any = <>El paciente tiene otro{element.patientCaseReportsCounter.length-1 > 1 ? 's':''} {element.patientCaseReportsCounter.length-1 > 1 ? (element.patientCaseReportsCounter.length-1):''} caso{element.patientCaseReportsCounter.length-1 > 1 ? 's':''} de estudio registrado{element.patientCaseReportsCounter.length-1 > 1 ? 's':''} ({numbers})<small style={{display:"inline-block"}}>Haz click para ver los casos de estudio anteriores (Si el caso de estudio pertenece a otro médico/patólogo no podrá consultarlo.)</small></>;
                
                previousCaseReport = <Tooltip placement="bottom" title={title}>
                                        <Tag className="previous-case-report-tag" color="blue" onClick={()=>{
                                        if (tableListRef.current != undefined){
                                            let x : any = tableListRef?.current;

                                            //x.updateFilter(`${element.patient.name} ${element.patient.surname} ${element.patient.secondSurname ? element.patient.secondSurname : ''}`);
                                            x.updateFilter(`paciente:${element.patient.id}`);
                                        }
                                        }}>{element.someOlderCaseReportsHasKeywords ? <AlertTwoTone twoToneColor="#eb2f96"/> : <></>} Casos anteriores</Tag>
                                    </Tooltip>
            }

        return previousCaseReport;
    }

    

    const getLastRequests = () : void => {

        const paramsApi : ParamsApi = {
            query:{
                N: lastRequestsNumber ?? 40
            }
        }

        setLoadingData(true);
        restApiClient.fetch("GetLastFinishedRequests", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setLastRequests(r.data);
                }
            }).finally(()=>{
                setLoadingData(false);
            });
    }

    const onChangeLastRequests = (e: any) : void => {

        if (e && tableListRef.current != undefined){
            let x : any = tableListRef?.current;
            x.updateFilter(`solicitud:${e}`);
        }else if (e == undefined){
            let x : any = tableListRef?.current;
            x.updateFilter(``);
        }
    }

    useEffect(()=>{
        getLastRequests();
        setTheColumns();
    },[]);

    
    useEffect(()=>{
        if (paramsFilter){
            setFilter(paramsFilter);
            setReloadCounter(reloadCounter + 1);
        }
    },[paramsFilter]);

    useEffect(()=>{
        if (props.filter){
            setFilter(props.filter);
            setReloadCounter(reloadCounter-1);

            if (tableListRef.current != undefined){
                let x : any = tableListRef?.current;

                x.updateFilter(`${props.filter}`);
            }
        }
    },[props.filter])


    const setTheColumns = () : void => {
        let theColumns = [
            {
            
            title: 'Caso/estudio',
            dataIndex: 'caseReportNum',
            key: 'caseReportNum',
            render: (text: string, element: CaseReports) => renderColumn(element, <div id={`record-table-${element.id}`} className={loginContext.idRole === DOCTOR_ROLE ? (element.readed ? 'readed' : 'not-readed') : ''}>
                                                                                            <a onClick={(e: any)=>{
                                                                                                e.preventDefault(); 
                                                                                                
                                                                                                if (loginContext.idRole == ADMIN_ROLE || loginContext.idRole == PATHOLOGIST_ROLE) {
                                                                                                    viewDetail(element);
                                                                                                }
                                                                                            }} target="_blank" rel="noopener noreferrer">
                                                                                                            {element.caseReportNum}
                                                                                                                                                
                                                                                                            {
                                                                                                                loginContext.idRole === ADMIN_ROLE || loginContext.idRole == PATHOLOGIST_ROLE ? <Tooltip title={`Asociado a petición con ID ${element.reception.request.id}`}><InfoCircleOutlined style={{marginLeft:"5px"}} /></Tooltip> : ''
                                                                                                            }
                                                                                            
                                                                                            {
                                                                                                loginContext.idRole === DOCTOR_ROLE && element.readed && element.readedDate ? 
                                                                                                    <Tooltip title={`Leído el ${moment(element.readedDate).format("DD/MM/YYYY HH:mm")}`}><InfoCircleOutlined className="readed-info"/></Tooltip>
                                                                                                : ''
                                                                                                
                                                                                            }
                                                                                            </a>
                                                                                            
                                                                                        <div className="special-words">{getSpecialWords(element)}</div>
                                                                                        
                                                                                    </div>, false),
                renderMobileOld:(i: number, element: CaseReports) => {
    
                const isUrgent : boolean = element.keywords !== undefined && element.keywords.length > 0;
    
                return <div className="mobile-view">
                    
                    <Row gutter={100}>
                        <Col span={24} className="file-data">
                            <a className={isUrgent ? 'case-report-urgent' : ''} onClick={(e: any)=>{
                                e.preventDefault(); 
                                if (loginContext.idRole == ADMIN_ROLE || loginContext.idRole == PATHOLOGIST_ROLE) {
                                    viewDetail(element);
                                }
                            }} target="_blank" rel="noopener noreferrer">
                                { isUrgent ? <AlertTwoTone className="icon-urgent" twoToneColor="red"  /> : ''}
                                {element.caseReportNum}
                            </a>
                            {getStatusTag(element.status, {position:"absolute", right: 70, })}
                            <AppstoreOutlined style={{position:"absolute", right: 43 }} className={`custom-table-actions ${element.id === selected?.id ? 'record-selected' : 'record-unselected'}`} onClick={()=>{setSelected(element)}}/>
    
                        </Col>
                        <Col xs={24} sm={12} className="file-data">
                            <FontAwesomeIcon className="icon" icon={Icons.faProcedures} title="Paciente"/>
                            <span>{element.patient.name} {element.patient.surname} {element.patient.secondSurname}</span>
                            <small style={{display:"block"}}>
                                <strong>Edad: { element.patient.birthDate ? moment(element.patient.birthDate).fromNow(true) : "N/D"} años</strong>
                            </small>
                            {getPreviousCaseReports(element)}
                        </Col>
                        <Col xs={24} sm={12} className="file-data">
                            <FontAwesomeIcon className="icon" icon={Icons.faVials} title="Muestra"/>
                            <span>
    
                                {element.product.description}
                            </span>
                            <small style={{display:"block"}}>
                                <div className="special-words">{getSpecialWords(element)}</div>
                            </small>
                            <small style={{display:"block"}}>
                                <strong>{element.company.businessName}</strong>
                                {getReceptionStatusTag(element.reception.status, element.reception.observations)}
                            </small>
                        </Col>
                        <Col xs={24} className="file-data">
                            <span>
                                <FontAwesomeIcon className="icon" icon={Icons.faNotesMedical} title="Patólogo asignado"/> {element.pathologist.name} {element.pathologist.surname} {element.pathologist.secondSurname}
                            </span>
                        </Col>
                    </Row>
                </div>
            }
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, element: CaseReports) => renderColumn(element, getStatusTag(element.status)),
        },
        {
            title: 'Paciente',
            dataIndex: 'patient',
            key: 'patient.name',
            render: (text: string, element: CaseReports) => renderColumn(element, <>
                <span>{element.patient.name} {element.patient.surname} {element.patient.secondSurname}</span>
                <small style={{display:"block"}}>
                    {
                        getAgeInfo(element.reception.request.start, element.patient.birthDate)
                    }
                </small>
                {getPreviousCaseReports(element)}
            </>)
        },
        { 
            title: 'Tipo de muestra',
            dataIndex: 'type',
            key: 'type',
            render: (i: number, element: CaseReports) => renderColumn(element, <>{element.product.description}
                <small style={{display:"block"}}>
                    {element.product.type}
                </small>
                
                </>)
        },
        {
            title: 'Compañia',
            dataIndex: 'company.name',
            key: 'company.name',
            render: (i: number, element: CaseReports) => renderColumn(element, <>
                <span>{element.company.businessName}</span>
                {getReceptionStatusTag(element.reception.status, element.reception.observations)}                
            </>)
            
        }
        ];

        theColumns.push(
            {
                title: 'Médico',
                dataIndex: 'doctor.name',
                key: 'doctor.name',
                render: (i: number, element: CaseReports) => renderColumn(element, <>
                    <span>
                        {element.reception.request.user.name} {element.reception.request.user.surname} {element.reception.request.user.secondSurname}
                    </span>
                    <small style={{display: 'block'}}>{element.specialTag}</small>
                    </>)
            });

        if (loginContext.idRole === DOCTOR_ROLE){
            theColumns.push(
                {
                    title: 'Acciones',
                    dataIndex: 'actions',
                    key: 'actions',
                    render: (i: number, element: CaseReports) => {
                        return <>
                                    
                                    <Button size='small' danger disabled={(element?.status !== CaseReportStatus.Finished || element.reception.status > 0 )} onClick={()=>{getPdfData([element.id]); setSelected(element)}}>
                                        <FilePdfOutlined />
                                        <small className="h-section-name" >PDF</small>
                                    </Button>
                                </>;
                    }
                });
        }

        setColumns(theColumns);
    }


    const [loadingMassivePrint, setLoadingMassivePrint] = useState<boolean>(false);
    const getPdfData = (caseReportIds: number[]) => {
        const paramsApi : ParamsApi = {
            body:caseReportIds
        }

        setLoadingMassivePrint(true);
        restApiClient.generatePdf(paramsApi, "GenerateCaseReportsPDF")
            .then((data)=> {
                processFile(data, setPdfData);
                setShowPdf(true);
            })
            .finally(()=>{
                setLoadingMassivePrint(false);

                caseReportIds.forEach((id: number)=>{
                    var element = document.getElementById(`record-table-${id}`);
    
                    if (element){
                        //Remove the class 'not-readed' to element
                        element.classList.remove('not-readed');
                    }
                });
            });
    }
        

    useEffect(()=>{
        if (pdfData){
            setShowPdf(true);
        }
    },[pdfData]);


    const [bagItemsIDs, setBagItemsIDs] = useState<number[]>([]);
    const [showModalPreformats, setShowModalPreformats] = useState<boolean>(false);
    const [showModalValidate, setShowModalValidate] = useState<boolean>(false);
    

    const removeItemFromBag = (caseReport: CaseReports) => {
        const newBagItemsIDs = bagItemsIDs.filter(i=>i !== caseReport.id);
        setBagItemsIDs(newBagItemsIDs);
    }

    const addItemToBag = (ids: number[]) => {
        var diferences = ids.filter(i=>!bagItemsIDs.includes(i));
        setBagItemsIDs([...bagItemsIDs, ...diferences]);
    }
    

    //Indica si es un PDF Masivo o no
    const [isMassivePdf, setIsMassivePdf] = useState<boolean>(false);
    const getActionButtons = () : any[] => {

        if (showHeaderButtons !== undefined && showHeaderButtons === false){
            return [];
        }

        let buttons : any[] = [
            {
                type: "ghost", 
                className:"massive-printer", 
                label:`Impresión masiva ${bagItemsIDs.length}`, 
                action: ()=> {
                    setIsMassivePdf(true);
                    getPdfData(bagItemsIDs)
                }, 
                icon: loadingMassivePrint ? <LoadingOutlined /> : <PrinterOutlined /> 
            },
            {
            item: <Select onChange={onChangeLastRequests} placeholder="Seleccione..." allowClear style={{width: "360px", textAlign:"left"}} 
                        dropdownRender={(menu:any) => (
                            <div>
                                {menu ? menu : ''} 
                                {
                                    
                                    <>
                                        <Divider style={{ margin: '4px 0' }} />
                                        {
                                            <div className="footer-add-patient">
                                                <Input.Group compact>
                                                    <Input type="number" size="small" placeholder="Cantidad (40 por defecto)" style={{width: "180px"}} onChange={(e:any)=>{setLastRequestsNumber(e.target.value)}}/>
                                                    <Button size="small" type="primary" onClick={()=>{getLastRequests()}}><Components.LoadingOrText showWhileLoading={false} loading={loadingData} text="Refrescar"/></Button>
                                                </Input.Group>
                                            </div>
                                        }
                                    </>
                                    
                                }
                                
                            </div>
                        )}
                        dropdownStyle={{minWidth: "360px"}}>
                            {
                                lastRequests.map((r: Request, i:number)=>{
                                    return <Option key={i} value={r.id}><Tooltip placement='topLeft' title={`${moment(r.requestDate).format("DD/MM/YYYY")} - ${r.requestType.description} ${loginContext.idRole != DOCTOR_ROLE ? `- ${r.user.name} ${r.user.surname} ${r.user.secondSurname} - ID RQ ${r.id} ` : ''}`}>{moment(r.requestDate).format("DD/MM/YYYY")} - {r.requestType.description} {loginContext.idRole != DOCTOR_ROLE ? `- ${r.user.name} ${r.user.surname} ${r.user.secondSurname} - ID RQ ${r.id} ` : ''}</Tooltip></Option>
                                })
                            }
                    </Select>
        }];
        
        if (loginContext.idRole !== DOCTOR_ROLE){
            buttons = [{
                            type: "ghost", 
                            className:"preformateado-masivo", 
                            label:`Formateado masivo ${bagItemsIDs.length}`, 
                            action: setShowModalPreformats, 
                            icon: <DeliveredProcedureOutlined />
                        }, 
                        {
                            type: "ghost", 
                            className:"preformateado-masivo", 
                            label:`Validado masivo ${bagItemsIDs.length}`, 
                            action: setShowModalValidate, 
                            icon: <DeliveredProcedureOutlined />
                        }, ...buttons];
        }


        return buttons;
    }

    const [reloadCounter, setReloadCounter] = useState<number>(0);

    return (
        <Row className="report-list">
            {
                loginContext.idRole !== DOCTOR_ROLE ?
                    <>
                        <Components.ModalCaseReport onFinish={()=>{setReloadCounter(reloadCounter+1); setBagItemsIDs([]); setShowModalPreformats(false);}} onRemoveItem={removeItemFromBag} idsList={bagItemsIDs} onClearItems={()=>{setBagItemsIDs([]);}}  onClose={()=>{setShowModalPreformats(false); }} visible={showModalPreformats} /> 
                        
                        <Components.ModalCaseReportValidate onFinish={()=>{setReloadCounter(reloadCounter+1); setBagItemsIDs([]); setShowModalValidate(false);}} onRemoveItem={removeItemFromBag} idsList={bagItemsIDs} onClearItems={()=>{setBagItemsIDs([]);}} onClose={()=>{setShowModalValidate(false); }} visible={showModalValidate} /> 
                    
                    </>
                    :
                    ''
            }

            <Components.ModalPDF isMassivePdf={isMassivePdf} selected={selected} onClose={()=>{setShowPdf(false); setPdfData(null); setIsMassivePdf(false);}} visible={showPdf} pdfData={pdfData}/> 
            <Components.TablePagination 
                    contextualMenu={loginContext.idRole != DOCTOR_ROLE}
                    ref={tableListRef}
                    reloadCounter={reloadCounter}
                    showCheckbox
                    selectedRowKeys={bagItemsIDs} 
                    onCheckedItem={addItemToBag}
                    aliasEndPoint="GetCaseReports"
                    entityName="casereport"
                    columns={columns}
                    paramsEndPoint={{onlyArchived:onlyArchived}}
                    title="Listado de casos de estudio"
                    initialPageSize={loginContext.idRole == ADMIN_ROLE || loginContext.idRole == DOCTOR_ROLE ? 100 : 300}
                    defaultFilter={filter && filter.length > 0 ? filter : ""}
                    filterButtons={showHeaderButtons ? ["autorizados", "pendiente autorización", "caso con incidencia", "rechazados aseguradora", "separator", "caso preferente", "separator", "finalizado", "recepcionado", "procesando","separator", "microbiologia", "biopsia","citologia","ihq-molecular"] : undefined}
                    actionButtons={getActionButtons()}
                />

        </Row>
    )
}


                            

export default CaseReportListComponent;