import { FileDoneOutlined } from '@ant-design/icons'
import { Button, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'


interface Props{
    visible: boolean,
    onClose: any,
    pdfData: any
}

const ModalReportPDFComponent = (props: Props) : JSX.Element => {

    const [visible, setVisible] = useState<boolean>(false);
    const [pdfData, setPdfData] = useState<any>();


    useEffect(()=>{
        if (props.pdfData){
            setPdfData(props.pdfData);
        }
    },[props.pdfData]);

    useEffect(()=>{
        setVisible(props.visible);
    },[props.visible])


    return(
        <Modal
            title={<><FileDoneOutlined /> Previsualización informe</>}
            centered
            visible={visible}
            onOk={() => props.onClose()}
            onCancel={() => props.onClose()}
            width={"90vw"}
            footer={
                <>
                        <Button type="primary" onClick={() => props.onClose()}>Cerrar</Button>
                </>
            }
        >
            {
                pdfData ? 
                    <iframe src={pdfData} style={{width:"100%", height:"70vh", border: "none", marginTop:"10px"}}></iframe>
                :
                ''
            }
        </Modal>
    )
}

export default ModalReportPDFComponent;