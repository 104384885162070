import React, { useEffect, useState } from 'react';
import { Bullet } from '@ant-design/charts';
import * as Components from '../../components/index'
import ParamsApi from '../../models/api/ParamsApi';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import moment from 'moment';
import { Button, Drawer, Input } from 'antd';
import { DotChartOutlined } from '@ant-design/icons';

interface Props {};
interface BulletData{
  requests: number,
  total: number,
  finished: number,
  received: number,
  processing: number
}
const DemoBullet = (props: Props) => {
  const restApiClient : RestApiClient = new RestApiClient();

  const [statisticsVisible, setStatisticsVisible] = React.useState<boolean>(false);

  const [year, setYear] = useState<number>(moment().year());
  const [caseReportData, setCaseReportData] = React.useState<BulletData>({requests:0, total:0, finished:0, received:0, processing:0});

  const getCounters = () : void => {
    const paramsApi : ParamsApi = {
        query:{
            "year": year
        }
    }

    restApiClient.fetch("GetCounters", paramsApi)
        .then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
               setCaseReportData(r.data);
            }
        });
  }

  useEffect(()=>{
    if (year && year > 1970){
      getCounters();
    }
  }, [year]);

  const data = [
    {
      title: '',
      ranges: [
                //procesando (#ffe58f)
                caseReportData.processing*20,
                //recibidos (#b7eb8f)
                caseReportData.received*10,
                //finalizados (#ffadd2)
                caseReportData.finished*0.5,
                //total (#91d5ff)
                caseReportData.total*0.5,
                //solicitudes (#c183f3)
                (caseReportData.total*0.5 + caseReportData.requests*0.5)],
      measures: [],
      Pendientes: (caseReportData.received*10),
    }
  ];
  const config : any = {
    data,
    height: 40,
    xField: 'title',
    measureField: 'measures',
    rangeField: 'ranges',
    targetField: 'Pendientes',
    color: {
      range: ['#ffe58f','#b7eb8f','#ffadd2','#91d5ff','#c183f3'],
      measure: '#5B8FF9',
      target: '#39a3f4',
    },
    tooltip: {
      formatter: (datum:any) => {
        return { name: "Pendientes de finalizar", value: (caseReportData.total - caseReportData.finished) };
      },
    },
    size:{
      target: 15,
      measure: 10,
      range: 10
    },
    bulletStyle:{
      target:{
        lineWidth: 3,
        cursor: "pointer"
      }
    },
    label: {
      measure: {
        position: 'middle',
        style: {
          fill: '#fff',
        },
      }
    },
    xAxis: {
      line: null,
    },
    yAxis: false,
    legend: {
      custom: true,
      position: 'bottom',
      items: [
        {
          value: `Recepcionados (${caseReportData.received})`,
          name: `Recepcionados (${caseReportData.received})`,
          marker: {
            symbol: 'square',
            style: {
              fill: '#b7eb8f',
              r: 5,
            },
          },
        },{
          value: `Procesando (${caseReportData.processing})`,
          name: `Procesando (${caseReportData.processing})`,
          marker: {
            symbol: 'square',
            style: {
              fill: '#ffe58f',
              r: 5,
            },
          },
        },
        
        {
          value: `Finalizados (${caseReportData.finished})`,
          name: `Finalizados (${caseReportData.finished})`,
          marker: {
            symbol: 'square',
            style: {
              fill: '#ffadd2',
              r: 5,
            },
          },
        },
        {
          value: `Total (${caseReportData.total})`,
          name: `Total (${caseReportData.total})`,
          marker: {
            symbol: 'square',
            style: {
              fill: '#91d5ff',
              r: 5,
            },
          },
        },
        {
          value: `Solicitudes (${caseReportData.requests})`,
          name: `Solicitudes (${caseReportData.requests})`,
          marker: {
            symbol: 'square',
            style: {
              fill: '#c183f3',
              r: 5,
            },
          },
        }
      ],
    },
  };
  return <>
          <Drawer title="Estadísticas casos de estudio" width={"100%"} placement="left" onClose={()=>{setStatisticsVisible(false)}} visible={statisticsVisible}>
            <Components.DetailedStatistics />
          </Drawer>
          <Bullet {...config} />
           <div style={{width:"100%", textAlign:"right"}}>
            <Button type='primary' className='btn-secondary' size='small' style={{marginRight: "10px"}}>
              <DotChartOutlined className="graph-icon" onClick={()=>{setStatisticsVisible(true)}}/> 
            </Button>
            <Input type="number" size='small' style={{maxWidth:"100px"}} onChange={(v: any)=>{setYear(v.target.value);}} defaultValue={year}/>
           </div>
         </>;
};

export default DemoBullet;

