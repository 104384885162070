import React from 'react'

import * as Components from '../../../components'

import './InvoicesPage.scss'

const InvoicesPage = () : JSX.Element => {

    return(
        <div className="invoices-page">
            <Components.InvoiceList />
        </div>
    )
}

export default InvoicesPage;