import React, { forwardRef, useEffect, useState } from 'react'
import { Form, Select } from 'antd'

//https://github.com/zenoamaro/react-quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ParamsApi from '../../../models/api/ParamsApi';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';


interface BiopsiaProps {
    reset: boolean,
    form: any,
    values?:{
        muestra:string,
        estudio:string,
        informacionClinica:string
    }
}


// https://stackoverflow.com/questions/37949981/call-child-method-from-parent
const BiopsiaReceptionComponent = forwardRef((props: BiopsiaProps, ref: any)=>{
    const restApiClient : RestApiClient = new RestApiClient();
    
    const formItemHeight : string = "300px";
    const textAreaHeight : string = "250px";

    const [shortCuts, setShortCuts] = useState<any[]>([]);
    const [selectedShortCode, setSelectedShortCode] = useState<string[]>([]);

    const [reset, setReset] = useState<boolean>(false);

    const { form, values } = props;

    const GetShortcuts = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                page: 0,
                N: 0,
                filter: 'ClinicInfo', 
            }
        }
        restApiClient.fetch("GetOptions", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setShortCuts(r.data.elements);
                }
            })
    }

    useEffect(()=>{
        if(props.reset){
            GetShortcuts();
            setReset(true);
        }
    },[props])

    
    useEffect(()=>{
        if(reset){
            form.setFieldsValue({"biopsiaReceptionData":{ "muestra":"ANEJO UTERINO", "estudio":"BIOPSIA CONSULTA", "informacionClinica":""}});
            setReset(false);
        }
    },[reset])

    useEffect(()=>{
        if(values){
            form.setFieldsValue({"biopsiaReceptionData":{ "muestra":values.muestra ?? "", "estudio":values.estudio ?? "", "informacionClinica":values.informacionClinica ?? ""}});
        }else{
            form.setFieldsValue({"biopsiaReceptionData":{ "muestra":"ANEJO UTERINO", "estudio":"BIOPSIA CONSULTA", "informacionClinica":""}});
        }
    },[values])


    return (<>
                <small>Biopsia</small>
                <Form.Item 
                    name={["biopsiaReceptionData","muestra"]}
                    rules={[{ required: true, message: "Debe seleccionar una muestra"}]}
                    label="Muestra">
                    <Select style={{ width: "100%" }} >
                        <option id="1489668502854" value="ANEJO UTERINO">ANEJO UTERINO</option>
                        <option id="1489668519492" value="CÉRVIX">CÉRVIX</option>
                        <option id="1489668531540" value="DECIDUA">DECIDUA</option>
                        <option id="1489668544515" value="ENDOMETRIO">ENDOMETRIO</option>
                        <option id="1489668563508" value="FETO - EMBRIÓN">FETO - EMBRIÓN</option>
                        <option id="1489668591845" value="HISTERECTOMÍA SUBTOTAL CON ANEJOS">HISTERECTOMÍA SUBTOTAL CON ANEJOS</option>
                        <option id="1489668609413" value="HISTERECTOMÍA SUBTOTAL SIN ANEJOS">HISTERECTOMÍA SUBTOTAL SIN ANEJOS</option>
                        <option id="1489668630243" value="HISTERECTOMÍA TOTAL CON ANEJOS">HISTERECTOMÍA TOTAL CON ANEJOS</option>
                        <option id="1489668644208" value="HISTERECTOMÍA TOTAL SIN ANEJOS">HISTERECTOMÍA TOTAL SIN ANEJOS</option>
                        <option id="1489668660594" value="MAMA">MAMA</option>
                        <option id="1489668678530" value="MAMA Y VACIAMIENTO AXILAR">MAMA Y VACIAMIENTO AXILAR</option>
                        <option id="1489668699476" value="MAMA (PEZÓN)">MAMA (PEZÓN)</option>
                        <option id="1489668715410" value="MIOMETRIO">MIOMETRIO</option>
                        <option id="1489668730080" value="OVARIO">OVARIO</option>
                        <option id="1489668741409" value="PIEL">PIEL</option>
                        <option id="1489668755334" value="PLACENTA">PLACENTA</option>
                        <option id="1489668769649" value="TROMPA UTERINA">TROMPA UTERINA</option>
                        <option id="1489668782609" value="VAGINA">VAGINA</option>
                        <option id="1489668794098" value="VULVA">VULVA</option>
                        <option id="1500476435835" value="ADENOPATIA">ADENOPATIA</option>
                        <option id="1500476454571" value="PARTES BLANDAS">PARTES BLANDAS</option>
                        <option id="1527503024511" value="CONDUCTO DEFERENTE">CONDUCTO DEFERENTE</option>
                        <option id="1552500322525" value="MUCOSA BUCAL">MUCOSA BUCAL</option>
                        <option id="1631034200622" value="VESICULA BILIAR">VESICULA BILIAR</option>
                        <option id="1631034294087" value="ESTÓMAGO">ESTÓMAGO</option>
                        <option id="1632841627329" value="INTESTINO GRUESO">INTESTINO GRUESO</option>
                        <option id="1632841627330" value="URETRA">URETRA</option>
                        <option id="1632841627331" value="ANO">ANO</option>
                    </Select>
                </Form.Item>
                <Form.Item 
                    name={["biopsiaReceptionData","estudio"]}
                    rules={[{ required: true, message: "Debe seleccionar un estudio"}]}
                    label="Estudio">
                    <Select style={{ width: "100%" }} >
                        <option id="1489666739790" value="BIOPSIA CONSULTA">BIOPSIA CONSULTA</option>
                        <option id="1489666754430" value="PIEZA QUIRÚRGICA">PIEZA QUIRÚRGICA</option>
                        <option id="1489666771438" value="INTRAOPERATORIA">INTRAOPERATORIA</option>
                        <option id="1489666783439" value="AUTOPSIA">AUTOPSIA</option>
                    </Select>
                </Form.Item>
                <Form.Item 
                    name={["biopsiaReceptionData","informacionClinica"]}
                    label="Información clínica" style={{height: formItemHeight}} initialValue="">
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
                <Select 
                    mode="tags" 
                    value={selectedShortCode}
                    onChange={(v)=>{ setSelectedShortCode(v)}}
                    style={{ width: '100%', marginBottom: 5 }} 
                    placeholder="Atajos" 
                    placement='topLeft'
                    onDeselect={(v: string)=>{
                        try{
                            let ob : string = form.getFieldValue("biopsiaReceptionData").informacionClinica;

                            ob = ob.replace(`${v}`, "");

                            form.setFieldsValue({"biopsiaReceptionData":{ "informacionClinica":ob}});

                        }catch(exception){

                        }
                    }} 
                    onSelect={(v: string)=>{
                        let ob : string = form.getFieldValue("biopsiaReceptionData").informacionClinica;

                        if (ob){
                            ob = `${ob} ${v} `;
                        }else{
                            ob = ` ${v} `;
                        }

                        form.setFieldsValue({"biopsiaReceptionData":{ "informacionClinica":ob}});
                    }}
                >
                    {
                        shortCuts.map((s: any) => {
                            return <Select.Option key={s.key} value={s.value}>{s.value}</Select.Option>
                        })
                    }
                </Select>
            </>)
        
});


export default BiopsiaReceptionComponent;
