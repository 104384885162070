import React from 'react'

import * as Components from '../../../components'


interface Props {}

const InvoicesGeneratorPage = (props: Props) : JSX.Element => {


    return (
        <div className="invoices-generator-page">
            <Components.InvoiceGenerator />
        </div>
    )
}

export default InvoicesGeneratorPage;