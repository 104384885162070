import Product from "./Product";

export default interface Company{
    id: number,
    businessName: string,
    cif: string,
    address: string,
    phone1: string,
    phone2: string,
    email: string,
    web: string,
    contactPersonName: string,
    observations: string,
    private: boolean,
    commission: number
    products?: Product[]
}

export const EmptyCompany : Company = {
    id: 0,
    businessName: '',
    cif: '',
    address: '',
    phone1: '',
    phone2: '',
    email: '',
    web: '',
    contactPersonName: '',
    observations: '',
    private: false,
    commission: 0
}
