import { Button, Col, DatePicker, Divider, Form, Input, Radio, Row, Table, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import Patient from '../../../models/Patient';
import PatientSearchComponent from '../search/PatientSearchComponent';
import ApiResponse from '../../../models/api/ApiResponse';
import RestApiClient from '../../../api/restApiClient';

import './MigrationsPatientsComponent.scss'
import PatientResultComponent from '../search/PatientResultComponent';
import ParamsApi from '../../../models/api/ParamsApi';
import CaseReports from '../../../models/CaseReports';
import { AlertTwoTone, ClearOutlined, QuestionCircleOutlined, RotateLeftOutlined, RotateRightOutlined } from '@ant-design/icons';
import { CaseReportStatus } from '../../../models/Constants';
import { PATHOLOGIST_ROLE } from '../../../utils/Constants';
import { LoadingOrText } from '../..';


const { Title } = Typography;


interface Props{}

const MigrationPatientsComponent = (props: Props) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();


    const [originId, setOriginId] = useState<number>(0);
    const [origin, setOrigin] = useState<Patient>();
    const [caseReportsOrigin, setCaseReportsOrigin] = useState<CaseReports[]>([]);

    const [destination, setDestination] = useState<Patient>();
    const [destinationId, setDestinationId] = useState<number>(0);
    const [caseReportsDestination, setCaseReportsDestination] = useState<CaseReports[]>([]);


    const theColumns = [
        {
            title: 'Caso/estudio',
            dataIndex: 'caseReportNum',
            key: 'caseReportNum'
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, element: CaseReports) => renderColumn(element, getStatusTag(element.status)),
        },{
            title: 'Tipo de muestra',
            dataIndex: 'type',
            key: 'type',
            render: (i: number, element: CaseReports) => renderColumn(element, <>{element.product.description}
                <small style={{display:"block"}}>
                    {element.product.type}
                </small>
                </>)
        },
        {
            title: 'Compañia',
            dataIndex: 'company.name',
            key: 'company.name',
            render: (i: number, element: CaseReports) => renderColumn(element, <>
                <span>{element.company.businessName}</span>
                {getReceptionStatusTag(element.reception.status, element.reception.observations)}
            </>)
        }
        ];

    const getStatusTag = (status: CaseReportStatus, customStyles?: any) : JSX.Element => {
        let result : JSX.Element = <></>;

        switch(status){
            case CaseReportStatus.Finished:
                result = <Tag className="case-report-status-tag" color="magenta" style={customStyles}>{status}</Tag>
                break;
            case CaseReportStatus.Processing:
                result = <Tag className="case-report-status-tag" color="gold" style={customStyles}>{status}</Tag>
                break
            case CaseReportStatus.Received:
                result = <Tag className="case-report-status-tag" color="green" style={customStyles}>{status}</Tag>
                break
            case CaseReportStatus.Empty:
                result = <Tag className="case-report-status-tag" color="purple" style={customStyles}>{status}</Tag>
                break;
        }

        return result;
    }

    const getReceptionStatusTag = (status: number, observations: string) : JSX.Element => {
        
        let result : JSX.Element = <></>;
        let color : string = "";
        let text : string = "";

        switch(status){
            case 1:
                color = "#f8ac59";
                text = "Pendiente de autorización";
                break;
            case 2:
                color = "#ed5565";
                text = "Caso con incidencia";
                break;
            case 3:
                color = "#ed7555";
                text = "Rechazado por aseguradora";
                break;
        }

        if (status > 0){
            if (observations && observations.length > 0){
                result = <Tag className={`reception-status-tag`} color={color}><Tooltip title={observations} placement='top'>{text} {observations && observations.length > 0 ? <QuestionCircleOutlined /> : ''}</Tooltip></Tag>
    
            }else{
                result = <Tag className={`reception-status-tag`} color={color}>{text}</Tag>
    
            }
        }

        return result;
    }

    const renderColumn = (element: CaseReports, children: any, addIcon: boolean = false) : any => {
        const isUrgent : boolean = element.keywords !== undefined && element.keywords.length > 0;

        const finalChildren = isUrgent && addIcon ? <>{children} <AlertTwoTone className="icon-urgent" twoToneColor="red"  /></> : children; 

        return {
            props: {
                className:`${isUrgent ? 'case-report-urgent' : ''}`,
            },
            children: finalChildren,
        };
    }


    useEffect(()=>{
        setOrigin(undefined);
        if (originId > 0){
            restApiClient.fetch("GetPatient", {
                query:{
                    id: originId
                }
            })
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setOrigin(r.data);
                }
            })
        }
    },[originId]);

    useEffect(()=>{
        if (origin){
            const paramsApi : ParamsApi = {
                body:{
                    patients: [origin.id],
                    dateRange: ["1900-01-01","3000-01-01"]
                }
            }

            restApiClient.fetch("GetCaseReportsForInvoices", paramsApi)
                .then((r : ApiResponse | null)=> {
                    if (r && r.code === 200){
                        setCaseReportsOrigin(r.data);
                    }
                })
        }
    },[origin]);


    useEffect(()=>{
        setDestination(undefined);
        if (destinationId > 0){
            restApiClient.fetch("GetPatient", {
                query:{
                    id: destinationId
                }
            })
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setDestination(r.data);
                }
            })
        }
    },[destinationId]);

    useEffect(()=>{
        if (destination){
            const paramsApi : ParamsApi = {
                body:{
                    patients: [destination.id],
                    dateRange: ["1900-01-01","3000-01-01"]
                }
            }

            restApiClient.fetch("GetCaseReportsForInvoices", paramsApi)
                .then((r : ApiResponse | null)=> {
                    if (r && r.code === 200){
                        setCaseReportsDestination(r.data);
                    }
                })
        }
    },[destination]);

    const migrate = () : void => {
        if (originId && originId > 0 && destinationId && destinationId > 0){
            const paramsApi : ParamsApi = {
                body:{
                    patientFrom: originId,
                    patientTo: destinationId,
                    deleteAfterMigrate : false
                }
            }
    
            restApiClient.fetch("MigratePatients", paramsApi)
                .then((r : ApiResponse | null)=> {
                    if (r?.code === 200){
                        clearAll();
                    }
                })

        }
    }

    const deleteAndMigrate = () : void => {
        if (originId && originId > 0 && destinationId && destinationId > 0){
            const paramsApi : ParamsApi = {
                body:{
                    patientFrom: originId,
                    patientTo: destinationId,
                    deleteAfterMigrate : true
                }
            }
    
            restApiClient.fetch("MigratePatients", paramsApi)
                .then((r : ApiResponse | null)=> {
                    if (r?.code === 200){
                        clearAll();
                    }
                })

        }
    }

    const clearAll = () : void => {
        setOrigin(undefined);
        setOriginId(0)
        setDestination(undefined);
        setDestinationId(0);
        setCaseReportsDestination([]);
        setCaseReportsOrigin([]);
    }

    return(
        <div className="migration-patients-component">
            <Col xs={24}>
                <div className="b-component-actions">
                    <Button  className="btn-save" type="primary" danger onClick={deleteAndMigrate}>
                        <LoadingOrText loading={false} showWhileLoading={false} text={<><RotateRightOutlined /> Migrar y borrar</>}/>
                    </Button>
                    <Button  className="btn-save" type="primary" onClick={migrate}>
                        <LoadingOrText loading={false} showWhileLoading={false} text={<><RotateRightOutlined /> Migrar</>}/>
                    </Button>
                </div>
            </Col>
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Title level={4}>
                        Paciente Origen
                        <small className="advise">De aquí se copiarán los datos</small>
                    </Title>
                    <Divider className="divider"/>
                    <small>Estos datos son solo informativos. No se pueden actualizar.</small>
                    <div className="patient-search">
                        <PatientSearchComponent onSelect={setOriginId}/>

                        {
                            origin && <PatientResultComponent patient={origin}/>
                        }

                        {
                            caseReportsOrigin && caseReportsOrigin.length>0 && <Table dataSource={caseReportsOrigin} columns={theColumns} />
                        }
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <Title level={4}>
                        Paciente Destino
                        <small className="advise">Aquí se copiarán los datos</small>
                    </Title>
                    <Divider className="divider"/>
                    <small>Estos datos son solo informativos. No se pueden actualizar.</small>
                    <div className="patient-search">
                        <PatientSearchComponent onSelect={setDestinationId}/>
                        {
                            destination && <PatientResultComponent patient={destination}/>
                        }
                        {
                            caseReportsDestination && caseReportsDestination.length>0 && <Table dataSource={caseReportsDestination} columns={theColumns} />
                        }
                    </div>
                </Col>
                <Col xs={24}>
                    <Title level={4}>
                        Resultado final
                        <Divider />
                    </Title>

                    {
                       caseReportsDestination && caseReportsOrigin && <Table dataSource={caseReportsOrigin.concat(caseReportsDestination)} columns={theColumns} />
                    }
                </Col>
            </Row>
            <Col xs={24}>
                <div className="b-component-actions">
                    <Button  className="btn-save" type="primary" danger onClick={deleteAndMigrate}>
                        <LoadingOrText loading={false} showWhileLoading={false} text={<><RotateRightOutlined /> Migrar y borrar</>}/>
                    </Button>
                    <Button  className="btn-save" type="primary" onClick={migrate}>
                        <LoadingOrText loading={false} showWhileLoading={false} text={<><RotateRightOutlined /> Migrar</>}/>
                    </Button>
                </div>
            </Col>
        </div>
    )
}

export default MigrationPatientsComponent;