import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';

import * as Components from '../../../components'

import './EditUser.scss'
import Users from '../../../models/Users';


const EditUserPage = () : JSX.Element => {
  const restApiClient : RestApiClient = new RestApiClient();

  const [user, setUser] = useState<Users>(new Users());


  const { id } = useParams<any>();
  
  const getUserData = () : void => {
    const paramsApi : ParamsApi = {
        query:{
            id: id
        }
    }

    restApiClient.fetch("GetUserById", paramsApi)
        .then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
              setUser(r.data);
            }
        });
  }

  useEffect(()=>{
      if (!isNaN(id)){
        getUserData();
      }
  },[id]);

  return (
    <div className="edit-user-page">
        <Components.UsersManage updateMode={true} userData={user}/>
    </div>
  )
}

export default EditUserPage;

