import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'CaseReports'

export const CaseReportsEP : EndPointConfig[] =[
        new EndPointConfig()
            .setMethod('GET')
            .setController(controller) 
            .setActionName("GetCaseReports"),    
        new EndPointConfig()
            .setMethod('GET')
            .setController(controller) 
            .setActionName("GetCaseReportsForAdvancedSearch"),        
        new EndPointConfig()
            .setMethod('GET')
            .setController(controller) 
            .setActionName("GetCaseReport"),
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller) 
            .setActionName("UpdateCaseReport"),
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller) 
            .setActionName("ValidateCaseReport"),
        new EndPointConfig()
            .setMethod('DELETE')
            .setController(controller) 
            .setActionName("InValidateCaseReport"),
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller)
            .setActionName("SaveCaseReport"),
        new EndPointConfig()
            .setMethod('DELETE')
            .setController(controller)
            .setActionName("DeleteCaseReport"),
        new EndPointConfig()
            .setMethod('PATCH')
            .setController(controller)
            .setActionName("UndeleteCaseReport"),
        new EndPointConfig()
            .setMethod('GET')
            .setController("Pdf")
            .setActionName("GeneratePdfTest"),
        new EndPointConfig()
            .setMethod('GET')
            .setController("Pdf")
            .setActionName("GeneratePdfTestBrowser"),
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller)
            .setActionName("GetCaseReportsForInvoices"),
        new EndPointConfig()
            .setMethod('PUT')
            .setController(controller)
            .setActionName("ChangeCaseReportPayedStatus"),
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller)
            .setActionName("GetCaseReportToMassivePreformmated"),
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller)
            .setActionName("MassiveCaseReportPreformatted"),
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller)
            .setActionName("MassiveCaseReportValidated"),
        new EndPointConfig()
            .setMethod('PATCH')
            .setController(controller)
            .setActionName("UpdateSpecialTag"),
        
]