import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Patients'

export const PatientsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetPatientsBasic"),
     new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetPatient"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetPatients"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("AddPatient"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller) 
        .setActionName("UpdatePatient"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("MigratePatients"),
        
]