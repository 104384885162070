import ContainerPage from './container/PageContainer'
import LoginPage from './auth/login/login'
import LockScreenPage from './auth/lockscreen/lockscreen'
import DashBoardPage from './dashboard/dashboard'
import ProfilePage from './user/profile'
import RegisterPage from './register/Register'
import RequestPage from './requests/Requests'
import RequestListPage from './requests/list/RequestsList'
import CompaniesPage from './companies/Companies'
import AddCompanyPage from './companies/add/AddCompany'
import EditCompanyPage from './companies/edit/EditCompany' 
import UsersPage from './users/Users'
import AddUserPage from './users/add/AddUser' 
import EditUserPage from './users/edit/EditUser'
import PatientsPage from './patients/patients'
import EditPatientPage from './patients/edit/EditPatient'
import AddPatientPage from './patients/add/AddPatient'
import CaseReportEditPage from './case-reports/edit/CaseReportEditPage'
import PreformattedPage from './preformatted/preformatteds'
import PreformattedEditPage from './preformatted/edit/preformattedEdit'
import PreformattedAddPage from './preformatted/add/preformattedAdd'
import InvoicesPage from './invoices/list/InvoicesPage'
import InvoicesGeneratorPage from './invoices/generator/InvoicesGeneratorPage'
import MigrationPatientsPage from './migrations-patients/MigrationPatientsPage'
import Page404 from './auth/404/404'
import Page500 from './auth/500/500'
import KeywordsPage from './keywords/KeywordsPage'
import StatisticsPage from './statistics/StatisticsPage'
import ArchivedPage from './archived/Archived'
import ConfigurationsPage from './configurations/ConfigurationsPage'
import AdvancedStatisticsPage from './statistics/AdvancedStatisticsPage'
import AdvancedSearchPage from './advanced-search/AdvancedSearchPage'

export const Container = ContainerPage;
export const Login = LoginPage
export const LockScreen = LockScreenPage 
export const DashBoard = DashBoardPage
export const Profile = ProfilePage
export const Register = RegisterPage
export const Requests = RequestPage
export const RequestList = RequestListPage
export const Companies = CompaniesPage
export const AddCompany = AddCompanyPage
export const EditCompany = EditCompanyPage
export const Users = UsersPage
export const AddUser = AddUserPage
export const EditUser = EditUserPage
export const Patients = PatientsPage
export const EditPatient = EditPatientPage
export const AddPatient = AddPatientPage
export const CaseReportEdit = CaseReportEditPage
export const Preformatted = PreformattedPage
export const PreformattedEdit = PreformattedEditPage
export const PreformattedAdd = PreformattedAddPage
export const Invoices = InvoicesPage;
export const InvoicesGenerator = InvoicesGeneratorPage;
export const MigrationPatients = MigrationPatientsPage;
export const Statistics = StatisticsPage;
export const AdvancedStatistics = AdvancedStatisticsPage;
export const Keywords = KeywordsPage
export const Archived = ArchivedPage;
export const Configurations = ConfigurationsPage;
export const AdvancedSearch = AdvancedSearchPage;
export const Error404 = Page404;
export const Error500 = Page500;
