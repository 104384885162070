import React, { forwardRef, useEffect, useState } from 'react'
import { Form } from 'antd'

//https://github.com/zenoamaro/react-quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


interface IHQMolecularProps {
    reset: boolean,
    form: any,
    values?:{
        diagnosticoAnatomopatologico:string,
        evaluacionMuestra:string,
        nota:string
    }
}


// https://stackoverflow.com/questions/37949981/call-child-method-from-parent
const IHQMolecularDiagnosisComponent = forwardRef((props: IHQMolecularProps, ref: any)=>{
    const formItemHeight : string = "300px";
    const textAreaHeight : string = "250px";

    const [reset, setReset] = useState<boolean>(false);

    const { form, values } = props;

    useEffect(()=>{
        if(props.reset){
            setReset(true);
        }
    },[props])

    
    useEffect(()=>{
        if(reset){
            form.setFieldsValue({
                        "ihqMolecularDiagnosisData":{ 
                            "diagnosticoAnatomopatologico":"", 
                            "evaluacionMuestra":"", 
                            "nota":""}});
            setReset(false);
        }
    },[reset])

    useEffect(()=>{
        if(values){
            form.setFieldsValue({
                    "ihqMolecularDiagnosisData":{ 
                        "diagnosticoAnatomopatologico":values.diagnosticoAnatomopatologico ?? "", 
                        "evaluacionMuestra":values.evaluacionMuestra ?? "", 
                        "nota":values.nota ?? ""
                    }
                });
        }else{
            form.setFieldsValue({
                "ihqMolecularDiagnosisData":{ 
                    "diagnosticoAnatomopatologico":"", 
                    "evaluacionMuestra":"", 
                    "nota":""}});
        }
    },[values])

    return (<>
                <Form.Item 
                    name={["ihqMolecularDiagnosisData","evaluacionMuestra"]}
                    label="Evaluación de la muestra" style={{height: formItemHeight}}>
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
                <Form.Item 
                    name={["ihqMolecularDiagnosisData","diagnosticoAnatomopatologico"]}
                    label="Diagnóstico anatomopatológico" style={{height: formItemHeight}}>
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
                <Form.Item 
                    name={["ihqMolecularDiagnosisData","nota"]}
                    label="Notas" style={{height: formItemHeight}}>
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
    </>)
        
});


export default IHQMolecularDiagnosisComponent;