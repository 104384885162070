import React, { useContext } from 'react'

import { Alert, Col, DatePicker, Divider, Form, Input, Radio, Row } from 'antd';
import moment from 'moment';
import { LoginContext } from '../../../../hooks/context/userContext';
import { ADMIN_ROLE } from '../../../../utils/Constants';

interface Props {
    isEdit: boolean,
    expiredPassword: boolean 
}
const AccessData = (props: Props) : JSX.Element => {
  let loginContext = useContext(LoginContext);

    return(
        <Row gutter={16}>
          <Col xs={24}>
            <Divider orientation="left">Acceso al sistema</Divider>
          </Col>
          
          <Col xs={24}>
            <Form.Item name="banned" initialValue={0} label="¿Baneado?. Si un usuario se encuentra baneado no podrá iniciar sesión." rules={[{ required: !props.isEdit, message: "Campo obligatorio"}]}>
                <Radio.Group >
                    <Radio value={0}>No</Radio>
                    <Radio value={1}>Si</Radio>
                </Radio.Group>
            </Form.Item>
          </Col>
          {
            loginContext.idRole == ADMIN_ROLE ? 
              <Col xs={24}>
                <Form.Item name="username" help={`Nombre de usuario de acceso al sistema.`} label="Nombre de usuario de acceso" rules={[{ required: true, message: "Campo obligatorio"}]}>
                    <Input />
                </Form.Item>
              </Col>
            :
            ''
          }
          <Col xs={24}>
            <Form.Item name="password" help={`Contraseña de acceso al sistema. ${props.isEdit ? 'Dejar en blanco para mantener la actual.' : ''}`} label="Contraseña de acceso" rules={[{ required: !props.isEdit, message: "Campo obligatorio"}]}>
                <Input.Password />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="passwordCaducity" help={`Fecha de caducidad en el sistema.`} label="Fecha de caducidad" rules={[{ required: !props.isEdit, message: "Campo obligatorio"}]}>
                <DatePicker style={{width: "100%"}} format="YYYY-MM-DD"/>
            </Form.Item>
          </Col>
          <Col xs={24}>
            {
              props.expiredPassword ?  <Alert message="Password caducado." type="error" showIcon style={{marginBottom: "5px"}} /> : ''
            }
            <Alert message="Recuerde que la contraseña caduca de manera automática cada 3 meses." type="info" showIcon />
          </Col>
        </Row>
    )
}

export default AccessData;