import { Button, Checkbox, Col, Divider, notification, Row } from 'antd';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react'

import * as Components from '../../../components'
import * as RequestComponents from '../../../components/requests/components'
import Doctor from '../../../models/Doctor';
import Request from '../../../models/Request';

import RestApiClient from '../../../api/restApiClient';
import { LoginContext } from '../../../hooks/context/userContext';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';

import './RequestsList.scss'
import { getItemStatus } from '../../../utils/RequestsUtils';
import { useHistory } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

interface RequestsListProps {}

const RequestsListPage = (props : RequestsListProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    let loginContext = useContext(LoginContext);
    
    const history = useHistory();

    const eventSelectorRef = useRef();

    // Profesional seleccionado
    const [professional, setProfessional] = useState<Doctor>();

    // Fecha Seleccionada
    const [selectedDate, setSelectedDate] = useState<any>(moment().add(1, "day"));
    // Tipo de solicitudes seleccionadas Seleccionados
    const [selectedsTypeRequests, setSelectedsTypeRequests] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [refreshData, setRefreshData] = useState<number>(0);


    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render(i: number, element: Request){
                let result = <span>{element.id}</span>
                
                return result;
            }
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            render(i: number, element: Request) {
                let result = <span className="status-icon">{getItemStatus(element.status)} {element.requestType.description}</span>
                
                if (["procesando", "finalizado"].indexOf(element.status) !== -1){
                    result = <span className="status-icon">{getItemStatus(element.status)} <Button type="dashed" onClick={()=>{history.push(`/dashboard/solicitud:${element.id}`)}}>{element.requestType.description} <EyeOutlined /></Button></span>
                }
                
                return result;
            }
        },
        {
            title: 'F. Solicitud',
            dataIndex: 'requestDate',
            key: 'requestDate',
            render(i: number, element: Request) {
                let result : any = <>{moment(element.requestDate).format("DD/MM/YYYY")} <small className='timeline'>{moment(element.requestDate).fromNow()}</small></>
                return result;
            }
        },
        {
            title: 'F. Indicada',
            dataIndex: 'start',
            key: 'start',
            render(i: number, element: Request) {
                let result : any = <>{moment(element.start).format("DD/MM/YYYY")} <small className='timeline'>{moment(element.start).fromNow()}</small></>
                return result;
            }
        }
    ];


    const saveRequest = () : void => {
        if (selectedDate && selectedsTypeRequests && selectedsTypeRequests.length > 0){
            
            const finalSelectedsTypeRequestsIds : number[] = selectedsTypeRequests.map((r: any) => {
                const id : number = r.split("#")[0];
                return id;
            });

            const paramsApi : ParamsApi = {
                body:{
                    requestType:finalSelectedsTypeRequestsIds,
                    userId: loginContext.id,
                    selectedDate: moment(selectedDate).format("YYYY-MM-DD"),
                    sendEmailNotification: false
                }
            }
    
            setLoading(true);
            restApiClient.fetch("AddRequests", paramsApi)
                .then((r : ApiResponse | null)=> {
                    if (r && r.code === 200){
                        setRefreshData(refreshData + 1);
                        setSelectedsTypeRequests([]);
                        
                        var item : any = eventSelectorRef.current;
                        item?.clearFields();
                    }
                }).finally(()=>{
                    setLoading(false);
                })
        }else{
            notification.warn({message:"Atención", description:"Compruebe que ha rellenado el formulario correctamente"})
        }
        
    }


    return (
        <Row gutter={16} className="request-list">
            <Col xs={24}>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={20}>
                        <RequestComponents.EventSelector onSelectDate={setSelectedDate} onSelectEvents={setSelectedsTypeRequests} ref={eventSelectorRef}/>
                    </Col>
                    <Col xs={24} sm={24} md={4}>
                        <div className="box-component">
                            <Divider orientation="left">Acciones</Divider>
                            <Button type="primary" className="btn-save-request" onClick={saveRequest}>
                                <Components.LoadingOrText loading={loading} text="Registrar"/>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <Components.TablePagination forceRefresh={refreshData} columns={columns} entityName='requests' aliasEndPoint='GetRequestsFiltered' title="Listado de solicitudes" paramsEndPoint={{sessionId: window.sessionStorage.getItem("token")}}/>
            </Col>
        </Row>
    )
}


export default RequestsListPage;