import React, { useState, useEffect, useRef } from 'react'

import { Button, Drawer, Form, Input, Select, AutoComplete, Divider, Alert } from 'antd'

import ApiResponse from '../../models/api/ApiResponse';
import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';

import * as Components from '../index'
import Doctor from "../../models/Doctor";
import Patient from '../../models/Patient';
import Company from '../../models/Company';

import './QuickEditComponent.scss';
import CaseReports from '../../models/CaseReports';
import Pathologist from '../../models/Pathologist';
import moment from 'moment';
import Title from 'antd/lib/typography/Title';
import Product from '../../models/Product';
import LoadingOrComponent from '../core/utils/LoadingOrComponent';

const { Option } = Select;

interface QuickEditComponentProps{
    visible: boolean,
    onclose: any,
    casereport?: CaseReports,
    callback: any,
}


const QuickEditComponent =(props : QuickEditComponentProps):JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const { visible, onclose, casereport } = props;

    const [form] = Form.useForm();
    const [showAddPatient, setShowAddPatient] = useState<boolean>(false);


    const [updatingStatus, setUpdatingStatus] = useState<boolean>(false);
    const [updatingVisibility, setUpdatingVisibility] = useState<boolean>(false);
    const [updatingPrice, setUpdatingPrice] = useState<boolean>(false);
    const [updatingDoctor, setUpdatingDoctor] = useState<boolean>(false);


    const [loadingCompanies, setLoadingcompanies] = useState<boolean>(false);
    const [companyOptions, setCompanyOptions] = useState<Company[]>([]);

    const [loadingPaying, setLoadingPaying] = useState<boolean>(false);
    const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
    const [productOptions, setProductOptions] = useState<Product[]>([]);
    const [updatingProduct, setUpdatingProduct] = useState<boolean>(false);
    
    const [loadingPathologist, setLoadingPathologist] = useState<boolean>(false);
    const [pathologistOptions, setPathologistOptions] = useState<Doctor[]>([]);
    const [updatingPathologist, setUpdatingPathologist] = useState<boolean>(false);

    const [loadingPatients, setLoadingPatients] = useState<boolean>(false);
    const [patientOptions, setPatientOptions] = useState<Patient[]>([]);
    const [updatingPatient, setUpdatingPatient] = useState<boolean>(false);
    const [noChangePatient, setNoChangePatient] = useState<boolean>(true);

    const [doctorOptions, setDoctorOptions] = useState<Doctor[]>([]);
    const [loadingDoctors, setLoadingDoctors] = useState<boolean>(false);

    const [updatingSpecialTag, setUpdatingSpecialTag] = useState<boolean>(false);
    const [specialTags, setSpecialTags] = useState<any[]>([]);


    const getSpecialTags = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                page: 0,
                N: 0,
                filter: 'SpecialTags', 
            }
        }
        restApiClient.fetch("GetOptions", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setSpecialTags(r.data.elements);
                }
            })
    }

    const getDoctors = () : void =>{
        const paramsApi : ParamsApi = {
            query:{
                filter: "",
                counters: false,
                billingInfo: false
            }
        }
        setLoadingDoctors(true);
        restApiClient.fetch("GetDoctors", paramsApi) 
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setDoctorOptions(r.data);
                }
            })
            .finally(()=>{
                setLoadingDoctors(false);
            })
    }

    const getCompanies = (filter: string) : void => { 
        const paramsApi : ParamsApi = {
            query:{
                filter: filter
            }
        }
        setLoadingcompanies(true);
        restApiClient.fetch("GetAllCompanies", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setCompanyOptions(r.data.elements);
                }
            })
            .finally(()=>{
                setLoadingcompanies(false);
            })
    }

    const GetCompanyProducts = (id: number) : void => {
        const paramsApi : ParamsApi = {
            query:{
                companyId: id
            }
        }
        setLoadingProducts(true);
        restApiClient.fetch("GetCompanyProducts", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setProductOptions(r.data.elements);
                }
            })
            .finally(()=>{
                setLoadingProducts(false);
            })
    }

    const getPathologist = (filter: string) : void => { 
        const paramsApi : ParamsApi = {
            query:{
                filter: ''
            }
        }
        setLoadingPathologist(true);
        restApiClient.fetch("GetPathologist", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setPathologistOptions(r.data);
                }
            })
            .finally(()=>{
                setLoadingPathologist(false);
            })
    }

    const getPatients = (filter: string) : void => { 
        const paramsApi : ParamsApi = {
            query:{
                page:0,
                N:999999,
                filter: filter
            }
        }
        setLoadingPatients(true);
        restApiClient.fetch("GetPatientsBasic", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setPatientOptions(r.data.elements);
                    if (casereport && r.data.elements.indexOf((p: Patient)=>{
                        return p.id === casereport.patient.id
                    }) !== -1){
                        form.setFieldsValue({
                            patient: casereport.patient.id
                        })
                    }
                }
            })
            .finally(()=>{
                setLoadingPatients(false);
            })
    }

    const chargeNewPatient = (patient: Patient) : void => {
        if (patient){
            setPatientOptions([patient]);
            form.setFieldsValue({patient:patient.id});
            setShowAddPatient(false);
        }
    }

    const updatePathologist = () : void =>{
        const paramsApi : ParamsApi = {
            body:{
                id: casereport?.reception.id,
                idPathologist: form.getFieldValue("pathologist")
            }
        }
        setUpdatingPathologist(true);
        restApiClient.fetch("UpdatePathologist", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200 && casereport){
                    casereport.pathologist.id = form.getFieldValue("pathologist");
                }
            })
            .finally(()=>{
                setUpdatingPathologist(false);
            })
    }

    const [updatingObservations, setUpdatingObservations] = useState<boolean>(false);

    const updateObservations = () : void => {
        const paramsApi : ParamsApi = {
            body:{
                id: casereport?.reception.id,
                pvp: -1,
                observations: form.getFieldValue('observations') ?? casereport?.reception.observations
            }
        }

        setUpdatingObservations(true);
        restApiClient.fetch("UpdateObservations", paramsApi).finally(()=>{
            setUpdatingObservations(false);
        });
    }

    const updatePatient = () : void =>{
        const paramsApi : ParamsApi = {
            body:{
                id: casereport?.reception.id,
                idPatient: form.getFieldValue('patient') ?? casereport?.patient.id
            }
        }
        setUpdatingPatient(true);
        restApiClient.fetch("UpdateReceptionPatient", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200 && casereport){
                    casereport.patient.id = form.getFieldValue('patient');
                    getPatients(`paciente:${form.getFieldValue('patient')}`);
                    setNoChangePatient(true);
                    props.callback();
                }
            })
            .finally(()=>{
                setUpdatingPatient(false);
            })
    }

    const updateStatus = () : void =>{
        const paramsApi : ParamsApi = {
            body:{
                id: casereport?.reception.id,
                status: form.getFieldValue('status')
            }
        }
        setUpdatingStatus(true);
        restApiClient.fetch("UpdateReceptionStatus", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200 && casereport){
                    casereport.status = form.getFieldValue('status');
                    props.callback();
                }
            })
            .finally(()=>{
                setUpdatingStatus(false);
            })
    }

    const updatePvp = () : void =>{
        const paramsApi : ParamsApi = {
            body:{
                id: casereport?.reception.id,
                pvp: form.getFieldValue('pvpMoment')
            }
        }

        setUpdatingPrice(true);
        restApiClient.fetch("UpdatePvpMoment", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200 && casereport){
                    casereport.reception.pvpMoment = form.getFieldValue('pvpMoment');
                    props.callback();
                }
            })
            .finally(()=>{
                setUpdatingPrice(false);
            })
    }

    const updatePayStatus = () : void =>{
        const paramsApi : ParamsApi = {
            body:{
                caseReportId: casereport?.id,
                payedStatus: form.getFieldValue('payStatus')
            }
        }
        setLoadingPaying(true);
        restApiClient.fetch("ChangeCaseReportPayedStatus", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200 && casereport){
                    casereport.payed = form.getFieldValue('payStatus');
                    props.callback();
                }
            })
            .finally(()=>{
                setLoadingPaying(false);
            })
    }

    const updateProduct = () : void =>{
        const paramsApi : ParamsApi = {
            body:{
                id: casereport?.reception.id,
                idProduct: form.getFieldValue('product')
            }
        }
        setUpdatingProduct(true);
        restApiClient.fetch("UpdateReceptionProduct", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200 && casereport){
                    casereport.product.id = form.getFieldValue('product');
                    props.callback();
                }
            })
            .finally(()=>{
                setUpdatingProduct(false);
            })
    }

    const updateDoctor = () : void =>{
        const paramsApi : ParamsApi = {
            body:{
                idRequest: casereport?.reception.request.id,
                idDoctor: form.getFieldValue('doctor')
            }
        }

        restApiClient.fetch("ChangeRequestDoctor", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200 && casereport){
                    casereport.deleteLogic = form.getFieldValue('deleted') == 1;
                    props.callback();
                }
            })
            .finally(()=>{
                setUpdatingVisibility(false);
            })
    }

    const updateSpecialTag = () : void => {
        const specialTag = form.getFieldValue("specialTag");

        const paramsApi : ParamsApi = {
            query:{
                id: casereport?.id,
                specialTag: specialTag
            }
        }

        setUpdatingSpecialTag(true);
        restApiClient.fetch('UpdateSpecialTag', paramsApi)
            .then((r : ApiResponse | null)=> {
                if (casereport){
                    casereport.specialTag = specialTag;
                }
            })
            .finally(()=>{
                setUpdatingSpecialTag(false);
            })
    }

    const updateVisibility = () : void =>{

        const deleteCaseReport : boolean = form.getFieldValue('deleteLogic') == 1;

        const paramsApi : ParamsApi = {
            query:{
                id: casereport?.id
            }
        }

        setUpdatingVisibility(true);

        var ep : string = deleteCaseReport ? "DeleteCaseReport" : "UndeleteCaseReport";   


        restApiClient.fetch(ep, paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200 && casereport){
                    casereport.deleteLogic = form.getFieldValue('deleted') == 1;
                    props.callback();
                }
            })
            .finally(()=>{
                setUpdatingVisibility(false);
            })
    }



    useEffect(()=>{
        form.resetFields();
        if(casereport && casereport?.company.id){
            getPatients(`paciente:${casereport.patient.id}`);
            GetCompanyProducts(casereport?.company.id);
        }
    },[casereport]);

    useEffect(()=>{
        getCompanies('');
        getPathologist('');
        getDoctors();
        getSpecialTags();
    },[]);



    return (
        <Drawer
            title={`Edición rápida: ${casereport?.caseReportNum}`}
            closable={true}
            onClose={()=>{onclose(false)}}
            visible={visible}
            className="quick-edit"
            >

            <Drawer 
                title="Añadir paciente" 
                closable={false} 
                onClose={()=>{setShowAddPatient(false);}} 
                visible={showAddPatient}
                className='add-patient-drawer' 
            >
                <Components.ManagePatient clear onFinish={chargeNewPatient} editMode={false}/>
            </Drawer>
            
            <Form form={form} layout="vertical" className='quick-edit-form'>
                

                <Form.Item name="pathologist" initialValue={casereport?.pathologist.id} label="Cambio de patólogo" required className="app-form-item" >
                    <Select style={{ width: "100%" }} loading={loadingPathologist} 
                            placeholder={`Actual: ${casereport?.pathologist.name} ${casereport?.pathologist.surname} ${casereport?.pathologist.secondSurname}`}
                    >
                        {
                            pathologistOptions.map((d: Pathologist, i: number)=>{
                                return <Select.Option key={i} value={d.id}>{d.id} - {d.name} {d.surname} {d.secondSurname}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Button type="primary" size="small" className="btn-update" disabled={loadingPathologist} onClick={updatePathologist}>
                    <LoadingOrComponent loading={updatingPathologist} text={`Actualizar patólogo`} loadingText={`Actualizando...`}/>                
                </Button>

                <Form.Item name="patient" label="Cambio de paciente" required className="app-form-item" >
                    <Select
                        onSearch={getPatients}
                        showSearch
                        style={{ width: "100%" }}
                        onChange={()=>{setNoChangePatient(false)}}
                        placeholder={`${casereport?.patient.name} ${casereport?.patient.surname} ${casereport?.patient.secondSurname}`}
                        filterOption={false}
                        defaultValue={casereport?.patient.id}
                        dropdownRender={(menu:any) => (
                            <div>
                                {menu ? menu : ''}
                                <Divider style={{ margin: '4px 0' }} />
                                {
                                    <div className="footer-add-patient">
                                        <Title level={5}>¿No encuentra el paciente?, puedes añadirlo desde aquí <Button type="primary" size="small" onClick={()=>{setShowAddPatient(true)}}>Crear nuevo</Button></Title>
                                    </div>
                                }
                            </div>
                        )}
                    >
                        {
                            patientOptions.map((p: Patient) => {
                                return <Option key={p.id} value={p.id}>{p.id} - {p.name} {p.surname} {p.secondSurname} - {p.birthDate ? moment(p.birthDate).format("DD/MM/YYYY") : 'N/D'}</Option>
                            })
                        }
                    </Select>
                     
                </Form.Item>
                <Button type="primary" size="small" className="btn-update" onClick={updatePatient}>
                    <LoadingOrComponent loading={updatingPatient} text={`Actualizar paciente`} loadingText={`Actualizando...`}/>
                </Button>

                <Form.Item name="status" label="Cambio de estado" required className="app-form-item" initialValue={casereport?.reception.status}>
                    <Select showSearch>
                        <Option value={0}>Autorizado</Option>
                        <Option value={1}>Pendiente de autorización</Option>
                        <Option value={2}>Caso con incidencia</Option>
                        <Option value={3}>Rechazado por aseguradora</Option>
                    </Select>
                </Form.Item>
                <Button type="primary" size="small" className="btn-update" onClick={updateStatus}>
                    <LoadingOrComponent loading={updatingStatus} text={`Actualizar estado`} loadingText={`Actualizando...`}/>
                </Button>

                <Form.Item name="observations" label="Observaciones" required className="app-form-item" initialValue={casereport?.reception.observations}>
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Button type="primary" size="small" className="btn-update" onClick={updateObservations} disabled={updatingObservations}>
                    <LoadingOrComponent loading={updatingObservations} text={`Actualizar`} loadingText={`Actualizando...`}/>
                </Button>

                <Divider orientation='left'>Cambio de producto</Divider>

                <Form.Item name="company" initialValue={casereport?.company.id} label="Compañia" required className="app-form-item" >
                    <Select defaultValue={casereport?.company.id} style={{ width: "100%" }} 
                            loading={loadingCompanies} 
                            disabled={casereport?.payed == "si" || casereport?.applyInvoiceNumber !== null}
                            placeholder={`Actual: ${casereport?.company.businessName}`}
                            onChange={(item:any)=>{
                                GetCompanyProducts(item);
                            }}
                            >
                        {
                            companyOptions.map((d: Company, i: number)=>{
                                return <Select.Option value={d.id} key={i}>{d.id} - {d.businessName}</Select.Option>
                            })
                        } 
                    </Select>
                </Form.Item>
                <Form.Item name="product" initialValue={casereport?.product.id} label="Producto" required className="app-form-item" tooltip={"Al modificar el producto de una recepción se actualizará también el precio."}>
                    <Select disabled={loadingCompanies || !productOptions || productOptions.length == 0 || casereport?.payed == "si" || casereport?.applyInvoiceNumber !== null} defaultValue={!productOptions ? "" : casereport?.product.id} style={{ width: "100%" }} loading={loadingCompanies} placeholder={`Actual: ${casereport?.product.description}`}>
                        {
                            productOptions.filter((product:Product)=>{ return product.type == casereport?.product.type}).map((p: Product, i: number)=>{
                                return <Select.Option value={p.id} key={i}>{p.id} - {p.description} ({p.price}€/{p.reducedPrice}€)</Select.Option>
                            })
                        } 
                    </Select>
                </Form.Item>
                <Button type="primary" size="small" className="btn-update" disabled={loadingProducts || casereport?.payed == "si" || casereport?.applyInvoiceNumber !== null} onClick={updateProduct}>
                    <LoadingOrComponent loading={updatingProduct} text={`Actualizar producto`} loadingText={`Actualizando...`}/>
                </Button>

                <Divider orientation='left'>Cambio de estado de pago o precio</Divider>
                {
                    casereport?.applyInvoiceNumber ? 
                        <Alert style={{ textAlign:"left" }} type="info" showIcon description={<>Este caso de estudio está incluido en la factura número <strong>{casereport?.applyInvoiceNumber}</strong></>} />
                    :
                        ''
                }
                <Form.Item name="payStatus" initialValue={casereport?.payed} label="Pagado" required className="app-form-item" >
                    <Select defaultValue={casereport?.payed} style={{ width: "100%" }} 
                            placeholder={`Actual: ${casereport?.payed}`}
                            disabled={loadingPaying || casereport?.payed == "si" || casereport?.applyInvoiceNumber !== null}
                            >
                            <Select.Option value={'si'} key={'p-s'}>Si</Select.Option>
                            <Select.Option value={'no'} key={'p-n'}>No</Select.Option>
                    </Select>
                </Form.Item>
                <Button type="primary" size="small" className="btn-update" disabled={loadingPaying || casereport?.payed == "si" || casereport?.applyInvoiceNumber !== null} onClick={updatePayStatus}>
                    <LoadingOrComponent loading={loadingPaying} text={`Actualizar pagado`} loadingText={`Actualizando...`}/>
                </Button>

                <Form.Item name="pvpMoment" label="Cambio de precio" required initialValue={(casereport?.reception.pvpMoment ? (Math.round(casereport?.reception.pvpMoment * 100 ) / 100) : casereport?.reception.pvpMoment )} className="app-form-item" tooltip={`El precio solo se actualizará para la recepción #${casereport?.reception.id}`}>
                    <Input type="number" suffix={`€`} min={0} max={999999} disabled={casereport?.payed == "si" || casereport?.applyInvoiceNumber !== null}/>
                </Form.Item>
                <Button type="primary" size="small" className="btn-update" onClick={updatePvp} disabled={casereport?.payed == "si" || casereport?.applyInvoiceNumber !== null}>
                    <LoadingOrComponent loading={updatingPrice} text={`Actualizar precio`} loadingText={`Actualizando...`}/>
                </Button>
                
                <Divider orientation='left'>Modificación Médico solictante</Divider>
                <Form.Item name="doctor" label="Médico" required className="app-form-item" initialValue={casereport?.reception.request.user.id} help={`Esta acción no puede revertirse.`} tooltip={`Al cambiar el médico de este caso de estudio se modificará también el médico a todos los casos de estudio asociados a la solicitud.`}>
                    <Select showSearch>
                        {
                            doctorOptions.map((d: Doctor) => {
                                return <Option key={d.id} value={d.id}>{d.id} - {d.name} {d.surname} {d.secondSurname}</Option>
                            })
                        }
                    </Select>
                </Form.Item>        
                <Button danger size="small" className="btn-update" onClick={updateDoctor}>
                    <LoadingOrComponent loading={updatingDoctor} text={`Actualizar médico`} loadingText={`Actualizando...`}/>
                </Button>

                <Divider orientation='left'>Modificación etiqueta especial</Divider>
                <Form.Item name="specialTag" label="Etiqueta especial" required className="app-form-item" initialValue={casereport?.specialTag}>
                    <Select showSearch>
                        {
                            specialTags?.map((data: any)=>{
                                return <Option value={data.value} key={data.id}>{data.key}</Option>
                            })
                        }
                    </Select>
                </Form.Item>        
                <Button danger size="small" className="btn-update" onClick={updateSpecialTag}>
                    <LoadingOrComponent loading={updatingSpecialTag} text={`Actualizar EE`} loadingText={`Actualizando...`}/>
                </Button>
                
                <Divider orientation='left'>Eliminación</Divider>
                <Form.Item name="deleteLogic" label="Borrado lógico" required className="app-form-item" initialValue={casereport?.deleteLogic ? 1 : 0} help={`Esta acción solo podrá revertirse desde la sección de archivo.`} tooltip={`Al poner el estado en borrado el caso de estudio dejará de estar disponible en el listado`}>
                    <Select showSearch>
                        <Option key="status-visible" value={0}>Visible</Option>
                        <Option key="status-oculto" value={1}>Oculto</Option>
                    </Select>
                </Form.Item>        
                <Button danger size="small" className="btn-update" onClick={updateVisibility}>
                    <LoadingOrComponent loading={updatingVisibility} text={`Actualizar visibilidad`} loadingText={`Actualizando...`}/>
                </Button>
            </Form>

        </Drawer>
    )
}


export default QuickEditComponent;
