import { Form, Button, Alert, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import AccessData from './modules/AccessData';
import BillingData from './modules/BillingData';
import ContactData from './modules/ContactData';
import OtherData from './modules/OtherData';
import { useHistory } from "react-router-dom";

import ParamsApi from '../../../models/api/ParamsApi';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';

import './UsersManageComponent.scss'
import moment from 'moment';
import User from '../../../models/Users';
import { DOCTOR_ROLE } from '../../../utils/Constants';


interface Props{
  updateMode: boolean
  userData?: User
}

const UsersManageComponent = (props: Props) : JSX.Element => {
  const history = useHistory();

  const restApiClient : RestApiClient = new RestApiClient();

  const [form] = Form.useForm();

  const [goExitOnFinish, setGoExitOnFinish] = useState<boolean>(true);
  const [expiredPassword, setExpiredPassword] = useState<boolean>(false);


  const setExitOnFinish = (goExitOnFinish:boolean) : void => {
    setGoExitOnFinish(goExitOnFinish);
  }

  const onFinish = (values: any) : void => {
    const paramsApi : ParamsApi = {
      body:values
    }

    if (props.updateMode){
      paramsApi.body.id = props.userData?.id;
    }

    paramsApi.body.banned = form.getFieldValue("banned") == 1;
    paramsApi.body.applyReducedPrice = form.getFieldValue("applyReducedPrice") == 1;

    const ep : string = props.updateMode ? "UpdateUser" : "AddUser";

    restApiClient.fetch(ep, paramsApi)
        .then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
                if (!props.updateMode){
                    onReset();
                }
                if (goExitOnFinish){
                    history.push("/users")
                }
            }
        });
  }

  const onReset = () : void => {
    form.resetFields();
  }

  const onSendWelcomeEmail = () : void => {
    const paramsApi : ParamsApi = {
      query:{
        userId: props.userData?.id,
        isNew: true
      }
    }

    restApiClient.fetch("PrepareWelcomePlatformEmail", paramsApi);
  }

  const onSendMigrationEmail = () : void => {
    const paramsApi : ParamsApi = {
      query:{
        userId: props.userData?.id,
        isNew: false
      }
    }

    restApiClient.fetch("PrepareWelcomePlatformEmail", paramsApi);
  }

  const getAlertRGPD = () : JSX.Element => {
    let result : JSX.Element = <></>;

    if (props.updateMode && props.userData) {
        if (!props.userData.acceptGDPR){
          result = <Alert className="alert-rgpd" message="El usuario aún no ha aceptado las políticas del RGPD." type="warning" showIcon closable />
        }else{
          result = <Alert className="alert-rgpd" message={`Aceptó las políticas del RGPD ${moment(props.userData?.acceptGdprDate).format("DD/MM/YYYY HH:mm:ss")}`} type="success" showIcon closable />
        }
    }
    return result;
  }

  const getActionButtons = () : JSX.Element => {
    let result : JSX.Element = <div className="b-component-actions">
                                  <Button className="btn-save" type="default" onClick={()=>{history.push("/users")}}>
                                      Volver al listado
                                  </Button>
                                  <Button className="btn-save" type="default" onClick={()=>{setExitOnFinish(false);}}  htmlType="submit">
                                      {props.updateMode ? "Actualizar": "Guardar"} y quedarse
                                  </Button>
                                  <Button className="btn-save" onClick={()=>{setExitOnFinish(true);}} type="primary" htmlType="submit">
                                      {props.updateMode ? "Actualizar": "Guardar"} y salir
                                  </Button>
                                  <Button className="btn-reset" htmlType="button" onClick={onReset}>
                                      Limpiar campos
                                  </Button>
                                  {
                                    (props.updateMode && props.userData && props.userData?.idRole) == DOCTOR_ROLE ? 
                                      <>
                                        <Button className="btn-reset" danger htmlType="button" onClick={onSendWelcomeEmail}>
                                          Email bienvenida
                                        </Button>
                                      </>
                                    :
                                      ''
                                  }
                              </div>;
  
    return result;
  }


  useEffect(()=>{
     if (props.userData && props.userData.idRole > 0){
         let txtDate : string = props.userData?.passwordCaducity?.replace("T", " ");

         if (moment(txtDate).isValid()){
            props.userData.passwordCaducity = moment(txtDate);
            setExpiredPassword(moment(txtDate).isBefore(moment()));
         }else{
           props.userData.passwordCaducity = moment();
         }

         form.setFieldsValue(props.userData as any);


         form.setFieldsValue({applyReducedPrice: props.userData?.applyReducedPrice ? 1 : 0});
         form.setFieldsValue({banned: props.userData?.banned ? 1 : 0});
     }
 },[props.userData])

  return (
    <Skeleton active loading={props.updateMode && (!props.userData || !(props.userData.idRole > 0))}>
      <Form layout="vertical" form={form} name="frm-add-company" onFinish={onFinish} className='user-edit-component'>
            
          {getActionButtons()}
          
          {getAlertRGPD()}

          <ContactData />
          <BillingData />
          <AccessData isEdit={props.updateMode} expiredPassword={expiredPassword}/>
          <OtherData />
          
          {getActionButtons()}

      </Form>
    </Skeleton>
    
  );
}

export default UsersManageComponent;