import React, { useState } from 'react'
import { LoadingOrText } from '../..';
import moment from 'moment';
import { Button, Col, Input, Row, Select } from 'antd';
import ApiResponse from '../../../models/api/ApiResponse';
import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';
import Patient from '../../../models/Patient';

import './PatientSearchComponent.scss';

interface Props{
    onSelect: (patientId: number) => void;
}

const PatientSearchComponent = (props: any) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    
    const [filter, setFilter] = useState<string>("");
    const [loadingPatients, setLoadingPatients] = useState<boolean>(false);
    const [patients, setPatients] = useState<Patient[]>([]);

    const filterPatients = (v : string) : void => {
        if (v.length > 0){
            GetPatients(v);
        }
    }

    const onChange = (patientId: number) : void => {
        props.onSelect(patientId);
    }

    const GetPatients = (filter: string) : void => {
        const paramsApi : ParamsApi = {
            query:{
                page:0,
                N:999999,
                filter: filter
            }
        }
        setLoadingPatients(true);
        restApiClient.fetch("GetPatientsBasic", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setPatients(r.data.elements);
                }
            })
            .finally(()=>{
                setLoadingPatients(false);
            })
    }

    const onSearch = () : void => {
        GetPatients(filter);
    }
    
    return(
        <div className="search-patient">
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Input onChange={(v)=>{setFilter(v.target.value)}}/> 
                </Col>
                <Col xs={24} md={12}>
                    <Select
                        onChange={(v)=>{onChange(v as number)}}
                        style={{ width: "100%" }}
                        placeholder={<LoadingOrText loading={loadingPatients} text="Escriba para buscar..."/>}
                        filterOption={false}
                    >
                        {
                            patients?.map((p: Patient) => {
                                return <Select.Option key={p.id} value={p.id}>{p.id} - {p.name} {p.surname} {p.secondSurname} - {p.birthDate ? `${moment(p.birthDate).format("DD/MM/YYYY")} (${moment(p.birthDate).fromNow(true)})` : 'N/D'} {p.patientNif ? `- ${p.patientNif}` : ''}</Select.Option>
                            })
                        }
                    </Select>
                </Col>
            </Row>
            <Button type='primary' className='btn-secondary btn-search' onClick={onSearch}>Buscar</Button>
        </div>
    )
}


export default PatientSearchComponent;