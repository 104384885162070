import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Requests'

export const RequestsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetRequestTypes"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetPendingDoctorRequests"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName("GetRequests"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName("GetRequestsFiltered"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName("AddRequests"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName("AddMultipleRequests"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName("GetRequestsByStatus"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName("RequestOrdered"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName("GetLastFinishedRequests"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setActionName("ChangeRequestDoctor"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)
        .setActionName("CancelRequest"),
        

        

        
]