import { Button, Divider, List, Popconfirm, Skeleton } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import * as Components from '../../../index';

import Request from '../../../../models/Request'

import "../Components.scss"
import { getItemStatus } from '../../../../utils/RequestsUtils';
import { useHistory } from 'react-router-dom';

interface Props{
    requests: Request[],
    onChange: any,
    onCancel: any
}

const RequestListComponent = (props: Props) : JSX.Element => {

    const history = useHistory();

    const [list, setList] = useState<Request[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showInfo, setShowInfo] = useState<boolean>(false);

    useEffect(()=>{
        setList(props.requests);
    },[props.requests]);

    useEffect(()=>{
        setLoading(false);
    },[list]);

    

    const getListTitle = () : string => {
        let title : string = '';

        if (list && list.length > 0){
            title = `${list.length} solicitud(es) el ${moment(list[0].start).format("DD/MM/YYYY")}`;
        }else{
            title = "Sin solicitudes"
        }

        return title;
    }

    const onCancel = (id: number) : void => {
        props.onCancel(id);
    }

    return (
            <div className="box-component">
                <Components.RequestsDrawer visible={showInfo} onClose={()=>{setShowInfo(false)}} title={"Detalle petición"}/>

                <Divider>{getListTitle()}</Divider>
                <List
                    className="daily-request-list"
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item: Request) => (
                    <List.Item
                    >
                        
                        <Skeleton avatar title={false} loading={loading} active>
                            <List.Item.Meta
                                key={item.id}
                                avatar={getItemStatus(item.status)}
                                title={<span className="list-status-title" onClick={()=>{setShowInfo(true)}}>
                                            {item.requestType.description}
                                        </span>}
                                description={
                                    <div className="event-list-info">
                                        <span onClick={()=>{setShowInfo(true)}}>
                                            {item.user.name} {item.user.surname} {item.user.secondSurname}
                                        </span>
                                        <span>
                                            <div>Solicitud: {moment(item.requestDate).format("DD/MM/YYYY")}</div>
                                            <div>Indicada: {moment(item.requestDate).format("DD/MM/YYYY")}</div>
                                        </span>

                                        {
                                            props.onCancel ? 
                                                <span>
                                                    <Popconfirm placement="topLeft" title={'Confirmación cancelar'} onConfirm={()=>{onCancel(item.id)}} okText="Si, cancelar" cancelText="No cancelar">
                                                        <Button type="primary" size='small' style={{float: "right"}} danger disabled={item.status === "cancelado"}>Cancelar</Button>
                                                    </Popconfirm>
                                                </span>
                                                :
                                                    ''
                                        }

                                        <span>
                                            <Button onClick={()=>{history.push(`/dashboard/solicitud:${item.id}`)}} type="primary" size='small' style={{float: "right", marginRight: "5px"}} className="btn-secondary">Ver casos</Button>
                                        </span>
                                    </div>
                                }
                            />
                        </Skeleton>
                    </List.Item>
                    )}
                />
            </div>)
}

export default RequestListComponent;