
export enum CaseReportStatus {
    Finished = "finalizado",
    Processing = "procesando",
    Received = "recepcionado",
    Empty = ""
}

export enum RequestDataStatus {
    Pending = "pendiente",
    Ordered = "ordenado",
    Received = "recepcionado",
    Processing = "procesando",
    Finished = "finalizado",
    Canceled = "cancelado" 
}