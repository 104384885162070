import React, {useEffect, useState }  from 'react'

import DrawerInsideComponent from '../../DrawerInside/DrawerInside'

import * as Components from '../../index'


interface ProfileDrawerProps{
    visible: boolean,
    setShowDrawer: any
}


const ProfileDrawerComponent = (props : ProfileDrawerProps): JSX.Element => {

    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    useEffect(()=>{
        setShowDrawer(props.visible);
    }, [props.visible])

    return(
    <DrawerInsideComponent 
        mobilefixed={showDrawer} 
        showadd={showDrawer} 
        donclose={()=>{ props.setShowDrawer(false); }} 
        dtitle="" 
        dwidth={"75%"}
    >
        <Components.Profile />
    </DrawerInsideComponent>)
}

export default ProfileDrawerComponent;