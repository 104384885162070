import React, { useEffect, useState } from 'react'
import * as Templates from '../../../components/templates';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';

import './preformattedEdit.scss'
import { useParams } from 'react-router-dom';
import Preformatted from '../../../models/Preformatted';

const PreformattedEditPage = () : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const [preformatted, setPreformatted] = useState<Preformatted>();

    const { id } = useParams<any>();
    
    const getPreformattedData = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                id: id
            }
        }

        restApiClient.fetch("GetPreformatted", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setPreformatted(r.data);
                }
            });
    }

    useEffect(()=>{
        getPreformattedData();
    },[id]);

    return (
        <div className='preformatted-edit-page'>
            <Templates.PreformattedManagement updateMode data={preformatted}/>
        </div>
    )
}

export default PreformattedEditPage;