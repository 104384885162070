import { Col, Row } from 'antd';
import React, { useContext } from 'react'
import { LoginContext } from '../../hooks/context/userContext'


import * as Components from '../../components/index'

import './dashboard.scss'
import DemoBullet from './DemoBullet';
import { DOCTOR_ROLE } from '../../utils/Constants';


const DashboardPage = (props : any) : any => {
  let loginContext = useContext(LoginContext);
    return(
        <Row gutter={16} className="hello">
            <Components.RGPD />
            {
              loginContext.idRole == DOCTOR_ROLE ? 
                <Components.InvoiceNotificator />
              :
              ''
            }
            <Col xs={24} >
                <DemoBullet />
            </Col>
            
            
            <Col xs={24}>
                <Components.CaseReportList onlyArchived={false} showHeaderButtons={true}/>
            </Col>
        </Row>
    )
}

export default DashboardPage;