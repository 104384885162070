import { AppstoreOutlined, ClearOutlined, FilterOutlined, ReloadOutlined, SearchOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Dropdown, Input, List, Menu, Pagination, Select, Skeleton, Table, Typography } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Helper } from '../..';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';
import { useHistory } from "react-router-dom";

import './UtilsStyles.scss';
import ActionButton from '../../../models/ActionButton';
import moment from 'moment';
import Doctor from '../../../models/Doctor';

const { Title } = Typography;

interface Props {
    reloadCounter?:number,
    columns: any[],
    expandableInfo?: any,
    aliasEndPoint: string,
    paramsEndPoint?: any,
    title?:string
    showCheckbox?: boolean,
    onCheckedItem?: any,
    filterButtons?: string[],
    orderButtons?:ActionButton[],
    defaultFilter?: string,
    actionButtons?: ActionButton[]
    contextualMenu?: any,
    contextualParameters?:any,
    entityName: string,
    forceRefresh?: number,
    selectedRowKeys?: number[],
    showFilters?:boolean,
    initialPageSize?: number,
    onFilter?: (e:any)=>void,
}

const TablePaginationComponent = forwardRef((props: Props, ref)=>{
    const history = useHistory();

    const restApiClient : RestApiClient = new RestApiClient();

    const { aliasEndPoint, title, showCheckbox, filterButtons, actionButtons, entityName, orderButtons } = props;

    const [anchor, setAnchor] = useState<number>(24);
    const [source, setSource] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [expandableInfo, setExpandableInfo] = useState<any>();
    const [actualPage, setActualPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(props.initialPageSize ?? 10);
    const [reloadCounter, setReloadCounter] = useState<number>(0);

    const [inputFilter, setInputFilter] = useState<string>("");
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const [order, setOrder] = useState<string | undefined>(undefined);
    const [defaultFilter, setDefaultFilter] = useState<string>("");

    const [totalPages, setTotalPages] = useState<number>(0);
    const [selected, setSelected] = useState<any>();
    const [responsive, setResponsive] = useState<string>("mobile");
    const [list, setList] = useState<JSX.Element>(<></>);
    const [loadingData, setLoadingData] = useState<boolean>(false);


    useImperativeHandle(ref, () => ({
        
        updateFilter(filter: string) {
            setFilter(filter);
            setInputFilter(filter);
        }
    
    }));


    // rowSelection object indicates the need for row selection
    const rowSelection = {
        preserveSelectedRowKeys: true,
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            
            if ( props.onCheckedItem && props.onCheckedItem instanceof Function){
                props.onCheckedItem(selectedRowKeys);
            }
        },
        getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
        }),
    };

    const getJSXList = () : JSX.Element => {
        let result : JSX.Element = <></>;

        if (responsive === "mobile" && columns.some(c => c.renderMobile)) {
            result = <List
                className={`demo-loadmore-list ${moment().format("YYYYMMDDHHmmss")}`}
                itemLayout="vertical"
                dataSource={source}
                renderItem={item =>
                    <List.Item
                                    actions={[]}
                                    >
                        {
                            columns.map((column, index) => {
                                if (column.renderMobile){
                                    return <div key={index}>
                                                {column.renderMobile(index, item)}
                                            </div>
                                }
                            })
                        }
                    </List.Item>
                }
            />;
        }else{
            result = <Table rowKey="id" 
                            size="small"
                            rowSelection={
                                showCheckbox ? {
                                        
                                        type:"checkbox",
                                        ...rowSelection
                                    } : undefined
                                }
                            dataSource={source} 
                            columns={columns} 
                            pagination={false}
                            expandable={{
                                ...expandableInfo
                            }}
                        />;
        }

        return result;
    }

    const getData = () : void => {

            
        let params : any = {
            page: isNaN(actualPage-1) ? 0 : actualPage-1,
            N: pageSize,
            filter: filter
        };

        if (order && order.length > 0){
            params["orderBy"] = order;
        }

        if (props.paramsEndPoint){
            params = {
                ...params,
                ...props.paramsEndPoint
            };
        }

        const paramsApi : ParamsApi = {
            query: params
        }

        setSelected(undefined);
        setLoadingData(true);
        restApiClient.fetch(aliasEndPoint, paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setSource(r.data.elements);
                    setTotalPages(r.data.totalPages);
                    setActualPage(r.data.actualPage+1);
                    setTotalRecords(r.data.totalRecords);
                }
            }).finally(()=>{
                setLoadingData(false);
            });
    }

    const filterData = (e: any) : void => {
        setFilter(e.target.value);
        //Informamos al padre si es necesrio
        
    }

    useEffect(()=>{
        if (props.onFilter && filter){
            props.onFilter(filter);
        }
    },[filter])

    const onChange = (pageNumber: any, pageSize: any)=>{
        setPageSize(pageSize);
        setActualPage(pageNumber);
    }

    const [doctors, setDoctors] = useState<Doctor[]>([]);
    const [loadingDoctors, setLoadingDoctors] = useState<boolean>(false);
    const GetDoctors = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                filter: '', 
                counters: false,
                billingInfo: true
            }
        }
        setLoadingDoctors(true);
        restApiClient.fetch("GetDoctors", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setDoctors(r.data);
                }
            })
            .finally(()=>{
                setLoadingDoctors(false);
            })
    }


    useEffect(()=>{
        if (filter !== undefined){
            getData();
        }
        
    },[actualPage, pageSize, filter]);

    useEffect(()=>{
        if (props.defaultFilter !== undefined && props.defaultFilter !== null && (props.defaultFilter.length > 0 || props.defaultFilter === "") && props.defaultFilter != filter){
            setFilter(props.defaultFilter);
            setInputFilter(props.defaultFilter);
        }else if (props.defaultFilter == undefined || props.defaultFilter == null){
            setFilter("");
            setInputFilter("");
        }
    },[]);

    useEffect(()=>{
        if(props.columns && props.columns.length > 0){
            if (props.contextualMenu){
                setColumns(props.columns.concat({
                    title: '',
                    key: 'actions',
                    render: (i: number, element: any) => {
                        return <>
                                    <AppstoreOutlined className={`custom-table-actions ${element.id === selected?.id ? 'record-selected' : 'record-unselected'}`} onClick={()=>{setSelected(element);}}/>
                               </> 
            
                    }
                }));
            }else{
                setColumns(props.columns);
            }
        }else{
            setColumns([]);
        }
    }, [props.columns]);

    useEffect(()=>{
        if(props.expandableInfo){
            setExpandableInfo(props.expandableInfo);
        }else{
            setExpandableInfo(undefined);
        }
    }, [props.expandableInfo]);

    useEffect(()=>{
        if (selected){
            setAnchor(23);
        }else{
            setAnchor(24);
        }
    },[selected]);

    useEffect(()=>{
        getJSXList();
    },[source]);

    useEffect(() => {
        const updateWindowDimensions = () => {
            const width : number = window.innerWidth;

            if(width < 768){
                setResponsive("mobile");
            }else if(width < 992){
                setResponsive("tablet");
            }else{
                setResponsive("desktop");
            }
        };

        updateWindowDimensions();

        window.addEventListener("resize", updateWindowDimensions);
    
        return () => window.removeEventListener("resize", updateWindowDimensions) 
    
      }, []);

      useEffect(() => {
        setList(getJSXList());
    }, [source,responsive]);

    useEffect(() => {
        if(props.defaultFilter && props.defaultFilter.length > 0){
            setDefaultFilter(props.defaultFilter);  
        }
        setList(getJSXList());
    }, []);

    useEffect(()=>{
        if (props.forceRefresh && props.forceRefresh > 0){
            getData();
        }
    },[props.forceRefresh]);

    useEffect(()=>{
        if (props.reloadCounter && !isNaN(props.reloadCounter) && props.reloadCounter != reloadCounter && props.reloadCounter > 0){
            setReloadCounter(props.reloadCounter);
            getData();
        }
    },[props.reloadCounter])


    const hideHelper = () : void => {
        setSelected(undefined);
    }

    const edition = () : void => {
        if (selected){
            history.push(`${entityName}/edit/${selected?.id}`);
        }
    }

    useEffect(()=>{
        if (filterButtons && filterButtons?.filter((tag: string)=>tag.startsWith('special-doctors')).length > 0){
            GetDoctors();
        }
    },[props])

    return (
        <div className="table-pagination">
            
            <Helper classname={`helper-component ${selected ? 'helper-visible' : 'helper-hidden'}`} location={entityName} properties={{
                        hideMenu: hideHelper,
                        edition: edition,
                        selected: selected,
                        callBack: getData,
                        params: props.contextualParameters
            }}/>

            {
                title ?
                    <Title level={5}>
                        {title} ({totalRecords})
                    </Title>
                :
                ''
            }

            

            {
                actionButtons || filterButtons || orderButtons?
                    <Col xs={24} md={anchor} className="filter-buttons">
                        {
                            actionButtons ? 
                                actionButtons.filter((ab: ActionButton)=>ab.noShow === undefined || ab.noShow === true).map((ab: ActionButton, i: number)=>{
                                    return ab.item ?? (
                                        <Button style={{marginLeft: "5px"}} type={ab.type ?? "primary"} className={ab.className ?? 'btn-secondary'} key={`ab-${i}`} onClick={ab.action}>{ab.icon} {ab.label}</Button>
                                    )
                                })
                                :
                                ''
                        }
                        {
                            filterButtons?.filter((tag: string)=>tag.startsWith('special-')).map((tag: string, i:number)=>{
                                switch(tag){
                                    case 'special-date-from-to':
                                        return <DatePicker.RangePicker format={"DD/MM/YYYY"} onChange={
                                            (e:any)=>{
                                                if (e && e.length > 0 && e[0] && e[1]){
                                                    const dateFilter : string = `desde ${moment(e[0]).format("DD/MM/YYYY")} hasta ${moment(e[1]).format("DD/MM/YYYY")}`;
                                                    if (filter && filter.length && !filter.startsWith('desde')){
                                                        setFilter(`${dateFilter} ${filter}`);
                                                        setInputFilter(`${dateFilter} ${filter}`);
                                                    }else{
                                                        setFilter(dateFilter);
                                                        setInputFilter(dateFilter);
                                                    }
                                                    
                                                }else{
                                                    let filterWitoutFor = filter;
                                                    if (filter && filter.indexOf(' para ') !== -1){
                                                        // Entonces tenemos un para, quitamos todo menos le para
                                                        filterWitoutFor = filter?.substring(filter.indexOf(' para '), filter.length)
                                                        setFilter(filterWitoutFor.trim());
                                                        setInputFilter(filterWitoutFor.trim());
                                                    }else{
                                                        // No tenemos el para
                                                        setFilter('');
                                                        setInputFilter('');
                                                    }
                                                    
                                                }
                                                
                                            }
                                        } />
                                    case 'special-doctors':
                                        return <Select showSearch style={{minWidth: "200px", margin: "0 0 0 5px"}} defaultValue={''} onChange={(e:any)=>{
                                                    if (e && e.length > 0){
                                                        // Por si hay puesto otro para que no se concatene
                                                        let filterWitoutFor = filter;
                                                        if (filter && filter.indexOf(' para ') !== -1){
                                                            filterWitoutFor = filter?.substring(0, filter.indexOf('para'))
                                                        }
                                                        

                                                        setFilter(`${filterWitoutFor?.trim()} para ${e} `);
                                                        setInputFilter(`${filterWitoutFor?.trim()} para ${e} `);
                                                    }else{
                                                        let filterWitoutFor = filter;
                                                        if (filter && filter.indexOf(' para ') !== -1){
                                                            filterWitoutFor = filter?.substring(0, filter.indexOf('para'))
                                                        }
                                                        setFilter(`${filterWitoutFor?.trim()}`);
                                                        setInputFilter(`${filterWitoutFor?.trim()}`);
                                                    }
                                                    
                                                }}>
                                                    <Select.Option key={-1} value={''}>Ninguno</Select.Option>
                                                    {doctors.map((d: Doctor, index: number)=>{
                                                        return <Select.Option key={index} value={`${d.name} ${d.surname} ${d.secondSurname}`}>
                                                                {`${d.name} ${d.surname} ${d.secondSurname}`}
                                                               </Select.Option>
                                                    })}
                                                </Select>
                                }
                                
                            })
                        }
                        {
                            filterButtons ?
                                <Dropdown.Button type="dashed" overlay={
                                    <Menu onClick={()=>{}}>
                                        {
                                            filterButtons.filter((tag: string)=>!tag.startsWith('special-')).map((tag: string, i:number)=>{
                                                if (tag === "separator"){
                                                    return <Menu.Divider key={`separator-${i}`}/>
                                                }else{
                                                    return (
                                                        <Menu.Item key={`f-${tag}`} onClick={()=>{setFilter(tag); setInputFilter(tag)}}>Filtrar por {tag}</Menu.Item>
                                                    )
                                                }
                                                
                                            })
                                        }
                                    </Menu>
                                }><FilterOutlined size={3}/> Filtros
                                </Dropdown.Button>
                            :
                            ''
                        }
                        {
                            orderButtons ?
                            <Dropdown.Button type="dashed" overlay={
                                <Menu onClick={()=>{}}>
                                    {
                                        orderButtons.map((ab: ActionButton, i:number)=>{
                                            if (ab.className === "separator"){
                                                return <Menu.Divider key={`separator-${i}`}/>
                                            }else{
                                                return (
                                                    <Menu.Item key={`f-${ab.label}`} onClick={()=>{setOrder(ab.label); ab.action(ab.label)}}>Ordenar por {ab.label}</Menu.Item>
                                                )
                                            }
                                            
                                        })
                                    }
                                </Menu>
                            }><SortAscendingOutlined size={3}/> Ordenar
                            </Dropdown.Button>
                            :
                            ''
                        }
                    </Col>
                    :
                    ''
            }
            
            
                    <Col xs={24} sm={24} md={anchor}>
                        <Input.Group compact style={{display:"flex", marginBottom: "5px", justifyContent: "flex-end"}}>
                        {
                            props.showFilters === undefined || (props.showFilters && props.showFilters === true)  ? 
                            <>
                                <Input size="small" onChange={(e)=>{
                                    setInputFilter(e.target.value);
                                }} onPressEnter={()=>{setFilter(inputFilter);}} value={inputFilter} placeholder="Escribe y pulsa ENTER para buscar."/>
                                <Button size="small" onClick={()=>{
                                    setFilter(inputFilter)
                                }}>
                                    <SearchOutlined />
                                </Button>
                                <Button size="small" danger onClick={()=>{
                                    setFilter('');
                                    setInputFilter('');
                                }}>
                                    <ClearOutlined />
                                </Button>
                                <Button size="small" type="primary" className='btn-secondary' onClick={()=>{                                        
                                    getData();
                                }}>
                                    <ReloadOutlined />
                                </Button>    
                            </>
                            : 
                            <Button size="small" type="primary" className='btn-secondary' onClick={()=>{
                                getData();
                            }}>
                            <ReloadOutlined />
                            </Button>    
                        }
                        </Input.Group>
                    </Col>
            
            
            
            <Col xs={24} sm={24} md={anchor}>
                <Skeleton loading={loadingData} active>
                    {list}
                </Skeleton>
            </Col>
            <Col xs={24} sm={24} md={anchor}>
                <Pagination 
                    disabled={loadingData}
                    key={`pagination-${actualPage}`}
                    className="pagination"
                    showSizeChanger
                    pageSizeOptions={["1","10","20","30","50","100","300","500","1000"]} 
                    onChange={onChange} 
                    current={actualPage} 
                    total={totalRecords} 
                    pageSize={pageSize}
                    showTotal={total => ``}
                    />
            </Col>
            <Col xs={24} className="bottom-spacer">&nbsp;</Col>
        </div>)
});

export default TablePaginationComponent;