import React, { useEffect, useState } from 'react'

import ParamsApi from '../../models/api/ParamsApi';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';

import './StatisticsComponent.scss'
import DoctorStatistics from '../../models/DoctorStatistics';
import { Table, Typography } from 'antd';

interface Props{
    year: number
}
const StatisticsDoctorComponent = (props: Props) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [data, setData] = useState<DoctorStatistics[]>([]);
    const [year, setYear] = useState<number>(0);
    const [maxRequests, setMaxRequests] = useState<number>(0);
    const [maxCaseReports, setMaxCaseReports] = useState<number>(0);
    const [maxNet, setMaxNet] = useState<number>(0);

    const [totalRequestsTitle, setTotalRequestsTitle] = useState<string>('T. Solicitudes')
    const [tocalCaseReportsTitle, setTocalCaseReportsTitle] = useState<string>('T. Casos')

    const columns = [
        {
            title: 'Posición',
            dataIndex: 'name',
            key: 'id',
            render: (text:any, record: any, index: number) => `#${(index+1)<10 ? '0'+(index+1) : index+1}`,
        },
        {
          title: 'Nombre',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: totalRequestsTitle,
          dataIndex: 'requests',
          className:"text-right",
          key: 'requests',
          render: (text:any, record: any, index: number) => {
            return <>{text} <small style={{display:"block"}}>{Math.round((100 * text / maxRequests)*100)/100}%</small></>
          }
        },
        {
          title: tocalCaseReportsTitle,
          dataIndex: 'caseReports',
          className:"text-right",
          key: 'requests',
          render: (text:any, record: any, index: number) => {
            return <>{text} <small style={{display:"block"}}>{Math.round((100 * text / maxCaseReports)*100)/100}%</small></>
          }
        },
        {
          title: 'Comisión aportada',
          dataIndex: 'commissions',
          className:"text-right",
          key: 'commissions',
          render: (text:any, record: any, index: number) => {
            return `${text}€`
          }
        },
        {
          title: 'Beneficio aportado',
          dataIndex: 'net',
          key: 'net',
          className:"text-right",
          render: (text:any, record: any, index: number) => {
                return <>{(Math.round(text * 100) / 100).toFixed(2)}€ <small style={{display:"block"}}>{(Math.round((100 * text / maxNet)*100)/100)}%</small></>
          }
        },
      ];

    const getData = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                year: year
            }
        }
        

        setLoadingData(true);
        restApiClient.fetch("GetDoctorStatistics", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    if (r.data as any[]){
                        const requestSum : number = r.data.reduce((accumulator: number, currentValue: DoctorStatistics) => accumulator + currentValue.requests, 0);
                        const caseReportsSum : number = r.data.reduce((accumulator: number, currentValue: DoctorStatistics) => accumulator + currentValue.caseReports, 0);
                        const netSum : number = r.data.reduce((accumulator: number, currentValue: DoctorStatistics) => accumulator + currentValue.net, 0);
                        setMaxRequests(requestSum);
                        setMaxCaseReports(caseReportsSum);
                        setTotalRequestsTitle(`T. Solicitudes (∑=${requestSum})`)
                        setTocalCaseReportsTitle(`T. Casos (∑=${caseReportsSum})`)
                        setMaxNet(netSum);
                        setData(r.data);
                    }
                }
            }).finally(()=>{
                setLoadingData(false);
            });
    }

    useEffect(()=>{
        setYear(props.year);
    },[props.year])

    useEffect(()=>{
        if (year && year>0){
            getData();
        }
    },[year]);

    return(
        <div className="statistics-component">
            <Typography.Title level={5}>Ranking de médicos en base a beneficios para el año <label className="current-year">{year}</label></Typography.Title>
            <Table dataSource={data} columns={columns} scroll={{ y: 400 }} style={{minHeight:400}}/>
        </div>
    )
}

export default StatisticsDoctorComponent