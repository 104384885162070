import moment from 'moment';
import React from 'react'
import Patient from '../../models/Patient';
import * as Components from '../index';
import { useHistory } from "react-router-dom";

import './PatientListComponent.scss'
import { Row } from 'antd';
import { ManOutlined, PlusOutlined, WomanOutlined } from '@ant-design/icons';

interface Props{}

const PatientListComponent = (props: Props) : JSX.Element => {
    const history = useHistory();


    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render(i: number, element: Patient) {
                return `${element.name} ${element.surname} ${element.secondSurname ? element.secondSurname : ''}`;
            }
        },
        {
            title: 'Sexo',
            dataIndex: 'sex',
            key: 'sex',
            render(i: number, element: Patient){
                return element.sex === "mujer" ? <><WomanOutlined /> {element.sex}</> : <><ManOutlined /> {element.sex}</>;
            }
        },
        {
            title: 'NIF',
            dataIndex: 'nif',
            key: 'nif'
        },
        {
            title: 'F. Nacimiento',
            dataIndex: 'birthDate',
            key: 'birthDate',
            render(i: number, element: Patient) {

                const years : any = element.birthDate ?   moment().diff(element.birthDate, 'years') : 0;

                let result : JSX.Element = element.birthDate ?  <span>{moment(element.birthDate).format('DD/MM/YYYY')}<small className="years">{years} años.</small></span> : <span className="no-birthdate">Fecha paciente no registrada</span>;
                return result;
            }
        }
        ];

    const goAddPatient = () : void => {
        history.push("/add-patient");
    }


    return (
        <div className="patient-list-component">
            <Row >
                <Components.TablePagination 
                    contextualMenu
                    entityName="patient"
                    aliasEndPoint="GetPatients"
                    columns={columns}
                    title="Listado de pacientes"
                    filterButtons={["Sexo mujer", "Sexo varón"]}
                    actionButtons={[{label:"Añadir", action: goAddPatient, icon: <PlusOutlined />}]}
                />
            </Row>
            
        </div>
    )
}

export default PatientListComponent;
