import React from 'react';

import * as Components from '../../../components';

import './AddPatient.scss';

const AddPatientPage = () : JSX.Element => {
    return (
        <div className="add-patient-page">
            <Components.ManagePatient clear editMode={false}/>
        </div>
    );
}

export default AddPatientPage;