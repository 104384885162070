import { AppstoreAddOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, Input, InputNumber } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { TablePagination } from '..';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';
import LoadingOrComponent from '../core/utils/LoadingOrComponent';

import './ShortcutsComponent.scss'

interface Props{
    type: 'Observations' | 'ClinicInfo' | 'SpecialTags' | 'all'
}

const ShortcutsComponent = (props:Props) : JSX.Element => {
    const [form] = Form.useForm();
    const restApiClient : RestApiClient = new RestApiClient();

    const [counter, setCounter] = useState<number>(0);
    const [ showAddRecord, setShowAddRecord] = useState<boolean>(false);
    const [savingData, setSavingData] = useState<boolean>(false);
    const [type, setType] = useState<'Observations' | 'ClinicInfo' | 'SpecialTags' | 'all'>(props.type);
    const [tableTitle, setTableTitle] = useState<string>('');

    const columns = [
        {
            title: 'Valor',
            dataIndex: 'key',
            key: 'key'
        },
        {
            title:"Acciones",
            key: 'action',
            render: (text: any, record: any) => (
                record.isProtected ? <></> : 
                <span>
                    <span className="ant-divider" />
                    <a href="#" onClick={()=>{
                        deleteRecord(record.id)
                    }}>Eliminar</a>
                </span>
            ),
        },
    ];

    const deleteRecord = (id: number) => {
       
        const paramsApi : ParamsApi = {
            query:{
                id: id
            }
        }

        restApiClient.fetch("DeleteOption", paramsApi)
            .then((r: ApiResponse | null) => {
                if (r && r.code === 200) {
                    setCounter(counter + 1);
                }
            })
    }

    const onFinish = () => {
        var formData = form.getFieldsValue();

        const paramsApi : ParamsApi = {
            body: {
                key: formData["value"],
                value: formData["value"],
                type: type
            }
        }

        setSavingData(true);
        restApiClient.fetch("CreateOption", paramsApi).then((r : ApiResponse | null)=> {
            if (r !== null && r.code === 200){
                setCounter(counter + 1);
                setShowAddRecord(false);
                form.resetFields();
            }
        }).finally(()=>{
            setSavingData(false);
        })

    }

    useEffect(()=>{
        switch (type) {
            case 'Observations':
                setTableTitle('Atajos para Observaciones (Recepción)');
                break;
            case 'ClinicInfo':
                setTableTitle('Atajos para Información clínica (Recepción)');
                break;
            case 'all':
                setTableTitle('Todos los valores de la tabla options');
                break;
            default:
                setTableTitle('Todos los valores de la tabla options');
        }
    },[props.type]);

    return (
        <div className='shortcuts-component'>
            <Drawer title="Añadir nuevo registro" placement="right" onClose={(()=>{setShowAddRecord(false)})} visible={showAddRecord}>
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item name="value" label="Indica el texto" rules={[{required: true, message: 'Por favor, introduce el dato...'}]}>                    
                        <Input style={{width:"100%"}} />
                    </Form.Item>
                    <Button htmlType='submit' size="small" type='primary' className="btn-secondary" style={{float:"right"}}>
                        <LoadingOrComponent loading={savingData} text={`Guardar registro`} loadingText={`Guardando...`}/>
                    </Button>
                </Form>
            </Drawer>


            <TablePagination 
                    reloadCounter={counter}
                    aliasEndPoint="GetOptions"
                    showFilters={false}
                    entityName="option"
                    defaultFilter={type}
                    columns={columns}
                    title={tableTitle}
                    actionButtons={[{type: "ghost", className:"add-new-keyword", label:"Añadir nuevo registro", action: ()=>{setShowAddRecord(true)}, icon: <AppstoreAddOutlined />}]}
                />
        </div>
    )
}

export default ShortcutsComponent;
