import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Divider, Drawer, Form, Result, Row, Select, Space, Switch, Table, Tag, Tooltip } from 'antd';

import ApiResponse from '../../../models/api/ApiResponse';
import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';

import InvoiceParamsComponent from './InvoiceParamsComponent';

import './InvoiceGeneratorComponent.scss'
import Doctor from '../../../models/Doctor';
import Pathologist from '../../../models/Pathologist';
import Company from '../../../models/Company';
import { CalendarOutlined, ClearOutlined, EuroCircleOutlined, FilePdfOutlined, InfoCircleOutlined, LoadingOutlined, ReloadOutlined, TagsOutlined, VerticalAlignMiddleOutlined, VerticalLeftOutlined } from '@ant-design/icons';
import LoadingOrComponent from '../../core/utils/LoadingOrComponent';
import CaseReports from '../../../models/CaseReports';
import moment from 'moment';
import { processFile } from '../../../utils/ProcessFile';
import * as Components from '../../index';
import { formatTimeStr } from 'antd/lib/statistic/utils';

const { RangePicker } = DatePicker;

interface Props {}

let previsibleInvoiceAmount: number = 0;

const InvoiceGeneratorComponent = (props: Props) : JSX.Element => {

    const [caseReportsExcluded, setCaseReportsExcluded] = useState<number[]>([]);


    const [list, setList] = useState<CaseReports[]>([]);



    const initialColumns = [
        {
            // Marcar pagado
            title: 'Pagado',
            dataIndex: 'payed',
            key: 'payed',
            render: (text: string, record: CaseReports) => {
                let result : any = <Switch checkedChildren='si' size='small' unCheckedChildren="No" defaultChecked={record.payed === "si"} onChange={(v)=>{
                    onChangePayedStatus(record.id, v ? 'si' : 'no');
                }}/>;

                if (record.applyInvoiceNumber){
                    result = <Tooltip title="La factura asociada a este caso de estudio ya ha sido abonada"><Tag>{record.applyInvoiceNumber}</Tag></Tooltip>
                }
                return <>{result}</>;
            }
        },
        {
            //Contabilizar
            title: 'Visible',
            dataIndex: 'invoiced',
            key: 'invoiced',
            width:120,
            render: (text: string, record: CaseReports) => {

                return <Switch checkedChildren="Si" unCheckedChildren="No" defaultChecked={caseReportsExcluded.findIndex(x => x === record.id ) ===-1} size='small' 
                onChange={(v)=>{
                    var actual = caseReportsExcluded;
        
                    if (actual.find(x=>x === record.id)){
                        actual.splice(actual.findIndex(x=>x === record.id), 1);
                    }else{
                        actual.push(record.id);
                    }
        
                    setCaseReportsExcluded(actual);
                    calculatePrevisibleAmount();
                }}/>;
            }
        },
        {
            // Número caso de estudio
            title: 'Nº Caso Estudio',
            dataIndex: 'num_case_report',
            key: 'num_case_report',
            render: (text: string, record: CaseReports) => {
                return record.caseReportNum;
            }
        },
        {
            // F. Recepción
            title: 'F. Recepción',
            dataIndex: 'reception_date',
            key: 'reception_date',
            render: (text: string, record: CaseReports) => {
                return moment(record.receptionDate).format('DD/MM/YYYY');
            }
        },
        {
            // Médico solicitante
            title: 'Médico',
            dataIndex: 'doctor_name',
            key: 'doctor_name',
            render: (text: string, record: CaseReports) => {
                return `${record.reception.request.user.name} ${record.reception.request.user.surname} ${record.reception.request.user.secondSurname}`;
            }
        },
        {
            // Paciente
            title: 'Paciente',
            dataIndex: 'patient_name',
            key: 'patient_name',
            render: (text: string, record: CaseReports) => {
                return `${record.patient.name} ${record.patient.surname} ${record.patient.secondSurname?record.patient.secondSurname:''}`;
            }
        },
        {
            //Compañía

            title: 'Compañía',
            dataIndex: 'company_name',
            key: 'company_name',
            render: (text: string, record: CaseReports) => {
                return record.company.businessName;
            }
        },
        {
            //Producto
            title: 'Producto',
            dataIndex: 'product_name',
            key: 'description',
            render: (text: string, record: CaseReports) => {
                return record.product.description;
            }
        },
        {
            // pvp producto
            title: 'PVP Producto',
            dataIndex: 'product_price',
            key: 'product_pvp',
            render: (text: string, record: CaseReports) => {
                const hasReducedPrice : boolean = record.reception.request.user.applyReducedPrice;

                return <span>{record.product.price}€ <small className="commission">{record.product.reducedPrice}€ <TagsOutlined /></small></span>;
            }
        },
        {
            //Comisión compañía
            title: 'Comisión compañía',
            dataIndex: 'company_commission',
            key: 'company_commission',
            render: (text: string, record: CaseReports) => {
                var pvpMoment = record.reception.pvpMoment;
                var commissionAmount = Math.round((pvpMoment * (record.reception.commissionMoment / 100)) * 100) / 100;
                
                return commissionAmount > 0 ? <span>{commissionAmount}€<small className="commission">{record.reception.commissionMoment}%</small></span> : <Tooltip title="Sin comisión"><span>S/C</span></Tooltip>;
            }
        },
        {
            //Pvp neto
            title: <Tooltip placement='topRight' title="Precio que se cobrará menos la comisión de la compañía. En la factura aparecerá el importe con la comisión."><InfoCircleOutlined /> PVP Neto</Tooltip>,
            dataIndex: 'company_commission',
            key: 'net_pvp',
            render: (text: string, record: CaseReports) => {
                const hasReducedPrice : boolean = record.reception.request.user.applyReducedPrice;

                var pvpMoment = record.reception.pvpMoment;
                var commissionAmount = pvpMoment * (record.reception.commissionMoment / 100);
                var amount = Math.round((pvpMoment - commissionAmount)*100) / 100;
                const classF = hasReducedPrice ? 'reduced-price commission' : 'commission';
                return <><span>{Math.round((pvpMoment)*100) / 100}€</span><small className={classF} >{amount}€ {hasReducedPrice ? <TagsOutlined /> : ''}</small></>;
            }
        }
    ];

    const [form] = Form.useForm();
    const restApiClient : RestApiClient = new RestApiClient();

    const [maxTagCountColumns, setMaxTagCountColumns] = useState<'responsive' | number | undefined>('responsive');
    const [maxTagCountReceptionStatus, setMaxTagCountReceptionStatus] = useState<'responsive' | number | undefined>('responsive');
    const [maxTagCountDoctors, setMaxTagCountDoctors] = useState<'responsive' | number | undefined>('responsive');
    const [maxTagCountPathologist, setMaxTagCountPathologist] = useState<'responsive' | number | undefined>('responsive');
    const [maxTagCountCompanies, setMaxTagCountCompanies] = useState<'responsive' | number | undefined>('responsive');
    const [maxTagCountCaseReportType, setMaxTagCountCaseReportType] = useState<'responsive' | number | undefined>('responsive');


    const [selectedDoctors, setSelectedDoctors] = useState<Doctor[]>([]);

    const [showPrepareInvoice, setShowPrepareInvoice] = useState<boolean>(false);

    const [columns, setColumns] = useState<any[]>(initialColumns); 
    const [isNormalMode, setIsNormalMode] = useState<boolean>();

    const [hasDates, setHasDates] = useState<boolean>(false);

    const defaultNormalMode = ['num_case_report', 'reception_date', 'doctor_name', 'patient_name', 'company_name', 'description', 'product_pvp', 'net_pvp', 'company_commission'];
    const defaultInvoiceMode = ['num_case_report', 'reception_date','patient_name','description','net_pvp'];

    
    
    const [loadingCaseReports, setLoadingCaseReports] = useState<boolean>(false);


    const [loadingDoctors, setLoadingDoctors] = useState<boolean>(false);
    const [doctors, setDoctors] = useState<Doctor[]>([]);

    const [loadingPathologists, setLoadingPathologists] = useState<boolean>(false);
    const [pathologists, setPathologists] = useState<Pathologist[]>([]);

    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [filterByPrivate, setFilterByPrivate] = useState<boolean>(false);

    const [criteria, setCriteria] = useState<any>({});

    const [pdfData, setPdfData] = useState<any>();
    const [showPdf, setShowPdf] = useState<boolean>(false);



    const GetCompanies = () : void => {
        const paramsApi : ParamsApi = {
            query:{}
        }
        setLoadingCompanies(true);
        restApiClient.fetch("GetAllCompanies", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setCompanies(r.data.elements);
                }
            })
            .finally(()=>{
                setLoadingCompanies(false);
            })
    }

    const GetPathologists = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                filter: ''
            }
        }
        setLoadingPathologists(true);
        restApiClient.fetch("GetPathologist", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setPathologists(r.data);
                }
            })
            .finally(()=>{
                setLoadingPathologists(false);
            })
    }

    const GetDoctors = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                filter: '', 
                counters: false,
                billingInfo: true
            }
        }
        setLoadingDoctors(true);
        restApiClient.fetch("GetDoctors", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setDoctors(r.data);
                }
            })
            .finally(()=>{
                setLoadingDoctors(false);
            })
    }

    const onChangeMode = (v: boolean) => {
        setIsNormalMode(v);
        setFilterByPrivate(v);

        form.setFieldsValue({
            columns: v ? defaultInvoiceMode : defaultNormalMode,
            company: v ? [25] : undefined,
        });

        filterColumns();

        
        onFinish();
    }

    const filterColumns = () : void => {
        const selectedColumns = form.getFieldValue('columns');

        if (selectedColumns && selectedColumns.length > 0) {
            const filtered = initialColumns.filter((column: any) => {
                return selectedColumns.includes(column.key) || column.key === 'payed' || column.key === 'invoiced';
            });
            setColumns(filtered);
        }else{
            setColumns([]);
        }
    }



    const onChangePayedStatus = (caseReportId: number, newStatus : string) : void => {
        const paramsApi : ParamsApi = {
            body:{
                caseReportId: caseReportId,
                payedStatus: newStatus
            }
        }

        restApiClient.fetch("ChangeCaseReportPayedStatus", paramsApi);
    }

    const onFinish = () : void => {
        const data = form.getFieldsValue();

        const paramsApi : ParamsApi = {
            body:data
        }

        if (!data.dateRange){
            console.log("Debe indicar un rango de fechas.");
        }else{
            setCaseReportsExcluded([]);
            setCriteria(data);
            setLoadingCaseReports(true);
            restApiClient.fetch("GetCaseReportsForInvoices", paramsApi)
                .then((r : ApiResponse | null)=> {
                    if (r && r.code === 200){
                        setList(r.data);
                    }
                })
                .finally(()=>{
                    setLoadingCaseReports(false);
                })
        }

       

    }

    const [generatingReport, setGeneratingReport] = useState<boolean>(false);

    const generateReportPdf = () : void => {
        const data = form.getFieldsValue();
        const paramsApi : ParamsApi = {
            body:{
                criteria: data,
                caseReportsExcluded: caseReportsExcluded
            }
        }
        setGeneratingReport(true);
        restApiClient.generatePdf(paramsApi, "GenerateResumePDF")
            .then((r : ApiResponse | null)=> {
                processFile(r, setPdfData);
            })
            .finally(()=>{
                setGeneratingReport(false);
            })
    }

    useEffect(()=>{
        if (pdfData){
            setShowPdf(true);
        }
    },[pdfData]);

    useEffect(()=>{
        form.setFieldsValue({
            payed: ['no'],
            invoiced:['no']
        });
    },[])

    

    const changeMaxCount = (option: string) : void => {
        switch(option){
            case "columns":
                setMaxTagCountColumns(maxTagCountColumns ? undefined : 'responsive')
                break;
            case "caseReceptionStatus":
                setMaxTagCountReceptionStatus(maxTagCountReceptionStatus ? undefined : 'responsive')
                break;
            case "doctors":
                setMaxTagCountDoctors(maxTagCountDoctors ? undefined : 'responsive')
                break;
            case "pathologists":
                setMaxTagCountPathologist(maxTagCountPathologist ? undefined : 'responsive')
                break;
            case "companies":
                setMaxTagCountCompanies(maxTagCountCompanies ? undefined : 'responsive')
                break;
            case "caseReportType":
                setMaxTagCountCaseReportType(maxTagCountCaseReportType ? undefined : 'responsive')
                break;
        }

    }

    const [previsibleInvoiceAmount, setPrevisibleInvoiceAmount] = useState<number>(0);
    const calculatePrevisibleAmount = () : void => {
        let amount : number = 0;

        list.forEach((caseReport : CaseReports) => {
            
            if (caseReportsExcluded.indexOf(caseReport.id) === -1){
                const commission = caseReport.company.commission / 100;

                let productPrice : number = caseReport.product.price;
                if (caseReport.reception.request.user.applyReducedPrice){
                     productPrice = caseReport.product.reducedPrice;
                }
    
                const netPrice = productPrice - (productPrice * commission);
                amount += caseReport.reception.pvpMoment;
            }
        });

        amount = Math.round(amount * 100) / 100;
        setPrevisibleInvoiceAmount(amount);
    }


    const getHeaderTable = () : JSX.Element => {
        calculatePrevisibleAmount();
        return <div className="invoice-actions">
                    <Button disabled={generatingReport} type="primary" className="btn-secondary" size="small" onClick={generateReportPdf}>
                        <LoadingOrComponent loading={generatingReport} text={<><FilePdfOutlined /> Generar PDF</>} loadingText="Generando..."/>
                    </Button>
                    <Button type="primary" disabled={selectedDoctors.length > 1} className="btn-secondary" size="small" onClick={()=>{setShowPrepareInvoice(true)}}>
                        <EuroCircleOutlined /> Generar factura {previsibleInvoiceAmount}€ <VerticalLeftOutlined />
                    </Button>
               </div>;
    }

    const getFooterTable = () : JSX.Element => {

        let totalAmountNoDcto : number = 0;
        let totalAmountDcto : number = 0;
        let totalCompany: number = 0;
        let totalNet : number = 0;

        list.forEach((caseReport : CaseReports) => {
            totalAmountDcto += caseReport.product.reducedPrice;
            totalAmountNoDcto += caseReport.product.price;
            let commission : number = caseReport.reception.pvpMoment * (caseReport.reception.commissionMoment / 100);
            totalCompany += commission;
            totalNet += caseReport.reception.pvpMoment - commission;
        });


        const totals : JSX.Element = <span className="totals-resume">
                                        <small className="total-sales"><strong>TOTAL: </strong>{Math.round(totalAmountNoDcto * 100) / 100}€ / {Math.round(totalAmountDcto * 100) / 100}€</small>
                                        <small className="total-company"><strong>TOTAL CIA: </strong>{Math.round(totalCompany * 100) / 100}€</small>
                                        <small className="total-net"><strong>TOTAL NETO: </strong>{Math.round(totalNet * 100) / 100}€</small>
                                     </span>

        return <div className="invoice-resume">
                    Encontrados <strong>{list.length}</strong> registros. 
                    {totals}
                </div>;
        
    }
    

    useEffect(()=>{
        GetDoctors();
        GetPathologists();
        GetCompanies();
        onChangeMode(false);

    },[]);

    useEffect(()=>{
        if (loadingCaseReports){
            setColumns([]);
        }else{
            filterColumns();
        }
    },[loadingCaseReports])


    
    return (
        <div className="invoice-generator">
            <Components.ModalReportPDF onClose={()=>{setShowPdf(false); setPdfData(null);}} visible={showPdf} pdfData={pdfData}/> 

            <Drawer title="Preparar factura" width="40%" placement="right" onClose={()=>{setShowPrepareInvoice(false);}} visible={showPrepareInvoice}>
                <InvoiceParamsComponent doctors={selectedDoctors} columns={columns} list={list} criteria={criteria} caseReportsExcluded={caseReportsExcluded} onFinish={()=>{setShowPrepareInvoice(false);onFinish()}}/>
            </Drawer>
            <Row gutter={16}>
                <Col className="options" xs={24} sm={24} md={5}>
                    <Form form={form} layout='vertical' name="frm-filter-invoice-data" onFinish={onFinish} size='small'>
                        <Switch checkedChildren="Modo factura" unCheckedChildren="Modo normal" onChange={onChangeMode} className="change-mode"/>
                        <Button disabled={!hasDates} type='primary' className="btn-secondary right-position" htmlType='submit'>Cargar <VerticalLeftOutlined /></Button>
                        <Button disabled={!hasDates} type='primary' className="btn-secondary right-position" size='small'><ClearOutlined /></Button>
                        <Divider />
                        <Form.Item name="dateRange" label={
                                            <>
                                                <Button type="primary" className="btn-secondary" onClick={()=>{form.setFieldsValue({dateRange:[moment().startOf('month'), moment().endOf('month')]}); setHasDates(true);}}>
                                                    <Tooltip title="Mes actual" placement='topRight'><CalendarOutlined /></Tooltip>
                                                </Button>
                                                &nbsp;Rango de fechas </>} help={!hasDates?'Seleccione las fechas para activar resto de opciones':''}>
                            <RangePicker style={{width: "100%", minWidth:"100%"}} format={"DD/MM/YYYY"} onChange={(values: any) => {
                                setHasDates(values !== null && values !== undefined && values.length === 2);
                            }}/>
                        </Form.Item>
                        <Form.Item name="columns" label={<><Button onClick={()=>{changeMaxCount('columns')}} type="primary" className="btn-secondary"><Tooltip title="Ver/ocultar contenido" placement='topRight'><VerticalAlignMiddleOutlined /></Tooltip></Button>&nbsp;Columnas informe </>} >
                            <Select
                                    showSearch
                                    disabled={!hasDates}
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Columnas informe PDF"
                                    defaultValue={defaultNormalMode}
                                    onSelect={filterColumns}
                                    onChange={filterColumns}
                                    maxTagCount={maxTagCountColumns ? 'responsive' : undefined}
                                >
                                    <Select.Option value="num_case_report">Número caso estudio</Select.Option>
                                    <Select.Option value="reception_date">Fecha recepción</Select.Option>
                                    <Select.Option value="doctor_name">Nombre médico</Select.Option>
                                    <Select.Option value="patient_name">Nombre paciente</Select.Option>
                                    <Select.Option value="company_name">Compañía</Select.Option>
                                    <Select.Option value="description">Descripción</Select.Option>
                                    <Select.Option value="product_pvp">Pvp producto</Select.Option>
                                    <Select.Option value="net_pvp">Pvp neto</Select.Option>
                                    <Select.Option value="company_commission">Comisión compañía</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="caseReceptionStatus" label={<><Button onClick={()=>{changeMaxCount('caseReceptionStatus')}}  type="primary" className="btn-secondary"><Tooltip title="Ver/ocultar contenido" placement='topRight'><VerticalAlignMiddleOutlined /></Tooltip></Button>&nbsp;Estado recepción</>}>
                            <Select
                                showSearch={false}
                                disabled={!hasDates}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Estado recepción"
                                onChange={()=>{}}
                                maxTagCount={maxTagCountReceptionStatus ? 'responsive' : undefined}

                            >
                                <Select.Option value={0}>Autorizado</Select.Option>
                                <Select.Option value={1}>Pendiente de autorización</Select.Option>
                                <Select.Option value={2}>Caso con incidencia</Select.Option>
                                <Select.Option value={3}>Rechazado por aseguradora</Select.Option>
                            </Select>
                        </Form.Item>
                        <Row gutter={16}>
                            <Col xs={24} sm={12}>
                                <Form.Item name="payed" label="Pagado" >
                                    <Select
                                        disabled={!hasDates}
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Pagados"
                                        onChange={()=>{}}
                                    >
                                        <Select.Option value={'si'}>Si</Select.Option>
                                        <Select.Option value={'no'}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item name="invoiced" label="Facturado" >
                                    <Select
                                        disabled={!hasDates}
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Facturados"
                                        onChange={()=>{}}
                                    >
                                        <Select.Option value={'si'}>Si</Select.Option>
                                        <Select.Option value={'no'}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        
                        <Form.Item name="doctor" label={<><Button onClick={()=>{changeMaxCount('doctors')}} type="primary" className="btn-secondary"><Tooltip title="Ver/ocultar contenido" placement='topRight'><VerticalAlignMiddleOutlined /></Tooltip></Button>&nbsp;Médicos </>} >
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    {
                                        var name : string[] = option?.children;

                                        return name.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                  }
                                disabled={!hasDates}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione Médico"
                                placement='topRight'
                                onChange={(v: number[])=>{
                                    const selected = doctors.filter((d: Doctor)=>{ return v.includes(d.id)});
                                    setSelectedDoctors(selected);
                                }}
                                maxTagCount={maxTagCountDoctors ? 'responsive' : undefined}

                            >
                                {
                                    doctors.map((doctor: Doctor)=>{
                                        return <Select.Option key={doctor.id} value={doctor.id}>{doctor.name} {doctor.surname} {doctor.secondSurname}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="pathologist" label={<><Button onClick={()=>{changeMaxCount('pathologists')}} type="primary" className="btn-secondary"><Tooltip title="Ver/ocultar contenido" placement='topRight'><VerticalAlignMiddleOutlined /></Tooltip></Button>&nbsp;Patólogos </>}>
                            <Select
                                disabled={!hasDates}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione patólogo"
                                placement='topRight'
                                onChange={()=>{}}
                                maxTagCount={maxTagCountPathologist ? 'responsive' : undefined}

                            >
                                {
                                    pathologists.map((patologo: Pathologist)=>{
                                        return <Select.Option key={patologo.id} value={patologo.id}>{patologo.name} {patologo.surname} {patologo.secondSurname}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="company" 
                                    
                                    label={<><Button type="primary" onClick={()=>{changeMaxCount('companies')}} 
                                    className="btn-secondary"><Tooltip title="Ver/ocultar contenido" placement='topRight'><VerticalAlignMiddleOutlined /></Tooltip></Button>&nbsp;Compañías </>} >
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    {
                                        var name : string = option?.children;

                                        return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                  }
                                disabled={!hasDates}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione compañía"
                                placement='topRight'
                                onChange={()=>{}}
                                maxTagCount={maxTagCountCompanies ? 'responsive' : undefined}

                            >
                                {
                                    companies.filter((company: Company) => {
                                        return filterByPrivate ? company.id == 25 : true;
                                    }).map((company: Company)=>{
                                        return <Select.Option key={company.id} value={company.id}>{company.businessName}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="caseReportType" 
                                    label={<><Button type="primary" onClick={()=>{changeMaxCount('caseReportType')}} className="btn-secondary"><Tooltip title="Ver/ocultar contenido" placement='topRight'><VerticalAlignMiddleOutlined /></Tooltip></Button>&nbsp;Tipo de estudio </>} >
                            <Select
                                showSearch={false}
                                disabled={!hasDates}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione tipo de estudio"
                                placement='topRight'
                                onChange={()=>{}}
                                maxTagCount={maxTagCountCaseReportType ? 'responsive' : undefined}

                            >
                                <Select.Option value="biopsia">Biopsia</Select.Option>
                                <Select.Option value="citologia">Citología</Select.Option>
                                <Select.Option value="ihq-molecular">IHQ Molecular</Select.Option>
                                <Select.Option value="microbiologia">Microbiología</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Col>
                <Col className="result" xs={24} sm={24} md={19}>
                    <LoadingOrComponent showSpinner={false} loading={loadingCaseReports} text={
                        !list || list.length === 0 ? <Result
                                                            className="empty"
                                                            status="success"
                                                            title="Realizar búsqueda"
                                                            subTitle="Sin resultados, Utiliza el filtro lateral para mostrar resultados."
                                                        />
                                                        : 
                                                       ( 
                                                           columns.length === 0 ?
                                                                <Result
                                                                    className="empty"
                                                                    status="success"
                                                                    title="Sin columnas"
                                                                    subTitle="Debe especificar al menos una columna para mostrar en el listado."
                                                                />
                                                            :
                                                                <Table 
                                                                    rowKey={(record: CaseReports)=>{return record.id}}
                                                                    title={getHeaderTable}
                                                                    scroll={{ y: 'calc(100vh - 400px)' }}
                                                                    footer={getFooterTable}
                                                                    columns={columns} 
                                                                    dataSource={list} 
                                                                    pagination={{pageSize:300, pageSizeOptions:["300"]}} 
                                                                    />
                                                        )

                    } loadingText={<Result
                        className="empty"
                        status="warning"
                        title={<>Obteniendo datos</>}
                        subTitle={<>Espere, consultando datos... <LoadingOutlined spin /></>}
                    />} />
                    
                </Col>
            </Row>
        </div>
    )
}

export default InvoiceGeneratorComponent;
