import React from 'react'

import { Drawer } from 'antd'

import './drawerInside.scss';

interface DrawerInsideProperties{
    showadd: boolean,
    donclose: any,
    children: React.ReactNode
    dtitle: any,
    dwidth?: string | number
    mobilefixed?: boolean,
    style?: any
    innercontainer?: 'no-inner' | undefined
    closable?: boolean
}

const DrawerInsideComponent = (props : DrawerInsideProperties) : JSX.Element | any => {

    return (
        <Drawer placement="left"
            title={props.dtitle}
            style={{...props.style, position:"absolute", maxHeight: "100vh"}}
            className={props.mobilefixed?'mobile-fixed':''}
            footer={<><span>¿Acaso hay algo más sencillo? Si tienes alguna duda escribe a <a href='mailto:inscripciones@clubatletismomalaga.es'>inscripciones@clubatletismomalaga.es</a></span></>}
            closable={props.closable === undefined || props.closable === null ? true : props.closable}
            onClose={props.donclose}
            getContainer={ props.innercontainer === 'no-inner' ? undefined : false }
            visible={props.showadd}
            width={ props.dwidth || "100%"}
            {...props}>
            {props.children}
        </Drawer>
    )
}

export default DrawerInsideComponent;