import React, { forwardRef, useEffect, useState } from 'react'
import { Form } from 'antd'

//https://github.com/zenoamaro/react-quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


interface BiopsiaProps {
    reset: boolean,
    form: any,
    values?:{
        descripcionMacroscopica: string,
        descripcionMicroscopica: string,
        diagnosticoAnatomopatologico: string,
        nota: string
    }
    
}


// https://stackoverflow.com/questions/37949981/call-child-method-from-parent
const BiopsiaDiagnosisComponent = forwardRef((props: BiopsiaProps, ref: any)=>{
    const formItemHeight : string = "300px";
    const textAreaHeight : string = "250px";

    const [reset, setReset] = useState<boolean>(false);

    const {form, values} = props;

    useEffect(()=>{
        if(props.reset){
            setReset(true);
        }
    }, [props.reset])

    useEffect(()=>{
        if(reset){
            form.setFieldsValue({
                                    "biopsiaDiagnosisData":{ 
                                        "descripcionMacroscopica":"", 
                                        "descripcionMicroscopica":"", 
                                        "diagnosticoAnatomopatologico":"", 
                                        "nota":""
                                    }
                                });
            setReset(false);
        }
    },[reset])

    useEffect(()=>{
        if(values){
            form.setFieldsValue({
                "biopsiaDiagnosisData":{ 
                    "descripcionMacroscopica":values.descripcionMacroscopica ?? "", 
                    "descripcionMicroscopica":values.descripcionMicroscopica ?? "", 
                    "diagnosticoAnatomopatologico":values.diagnosticoAnatomopatologico ?? "", 
                    "nota":values.nota
                }
            });
        }else{
            form.setFieldsValue({
                "biopsiaDiagnosisData":{ 
                    "descripcionMacroscopica":"", 
                    "descripcionMicroscopica":"", 
                    "diagnosticoAnatomopatologico":"", 
                    "nota":""
                }
            });    
        }
    },[values])

    return (<>
                <Form.Item 
                    name={["biopsiaDiagnosisData","descripcionMacroscopica"]}
                    label="Descripción Macroscópica" style={{height: formItemHeight}}>
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
                <Form.Item 
                    name={["biopsiaDiagnosisData","descripcionMicroscopica"]}
                    label="Descripción Microscópica" style={{height: formItemHeight}}>
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
                <Form.Item 
                    name={["biopsiaDiagnosisData","diagnosticoAnatomopatologico"]}
                    label="Diagnóstico Anatomopatológico" style={{height: formItemHeight}}>
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
                <Form.Item 
                    name={["biopsiaDiagnosisData","nota"]}
                    label="Nota" style={{height: formItemHeight}}>
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
    </>)
        
});


export default BiopsiaDiagnosisComponent;