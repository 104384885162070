import { BankOutlined, EuroCircleOutlined, QuestionCircleOutlined, SendOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Form, DatePicker, Input, Checkbox, Switch, Select, Tooltip, Button, Result } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CaseReports from '../../../models/CaseReports';
import Doctor from '../../../models/Doctor';
import ApiResponse from '../../../models/api/ApiResponse';
import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';
import * as Components from '../../index';

import './InvoiceGeneratorComponent.scss';
import LoadingOrComponent from '../../core/utils/LoadingOrComponent';
import { processFile } from '../../../utils/ProcessFile';

interface Props{
    columns: string[],
    list: CaseReports[],
    doctors: Doctor[]
    criteria: any,
    caseReportsExcluded: number[],
    onFinish: any,
}

const InvoiceParamsComponent = (props: Props) : JSX.Element => {
    const [form] = Form.useForm();
    const restApiClient : RestApiClient = new RestApiClient();

    const {columns, list, doctors} = props;

    const prefix = "CDA";
    
    const [showBillingData, setShowBillingData] = useState<boolean>(false);
    const [generatingInvoice, setGeneratingInvoice] = useState<boolean>(false);

    const [showPdf, setShowPdf] = useState<boolean>(false);
    const [pdfData, setPdfData] = useState<any>(null);

    const [lastDataSent, setLastDataSent] = useState<any>(null);

    const onFinish = () : void => {

        let data : any = {
            invoiceDate: form.getFieldValue('invoiceDate'),
            doctorId:  doctors[0].id,
            billingBusinessName: doctors[0].billingBusinessName ?? `${doctors[0].name} ${doctors[0].surname} ${doctors[0].secondSurname}`,                    
            billingAddress: doctors[0].billingAddress ?? doctors[0].contactAddress,                
            billingCif: doctors[0].billingCif ?? doctors[0].dniNie,                
            billingIban: doctors[0].billingIban,
            invoiceNumber: `${prefix}${form.getFieldValue("invoiceNumber")}`,
            description: form.getFieldValue("description"),
            notify: form.getFieldValue("notify"),
            attach: form.getFieldValue("attach"),
            detailed: form.getFieldValue("detailed"),
            paymentType: form.getFieldValue("paymentType"),
        };

        if (showBillingData){
            data.billingbusinessName = form.getFieldValue("billingBusinessName");
            data.billingAddress = form.getFieldValue("billingAddress");
            data.billingCif = form.getFieldValue("billingCif");
            data.billingIban = form.getFieldValue("billingIban");
        }

        const paramsApi : ParamsApi = {
            body:{
                params: data,
                criteria: props.criteria,
                caseReportsExcluded: props.caseReportsExcluded
            }
        }

        setLastDataSent(data);
        setGeneratingInvoice(true);
        restApiClient.fetch("GenerateInvoice", paramsApi)
                        .then((r : ApiResponse | null)=> {
                            if (r && r.code === 200){
                                form.resetFields();
                                GetInvoicePdf(data.invoiceNumber);
                            }
                        })
                        .finally(()=>{
                            setGeneratingInvoice(false);
                        })

        
            
    }

    const GetInvoicePdf = (invoiceNumber: string) : void => {

        const paramsApi : ParamsApi = {
            query:{
                invoiceNumber : invoiceNumber
            }
        }

        restApiClient.generatePdf(paramsApi, "GetInvoicePDF")
            .then((data)=> {
                processFile(data, setPdfData);
                setShowPdf(true);
                props.onFinish();
            })
        
    }

   

    const changeBillingData = (v: any) : void => {
        setShowBillingData(v.target.checked);
    }


    return(
        <div className="invoice-params-component">

            <Components.ModalInvoicePDF selected={lastDataSent?.invoiceNumber} onClose={()=>{setShowPdf(false); setPdfData(null);}} visible={showPdf} pdfData={pdfData}/> 

            {
                columns.length === 0 || doctors.length !== 1 || list.length === 0 ?
                    <Result
                        status="warning"
                        title="Datos imcompletos"
                        extra={
                            <div className="invalid-data-resume">
                                <p>No se puede generar la factura. Alguno de los datos necesarios (columnas, médico o listado de casos de estudio) están vacíos..</p>
                                <p>Resumen:</p>
                                <ul>
                                    <li>Médico: <strong className={doctors.length !== 1 ? 'has-error': 'no-errors'}>{doctors.length}</strong> Médicos seleccionados <small>(Debe seleccionar solo uno)</small></li>
                                    <li>Casos de estudio: <strong className={list.length === 0 ? 'has-error': 'no-errors'}>{list.length}</strong> seleccionados</li>
                                    <li>Columnas seleccionadas: <strong className={columns.length === 0? 'has-error': 'no-errors'}>{columns.length}</strong> seleccionadas</li>
                                </ul>
                            </div>
                        }
                    />
                :
                    <Form form={form} layout='vertical' name="frm-filter-invoice-data" onFinish={onFinish} size='small'>
                        
                        <Form.Item name="invoiceDate" label="Fecha de factura" initialValue={moment()}  rules={[{required: true, message: 'Campo obligatorio'}]}>
                            <DatePicker style={{width: "100%"}} defaultValue={moment()} format="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item name="invoiceNumber" label="Número de factura" rules={[{required: true, message: 'Campo obligatorio'}]}>
                            <Input prefix={prefix}/>
                        </Form.Item>

                        <Checkbox onChange={changeBillingData} className="modify-billing-data">Modificar datos facturación <Tooltip title="Si lo deseas puedes modificar los datos fiscales del cliente que aparecerán en la cabecera de la factura."><QuestionCircleOutlined /></Tooltip></Checkbox>
                        {
                            showBillingData ? 
                            <>
                                <Form.Item name="billingBusinessName" label="Nombre Social" initialValue={doctors[0].billingBusinessName ?? `${doctors[0].name} ${doctors[0].surname} ${doctors[0].secondSurname}`}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item name="billingAddress" label="Dirección Facturación" initialValue={doctors[0].billingAddress ?? `${doctors[0].contactAddress}`}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item name="billingCif" label="CIF/NIF Facturación" initialValue={doctors[0].billingCif ??  `${doctors[0].dniNie}`}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item name="billingIban" label="Cuenta IBAN" initialValue={doctors[0].billingIban}>
                                    <Input/>
                                </Form.Item>
                            </>
                            :
                            <p><strong>Atención:</strong> Se usarán los datos de facturación del médico (Salvo que no tenga ningún dato de facturación, en cuyo caso se usarán los personales).</p>
                        }
                        <Form.Item name="description" label="Descripción" help={"La descripción aparecerá en el aviso de disponibilidad de factura."}>
                            <Input.TextArea rows={5}/>
                        </Form.Item>
                        <h4 className="title">
                            Configuración básica
                            <small className="subtitle">Marca las opciones deseadas</small>
                        </h4>
                        
                        <Form.Item name="notify" label={"Avisar al médico de la disponibilidad de su factura."} initialValue={true}>
                            <Switch checkedChildren="Si" unCheckedChildren="No" defaultChecked size='default'/>
                        </Form.Item>
                        <Form.Item name="attach" label={"Adjuntar copia de factura en email de aviso."} initialValue={false}>
                            <Switch checkedChildren="Si" unCheckedChildren="No" size='default'/>
                        </Form.Item>
                        <Form.Item name="detailed" label={"Incluir desglose."} initialValue={true}>
                            <Switch checkedChildren="Si" unCheckedChildren="No" defaultChecked size='default'/>
                        </Form.Item>
                        

                        <Form.Item name="paymentType" label="Tipo de pago" initialValue={"transfer"} rules={[{required: true, message: 'Campo obligatorio'}]}>
                            <Select
                                    style={{ width: '100%' }}
                                    placeholder="Indique tipo de factura"
                                    defaultValue={"transfer"}
                                >
                                    <Select.Option value="transfer"><SendOutlined /> Mediante transferencia</Select.Option>
                                    <Select.Option value="SEPA"><BankOutlined /> Domiciliación SEPA</Select.Option>
                                    <Select.Option value="cash"><EuroCircleOutlined /> Efectivo</Select.Option>
                            </Select>
                        </Form.Item>
                        <div className="btn-actions">
                            <Button type="primary" className="btn-secondary" htmlType='submit' size='middle'>
                                 <LoadingOrComponent loading={generatingInvoice} text={<><SnippetsOutlined /> Generar</>} loadingText="Generando..."/>
                            </Button>
                        </div>
                    </Form>
            }

        </div>
    )
}


export default InvoiceParamsComponent;