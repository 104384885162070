import { AlertOutlined, AlertTwoTone, CheckCircleTwoTone, ClearOutlined, FileDoneOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Alert, Badge, Button, Col, Input, Modal, Row, Select, Skeleton, Table, Tag, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import RestApiClient from '../../../../api/restApiClient';
import ApiResponse from '../../../../models/api/ApiResponse';
import ParamsApi from '../../../../models/api/ParamsApi';
import CaseReports from '../../../../models/CaseReports';
import { CaseReportStatus } from '../../../../models/Constants';
import Preformatted from '../../../../models/Preformatted';


interface Props{
    visible: boolean,
    onClose: any,
    onClearItems: any,
    idsList: number[]
    onRemoveItem: any,
    onFinish: any
}

const ModalCaseReportComponent = (props: Props) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [alert, setAlert] = useState<string>("");
    const [visible, setVisible] = useState<boolean>(false);
    const [bag, setBag] = useState<CaseReports[]>([]);
    const [idsList, setIdsList] = useState<number[]>([]);
    const [preformattedList, setPreformattedList] = useState<Preformatted[]>([]);
    const [loaddingPreformatted, setLoaddingPreformatted] = useState<boolean>(false);
    const [loadingCaseReports, setLoadingCaseReports] = useState<boolean>(false);
    const [selectedPreformattedItem, setSelectedPreformattedItem] = useState<Preformatted | undefined>(undefined);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

    

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text: any, record: CaseReports) => <span>{record.id}</span>,
          },
          {
            title: 'Número',
            dataIndex: 'caseReportNum',
            key: 'caseReportNum',
            render: (text: any, record: CaseReports) => <span>{record.caseReportNum}</span>,
          },
          {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: (text: any, record: CaseReports) => renderColumn(record, getStatusTag(record.status, {minWidth: "100px", textAlign: "center"})),
          },
          {
            title: 'Tipo plantilla',
            dataIndex: 'template',
            key: 'template',
            render: (text: any, record: CaseReports) => {
                let result = <></>
                if (record.product.type !== bag[0].product.type) {
                    result = <span><AlertTwoTone twoToneColor="red"/> <strong>{record.product.type}</strong></span>
                }else{
                    result = <span><CheckCircleTwoTone twoToneColor="green"/> {record.product.type}</span>;
                }
                
                return result;
            },
          },
          {
            title: 'Tipo muestra',
            dataIndex: 'description',
            key: 'description',
            render: (text: any, record: CaseReports) => <span>{record.product.description}</span>,
          },
          {
            title: 'Eliminar',
            dataIndex: 'remove',
            key: 'remove',
            render: (text: any, record: CaseReports) => <Button danger onClick={()=>{removeItem(record)}}><ClearOutlined /> Quitar de la lista</Button>,
          },
      ];

      const getStatusTag = (status: CaseReportStatus, customStyles?: any) : JSX.Element => {
        let result : JSX.Element = <></>;

        switch(status){
            case CaseReportStatus.Finished:
                result = <Tag className="case-report-status-tag" color="magenta" style={customStyles}>{status}</Tag>
                break;
            case CaseReportStatus.Processing:
                result = <Tag className="case-report-status-tag" color="gold" style={customStyles}>{status}</Tag>
                break
            case CaseReportStatus.Received:
                result = <Tag className="case-report-status-tag" color="green" style={customStyles}>{status}</Tag>
                break
            case CaseReportStatus.Empty:
                result = <Tag className="case-report-status-tag" color="purple" style={customStyles}>{status}</Tag>
                break;
        }

        return result;
    }
    
      const renderColumn = (element: CaseReports, children: any, addIcon: boolean = false) : any => {
        const isUrgent : boolean = element.keywords !== undefined && element.keywords.length > 0;

        const finalChildren = isUrgent && addIcon ? <>{children} <AlertTwoTone className="icon-urgent" twoToneColor="red"  /></> : children; 

        return {
            props: {
                className:`${isUrgent ? 'case-report-urgent' : ''}`,
            },
            children: finalChildren,
        };
    }
    
    const checkTypes = () : void => {
        let allTypesAllSame : boolean = true;
        if (bag.length > 0){
            allTypesAllSame = bag.every(caseReport => caseReport.product.type === bag[0].product.type);
        }

        if (bag.length === 0){
            setAlert("No se ha seleccionado ningún caso.");
        }else{
            if (!allTypesAllSame){
                setAlert("No se pueden preformatear de manera masiva casos de diferente tipo. Revisa el listado.");
            }else{
                setAlert("");
            }
        }
    }

    const removeItem = (item: CaseReports) => {
        setIsConfirmed(false);
        const newBag = bag.filter(i => i.id !== item.id);
        const newIds = idsList.filter(i => i !== item.id);
        setIdsList(newIds);
        setBag(newBag);
        props.onRemoveItem(item); 
    }

    const getCaseReportsData = () : void => {
        console.log("From ModalCaseReportComponent");
        const paramsApi : ParamsApi = {
            body:idsList
        }

        setLoadingCaseReports(true);
        restApiClient.fetch("GetCaseReportToMassivePreformmated", paramsApi) 
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setBag(r.data);
                }
            }).finally(()=>{
                setLoadingCaseReports(false);
            });
    }

    const getPreformattedList = () : void => {
        if (bag && bag.length > 0 && bag[0]?.product){
            const paramsApi : ParamsApi = {
                query:{
                    page: 0,
                    N: 0,
                    filter: `${bag[0]?.product.type}|diagnostico`,
                    onlyActives: true
                }
            }
    
            setLoaddingPreformatted(true);
            restApiClient.fetch("GetPreformattedList", paramsApi) 
                .then((r : ApiResponse | null)=> {
                    if (r && r.code === 200){
                        setPreformattedList(r.data.elements);
                    }
                }).finally(()=>{
                    setLoaddingPreformatted(false);
                });
        }
        
    }

    const getPreformatSelector = () : JSX.Element => {
        return <Select placeholder="Seleccione..." className="preforms" style={{width: "100%", marginBottom: "10px"}}
                    onChange={(id: any)=>{
                        setIsConfirmed(false);
                        setSelectedPreformattedItem(preformattedList.find(p => p.id === id));
                    }}
                    >
                        {
                            preformattedList.map((preformatted:Preformatted, index: number)=>{
                                return <Select.Option key={index} value={preformatted.id}>{preformatted.description}</Select.Option>
                            })
                        }
                </Select>
    }

    const applyPreformats = () : void => {
        if (selectedPreformattedItem && bag.length > 0 && alert.length === 0){

            const paramsApi : ParamsApi = {
                body:{
                    PreformattedId: selectedPreformattedItem.id,
                    CaseReportsIds: bag.map(b => b.id)
                }
            }

            setLoaddingPreformatted(true);
            restApiClient.fetch("MassiveCaseReportPreformatted", paramsApi) 
                .then((r : ApiResponse | null)=> {
                   props.onFinish();
                }).finally(()=>{
                    setLoaddingPreformatted(false);
                });
           
        }
    }

    const clearList = () : void => {
        setIsConfirmed(false);
        setIdsList([]);
        setBag([]);
        props.onClearItems();
        props.onClose();
    }

    const confirm = () : void => {
        if (selectedPreformattedItem && bag.length > 0 && alert.length === 0){
            setIsConfirmed(true);
        }
    }

    useEffect(() => {
        setIdsList(props.idsList);
    }, [props.idsList]);

    useEffect(()=>{
        if (visible && idsList && idsList.length > 0){
            getCaseReportsData();
        }
    }, [visible])


    useEffect(()=>{
        setVisible(props.visible);
    },[props.visible])

    useEffect(()=>{
        checkTypes();
        getPreformattedList();
    },[bag])

    

    return(
        <Modal
            title={<><FileDoneOutlined /> Preformateado en masa: {bag.length} casos de estudio seleccionados</>}
            centered
            visible={visible}
            onOk={() => props.onClose()}
            onCancel={() => props.onClose()}
            width={"90vw"}
            footer={
                <>
                        <Button type="primary" onClick={() => props.onClose()}>Cerrar</Button>
                        <Button type="ghost" danger onClick={clearList} disabled={bag.length === 0}><ClearOutlined /> Limpiar</Button>
                        <Button type="ghost" danger onClick={confirm} disabled={bag.length === 0 || !selectedPreformattedItem || isConfirmed}><Tooltip title="Por seguridad esta operación debe confirmarse.">Confirmar <InfoCircleOutlined /></Tooltip></Button>
                        <Button type="primary" danger onClick={applyPreformats} disabled={!selectedPreformattedItem || !isConfirmed}><Tooltip title="Una vez aplicados los cambios éstos no podrán deshacerse. Revise bien toda la información antes de aplicar." placement='topLeft'>Aplicar <WarningOutlined /></Tooltip></Button>
                </>
            }
        >
            {
                loadingCaseReports ? <Typography.Title level={5}>Obteniendo casos de estudio...</Typography.Title> : ''
            }
            <Skeleton loading={loadingCaseReports} active >
                <Row gutter={16}>
                    <Col span={24}>
                        {
                            alert !== "" ? 
                                            <Alert style={{marginBottom: "10px"}} message={alert} type="warning" showIcon /> 
                                        : 
                                            getPreformatSelector()
                        }
                    </Col>
                    <Col span={24}>
                        <Table scroll={{ y: 500 }} rowKey="id" columns={columns} dataSource={bag} pagination={false}/>
                    </Col>
                </Row>
            </Skeleton>
            
        </Modal>
    )
}

export default ModalCaseReportComponent;