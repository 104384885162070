import { CaretLeftOutlined } from '@ant-design/icons';
import { Button, Card, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Patient from '../../../models/Patient';


interface Props{
    coincidences: Patient[],
    onSelect: (coincidence: any) => void,
    numberOfRecords: number,
    onShowAll: (numOfRecords: number) => void
}

const PatientCoincidencesComponent = (props: Props) : JSX.Element => {

    const [coincidences, setCoincidences] = useState<Patient[]>(props.coincidences);
    const [limit, setLimit] = useState<number>(10);


    useEffect(()=>{
        setCoincidences(props.coincidences);
    },[props.coincidences])

    const columns = [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          render(i: number, element: Patient) {
              var finishButton = props.onSelect 
              ?
                  <Button size="small" className="ssm btn-secondary use-coincidence" type="primary" onClick={()=>{
                      props.onSelect(element);
                  }}><CaretLeftOutlined /> Selecciona {element.id}</Button>
              :
                  element.id

              return finishButton;
          }
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render(i: number, element: Patient) {
                return `${element.name} ${element.surname} ${element.secondSurname ? element.secondSurname : ''}`;
            }
        },
        {
          title: 'DNI/NIE',
          dataIndex: 'nif',
          key: 'nif',
          render(i: number, element: Patient) {
            return element.patientNif ? element.patientNif : "N/D";
         }
        },
        {
          title: 'F. Nacimiento',
          dataIndex: 'birthDate',
          key: 'birthDate',
          render(i: number, element: Patient) {
              return element.birthDate ? `${moment(element.birthDate).format("DD/MM/YYYY")}` : "N/D";
          }
        },
      ];

    return (
        <div className="coincidences">
                <Card title="Pacientes coincidentes" extra={<><span>
                            &nbsp;Mostrando <strong>{coincidences.length}/{props.numberOfRecords}</strong> coincidencias
                                <Button type="primary" size="small" className="ssm" style={{marginLeft:10}} onClick={()=>{props.onShowAll(props.numberOfRecords)}}>Ver todo</Button>
                                <Button type="primary" size="small" className="ssm" style={{marginLeft:10}} onClick={()=>{props.onShowAll(10)}}>Ver 10</Button>
                            </span></>} >
                    <Table dataSource={coincidences} columns={columns} size='small' />
                </Card>
            </div>
    )
}


export default PatientCoincidencesComponent;