import { EyeOutlined, FileSearchOutlined, MacCommandOutlined, ManOutlined, SearchOutlined, WomanOutlined } from '@ant-design/icons';
import { Button, Divider, Input, List, Result, Skeleton, Tag  } from 'antd';
import React, { useEffect } from 'react'
import { useState } from 'react';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';
import CaseReports from '../../models/CaseReports';
import Company from '../../models/Company';
import { CaseReportStatus } from '../../models/Constants';
import Patient from '../../models/Patient';
import Product from '../../models/Product';
import SearchData from '../../models/SearchData';
import { useHistory } from "react-router-dom";


import './SearchComponent.scss'
import { LoadingOrText } from '..';


interface SearchComponentProps{}

const SearchComponent = (props : SearchComponentProps) : JSX.Element => {
    const history = useHistory();

    const restApiClient : RestApiClient = new RestApiClient();

    const [limitSearchList] = useState<number>(5);
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [suffix, setFSuffix] = useState<any>(<></>);
    const [searchFilter, setSearchFilter] = useState<string>("undefined");
    const [searchResult, setSearchResult] = useState<SearchData>();

    const searchData = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                filter: searchFilter
            }
        }

        setLoadingData(true);
        restApiClient.fetch("SearchInApp", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setSearchResult(r.data);
                }
            }).finally(()=>{
                setLoadingData(false);
            });
    }

    const handleSearch = (e : any) => {
        setSearchFilter(e.target.value);
    }

    const getSuffix = () : JSX.Element => {

        
        return showSearch ? <span className="btn-search" onClick={()=>{searchData();}}><FileSearchOutlined /></span> : <span style={{color:"#d3d3ce"}}><MacCommandOutlined />+B</span>;
    }

    useEffect(()=>{
        setFSuffix(getSuffix());
    }, [searchFilter]);

    useEffect(()=>{
        setFSuffix(getSuffix());
    }, [showSearch]);

    useEffect(()=>{
        setFSuffix(getSuffix());
    }, []);

    const [keyCode, setKeyCode] = useState<number>(0);
    const [isCtrl, setIsCtrl] = useState<boolean>(false);
    const onKeyCodePress = (e : any) => {
        setKeyCode(e.keyCode);
        setIsCtrl(e.ctrlKey);
    }

    const handleKeyDown = () => {
        if (isCtrl && keyCode === 66) {
            var search = document.getElementById("search");
            search?.focus();
            setShowSearch(!showSearch);
        }else if (showSearch && keyCode === 13) {
            searchData();
        }else if (keyCode === 27) {
            setShowSearch(false);
        }
    } 

    useEffect(()=>{
        handleKeyDown();
    }, [isCtrl, keyCode]);


    useEffect(() => {

        window.addEventListener("keydown", onKeyCodePress);
    
        return () => window.removeEventListener("keydown", onKeyCodePress) 
    
      }, []);

    const getStatusTag = (status: CaseReportStatus, customStyles?: any) : JSX.Element => {
        let result : JSX.Element = <></>;

        switch(status){
            case CaseReportStatus.Finished:
                result = <Tag className="case-report-status-tag" color="magenta" style={customStyles}>{status}</Tag>
                break;
            case CaseReportStatus.Processing:
                result = <Tag className="case-report-status-tag" color="gold" style={customStyles}>{status}</Tag>
                break
            case CaseReportStatus.Received:
                result = <Tag className="case-report-status-tag" color="green" style={customStyles}>{status}</Tag>
                break
            case CaseReportStatus.Empty:
                result = <Tag className="case-report-status-tag" color="purple" style={customStyles}>{status}</Tag>
                break;
        }

        return result;
    }

    const getSexTag = (sex: string, customStyles?: any) : JSX.Element => {
        let result : JSX.Element = <></>;

        switch(sex){
            case "mujer":
                result = <Tag className="case-report-sex-tag" color="purple" style={customStyles}><WomanOutlined /> {sex}</Tag>
                break;
            default:
                result = <Tag className="case-report-sex-tag" color="gold" style={customStyles}><ManOutlined /> {sex}</Tag>
                break;
        }

        return result;
    }

    const goCaseReportDetail = (id: number) : void => {
        history.push(`/case-report/${id}`);
    }

    const goCompanyDetail = (id: number) : void => {
        history.push(`/company/edit/${id}`);
    }

    const goPatientDetail = (id: number) : void => {
        history.push(`/patient/edit/${id}`);
    }

    
    return (
        <div className="search" onMouseLeave={()=>{setShowSearch(false)}}>
            <Input id="search" onClick={()=>{setShowSearch(true)}} onFocus={()=>{setShowSearch(true)}} onChange={handleSearch} onBlur={handleSearch}
             className="search-input" prefix={<SearchOutlined className="search-icon"/>} placeholder="Buscador" suffix={suffix} size="small"/>
            <div className={`search-result ${showSearch ? 'visible' : 'hidden'}`} >
                <div className="title-result">
                    Resultado búsqueda
                    <small style={{position: "absolute", right: "8px"}}>Cerrar ESC o <span className="shortcut"><MacCommandOutlined />+B</span></small>
                </div>
                {
                    (! loadingData && searchResult && (searchResult.caseReports.length > 0 || searchResult.companies.length > 0 ||  searchResult?.patients.length > 0 || searchResult?.products.length > 0) )? 
                    <div>
                        {
                            searchResult.caseReports && searchResult.caseReports.length > 0 ?
                            <div>
                                <List
                                    className='search-list'
                                    size="small"
                                    header={<div>Casos de estudio</div>}
                                    footer={<div>
                                                <small>
                                                    {
                                                        searchResult.caseReports.length > limitSearchList ?
                                                        <>
                                                            <span>Mostrando {limitSearchList} de {searchResult.caseReports.length}</span>
                                                            <small style={{position:"absolute", right: "10px"}}>
                                                                <a href="#" onClick={()=>{setShowSearch(false);}}>Ver todos</a>
                                                            </small>
                                                        </>
                                                        :
                                                        <>
                                                            <span>Mostrando {searchResult.caseReports.length}</span>
                                                        </>
                                                    }
                                                </small>
                                            </div>}
                                    bordered
                                    dataSource={searchResult.caseReports.length > limitSearchList ? searchResult.caseReports.slice(0, limitSearchList) : searchResult.caseReports}
                                    renderItem={(item: CaseReports) => <List.Item onClick={()=>{goCaseReportDetail(item.id)}}><span className="link">{item.caseReportNum} <EyeOutlined className="see"/></span> {getStatusTag(item.status, {position:"absolute", right: 0, })}</List.Item>}
                                />
                            </div>
                            :
                            <></>
                        }

                        {
                            searchResult.patients && searchResult.patients.length > 0 ?
                            <div>
                                <List
                                    className='search-list'
                                    size="small"
                                    header={<div>Pacientes</div>}
                                    footer={<div>
                                                <small>
                                                    {
                                                        searchResult.patients.length > limitSearchList ?
                                                        <>
                                                            <span>Mostrando {limitSearchList} de {searchResult.patients.length}</span>
                                                            <small style={{position:"absolute", right: "10px"}}>
                                                                <a href="#" onClick={()=>{setShowSearch(false);}}>Ver todos</a>
                                                            </small>
                                                        </>
                                                        :
                                                        <>
                                                            <span>Mostrando {searchResult.patients.length}</span>
                                                        </>
                                                    }
                                                </small>
                                            </div>}
                                    bordered
                                    dataSource={searchResult.patients.length > limitSearchList ? searchResult.patients.slice(0, limitSearchList) : searchResult.patients}
                                    renderItem={(item: Patient) => <List.Item onClick={()=>{goPatientDetail(item.id)}}>
                                                                            <span className="link">{item.name} {item.surname} {item.secondSurname}  <EyeOutlined className="see"/></span>
                                                                            {getSexTag(item.sex, {position:"absolute", right: 0, })}
                                                                            
                                                                    </List.Item>}
                                />
                            </div>
                            :
                            <></>
                        }

                        {
                            searchResult.companies && searchResult.companies.length > 0 ?
                            <div>
                                <List
                                    className='search-list'
                                    size="small"
                                    header={<div>Compañías</div>}
                                    footer={<div>
                                                <small>
                                                    {
                                                        searchResult.companies.length > limitSearchList ?
                                                        <>
                                                            <span>Mostrando {limitSearchList} de {searchResult.companies.length}</span>
                                                            <small style={{position:"absolute", right: "10px"}}>
                                                                <a href="#" onClick={()=>{setShowSearch(false);}}>Ver todos</a>
                                                            </small>
                                                        </>
                                                        :
                                                        <>
                                                            <span>Mostrando {searchResult.companies.length}</span>
                                                        </>
                                                    }
                                                </small>
                                            </div>}
                                    bordered
                                    dataSource={searchResult.companies.length > limitSearchList ? searchResult.companies.slice(0, limitSearchList) : searchResult.companies}
                                    renderItem={(item: Company) => <List.Item onClick={()=>{goCompanyDetail(item.id)}}>
                                                                        <span className="link">{item.businessName}  <EyeOutlined className="see"/></span>
                                                                    </List.Item>}
                                />
                            </div>
                            :
                            <></>
                        }

                        {
                            searchResult.products && searchResult.products.length > 0 ?
                            <div>
                                <List
                                    className='search-list'
                                    size="small"
                                    header={<div>Productos</div>}
                                    footer={<div>
                                                <small>
                                                    {
                                                        searchResult.products.length > limitSearchList ?
                                                        <>
                                                            <span>Mostrando {limitSearchList} de {searchResult.products.length}</span>
                                                            
                                                            <small style={{position:"absolute", right: "10px"}}>
                                                                <a href="#" onClick={()=>{setShowSearch(false);}}>Ver todos</a>
                                                            </small>
                                                        </>
                                                        :
                                                        <>
                                                            <span>Mostrando {searchResult.products.length}</span>
                                                        </>
                                                    }
                                                </small>
                                            </div>}
                                    bordered
                                    itemLayout='vertical'
                                    dataSource={searchResult.products.length > limitSearchList ? searchResult.products.slice(0, limitSearchList) : searchResult.products}
                                    renderItem={(item: Product) => <List.Item>
                                                                        <span style={{width: "100%"}}>{item.description}</span>
                                                                        <small className="link" onClick={()=>{goCompanyDetail(item.idCompany)}}> ({item.companyName}  <EyeOutlined className="see small"/>)</small>
                                                                    </List.Item>}
                                />
                            </div>
                            :
                            <></>
                        }
                    </div>
                :
                    <>
                        {
                            loadingData ? 
                                    <div className="loading-data"><LoadingOrText loading={loadingData} text={"Espere, realizando búsqueda..."}/></div>
                                :
                                <Result
                                    status="success"
                                    title="Sin resultados"
                                    subTitle="Realiza tu búsqueda escribiendo en el buscador."
                                />
                        }
                    </>
                }
                
            </div>
        </div>
    )
}

export default SearchComponent;