import * as Components from '../../../components/index';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';
import CaseReports from '../../../models/CaseReports';
import { LoginContext } from '../../../hooks/context/userContext'

import './CaseReportEditPage.scss';
import { notification, Skeleton } from 'antd';
import { DOCTOR_ROLE } from '../../../utils/Constants';


const CaseReportEditPage = () : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [caseReport, setCaseReport] = useState<CaseReports>();
    const { id } = useParams<any>();
    const history = useHistory();

    let loginContext = useContext(LoginContext);
    
    const getCasereport = () : void => {

        const paramsApi : ParamsApi = {
            query:{
                id: id
            }
        }

        setLoadingData(true);
        restApiClient.fetch("GetCaseReport", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){

                    if (loginContext.idRole === DOCTOR_ROLE && r.data.status !== "finalizado"){
                        notification.error({
                            message: 'Error',
                            description: 'No es posible consultar un caso de estudio que no haya sido finalizado. Será redirigido a la página de inicio.'
                        })
                        setTimeout(() => {
                            history.push("/");
                        }, 2000);
                        
                    }else{
                        setCaseReport(r.data);
                    }
                }
            }).finally(()=>{
                setLoadingData(false);
            });
    }

    useEffect(()=>{
        getCasereport();
    },[]);

    return (
        <div className="case-report-edit-page">
            <Skeleton loading={loadingData} active>
                <Components.CaseReportDetail caseReportData={caseReport}/>
            </Skeleton>
        </div>
    )
}

export default CaseReportEditPage;