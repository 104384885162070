import React, { forwardRef, useEffect, useState } from 'react'
import { Form, Select } from 'antd'

//https://github.com/zenoamaro/react-quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';
import RestApiClient from '../../../api/restApiClient';


interface IHQMolecularReceptionProps {
    reset: boolean,
    form: any,
    values?:{
        muestra:string,
        estudio:string,
        informacionClinica:string
    }
}


// https://stackoverflow.com/questions/37949981/call-child-method-from-parent
const IHQMolecularReceptionComponent = forwardRef((props: IHQMolecularReceptionProps, ref: any)=>{
    const restApiClient : RestApiClient = new RestApiClient();
    
    const formItemHeight : string = "300px";
    const textAreaHeight : string = "250px";

    const [reset, setReset] = useState<boolean>(false);
    const [shortCuts, setShortCuts] = useState<any[]>([]);
    const [selectedShortCode, setSelectedShortCode] = useState<string[]>([]);

    const { form, values } = props;

    const GetShortcuts = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                page: 0,
                N: 0,
                filter: 'ClinicInfo', 
            }
        }
        restApiClient.fetch("GetOptions", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setShortCuts(r.data.elements);
                }
            })
    }

    useEffect(()=>{
        if(props.reset){
            GetShortcuts();
            setReset(true);
        }
    },[props])

    
    useEffect(()=>{
        if(reset){
            form.setFieldsValue({"iHQMolecularReceptionData":{ "muestra":"CITOLOGÍA VAGINAL TRIPLE TOMA", "estudio":"HIBRIDACIÓN IN SITU -  VIRUS PAPILOMA HUMANO", "informacionClinica":""}});
            setReset(false);
        }
    },[reset])

    useEffect(()=>{
        if(values){
            form.setFieldsValue({"iHQMolecularReceptionData":{ "muestra":values.muestra ?? "", "estudio":values.estudio ?? "", "informacionClinica":values.informacionClinica ?? ""}});
        }else{
            form.setFieldsValue({"iHQMolecularReceptionData":{ "muestra":"CITOLOGÍA VAGINAL TRIPLE TOMA", "estudio":"HIBRIDACIÓN IN SITU -  VIRUS PAPILOMA HUMANO", "informacionClinica":""}});
        }
    },[values])


    return (<>
                <small>IHQ Molecular</small>
                <Form.Item 
                    name={["iHQMolecularReceptionData","muestra"]}
                    rules={[{ required: true, message: "Debe seleccionar una muestra"}]}
                    label="Muestra">
                    <Select style={{ width: "100%" }} >
                        <option id="1489666217809" value="CITOLOGÍA VAGINAL TRIPLE TOMA">CITOLOGÍA VAGINAL TRIPLE TOMA</option>
                        <option id="1489666263089" value="CITOLOGÍA VAGINAL SIMPLE">CITOLOGÍA VAGINAL SIMPLE</option>
                        <option id="1489666292785" value="CITOLOGÍA ENDOCERVICAL">CITOLOGÍA ENDOCERVICAL</option>
                        <option id="1489666321059" value="CITOLOGÍA ENDOMETRIAL">CITOLOGÍA ENDOMETRIAL</option>
                        <option id="1489666346209" value="CITOLOGÍA VULVAR">CITOLOGÍA VULVAR</option>
                        <option id="1489666392209" value="CITOLOGÍA SECRECIÓN MAMARIA">CITOLOGÍA SECRECIÓN MAMARIA</option>
                        <option id="1489767387295" value="ANEJO UTERINO">ANEJO UTERINO</option>
                        <option id="1489767406592" value="CÉRVIX">CÉRVIX</option>
                        <option id="1489767424368" value="DECIDUA">DECIDUA</option>
                        <option id="1489767439265" value="ENDOMETRIO">ENDOMETRIO</option>
                        <option id="1489767456801" value="FETO-EMBRIÓN">FETO-EMBRIÓN</option>
                        <option id="1489767480992" value="HISTERECTOMÍA SUBTOTAL CON ANEJOS">HISTERECTOMÍA SUBTOTAL CON ANEJOS</option>
                        <option id="1489767495265" value="HISTERECTOMÍA SUBTOTAL SIN ANEJOS">HISTERECTOMÍA SUBTOTAL SIN ANEJOS</option>
                        <option id="1489767512705" value="HISTERECTOMÍA TOTAL CON ANEJOS">HISTERECTOMÍA TOTAL CON ANEJOS</option>
                        <option id="1489767525617" value="HISTERECTOMÍA TOTAL SIN ANEJOS">HISTERECTOMÍA TOTAL SIN ANEJOS</option>
                        <option id="1489767539794" value="MAMA">MAMA</option>
                        <option id="1489767556144" value="MAMA Y VACIAMIENTO AXILAR">MAMA Y VACIAMIENTO AXILAR</option>
                        <option id="1489767587186" value="MAMA (PEZÓN)">MAMA (PEZÓN)</option>
                        <option id="1489767603105" value="MIOMETRIO">MIOMETRIO</option>
                        <option id="1489767620033" value="OVARIO">OVARIO</option>
                        <option id="1489767632576" value="PIEL">PIEL</option>
                        <option id="1489767644771" value="PLACENTA">PLACENTA</option>
                        <option id="1489767660178" value="TROMPA UTERINA">TROMPA UTERINA</option>
                        <option id="1489767674321" value="VAGINA">VAGINA</option>
                        <option id="1489767687633" value="VULVA">VULVA</option>
                        <option id="1589544169656" value="ANO">ANO</option>
                        <option id="1589544169700" value="MUCOSA BUCAL">MUCOSA BUCAL</option>
                        <option id="1589544169853" value="FARINGE">FARINGE</option>
                        <option id="1589544169854" value="PENE">PENE</option>
                        <option id="1589544169855" value="SANGRE">SANGRE</option>
                    </Select>
                </Form.Item>
                <Form.Item 
                    name={["iHQMolecularReceptionData","estudio"]}
                    rules={[{ required: true, message: "Debe seleccionar un estudio"}]}
                    label="Estudio">
                    <Select style={{ width: "100%" }} >
                        <option id="1489767892274" value="HIBRIDACIÓN IN SITU -  VIRUS PAPILOMA HUMANO">HIBRIDACIÓN IN SITU -  VIRUS PAPILOMA HUMANO</option>
                        <option id="1489767921058" value="TÉCNICAS INMUNOHISTOQUÍMICAS">TÉCNICAS INMUNOHISTOQUÍMICAS</option>
                        <option id="1619370154833" value="HIBRIDACIÓN IN SITU - VIRUS HERPES SIMPLE">HIBRIDACIÓN IN SITU - VIRUS HERPES SIMPLE</option>
                        <option id="1635701391845" value="DETECCIÓN DNA (PCR) - LEISHMANIA">DETECCIÓN DNA (PCR) - LEISHMANIA</option>
                        <option id="1635701391866" value="DETECCIÓN DNA (PCR) - INFECCIONES UROGENITALES">DETECCIÓN DNA (PCR) - INFECCIONES UROGENITALES</option>
                        <option id="1635701391888" value="ESTUDIO MICROBIOTA VAGINAL">ESTUDIO MICROBIOTA VAGINAL</option>
                        <option id="1635701391888" value="TEST PRENATAL NO INVASIVO">TEST PRENATAL NO INVASIVO</option>
                    </Select>
                </Form.Item>
                <Form.Item 
                    name={["iHQMolecularReceptionData","informacionClinica"]}
                    label="Información clínica" style={{height: formItemHeight}} initialValue="">
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
                <Select 
                    mode="tags" 
                    value={selectedShortCode}
                    onChange={(v)=>{ setSelectedShortCode(v)}}
                    style={{ width: '100%', marginBottom: 5 }} 
                    placeholder="Atajos" 
                    placement='topLeft'
                    onDeselect={(v: string)=>{
                        try{
                            let ob : string = form.getFieldValue("iHQMolecularReceptionData").informacionClinica;

                            ob = ob.replace(`${v}`, "");

                            form.setFieldsValue({"iHQMolecularReceptionData":{ "informacionClinica":ob}});

                        }catch(exception){

                        }
                    }} 
                    onSelect={(v: string)=>{
                        let ob : string = form.getFieldValue("iHQMolecularReceptionData").informacionClinica;

                        if (ob){
                            ob = `${ob} ${v} `;
                        }else{
                            ob = ` ${v} `;
                        }

                        form.setFieldsValue({"iHQMolecularReceptionData":{ "informacionClinica":ob}});
                    }}
                >
                    {
                        shortCuts.map((s: any) => {
                            return <Select.Option key={s.key} value={s.value}>{s.value}</Select.Option>
                        })
                    }
                </Select>
            </>)
        

            
});


export default IHQMolecularReceptionComponent;
