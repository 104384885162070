import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Companies'

export const CompaniesEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetFilteredCompanies"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetAllCompanies"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetCompany"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("AddCompany"),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)
        .setActionName("UpdateCompanyBasicData"),
]