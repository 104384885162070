import React, { useState, useEffect } from 'react'

import ParamsApi from '../../models/api/ParamsApi';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';

import './StatisticsComponent.scss'

import { Column, Area, DualAxes } from '@ant-design/plots';

import moment from 'moment';
import { Col, Divider, Row, Statistic, Tooltip, Typography } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, SwapOutlined, WarningOutlined } from '@ant-design/icons';


interface Props{
    fromYear: number,
    toYear: number
}
const StatisticsBillingComponent = (props: Props) : JSX.Element => {

    const restApiClient : RestApiClient = new RestApiClient();

    const [loadingDataBasic, setLoadingDataBasic] = useState<boolean>(false);
    
    const [dataBasicFrom, setDataBasicFrom] = useState<any[]>([]);
    const [dataBasicTo, setDataBasicTo] = useState<any[]>([]);

    const [dataBasicMonthlyFrom, setDataBasicMonthlyFrom] = useState<any[]>([]);
    const [dataBasicMonthlyTo, setDataBasicMonthlyTo] = useState<any[]>([]);

    const [dataBasicCaseReportsMonthlyFrom, setDataBasicCaseReportsMonthlyFrom] = useState<any[]>([]);
    const [dataBasicCaseReportsMonthlyTo, setDataBasicCaseReportsMonthlyTo] = useState<any[]>([]);

    const [loadingDataBeginOfDays, setLoadingDataBeginOfDays] = useState<boolean>(false);
    const [dataBeginOfDays, setDataBeginOfDays] = useState<any>();

    const {fromYear, toYear} = props;

    
    const getBasicStatisticsCaseReportsMonthly = (year: number, callBack: any) : void => {
        const paramsApi : ParamsApi = {
            query:{
                "year": year
            }
        }

        restApiClient.fetch("getBasicStatisticsCaseReportsMonthly", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    const items = [{
                        "month": moment().month(0).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":0,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(1).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":1,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(2).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":2,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(3).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":3,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(4).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":4,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(5).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":5,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(6).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":6,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(7).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":7,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(8).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":8,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(9).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":9,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(10).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":10,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(11).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalCaseReports": 0,
                        "year":`${year}`,
                        "index":11,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    }];

                    for(let i = 0; i < r.data.length; i++){
                        items[r.data[i].month-1]["totalCommissions"] = `${r.data[i].totalCommissions}€`;
                        items[r.data[i].month-1]["totalCommissionsNumber"] = r.data[i].totalCommissions;
                        items[r.data[i].month-1]["totalGross"] = `${r.data[i].totalGross}€`;
                        items[r.data[i].month-1]["totalGrossNumber"] = r.data[i].totalGross;
                        items[r.data[i].month-1]["totalNet"] = `${r.data[i].totalNet}€`;
                        items[r.data[i].month-1]["totalNetNumber"] = r.data[i].totalNet;
                        items[r.data[i].month-1]["totalCaseReports"] = r.data[i].totalCaseReports;
                    }

                    callBack(items);
                }
            });
    }

    const getBasicStatisticsMonthly = (year: number, callBack: any) : void => {
        const paramsApi : ParamsApi = {
            query:{
                "year": year
            }
        }

        restApiClient.fetch("getBasicStatisticsMonthly", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    const items = [{
                        "month": moment().month(0).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":0,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(1).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":1,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(2).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":2,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(3).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":3,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(4).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":4,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(5).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":5,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(6).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":6,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(7).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":7,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(8).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":8,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(9).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":9,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(10).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":10,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    },{
                        "month": moment().month(11).format("MMMM").toLocaleUpperCase(),
                        "totalCommissions": '0€',
                        "totalGross": '0€',
                        "totalNet": '0€',
                        "totalRequests": 0,
                        "year":`${year}`,
                        "index":11,
                        "totalCommissionsNumber":0,
                        "totalGrossNumber":0,
                        "totalNetNumber":0
                    }];

                    for(let i = 0; i < r.data.length; i++){
                        items[r.data[i].month-1]["totalCommissions"] = `${r.data[i].totalCommissions}€`;
                        items[r.data[i].month-1]["totalCommissionsNumber"] = r.data[i].totalCommissions;
                        items[r.data[i].month-1]["totalGross"] = `${r.data[i].totalGross}€`;
                        items[r.data[i].month-1]["totalGrossNumber"] = r.data[i].totalGross;
                        items[r.data[i].month-1]["totalNet"] = `${r.data[i].totalNet}€`;
                        items[r.data[i].month-1]["totalNetNumber"] = r.data[i].totalNet;
                        items[r.data[i].month-1]["totalRequests"] = r.data[i].totalRequests;
                    }

                    callBack(items);
                }
            });
    }

    const getBasicStatisticsFromBeginOfDays = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                "year": fromYear
            }
        }
        
        setLoadingDataBeginOfDays(true);
        restApiClient.fetch("GetBasicStatisticsFromBeginOfDays", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setDataBeginOfDays(r.data);
                }
            }).finally(()=>{
                setLoadingDataBeginOfDays(false);
            });
    }

    const getBasicStatistics = (year: number, callBack: any) : void => {
        const paramsApi : ParamsApi = {
            query:{
                "year": year
            }
        }
        
        setLoadingDataBasic(true);
        restApiClient.fetch("GetBasicStatistics", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    
                    callBack(r.data);
                }
            }).finally(()=>{
                setLoadingDataBasic(false);
            });
    }

    useEffect(()=>{
        if (fromYear > 0 && toYear > 0){

            getBasicStatistics(fromYear,setDataBasicFrom);
            getBasicStatistics(toYear,setDataBasicTo);

            getBasicStatisticsFromBeginOfDays();

            getBasicStatisticsMonthly(fromYear, setDataBasicMonthlyFrom);
            getBasicStatisticsMonthly(toYear, setDataBasicMonthlyTo);

            getBasicStatisticsCaseReportsMonthly(fromYear, setDataBasicCaseReportsMonthlyFrom);
            getBasicStatisticsCaseReportsMonthly(toYear, setDataBasicCaseReportsMonthlyTo);
        }
    },[fromYear, toYear]);

    const [all, setAll] = useState<any[]>([]);
    useEffect(()=>{
        setAll([...dataBasicMonthlyFrom, ...dataBasicMonthlyTo])
    },[dataBasicMonthlyFrom, dataBasicMonthlyTo])

    const [allCaseReports, setAllCaseReports] = useState<any[]>([]);
    useEffect(()=>{
        setAllCaseReports([...dataBasicCaseReportsMonthlyFrom, ...dataBasicCaseReportsMonthlyTo])
    },[dataBasicCaseReportsMonthlyFrom, dataBasicCaseReportsMonthlyTo])

      const [grownIcon, setGrownIcon] = useState<JSX.Element>(<></>)
      const [grownValue, setGrownValue] = useState<number>(0);
      const [realGrownValue, setRealGrownValue] = useState<number>(0);
      useEffect(()=>{
          if (dataBasicFrom && dataBasicTo && dataBasicFrom.length > 0 && dataBasicTo.length > 0){
  
            let fromData = dataBasicFrom;
            let toData = dataBasicTo;
  
            const totalNetAmountFrom : number = fromData[0]?.totalNet;
            const totalNetAmountTo : number = toData[0]?.totalNet;

            const realValue = (100 * totalNetAmountTo) / totalNetAmountFrom;
            const diff = (Math.round((realValue)*100) / 100) / 100; //Queremos saber el número de veces que hay que multiplicar el valor actual para alcanzar el máximo
  
            setGrownValue(Math.round(diff * 100) / 100);
            setRealGrownValue(realValue);
            if (diff > 1){
                  setGrownIcon(<ArrowUpOutlined  style={{color:"green"}}/>);
            }else if (diff < 1){
                  setGrownIcon(<ArrowDownOutlined style={{color:"red"}}/>);
            }else{
                // ==
                setGrownIcon(<SwapOutlined />);
            }
          }else{
            setGrownValue(0);
            setGrownIcon(<SwapOutlined />);
          }
      },[dataBasicMonthlyFrom, dataBasicMonthlyTo]);

    return(
        <div className="statistics-company">
            <Row gutter={16}>
                <Divider />
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Beneficios Brutos en ${fromYear}`} value={dataBasicFrom[0]?.totalGross.toFixed(2)} suffix="€" decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Comisiones en ${fromYear}`} value={dataBasicFrom[0]?.totalCommissions.toFixed(2)} suffix="€" decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Beneficios Netos en ${fromYear}`} value={dataBasicFrom[0]?.totalNet.toFixed(2)} suffix="€" decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Solicitudes en ${fromYear}`} value={dataBasicFrom[0]?.totalRequests} decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Beneficios Brutos Totales`} value={dataBeginOfDays?.totalGross.toFixed(2)} suffix="€" decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Comisiones Totales`} value={dataBeginOfDays?.totalCommissions.toFixed(2)} suffix="€" decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Netos Totales`} value={dataBeginOfDays?.totalNet.toFixed(2)} suffix="€" decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Solicitudes Totales`} value={dataBeginOfDays?.totalRequests} decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Beneficios Brutos en ${toYear}`} value={dataBasicTo[0]?.totalGross.toFixed(2)} suffix="€" decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Comisiones en ${toYear}`} value={dataBasicTo[0]?.totalCommissions.toFixed(2)} suffix="€" decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Beneficios Netos en ${toYear}`} value={dataBasicTo[0]?.totalNet.toFixed(2)} suffix="€" decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic title={`Solicitudes en ${toYear}`} value={dataBasicTo[0]?.totalRequests} decimalSeparator="," groupSeparator={"."}/>
                </Col>
                <Col xs={12} sm={8} md={8} lg={3}>
                    <Statistic
                        title="Crecimiento"
                        value={grownValue}
                        decimalSeparator="," groupSeparator={"."}
                        formatter={(value) => <span style={{color:value>=1 ? 'green' : 'red'}}>x{value} <Tooltip title={`Sin el redondeo el valor de crecimiento obtenido es de: x${realGrownValue}`}><WarningOutlined style={{color:"orange", fontSize:".8em"}}/></Tooltip></span>}
                        prefix={grownIcon}
                    />
                </Col>
                <Divider />
                <Col xs={0} sm={24} lg={12}>
                <Typography.Title level={5}>
                    Solictudes
                </Typography.Title>
                <Column 
                    key={1}
                    isGroup
                    dodgePadding={2}
                    height={200}
                    tooltip={
                        {
                            fields: ['totalRequests', 'totalCommissions', 'totalGross', 'totalNet']
                        }
                    }
                    columnStyle={{
                        radius: [10, 10, 0, 0],
                    }}
                    meta={
                            {
                                "totalRequests":
                                    {
                                        alias:'Total solicitudes'
                                    },
                                    "totalCommissions":
                                    {
                                        alias:'Total comisiones'
                                    },
                                    "totalGross":
                                    {
                                        alias:'Total Bruto'
                                    },
                                    "totalNet":
                                    {
                                        alias:'Total Neto'
                                    }

                            }
                        } 
                    data={all} 
                    seriesField={'year'}
                    xField={'month'} 
                    yField={'totalRequests'} 
                    label={{
                            position: 'middle',
                            }}
                    />
            </Col>
            <Col xs={0} sm={24} lg={12}>
                <Typography.Title level={5}>
                    Casos de estudio
                </Typography.Title>
                <Column 
                    key={11}
                    isGroup
                    dodgePadding={2}
                    height={200}
                    tooltip={
                        {
                            fields: ['totalCaseReports', 'totalCommissions', 'totalGross', 'totalNet']
                        }
                    }
                    columnStyle={{
                        radius: [10, 10, 0, 0],
                    }}
                    meta={
                            {
                                "totalCaseReports":
                                    {
                                        alias:'Total casos de estudio'
                                    },
                                    "totalCommissions":
                                    {
                                        alias:'Total comisiones'
                                    },
                                    "totalGross":
                                    {
                                        alias:'Total Bruto'
                                    },
                                    "totalNet":
                                    {
                                        alias:'Total Neto'
                                    }

                            }
                        } 
                    data={allCaseReports} 
                    seriesField={'year'}
                    xField={'month'} 
                    yField={'totalCaseReports'} 
                    label={{
                            position: 'middle',
                            }}
                    />
            </Col>
            <Col xs={24} sm={24} lg={24}>
                <Typography.Title level={5} style={{marginTop: "10px"}}>
                    Crecimiento económico
                </Typography.Title>
                <DualAxes 
                    data={[dataBasicMonthlyFrom,dataBasicMonthlyTo]}
                    xField={'month'}
                    height={200}
                    tooltip={{
                            fields: ['totalNet','totalCommissions', 'totalGross']
                        }}
                    yField={['totalNetNumber', 'totalNetNumber']}
                    
                    meta={
                        {
                            "totalRequests":
                            {
                                alias:'Total solicitudes'
                            },
                            "totalCommissions":
                            {
                                alias:'Total comisiones'
                            },
                            "totalGross":
                            {
                                alias:'Total Bruto'
                            },
                            "totalNet":
                            {
                                alias:'Total Neto'
                            },
                            "totalNetNumber":
                            {
                                alias:'Total Neto'
                            }
                        }
                    } 
                    geometryOptions={[
                        {
                            legend: {
                                title: {
                                    text:`Año ${fromYear}`,
                                }
                              },
                            //Geometry of the first series (dateFrom)
                            geometry: 'line',
                            seriesField: 'year',
                            smooth:true,
                            color: '#6395f9',
                            lineStyle: {
                                lineWidth: 3,
                                lineDash: [5, 5],
                              },
                              
                        },
                        {
                            //Geometry of the second series (dateTo)
                            smooth:true,
                            geometry: 'line',
                            seriesField: 'year',
                            color: '#62daab',
                            lineStyle: {
                                lineWidth: 3,
                                opacity: 1,
                              },label: {
                                formatter: (datum: any, obj: any, index:number) => {
                                    let result = ``;
                                    //If totalNetNumber of the first series is greater than totalNetNumber of the second series
                                    if (datum && dataBasicMonthlyFrom[index] && datum.totalNetNumber > dataBasicMonthlyFrom[index]["totalNetNumber"]){
                                        result = `↑`;
                                    }else if ((datum && dataBasicMonthlyFrom[index] && datum.totalNetNumber < dataBasicMonthlyFrom[index]["totalNetNumber"])){
                                        result = `↓`;
                                    }else{
                                        result = `=`;
                                    }

                                  return result;
                                },
                              },
                              point: {
                                shape: 'circle',
                                size: 4,
                                style: {
                                  opacity: 0.5,
                                  stroke: '#5AD8A6',
                                  fill: '#fff',
                                },
                              }
                        },
                        
                    ]}
                    
                />
                </Col>
            </Row>
        </div>
    )
}

export default StatisticsBillingComponent