import { ClearOutlined, ReloadOutlined, RotateLeftOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { LoadingOrText } from "../../..";
import RestApiClient from '../../../../api/restApiClient';
import ApiResponse from '../../../../models/api/ApiResponse';
import ParamsApi from '../../../../models/api/ParamsApi';
import Request from "../../../../models/Request";
import { getItemStatus } from "../../../../utils/RequestsUtils";


import './OrderRequestComponent.scss';


const { Option } = Select;



interface IOrderRequestComponent {
    onFinish?: any
}

const OrderRequestComponent = (props: IOrderRequestComponent) : JSX.Element => {
    const [form] = Form.useForm();
    const restApiClient : RestApiClient = new RestApiClient();
    const [savingData, setSavingData] = useState<boolean>(false);
    const [requests, setRequests] = useState<Request[]>([]);
    const [loadingRequests, setLoadingRequests] = useState<boolean>(false);
    const [notify, setNotify] = useState<boolean>(false);

    const getPendingRequests = () : void => {
        const params : ParamsApi = {
            query: {
                status: 'pendiente'
            }
        };
        setLoadingRequests(true);
        restApiClient.fetch('GetRequestsByStatus', params).then((r : ApiResponse | null)=> {
                if (r?.code === 200 && r.data) {
                    setRequests(r.data);
                    form.setFieldsValue({"requestId":null});
                }
        }).finally(()=> {
            setLoadingRequests(false);
        });
    };

    const changeNotify = (e : any) : void => {
        setNotify(e.target.checked);
    }
    
    const onFinish = () => {
        const paramsApi : ParamsApi = {
            body : form.getFieldsValue()
        }

        paramsApi.body.notify = notify;


        setSavingData(true);
        restApiClient.fetch("RequestOrdered", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r?.code !== 500) {
                    getPendingRequests();
                    form.resetFields();

                    if (props.onFinish){
                        props.onFinish();
                    }
                }
            })
            .finally(()=>{
                setSavingData(false);
            })
    }

    const resetFields = () => {
        form.resetFields();
    }

    useEffect(()=>{
        getPendingRequests();
    },[]);

    return <Form form={form} onFinish={onFinish} layout="vertical" className="order-requests">
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item name="requestId" label="Solicitud" rules={[{ required: true, message: "Debe seleccionar un médico."}]} style={{marginBottom:"5px"}}>
                            <Select showAction={["click","focus"]} style={{ width: "100%" }} placeholder={<LoadingOrText loading={loadingRequests} text="Seleccione..."/>}>
                                {
                                    requests.map((rq: Request) => {
                                        return <Option key={rq.id} value={rq.id} title={`${rq.id} - ${rq.requestType.description} - ${rq.start} - ${rq.user.name} ${rq.user.surname} ${rq.user.secondSurname}`}>{getItemStatus(rq.status, "inline")} - {rq.id} - {rq.requestType.description} - {rq.start} - {rq.user.name} {rq.user.surname} {rq.user.secondSurname}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Button onClick={getPendingRequests} type="primary" className="btn-secondary refresh" size="small" loading={savingData}>
                            <LoadingOrText loading={loadingRequests} showWhileLoading={false}  text={<><ReloadOutlined /> Refrescar</>}/>
                        </Button>
                    </Col>
                    <Col xs={24}>
                        <Form.Item initialValue={"N/D"} name="locator" label="Número de seguimiento" rules={[{ required: true, message: "Debe indicar el localizador para el seguimiento. Si no lo tiene indique N/D."}]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="observations" label="Observaciones">
                            <Input.TextArea rows={5}/>
                        </Form.Item>
                        <Form.Item name="notify" >
                            <Checkbox onChange={changeNotify}>
                                Notificar al médico el cambio de estado de la solicitud
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <div className="b-component-actions">
                            <Button className="btn-save" onClick={resetFields} type="default">
                                <ClearOutlined /> Limpiar campos
                            </Button>
                            <Button disabled={savingData}  className="btn-save" type="primary" htmlType="submit">
                                <LoadingOrText loading={savingData} showWhileLoading={false} text={<><RotateLeftOutlined /> Guarda y mantén</>}/>
                            </Button>
                        </div>
                    </Col>
                    
                </Row>

           </Form>;
};

export default OrderRequestComponent;