import React, { useEffect, useState } from 'react'


import './helper.scss'

import DashboardHelperComponent from './helpers/dashboardHelper'
import LoginHelperComponent from './helpers/loginHelper'
import CaseReportsHelperComponent from './helpers/caseReportsHelper'
import CompaniesHelperComponent from './helpers/companiesHelper'
import PatientsHelperComponent from './helpers/patientsHelper'
import PreformattedHelpercomponent from './helpers/preformattedHelper'
import InvoicesHelperComponent from './helpers/invoicesHelper'

interface HelperProps {
    location: string,
    extendedProps?: any[],
    classname: string,
    properties?: any
}

const HelperComponent = (props : HelperProps) : any => {

    const [helperBalls, setHelperBalls] = useState<JSX.Element>(<LoginHelperComponent />)

    useEffect(()=> {
        if (props.location === "login"){
            setHelperBalls(<LoginHelperComponent />);
        }else if(props.location === "dashboard"){
            setHelperBalls(<DashboardHelperComponent />)
        }else if(props.location === "case-report" || props.location === "casereport"){
            setHelperBalls(<CaseReportsHelperComponent properties={props.properties}/>)
        }else if(props.location === "company"){
            setHelperBalls(<CompaniesHelperComponent  properties={props.properties}/>)
        }else if(props.location === "patient"){
            setHelperBalls(<PatientsHelperComponent  properties={props.properties}/>)
        }else if(props.location === "preformatted"){
            setHelperBalls(<PreformattedHelpercomponent  properties={props.properties}/>)
        }else if(props.location === "invoice" || props.location === "invoice-list" || props.location === "my-invoices"){
            setHelperBalls(<InvoicesHelperComponent  properties={props.properties}/>)
        }
    }, [props])


    return (
        <div className={props.classname}>{helperBalls}</div>
    )
}

export default HelperComponent;