import { BankOutlined, CheckCircleTwoTone, EuroCircleOutlined, FileDoneOutlined, FilePdfOutlined, InfoCircleOutlined, OrderedListOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Modal, Select, Tooltip } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { Invoice } from '../../../models/Invoice';
import { LoginContext } from '../../../hooks/context/userContext'
import ParamsApi from '../../../models/api/ParamsApi';

import * as Components from '../../index'

import './InvoiceListcomponent.scss'
import { ADMIN_ROLE, DOCTOR_ROLE } from '../../../utils/Constants';
import { processFile } from '../../../utils/ProcessFile';
import RestApiClient from '../../../api/restApiClient';
import LoadingOrComponent from '../../core/utils/LoadingOrComponent';
interface Props {}

const InvoiceListComponent = (props: Props) : JSX.Element => {
    let loginContext = useContext(LoginContext);
    const restApiClient : RestApiClient = new RestApiClient();

    const [columns, setColumns] = useState<any[]>([]);
    
    const [reloadCounter, setReloadCounter] = useState<number>(0);
    const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
    const [pdfData, setPdfData] = useState<any>(null);
    const [showPdf, setShowPdf] = useState<boolean>(false);
  
    const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState<string>('');

    const [selectedYear347, setSelectedYear347] = useState<number>(moment().year()-1);  
    // Contiene el modal para indicar el año
    const [show347YearSelector, setShow347YearSelector] = useState<boolean>(false);
    // Contiene el modal para visualizar el pdf
    const [showPdf347, setShowPdf347] = useState<any>(null);

    const [showPdfList, setShowPdfList] = useState<any>(null);


    const [filter, setFilter] = useState<string>('');
    const [order, setOrder] = useState<string>('');

    const changeSelecteYear = (value: number) : void => {
        setSelectedYear347(value);
    }
    
    const onChangeLastRequests = (e: any) : void => {
        setFilter(e);
    }

    const downloadPdf = (): void => {
        const paramsApi : ParamsApi = {
            query:{
                filter : filter,
                orderBy: order
            }
        }

        setLoadingPdf(true);
        restApiClient.generatePdf(paramsApi, "GenerateInvoiceListPDF")
            .then((data:any)=> {
                processFile(data, setPdfData);
                setShowPdfList(true);
            }).finally(()=>{
                setLoadingPdf(false);
            });
    }

    const view = (invoiceNumber: string) : void => {
        const paramsApi : ParamsApi = {
            query:{
                invoiceNumber : invoiceNumber
            }
        }

        setLoadingPdf(true);
        restApiClient.generatePdf(paramsApi, "GetInvoicePDF")
            .then((data:any)=> {
                processFile(data, setPdfData);
                setShowPdf(true);
            }).finally(()=>{
                setLoadingPdf(false);
            });
    }

    const view347 = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                year : selectedYear347
            }
        }

        setLoadingPdf(true);
        restApiClient.generatePdf(paramsApi, "Get347Model")
            .then((data:any)=> {
                processFile(data, setPdfData);
                setShow347YearSelector(false);
                setShowPdf347(true);
            }).finally(()=>{
                setLoadingPdf(false);
            });
    }

    useEffect(()=>{
        if (selectedInvoiceNumber.length>0){
            view(selectedInvoiceNumber);
        }
    },[selectedInvoiceNumber]);

    const setTheColumns = () : void => {
        let theColumns = [
            {
                title: "",
                dataIndex: "other",
                key: "other",
                render: (i: number, element : Invoice) => {
                    let withDetail = <></>;
                    let readStatus = <></>;
    
                    if (element.includeDetails){
                        withDetail = <Tooltip placement='right' title="Esta factura incluye el desglose adjunto."><OrderedListOutlined className="with-detail"/></Tooltip>
                    }
    
                    switch(element.readStatus){
                        case "pendiente notificación":
                        case "notificada":
                        case "notificación oculta":
                            readStatus = <Tooltip placement='right' title={`${element.readStatus}`}><InfoCircleOutlined className="read-status"/></Tooltip>;
                            break;
                        case "visualizada":
                            readStatus = <Tooltip placement='right' title={`${element.readStatus}`}><CheckCircleTwoTone  twoToneColor="#52c41a" className="read-status readed"/></Tooltip>;
                            break;
                    }
    
                    let result = <div className="other-data">
                                    {withDetail}
                                    {readStatus}
                                 </div>
                    
                    return result;
                }
            },
            {
                title: "Nº Factura",
                dataIndex: "number",
                key: "number",
                render: (i: number, element : Invoice) => {
                    
                    return <div className="invoice-number">
                                    <div className="number">{element.number}</div>
                            </div>
                }
            },
            {
                title: "D. Facturación",
                dataIndex: "billingHolder",
                key: "billingHolder",
                render: (i: number, element : Invoice) => {
                    return <div className="invoice-data">
                                <div>{element.billingHolder}</div>
                                <div>{element.billingAddress}</div>
                                <div>{element.billingCif}</div>
                           </div>
                }
            }];
            
            if (loginContext.idRole !== DOCTOR_ROLE){
                theColumns.push(
                    {
                        title: "Dirigida a",
                        dataIndex: "userName",
                        key: "userName",
                        render: (i: number, element : Invoice) => {
                            return <div className="invoice-data">
                                        <div>{element.userName}</div>
                                        <div>{element.userPhone1}/{element.userPhone2}</div>
                                        <div><a href={`mailTo:${element.userEmail}`}>{element.userEmail}</a></div>
                                   </div>
                        }
                    });
            }
            
            
            let theColumns2 = [...theColumns, {
                title: "Estado",
                dataIndex: "status",
                key: "status",
                render: (i: number, element : Invoice) => {
    
                    var result = <div className={`status-tag status-${element.status}`}>{element.status} </div>
    
                    return <div className="invoice-status">
                                    <div>{result}</div>
                            </div>
                    }
            },
            {
                title: "Descripción",
                dataIndex: "description",
                key: "description",
                render: (i: number, element : Invoice) => {
                    return <div className="description">{element.description}</div>
                }
            }]

                if (loginContext.idRole !== DOCTOR_ROLE){
                    theColumns2.push({
                        title: "F. Creación",
                        dataIndex: "creationDate",
                        key: "creationDate",
                        render: (i: number, element : Invoice) => {
                            return moment(element.creationDate).format('DD/MM/YYYY HH:mm:ss')
                        }
                    } as any);
                }
                

                let theColumns3 = [...theColumns2,{
                        title: "F. Factura",
                        dataIndex: "invoiceDate",
                        key: "invoiceDate",
                        render: (i: number, element : Invoice) => {
                            return moment(element.billingDate).format('DD/MM/YYYY')
                        }
                    },
                    {
                        title: "Importe",
                        dataIndex: "amount",
                        key: "amount",
                        render: (i: number, element : Invoice) => {
                            return `${element.amount}€`
                        }
                    },
                    {
                        title: "Tipo Pago",
                        dataIndex: "paymentType",
                        key: "paymentType",
                        render: (i: number, element : Invoice) => {
                            let paymentType = <></>;
                            switch(element.paymentType){
                                case 0:
                                    paymentType = <><SendOutlined /> Transferencia</>;
                                    break;
                                case 1:
                                    paymentType = <><BankOutlined /> SEPA</>;
                                    break;
                                case 2:
                                    paymentType = <><EuroCircleOutlined /> Efectivo</>;
                                    break;
                            }
                            
                            return <span className="paymentType">{paymentType}</span>
                        }
                    }]


        if (loginContext.idRole === DOCTOR_ROLE){
            theColumns3.push(
                {
                    title: 'Acciones',
                    dataIndex: 'actions',
                    key: 'actions',
                    render: (i: number, element: Invoice) => {
                        return <>
                                    <Button size='small' danger onClick={()=>{setSelectedInvoiceNumber(element.number); setLoadingPdf(true); setShowPdf(true)}}>
                                        <LoadingOrComponent loading={loadingPdf} text={<><FilePdfOutlined /><small className="h-section-name" >PDF</small></>} showSpinner showWhileLoading={false}/>
                                        
                                    </Button>
                                </>;
                    }
                });
        }

        setColumns(theColumns3);
    }

    

    useEffect(()=>{
        setTheColumns();
    },[]);


    
    return (<div className="invoice-list-component">
               
               <Components.ModalInvoicePDF loadingData={loadingPdf} 
                                    selected={selectedInvoiceNumber} 
                                    onClose={()=>{setShowPdf(false); setPdfData(null); }} 
                                    visible={showPdf} 
                                    pdfData={pdfData}/>  

                <Components.ModalListInvoicesPDF loadingData={loadingPdf} 
                                    selected={filter} 
                                    onClose={()=>{setShowPdfList(false); setPdfData(null); }} 
                                    visible={showPdfList} 
                                    pdfData={pdfData}/>  
                                        
               
                <Components.Modal347PDF loadingData={loadingPdf} 
                    selected={selectedYear347} 
                    onClose={()=>{setShowPdf347(false); setPdfData(null); }} 
                    visible={showPdf347} 
                    pdfData={pdfData}/>  

                <Modal title="Modelo 347" visible={show347YearSelector} onOk={view347} onCancel={()=>{ setShow347YearSelector(false);}}>
                    <p>Indique el año para obtener el modelo <strong>347</strong> y pulse aceptar</p>
                    <Select defaultValue={moment().year()-1} style={{ width: "100%" }} onChange={changeSelecteYear}>
                        {
                            Array(50).fill(50).map((v,i)=>i).map(i=>{
                                return <Select.Option value={i+2020} disabled={(i+2020)>=moment().year()}>{i+2020}</Select.Option>
                            })
                        }
        .            </Select>
                </Modal>
                
               <Components.TablePagination 
                    onFilter={onChangeLastRequests}
                    contextualMenu={loginContext.idRole === ADMIN_ROLE}
                    reloadCounter={reloadCounter}
                    contextualParameters={{updateList: ()=>{setReloadCounter(reloadCounter+1)}}}
                    aliasEndPoint="GetInvoiceList"
                    entityName="invoice"
                    columns={columns}
                    title="Listado de facturas"
                    orderButtons={[
                                    {
                                        type:"primary", 
                                        className:"order-list-button", 
                                        label:"Sin orden",
                                        action:(element: string)=>{setReloadCounter(reloadCounter+1);setOrder(element);}
                                    },
                                    {
                                        className:"separator", 
                                        label:""
                                    },
                                    {
                                        type:"primary", 
                                        className:"order-list-button", 
                                        label:"Nº Factura",
                                        action:(element: string)=>{setReloadCounter(reloadCounter+1);setOrder(element);}
                                    },
                                    {
                                        type:"primary", 
                                        className:"order-list-button", 
                                        label:"Estado",
                                        action:(element: string)=>{setReloadCounter(reloadCounter+1);setOrder(element);}
                                    },
                                    {
                                        type:"primary", 
                                        className:"order-list-button", 
                                        label:"Tipo Pago",
                                        action:(element: string)=>{setReloadCounter(reloadCounter+1);setOrder(element);}
                                    },
                                    {
                                        className:"separator", 
                                        label:""
                                    },
                                    {
                                        type:"primary", 
                                        className:"order-list-button", 
                                        label:"D. Facturación",
                                        action:(element: string)=>{setReloadCounter(reloadCounter+1);setOrder(element);}
                                    },
                                    {
                                        type:"primary", 
                                        className:"order-list-button", 
                                        label:"Dirigida a",
                                        action:(element: string)=>{setReloadCounter(reloadCounter+1);setOrder(element);}
                                    },
                                    
                                    {
                                        type:"primary", 
                                        className:"order-list-button", 
                                        label:"Descripción",
                                        action:(element: string)=>{setReloadCounter(reloadCounter+1);setOrder(element);}
                                    },
                                    {
                                        type:"primary", 
                                        className:"order-list-button", 
                                        label:"F. Factura",
                                        action:(element: string)=>{setReloadCounter(reloadCounter+1);setOrder(element);}
                                    },
                                    {
                                        type:"primary", 
                                        className:"order-list-button", 
                                        label:"Importe",
                                        action:(element: string)=>{setReloadCounter(reloadCounter+1);}
                                    }
                                ]}
                    filterButtons={["emitida", "abonada", "separator", "pendiente notificación", "notificada", "notificación oculta", "visualizada", "special-date-from-to", loginContext.idRole === ADMIN_ROLE ? "special-doctors" : '']}
                    actionButtons={[{noShow: loginContext.idRole === DOCTOR_ROLE, type: "ghost", className:"export-pdf-data", label:"Modelo 347", action: ()=>{setShow347YearSelector(true);}, icon: <FileDoneOutlined />}, {type: "ghost", className:"export-pdf-data", label:"Descargar listado", action: downloadPdf, icon: <FilePdfOutlined />}]}

                />
            </div>)
}

export default InvoiceListComponent;
