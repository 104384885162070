import { Alert, Button, Result } from 'antd';
import React from 'react'
import { useHistory } from "react-router-dom";

import './403.scss'

interface Page404Props{}

const Page403 = (props : Page404Props) : JSX.Element => {

    const history = useHistory();

    const goHome = () : void => {
        window.location.href = "/dashboard";
    }
    const goBack = () : void => {
        history.goBack();
    }

    return <Result
        status="403"
        title="403"
        subTitle="No dispone de los permisos necesarios para ver el contenido"
        extra={<><Alert closeText={false}
        className='alert-403'
            message="Atención"
            description="Esta acción ha sido registrada y notificada para su estudio"
            type="warning"
            showIcon
            closable
          /><Button type="primary" onClick={goBack}>Volver atras</Button><Button type="primary" onClick={goHome}>Inicio</Button></>}
    />
}

export default Page403;