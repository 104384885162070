import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Divider, Checkbox, InputNumber, Alert } from 'antd'
import ParamsApi from '../../../models/api/ParamsApi';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import { useHistory } from "react-router-dom";

import './CompanyCEComponent.scss'
import Company from '../../../models/Company';

const { TextArea } = Input;

interface AddCompanyProps{
    companyData?: Company,
    updateMode?: boolean,
    showBack:boolean
}

const CompanyCEComponent = (props: AddCompanyProps) : JSX.Element => {

    const history = useHistory();

    const restApiClient : RestApiClient = new RestApiClient();

    const [form] = Form.useForm();
    const [goExitOnFinish, setGoExitOnFinish] = useState<boolean>(true);
    const [allowSave, setAllowSave] = useState<boolean>(false);

    const setExitOnFinish = (goExitOnFinish:boolean) : void => {
        setGoExitOnFinish(goExitOnFinish);
    }

    const onFinish = (values: any) : void => {

        if (props.updateMode){
            values.id = props.companyData?.id;
        }

        const paramsApi : ParamsApi = {
            body:values
        }

        const ep : string = props.updateMode ? "UpdateCompanyBasicData" : "AddCompany";

        restApiClient.fetch(ep, paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    if (!props.updateMode){
                        onReset();
                    }
                    if (goExitOnFinish){
                        history.push("/companies")
                    }
                }
            });
    }

    const onReset = () : void => {
        form.resetFields();
    }

    useEffect(()=>{
       /*form.setFieldsValue({
        businessName: "Compañia Test S.L",
        cif: "B92579698",
        address: "Una dirección",
        contactPersonName: "Persona de contacto",
        phone1: "951296122",
        phone2: "963698745",
        email: "we.love@omnicode.es",
        web: "www.omnicode.es",
        private: true,
        commission: 10.5,
        observations: "empresa demo con un 10.5% de comisión.",
        id: 0
        } as any); */
        if (props.companyData){
            form.setFieldsValue(props.companyData as any);
        }
    },[props.companyData])


    


    return (
        <div className="add-company">
            <Form form={form} wrapperCol={{ span: 22 }} labelCol={{ span: 2 }} name="frm-add-company" onFinish={onFinish}>
                <div className="b-component-actions">
                    <Button className="btn-save" type="default" onClick={()=>{history.goBack()}}>
                        Volver al listado
                    </Button>
                    <Button className="btn-reset" htmlType="button" onClick={onReset}>
                        Limpiar campos
                    </Button>
                    <Button className="btn-secondary" type="primary" onClick={()=>{setExitOnFinish(false);}}  htmlType="submit">
                        {props.updateMode ? "Actualizar": "Guardar"} y quedarse
                    </Button>
                    
                    <Button className="btn-save" onClick={()=>{setExitOnFinish(true);}} type="primary" htmlType="submit">
                        {props.updateMode ? "Actualizar": "Guardar"} y salir
                    </Button>
                </div>
                <Divider orientation="left">Datos de la compañía</Divider>
                <Form.Item name="businessName" help="Indica la razón social de la compañía." label="Nombre" rules={[{ required: true, message: "El nombre de la compañía es obligatorio"}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="cif" label="CIF" help="Indica el CIF de la compañia, será utilizada en las facturas." rules={[{ required: true, message: "El CIF de la compañía es obligatorio"}]}>
                    <Input />
                </Form.Item>
                
                <Divider orientation="left">Datos de contacto</Divider>

                <Form.Item name="address" label="Dirección" help="Indica la dirección completa de la compañia. Esta dirección será utilizada en los datos de facturación." rules={[{ required: true, message: "La dirección de la compañía es obligatoria"}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="contactPersonName" label="Persona de contacto" help="Indica el nombre de la persona de contacto en la compañía">
                    <Input />
                </Form.Item>
                <Form.Item name="phone1" label="Teléfono principal" help="Indica el teléfono principal de la compañía" rules={[{ required: true, message: "El teléfono principal de la compañía es obligatorio."}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="phone2" label="Teléfono alternativo" help="Indica un teléfono alternativo">
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="Correo electrónico" help="Indica el email de la compañia" rules={[{ required: true, message: "El email de contacto es obligatorio."}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="web" label="Web" help="Indica, si la tiene, cuál es la dirección web de la compañía">
                    <Input />
                </Form.Item>

                <Divider orientation="left">Otra información</Divider>

                <Form.Item name="private" label="Empresa privada" valuePropName="checked">
                    <Checkbox>(Marcar si la empresa es privada)</Checkbox>
                </Form.Item>

                <Form.Item name="commission" label="Comisiones" help="Indica el % de comisión que se aplicará a la compañía">
                    <InputNumber min={0} max={100} defaultValue={0} />
                </Form.Item>

                <Form.Item name="observations" label="Observaciones" help="Escribe observaciones. Esta información solo la verán los administradores del sistema.">
                    <TextArea rows={4} />
                </Form.Item>
                <div className="b-component-actions">
                    <Button className="btn-save" type="default" onClick={()=>{setExitOnFinish(false);}}  htmlType="submit">
                        {props.updateMode ? "Actualizar": "Guardar"} y quedarse
                    </Button>
                    <Button className="btn-save" onClick={()=>{setExitOnFinish(true);}} type="primary" htmlType="submit">
                        {props.updateMode ? "Actualizar": "Guardar"} y salir
                    </Button>
                    <Button className="btn-reset" htmlType="button" onClick={onReset}>
                        Limpiar campos
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default CompanyCEComponent;