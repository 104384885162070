import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Statistics'

export const StatisticsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetDoctorStatistics"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetCompanyStatistics"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetBasicStatistics"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("getBasicStatisticsMonthly"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("getBasicStatisticsCaseReportsMonthly"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetBasicStatisticsFromBeginOfDays"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetCounters"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetWordStatistics")
]