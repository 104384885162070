import { AppstoreAddOutlined, CheckOutlined, ClockCircleOutlined, LoadingOutlined, RedoOutlined, SaveOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Divider, Form, Input, Row, Select, notification, Popconfirm } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react'
import CaseReports from '../../../models/CaseReports';
import { loadTemplate } from '../../../utils/TemplateNames';

import './CaseReportDetailComponent.scss'
import Title from 'antd/lib/typography/Title';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';
import Preformatted from '../../../models/Preformatted';

import { LoginContext } from '../../../hooks/context/userContext'
import { CaseReportStatus } from '../../../models/Constants';
import { ADMIN_ROLE, DOCTOR_ROLE, PATHOLOGIST_ROLE } from '../../../utils/Constants';
import { useHistory } from 'react-router-dom';
import Page403 from '../../../pages/auth/403/403';
import { getAgeInfo } from '../../../utils/AgeUtils';


interface CaseReportDetailComponentProps{
    caseReportData: CaseReports | undefined
}

const CaseReportDetailComponent = (props: CaseReportDetailComponentProps) : JSX.Element => {
    const [form] = Form.useForm();
    const history = useHistory();

    let loginContext = useContext(LoginContext);

    const restApiClient : RestApiClient = new RestApiClient();

    const headerRef = useRef(null);
    const markerRef = useRef(null);
    const [caseReport, setCaseReport] = useState<CaseReports>();
    const [sticky, setSticky] = useState<string>('');
    const [validatingOrInvalidatingLoading, setValidatingOrInvalidatingLoading] = useState<boolean>(false);
    const [loaddingPreformatted, setLoaddingPreformatted] = useState<boolean>(false);
    const [savingData, setSavingData] = useState<boolean>(false);
    const [preformattedList, setPreformattedList] = useState<Preformatted[]>([]);
    const [selectedPreformattedItem, setSelectedPreformattedItem] = useState<Preformatted | undefined>(undefined);
    const [observations, setObservations] = useState<string>('');
    const [pvp, setPvp] = useState<number>(-1); 
    const [updatingObservations, setUpdatingObservations] = useState<boolean>(false);
    const [updatingPvp, setUpdatingPvp] = useState<boolean>(false);

    const onFinish = (values: any) => {
    }

    const saveData = () => {
        const paramsApi : ParamsApi = {
            body:{
                userId: loginContext.id,
                caseReportId: caseReport?.id,
                ...form.getFieldsValue()
            }
        }


        setSavingData(true);
        restApiClient.fetch("SaveCaseReport", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (caseReport){
                    caseReport.status = CaseReportStatus.Processing;
                    setCaseReport(caseReport);
                }
            }).finally(()=>{
                setSavingData(false);
            });
    }

    const getPreformattedList = () : void => {
        if (caseReport && caseReport?.product){
            const paramsApi : ParamsApi = {
                query:{
                    page: 0,
                    N: 0,
                    filter: `${caseReport?.product.type}|diagnostico`,
                    onlyActives: true
                }
            }
    
            setLoaddingPreformatted(true);
            restApiClient.fetch("GetPreformattedList", paramsApi) 
                .then((r : ApiResponse | null)=> {
                    if (r && r.code === 200){
                        setPreformattedList(r.data.elements);
                    }
                }).finally(()=>{
                    setLoaddingPreformatted(false);
                });
        }
        
    }

    const showReceptionTemplate = (caseReport: CaseReports) => {
        let type : any = {};

        switch(caseReport.product.type){
            case "biopsia":
                type = caseReport.biopsiaReceptionData;
                break;
            case "citologia":
                type = caseReport.citologiaReceptionData;
                break;
            case "ihq-molecular":
                type = caseReport.ihqMolecularReceptionData;
                break;
            case "microbiologia":
                type = caseReport.microbiologiaReceptionData;
                break;
        }

        return loadTemplate(`${caseReport.product.type}-recepcion`, form, type, false);
    }

    const showDiagnosisTemplate = (caseReport: CaseReports) => {
        let type : any = {};

        switch(caseReport.product.type){
            case "biopsia":
                type = caseReport.biopsiaDiagnosisData;
                break;
            case "citologia":
                type = caseReport.citologiaDiagnosisData;
                break;
            case "ihq-molecular":
                type = caseReport.ihqMolecularDiagnosisData;
                break;
            case "microbiologia":
                type = caseReport.microbiologiaDiagnosisData;
                break;
        }

        return loadTemplate(`${caseReport.product.type}-diagnosis`, form, type, false);
    }

    const updateReferencesScrolls = () : void => {
        var header : any = headerRef.current;
        var marker : any = markerRef.current;

        if (header && marker){
            const headerYPosition : number = header.getBoundingClientRect().top;
            const markerYPosition : number = header.getBoundingClientRect().top;

            try{
                if (headerYPosition < 0){
                    setSticky("sticky");
                }else if (headerYPosition <= markerYPosition && markerYPosition > 0){
                    setSticky("");
                }
            }catch(e){
            }
            
        }
    }

    const applyPreformatted = () : void => {

        switch (selectedPreformattedItem?.type) {
            case "biopsia":
                form.setFieldsValue({
                    "biopsiaDiagnosisData":{ 
                        "descripcionMacroscopica":selectedPreformattedItem?.biopsiaDiagnosisData?.descripcionMacroscopica,
                        "descripcionMicroscopica":selectedPreformattedItem?.biopsiaDiagnosisData?.descripcionMicroscopica,
                        "diagnosticoAnatomopatologico":selectedPreformattedItem?.biopsiaDiagnosisData?.diagnosticoAnatomopatologico,
                        "nota":selectedPreformattedItem?.biopsiaDiagnosisData?.nota,
                    }
                }); 
                break;
            case "citologia":
                form.setFieldsValue({
                        "citologiaDiagnosisData":{ 
                            "diagnosticoAnatomopatologico":selectedPreformattedItem?.citologiaDiagnosisData?.diagnosticoAnatomopatologico, 
                            "evaluacionMuestra":selectedPreformattedItem?.citologiaDiagnosisData?.evaluacionMuestra, 
                            "nota":selectedPreformattedItem?.citologiaDiagnosisData?.nota
                }});
                break;
            case "ihq-molecular":
                form.setFieldsValue({
                    "ihqMolecularDiagnosisData":{ 
                        "diagnosticoAnatomopatologico":selectedPreformattedItem?.ihqMolecularDiagnosisData?.diagnosticoAnatomopatologico, 
                        "evaluacionMuestra":selectedPreformattedItem?.ihqMolecularDiagnosisData?.evaluacionMuestra, 
                        "nota":selectedPreformattedItem?.ihqMolecularDiagnosisData?.nota
                }});
                break;
            case "microbiologia":
                form.setFieldsValue({
                    "microbiologiaDiagnosisData":{ 
                        "analisisAmpliado":selectedPreformattedItem?.microbiologiaDiagnosisData?.analisisAmpliado, 
                        "analisisBacteriologico":selectedPreformattedItem?.microbiologiaDiagnosisData?.analisisBacteriologico, 
                        "analisisMicologico":selectedPreformattedItem?.microbiologiaDiagnosisData?.analisisMicologico, 
                        "nota":selectedPreformattedItem?.microbiologiaDiagnosisData?.nota
                    }});
                break;
            default:
                break;
        }

        saveData();
    }

    const updatePvp = () : void =>{
        const paramsApi : ParamsApi = {
            body:{
                id: caseReport?.reception.id,
                pvp: pvp,
                observations: caseReport?.reception.observations
            }
        }

        setUpdatingPvp(true);
        restApiClient.fetch("UpdatePvpMoment", paramsApi).finally(()=>{
            setUpdatingPvp(false);
        }).catch(()=>{
            if (caseReport){
                form.setFieldsValue({
                    pvp: caseReport?.reception.pvpMoment
                });
                setPvp(caseReport?.reception.pvpMoment);
            }   
        });
    }

    const onChangePrice = (e: any) : void => {
        setPvp(e.target.value)
    }

    const updateObservations = () : void =>{
        const paramsApi : ParamsApi = {
            body:{
                id: caseReport?.reception.id,
                pvp: -1,
                observations: observations
            }
        }

        setUpdatingObservations(true);
        restApiClient.fetch("UpdateObservations", paramsApi).finally(()=>{
            setUpdatingObservations(false);
        });
    }

    const onChangeObservations = (e: any) : void => {
        setObservations(e.target.value);
    }

    const onInvalidate = () : void =>{
        if (caseReport?.status !== "finalizado"){
            notification.warn({
                message: 'Atención',
                description: "No puede invalidarse un caso de estudio que no haya sido previamente validado"
            });
        }else{
            const paramsApi : ParamsApi = {
                query:{
                    caseReportId: caseReport?.id,
                    pathologistId: loginContext?.id
                }
            }
    
            setValidatingOrInvalidatingLoading(true);
            restApiClient.fetch("InValidateCaseReport", paramsApi)
                .then((r : ApiResponse | null)=> {
                    if (r && r.code === 200 && caseReport){
                        caseReport.status = CaseReportStatus.Processing;
                        caseReport.validatedBy = undefined;
                        caseReport.diagnosisDate = null;
                    }
                }).finally(()=>{
                    setValidatingOrInvalidatingLoading(false);
                });
        }

        
    }

    const onValidate = () : void =>{
        if (caseReport?.status !== "procesando"){
            notification.warn({
                message: 'Atención',
                description: "No puede validarse un caso de estudio que no se encuentra procesando."
            });
        }else{
            const paramsApi : ParamsApi = {
                body:{
                    caseReportId: caseReport?.id,
                    pathologistId: loginContext?.id
                }
            }
    
            setValidatingOrInvalidatingLoading(true);
            restApiClient.fetch("ValidateCaseReport", paramsApi)
                .then((r : ApiResponse | null)=> {
                    if (r && r.code === 200 && caseReport){
                        caseReport.status = CaseReportStatus.Finished;
                        caseReport.diagnosisDate = moment().format("YYYY-MM-DD HH:mm");
                        caseReport.validatedBy = loginContext?.id;
                    }
                }).finally(()=>{
                    setValidatingOrInvalidatingLoading(false);
                });
        }
    }   

    useEffect(() => {
        const onScroll = (e : any) => {
            updateReferencesScrolls();
        };
        document.getElementById("container")?.addEventListener("scroll", onScroll);
    
        return () => document.getElementById("container")?.removeEventListener("scroll", onScroll);
      }, []);

    useEffect(()=>{
        if (props.caseReportData){
            if (loginContext.idRole === DOCTOR_ROLE && props.caseReportData.status !== "finalizado"){
                history.push("/");
            }

            setCaseReport(props.caseReportData)
        }
    },[props.caseReportData])

    useEffect(()=>{
        //Cargamos el listado de preformateados si es un admin o un patólogo
        if (loginContext?.idRole === ADMIN_ROLE || loginContext?.idRole === PATHOLOGIST_ROLE){
            getPreformattedList();
        }

        if (caseReport){
            setPvp(caseReport?.reception.pvpMoment);
            setObservations(caseReport?.reception.observations);
        }
    },[caseReport]);
    
    return (
        <div className={`case-report-detail-component ${sticky}`}>
            <div id="marker" ref={markerRef}></div>
            <div id="header" ref={headerRef}>
                {
                    (loginContext?.idRole === ADMIN_ROLE || loginContext?.idRole === PATHOLOGIST_ROLE)
                        ?
                        <Header className="menu">
                            <Row>
                                <Col xs={24} sm={12} md={12} lg={caseReport && caseReport.status !== "finalizado" ? 12 : 24} xl={caseReport && caseReport.status !== "finalizado" ? 12 : 24} className="actions">
                                    <h3>Acciones</h3>
                                    {
                                        caseReport && caseReport.status === "finalizado" 
                                            ? 
                                                <Button  danger onClick={onInvalidate}><CheckOutlined/> Desvalidar</Button>	
                                            :
                                                <>
                                                    <Button type="primary" className="btn-secondary" onClick={saveData}><SaveOutlined /> Guardar</Button>
                                                    <Button danger onClick={onValidate}><CheckOutlined /> Validar</Button>
                                                </>

                                    }
                                </Col>
                                {
                                    caseReport && caseReport.status !== "finalizado" ?
                                        <Col xs={0} sm={12} md={12} lg={12} xl={12}>
                                            <h3>Preformateados</h3>
                                            <Select placeholder="Seleccione..." className="preforms"
                                                onChange={(id: any)=>{
                                                    setSelectedPreformattedItem(preformattedList.find(p => p.id === id));
                                                }}
                                                dropdownRender={(menu:any) => (
                                                    <div>
                                                        {menu ? menu : ''}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        {
                                                            <div className="footer-add-patient">
                                                                <Title level={5}><Button type="primary" size="small" ><AppstoreAddOutlined /> Crear nuevo</Button></Title>
                                                            </div>
                                                        }
                                                    </div>
                                                )}>
                                                    {
                                                        preformattedList.map((preformatted:Preformatted, index: number)=>{
                                                            return <Select.Option key={index} value={preformatted.id}>{preformatted.description}</Select.Option>
                                                        })
                                                    }
                                            </Select>
                                            
                                            <Popconfirm placement="bottom" title={"Confirme la aplicación del preformateado."} onConfirm={applyPreformatted} okText="Yes" cancelText="No">
                                                <Button danger className="btn-preforms"><SnippetsOutlined />Aplicar</Button>	
                                            </Popconfirm>

                                        </Col>
                                        :
                                        ''
                                }
                            </Row>
                        </Header>
                    :
                    ''
                }
                
            </div>

            
            {
                caseReport ?
                    <Form form={form} className="frm-login" onFinish={onFinish} layout="vertical">
                        <div>
                            <Divider orientation="left" className="section">Resumen</Divider>

                            <Descriptions bordered>
                                <Descriptions.Item label="Referencia" span={3}>
                                    <strong>{caseReport.caseReportNum}</strong>
                                    
                                    <small className="block-element">
                                        Identificador interno: <strong>#{caseReport.id}</strong> 
                                    </small> 
                                </Descriptions.Item>
                                <Descriptions.Item label="Paciente">
                                                {caseReport.patient.name} {caseReport.patient.surname} {caseReport.patient.secondSurname?caseReport.patient.secondSurname:''}
                                                <span className="block-element">
                                                    Nacid{caseReport.patient.sex == "mujer" ? 'a' : 'o'} el <strong>{moment(caseReport.patient.birthDate).format("DD/MM/YYYY")}</strong>.
                                                </span>
                                                {
                                                    getAgeInfo(caseReport.reception.request.start, caseReport.patient.birthDate)
                                                }
                                                <span >
                                                    {caseReport.patient.patientNif} 
                                                </span>
                                </Descriptions.Item>
                                <Descriptions.Item label="Compañía">
                                    {caseReport.company.businessName}    
                                </Descriptions.Item>
                                {
                                    loginContext.idRole === ADMIN_ROLE || loginContext.idRole === PATHOLOGIST_ROLE ?
                                    <Descriptions.Item label="Médico">
                                        {caseReport.reception.request.user.name} {caseReport.reception.request.user.surname} {caseReport.reception.request.user.secondSurname?caseReport.reception.request.user.secondSurname:''}    
                                        <small className="block-element">Especialidad: <strong>{caseReport.reception.request.user.specialty}</strong></small>
                                    </Descriptions.Item>
                                    :
                                    ''
                                }
                                
                                {
                                    loginContext.idRole === ADMIN_ROLE ?
                                        <>
                                            <Descriptions.Item label="Precio">
                                                <Input type="number" defaultValue={Math.round(caseReport.reception.pvpMoment * 100) /100} suffix="€" onChange={onChangePrice} value={Math.round(pvp * 100) / 100}/>
                                                <small className="block-element">
                                                    Al modificarlo cambiará el precio de la recepción #{caseReport.reception.id}
                                                </small>
                                                <Button className="btn-secondary btn-update" type="primary" size="small" onClick={updatePvp}>
                                                    {
                                                        updatingPvp ?
                                                                <LoadingOutlined />
                                                            :
                                                                <RedoOutlined />
                                                    } Actualizar</Button>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Comisión">
                                                <Input disabled type="number" className="commission" defaultValue={caseReport.reception.commissionMoment} suffix="%"/>
                                            </Descriptions.Item>
                                        </>
                                    :
                                    ''
                                }

                                <Descriptions.Item label="Fechas">
                                {
                                        caseReport.reception.request.requestDate ?
                                            <span className="block-element">Recepcionado: <strong><br/>{caseReport.reception.receptionDate?moment(caseReport.reception.receptionDate).format("DD/MM/YYYY HH:mm"):''}</strong> <ClockCircleOutlined /></span>
                                        :
                                            ''
                                    }
                                    {
                                        caseReport.processingDate ?
                                            <span className="block-element">Procesado: <strong><br/>{caseReport.processingDate?moment(caseReport.processingDate).format("DD/MM/YYYY HH:mm"):''}</strong> <ClockCircleOutlined /></span>
                                        :
                                            ''
                                    }
                                    {
                                        caseReport.diagnosisDate ?
                                            <span className="block-element">Diagnóstico: <strong><br/>{caseReport.diagnosisDate?moment(caseReport.diagnosisDate).format("DD/MM/YYYY HH:mm"):''}</strong> <ClockCircleOutlined /></span>
                                        :
                                            ''
                                    }
                                </Descriptions.Item>

                                <Descriptions.Item label="Observaciones" span={3}>
                                    {
                                        loginContext.idRole === ADMIN_ROLE ?
                                        <>
                                            <Input.TextArea rows={4} defaultValue={caseReport.reception.observations} onChange={onChangeObservations} value={observations}/>
                                            <Button className="btn-secondary btn-update" type="primary" size="small" onClick={updateObservations}>
                                            {
                                                updatingObservations ?
                                                    <LoadingOutlined />
                                                :
                                                    <RedoOutlined />
                                            } Actualizar</Button>
                                        </>
                                        :
                                        <p>{caseReport.reception.observations}</p>
                                    }
                                    
                                </Descriptions.Item>
                            </Descriptions>
                            <Divider orientation="left" className="section">Datos introducidos en la recepción</Divider>
                            {
                                showReceptionTemplate(caseReport)                              
                            }

                            <Divider orientation="left" className="section">Datos diagnósticos</Divider>
                            {
                                showDiagnosisTemplate(caseReport)                              
                            }
                        </div>
                    </Form>
                :
                    <Page403 />
            }
        </div>
    )
}

export default CaseReportDetailComponent;