import { CarOutlined, EditOutlined, FilePdfOutlined, FilterOutlined, LikeOutlined, LoadingOutlined, BankOutlined, PhoneOutlined, StopOutlined, BarsOutlined, ClearOutlined, DownloadOutlined, CloseSquareOutlined, ExclamationCircleOutlined, CloseOutlined, VerticalLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Checkbox, Col, DatePicker, Divider, Drawer, Dropdown, Menu, notification, Row, Select, Skeleton, Spin, Tooltip } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { LoadingOrText } from '..';
import RestApiClient from '../../api/restApiClient';
import { LoginContext } from '../../hooks/context/userContext';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';
import Doctor from "../../models/Doctor";
import Request from '../../models/Request';
import RequestTypes from '../../models/RequestType';

import * as SubComponents from './components';

import './RequestsComponent.scss'


interface RequestsComponentProps {
    simpleMode?:boolean
}

const RequestsComponent = (props: RequestsComponentProps) : JSX.Element => {
    const loginContext = useContext(LoginContext);
    const restApiClient : RestApiClient = new RestApiClient();
    const localeDefaultFormat : string = "YYYY-MM-DD";
    
    const [showAddOrder, setShowAddOrder] = useState<boolean>(false);
    const [showDailyRequest, setShowDailyRequest] = useState<boolean>(false);
    const [selectedDay, setSelectedDay] = useState<moment.Moment>(moment());
    const [dailyRequests, setDailyRequests] = useState<Request[]>([]);
    const [previousFilter, setPreviousFilter] = useState<Request[]>([]);

    const [requests, setRequests] = useState<Request[]>([]);

    const [lastFrom, setLastFrom] = useState<any>(moment());
    const [lastTo, setLastTo] = useState<any>(moment());

    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const [loadingDoctors, setLoadingDoctors] = useState<boolean>(false);
    const [doctors, setDoctors] = useState<Doctor[]>();

    const [events, setEvents] = useState<RequestTypes[]>([]);
    const [loadingEvents, setLoadingEvents] = useState<boolean>(false);

    // Profesional seleccionado
    const [professional, setProfessional] = useState<Doctor>();
    // Fecha Seleccionada
    const [selectedDate, setSelectedDate] = useState<any>(moment().add(1, "day"));
    const [selectedEvents, setSelectedEvents] = useState<any[]>([])
    // Si true => se envía notificación del registro por email
    const [sendNotification, setSendNotification] = useState<boolean>(false);
    // Tipo de solicitudes seleccionadas Seleccionados
    const [selectedsTypeRequests, setSelectedsTypeRequests] = useState<string[]>(["1#algo"]);

    const [requestsGroupForSave, setRequestsGroupForSave] = useState<any[]>([]);

    
    // Filtro aplicado 
    const [requestFilter, setRequestFilter] = useState<string>('');

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const getRequests = (from: any, to: any, isDaily: boolean = false) : void => {
        const paramsApi : ParamsApi = {
            query:{
                from: from,
                to: to,
                sessionId: loginContext.sessionId
            }
        }

        restApiClient.fetch("GetRequests", paramsApi)
        
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    if (isDaily){
                        setDailyRequests(r.data);
                    }else{
                        setRequests(r.data);
                        setPreviousFilter(r.data);
                    }
                }
            }).finally(()=>{
            });
    }

    const getInfoByMonth = (v : any, exact: boolean = false) : void => {
        var firstDay = new Date(moment(v).year(), moment(v).month(), 1);
        var lastDay = new Date(moment(v).year(), moment(v).month(), moment(v).daysInMonth());
        var actualDay = new Date(moment(v).year(), moment(v).month(), moment(v).date());


        setLastFrom(firstDay);
        setLastTo(lastDay);

        getRequests(moment(firstDay).add(exact ? 0 : -10, "days").format(localeDefaultFormat), moment(lastDay).add(exact ? 0 : 10, "days").format(localeDefaultFormat), false);
        //getRequests(moment(actualDay).format(localeDefaultFormat), moment(actualDay).format(localeDefaultFormat), true);
    }
    
    const reloadData = () => {

        getRequests(moment(lastFrom).add(false ? 0 : -10, "days").format(localeDefaultFormat), moment(lastTo).add(false ? 0 : 10, "days").format(localeDefaultFormat), false);
    }

    const onSetNotification = (e: any) : void => {
        setSendNotification(e.target.checked);
    }

    const prepareForSave = (event: RequestTypes) : void => {
         
        if (professional && selectedDate && event){
            

            const actualRequests = requestsGroupForSave;

            const index = actualRequests.findIndex((r: any) => r.professional.id === professional.id && r.selectedDate === moment(selectedDate).format(localeDefaultFormat));

            if (index >= 0){
                var actualItem = actualRequests[index];

                //Lo descartamos si ya existe un registro igual creado
                const existsRq = actualItem.requestType.find((rqType:string) => rqType === `${event.id}#${event.description}`) && actualItem.selectedDate ===  moment(selectedDate).format(localeDefaultFormat);
                
                if(!existsRq){
                    if (actualItem.selectedDate ===  moment(selectedDate).format(localeDefaultFormat)){
                        actualItem.requestType = [...actualItem.requestType, `${event.id}#${event.description}`];
                        actualRequests[index] = actualItem;
                        setRequestsGroupForSave([...actualRequests]);
                    }else{
                        setRequestsGroupForSave([...actualRequests, {
                            requestType:[`${event.id}#${event.description}`],
                            professional: professional,
                            selectedDate: moment(selectedDate).format(localeDefaultFormat),
                            sendEmailNotification: sendNotification
                        }]);
                    }
                    
                }else{
                    notification.warn({message:"Atención", description:"Ya añadió esta solicitud."});
                }
                
            }else{
                setRequestsGroupForSave([...actualRequests, {
                    requestType:[`${event.id}#${event.description}`],
                    professional: professional,
                    selectedDate: moment(selectedDate).format(localeDefaultFormat),
                    sendEmailNotification: sendNotification
                }]);
            }

            

        }else{
            notification.warn({message:"Atención", description:"Compruebe que ha rellenado el formulario correctamente"})
        }
    }

    const filterByStatus = (elem: any) : void => {
        setRequestFilter(elem.key);
        switch(elem.key){
            case "todos":
                setRequests(previousFilter);
                break;
            default:
                const newList = previousFilter.filter(rq => rq.status === elem.key);
                setRequests(newList);
                break;
        }
        
    }

    const removeFromRequestGroup = (indexA : number, indexB : number) : void =>{
        const filtered : any[] = [];
        requestsGroupForSave.forEach((rq: any, iA: number) => {
            var fRq = {
                requestType:[],
                professional: rq.professional,
                selectedDate: rq.selectedDate,
                sendEmailNotification: rq.sendEmailNotification
            } as any;

            rq.requestType.forEach((r: any, iB: number) => {
                if (iA === indexA && iB === indexB){
                }else{
                    fRq.requestType.push(r);
                }
            });

            if (fRq.requestType.length > 0){
                filtered.push(fRq);
            }
        });
        
        setRequestsGroupForSave(filtered);
    }

    const getDoctors = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                filter: ''
            }
        }

        setLoadingDoctors(true);
        restApiClient.fetch("GetDoctors", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setDoctors(r.data);
                }
            })
            .finally(()=>{
                setLoadingDoctors(false);
            })
    }

    const onChangeDoctor = (id: number) : void => {
        if (doctors){
            const doc : Doctor | undefined = doctors.find((doctor: Doctor)=> doctor.id == id);

            if (doc){
                setProfessional(doc);
            }
        }
    }

    const getEventList = () : void => {
        const paramsApi : ParamsApi = {}

        setLoadingEvents(true);
        restApiClient.fetch("GetRequestTypes", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setEvents(r.data);
                }
            })
            .finally(()=>{
                setLoadingEvents(false);
            })
    }

    const [cancellingRequest, setCancellingRequest] = useState<boolean>(false);
    const cancelRequest = (id: number) : void => {
        const paramsApi : ParamsApi = {
            query: {
                requestId:id
            }
        }

        setCancellingRequest(true);
        restApiClient.fetch("CancelRequest", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    getRequests(moment(selectedDay).format("YYYY-MM-DD"), moment(selectedDay).format("YYYY-MM-DD"), true);
                    reloadData();
                }
            })
            .finally(()=>{
                setCancellingRequest(false);
            })
    }

    const handleChangeDatePicker = (v: any) : void => {
        setSelectedDate(v);
    }

    const disabledDate = (current: any) => {
        return moment(current) <= moment().add(0, "day");
    };


    const cleanCorrectRequests = (invalidRequestsIndex: {key:number, value: string}[]) : void => {
        const actualRequests = requestsGroupForSave.map((rq: any, i: number) => {
            var exists = invalidRequestsIndex.find((rqIndex: any) => rqIndex.key === i);

            if (exists){
                return rq;
            }else{
                return null;
            }
        });
        

        setRequestsGroupForSave(actualRequests.filter(function (el) {
            return el != null;
          }));
    }

   

    const saveRequest = () : void => {
        const paramsApi : ParamsApi = {
            body:[]
        }

        if (requestsGroupForSave.length > 0){
            requestsGroupForSave.forEach((requestGroup: any, index: number) => {
                const finalSelectedsTypeRequestsIds : number[] = requestGroup.requestType.map((r: any) => {
                    const id : number = r.split("#")[0];
                    return id;
                });
                
                paramsApi.body.push({
                    requestType:finalSelectedsTypeRequestsIds,
                    userId: requestGroup.professional.id,
                    selectedDate: requestGroup.selectedDate,
                    sendEmailNotification: sendNotification,
                    doctorName: `${requestGroup.professional.name} ${requestGroup.professional.surname} ${requestGroup.professional.secondSurname}`
                });
            })

            setLoadingSave(true);
            restApiClient.fetch("AddMultipleRequests", paramsApi)
                        .then((r : ApiResponse | null)=> {
                            if (r && r.code === 200){
                                getRequests(moment(lastFrom).format(localeDefaultFormat), moment(lastTo).format(localeDefaultFormat));
                                setSelectedsTypeRequests([])
                            }

                            if (r?.data){
                                cleanCorrectRequests(r?.data);
                            }else{
                                setRequestsGroupForSave([]);
                            }

                        }).finally(()=>{
                            setLoadingSave(false);
                        });
        }

        
    }

    const onChangeNotifyOnCreation = () : void => {

    }

    useEffect(()=>{
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m - 1, -10);
        var lastDay = new Date(y, m + 1, 10);
        setLastFrom(firstDay);
        setLastTo(lastDay);

        getRequests(moment(firstDay).format(localeDefaultFormat), moment(lastDay).format(localeDefaultFormat));
        getRequests(moment(date).format(localeDefaultFormat), moment(date).format(localeDefaultFormat), true);

        getDoctors();
        getEventList();
    },[]);

    useEffect(()=>{
        if (requestFilter && requestFilter.length > 0){
            filterByStatus({key:requestFilter});
        }else {}
    }, [previousFilter])

    useEffect(()=>{
        if (requests.length > 0){
            setShowDailyRequest(true);
        }
    }, [dailyRequests])

    const cardButtons: JSX.Element = <div>
        <Button type="primary" className="btn-secondary" onClick={()=>{setRequestsGroupForSave([])}}><ClearOutlined /> Limpiar todo</Button>
        <Button type="primary" className="btn-secondary" style={{marginLeft: "5px"}} onClick={saveRequest}><LoadingOrText loading={loadingSave} text='Enviar'/> <VerticalLeftOutlined /></Button>
    </div>

    const actions : JSX.Element = <div className="box-component">
                                    <Divider orientation="left">Acciones</Divider>
                                    <Button type="primary" icon={<DownloadOutlined />} className="btn-secondary">
                                        Preparar para envío
                                    </Button>
                                    <div className="send-email">
                                        <Checkbox onChange={onSetNotification}>Notificar por email</Checkbox>
                                    </div>
                                </div>;

    const filter : JSX.Element = <Col xs={24}>
                                    <div className="b-component-actions">
                                        <Dropdown.Button type="primary" className="button-filter-menu" overlay={
                                            <Menu onClick={filterByStatus}>
                                                <Menu.Item key="todos"><ClearOutlined className="status-icon"/> Mostrar todos (sin filtro)</Menu.Item>
                                                <Menu.Item key="pendiente"><PhoneOutlined className="status-icon"/> Mostrar solo pendientes</Menu.Item>
                                                <Menu.Item key="ordenado"><CarOutlined className="status-icon"/> Mostrar solo ordenados</Menu.Item>
                                                <Menu.Item key="recepcionado"><EditOutlined className="status-icon"/> Mostrar solo recepcionados</Menu.Item>
                                                <Menu.Item key="procesando"><LoadingOutlined className="status-icon"/> Mostrar solo procesando</Menu.Item>
                                                <Menu.Item key="finalizado"><LikeOutlined className="status-icon"/> Mostrar solo finalizados</Menu.Item>
                                                <Menu.Item key="cancelado"><StopOutlined className="status-icon"/> Mostrar solo cancelados</Menu.Item>
                                            </Menu>
                                        }>
                                            <FilterOutlined size={3}/> Filtrar
                                        </Dropdown.Button>
                                        <Button type="primary" onClick={()=>{reloadData();}}><ReloadOutlined size={3}/> Refrescar&nbsp;&nbsp;</Button>
                                        <Button type="primary" onClick={()=>{setShowAddOrder(true)}}><CarOutlined size={3}/> Ordenar&nbsp;&nbsp;</Button>
                                        
                                        
                                    </div>
                                    
                                 </Col>

    return (<Row gutter={16}>
                <Drawer title="Ordenar recogida" width="40%" placement="right" onClose={()=>{setShowAddOrder(false);}} visible={showAddOrder}>
                    <SubComponents.OrderRequest onFinish={reloadData}/>
                </Drawer>

                <Drawer title="Solicitudes día seleccionado" width="40%" placement="right" onClose={()=>{setShowDailyRequest(false);setDailyRequests([])}} visible={showDailyRequest && dailyRequests != null && dailyRequests.length > 0}>
                    <SubComponents.RequestList requests={dailyRequests} onChange={()=>{}} onCancel={cancelRequest}/>
                </Drawer>

                <Col xs={24} md={5}>
                    <div className="box-component">
                        <Divider orientation="left">Listado de profesionales</Divider>
                        <Select
                            style={{ width: '100%' }}
                            onChange={onChangeDoctor} 
                            placeholder={<LoadingOrText loading={loadingDoctors} text="Seleccione..."/>}
                        >
                            {
                                doctors?.map((doctor: Doctor) => {
                                    return <Select.Option key={doctor.id} value={doctor.id}>{doctor.name} {doctor.surname} {doctor.secondSurname}</Select.Option>
                                })
                            }
                        </Select>
                        
                    </div>
                    <div className="send-email" style={{marginLeft:"8px"}}>
                        <Checkbox onChange={onSetNotification}>Notificar por email</Checkbox>
                    </div>
                </Col>

                <Col xs={24} md={4}>
                    <div className="event-selector box-component">
                        <Divider orientation="left">Fecha</Divider>
                        <div>
                            <DatePicker style={{width: "100%", clear:"both", display: "block"}} value={selectedDate} placeholder="Seleccione una fecha" onChange={handleChangeDatePicker}/>
                            
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={15}>
                    <div className="event-selector box-component">
                        <Divider orientation="left">Motivo de la petición</Divider>
                        <div className='resume'>
                            {events.map((event: RequestTypes)=>{
                                return  <div className="pre-save-notify available-event" onClick={()=>{prepareForSave(event)}}>
                                            <DownloadOutlined /> <span key={event.id}>{event.description}</span>
                                        </div>;
                            })}
                        </div>
                    </div>
                </Col>

                <Col xs={requestsGroupForSave.length > 0 ? 24 : 0}>
                    <Card title={`Preparados para enviar: ${requestsGroupForSave.length} grupos.`} 
                            extra={cardButtons} style={{ width: "100%" }}>
                        
                        {
                            requestsGroupForSave.sort(function compare( a: any, b: any ) {
                                if ( moment(a.selectedDate).isBefore(moment(b.selectedDate)) ){
                                  return -1;
                                }else{
                                  return 1;
                                }

                                return 0;
                              }).map((rq: any, indexA: number) => {
                                const finalAlerts = rq.requestType.map((r: any, indexB: number)=>{

                                    const desc : string = r.split("#")[1];

                                    const finalDescription = <span>{desc.replace(/,/g, "")}</span>;

                                    return <div className="pre-save-notify">
                                        <CloseOutlined className="icon" style={{color: "#faad14"}} onClick={()=>{removeFromRequestGroup(indexA, indexB)}}/>
                                        {finalDescription}
                                        <small>{moment(rq.selectedDate).format("DD/MM/YYYY").toString()}</small>
                                    </div>
                                });

                                return <><Divider style={{margin: "16px 0 5px 0"}} orientation='left'>{moment(rq.selectedDate).format("DD/MM/YYYY")} - {rq.professional.name} {rq.professional.surname} {rq.professional.secondSurname}</Divider><div className="resume">{finalAlerts}</div></>;
                                
                            })
                        }
                        
                    </Card>
                    
                </Col>

                <Col xs={24}>
                    {filter}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Skeleton loading={loadingData} active>
                        <SubComponents.Calendar 
                            requests={requests} 
                            filterTitle = {requestFilter}
                            onChange={getInfoByMonth}
                            onSelect={()=>{}}
                            onSelectDay={(e:any)=>{
                                console.log("Selecciona", e)
                                setSelectedDay(moment(e))
                                getRequests(moment(e).format("YYYY-MM-DD"), moment(e).format("YYYY-MM-DD"), true)
                            }}
                            />  
                    </Skeleton>
                    
                </Col>
                
            </Row>)
}


export default RequestsComponent;