import React from 'react'
import AppCfg from '../../models/AppCfg'

export let cfgDefault : AppCfg = {
    address: '',
    cfg: {
        colorsSecondary: '#ee79f5',
        colorsMain: '#a659ef',
        emailCfgUser: 'no-reply@centrodiagnosticocda.com',
        emailTechnicalSupport: 'we.love@omnicode.es',
        textDescriptions: 'Descripción...',
        textInitials: 'CDA',
        urlsLogoImg: 'https://www.centrodiagnosticocda.com/img/cda/cda.png',
        urlsMainImg: 'https://www.omnicode.es/images/background-image-clubes.png',
        urlsMainWeb: 'https://www.centrodiagnosticocda.com/',
        urlsShieldImg: 'https://www.centrodiagnosticocda.com/img/cda/cda.png'
    },
    contactEmail: 'info@centrodiagnosticocda.com',
    contactPhone: '679193834',
    name: 'Centro Diagnóstico CDA'
} as AppCfg

sessionStorage.setItem("app-config", JSON.stringify(cfgDefault));


export const AppCfgContext = React.createContext(cfgDefault);
