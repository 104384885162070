import React from 'react'
import { Row, Col } from 'antd'

import { CompaniesList } from '../../components/index'

import './Companies.scss'

const CompaniesPage = () : JSX.Element=>{
    return (
        <Row gutter={16} className="companies-page">
            <Col xs={24}>
                <CompaniesList />
            </Col>
        </Row>
    )
}


export default CompaniesPage;