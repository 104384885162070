import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Receptions'

export const ReceptionsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName("AddReception"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setActionName("UpdatePvpMoment"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setActionName("UpdateObservations"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setActionName("UpdatePathologist"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setActionName("UpdateReceptionPatient"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setActionName("UpdateReceptionProduct"),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setActionName("UpdateReceptionStatus"),
]