import React, { useEffect, useState } from 'react'

import { Popconfirm, Tooltip } from 'antd'
import { 
         LoginOutlined,
         FilePdfOutlined,
         MailOutlined,
         EuroCircleOutlined,
         ClearOutlined,
         SendOutlined,
} from '@ant-design/icons'
import { Invoice } from '../../../models/Invoice';
import ParamsApi from '../../../models/api/ParamsApi';
import RestApiClient from '../../../api/restApiClient';
import { processFile } from '../../../utils/ProcessFile';
import * as Components from '../../index';
import LoadingOrComponent from '../../core/utils/LoadingOrComponent';


interface InvoicesHelperProps{
    properties: any
} 

const InvoicesHelperComponent = (props: InvoicesHelperProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const [showPdf, setShowPdf] = useState<boolean>(false);
    const [pdfData, setPdfData] = useState<any>(null);
    const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
    const [changingStatusPayed, setChaningStatusPayed] = useState<boolean>(false);
    const [changingStatusDeleted, setChangingStatusDeleted] = useState<boolean>(false);

    const {
        hideMenu,
        params
    } = props.properties;

    const [selected, setSelected] = useState<Invoice>();

    const view = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                invoiceNumber : selected?.number
            }
        }

        setLoadingPdf(true);
        restApiClient.generatePdf(paramsApi, "GetInvoicePDF")
            .then((data)=> {
                processFile(data, setPdfData);
                setShowPdf(true);
            }).finally(()=>{
                setLoadingPdf(false);
            });
    }

    const generateBill = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                invoiceId : selected?.id
            }
        }

        restApiClient.generatePdf(paramsApi, "GenerateBill").finally(()=>{
            params.updateList();
        });
    }

    const payed = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                invoiceNumber : selected?.number
            }
        }

        setChaningStatusPayed(true);
        restApiClient.fetch("SetPayedInvoice", paramsApi).finally(()=>{
            setChaningStatusPayed(false);
            if (params && params.updateList){
                params.updateList();
            }
        });
    }

    const deleted = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                invoiceNumber : selected?.number
            }
        }

        setChangingStatusDeleted(true);
        restApiClient.fetch("DeleteInvoice", paramsApi).finally(()=>{
            setChangingStatusDeleted(false);
            if (params && params.updateList){
                params.updateList();
            }
        });
    }

    useEffect(()=>{
        if (props.properties.selected){
            setSelected(props.properties.selected as Invoice);
        }
    },[props]);
    
    return (
        <> 
            <Components.ModalInvoicePDF selected={selected?.number} onClose={()=>{setShowPdf(false); setPdfData(null);}} visible={showPdf} pdfData={pdfData}/> 

            <ul className="helper-balls dashboard-balls">

                {
                    selected?.status === "borrador" ? 
                        <Tooltip placement="left" title="Emitir">
                            <li className={`h-generic ${selected ? '' : 'disabled'}`} onClick={generateBill}>
                                <SendOutlined /> <small className="h-section-name">Visualizar</small>
                            </li>
                        </Tooltip> : 
                        ''

                }
                

                <Tooltip placement="left" title="Visualizar factura">
                    <li className={`h-generic ${selected ? '' : 'disabled'}`} onClick={view}>
                        <LoadingOrComponent loading={loadingPdf} text={<FilePdfOutlined />} loadingText="" showWhileLoading={false}/>
                        <small className="h-section-name">Visualizar</small>
                    </li>
                </Tooltip>

                {
                    selected?.status === "emitida" ? 
                        <Tooltip placement="left" title="Marcar pagada">
                            <Popconfirm placement="left" title={"¿Está seguro de que desea marcar como pagada la factura? El proceso no puede revertirse."} onConfirm={payed} okText="Si, marcar pagada." cancelText="No">
                                <li className={`h-generic ${selected ? '' : 'disabled'}`}>
                                    <LoadingOrComponent loading={changingStatusPayed} text={<EuroCircleOutlined />} loadingText="" showWhileLoading={false}/>
                                    <small className="h-section-name">Marcar pagada</small>
                                </li>
                            </Popconfirm>
                        </Tooltip> : 
                        ''

                }
                
                
                {
                    /**<Tooltip placement="left" title="Notificar por correo">
                    <li className={`h-generic ${selected ? '' : 'disabled'}`} onClick={notify}>
                        <MailOutlined />
                        <small className="h-section-name">Notificar</small>
                    </li>
                </Tooltip> */
                }

                {
                    selected?.status === "emitida" ? 
                        <Tooltip placement="left" title="Eliminar">
                            <Popconfirm placement="left" title={"¿Está seguro de que desea eliminar la factura? El proceso no puede revertirse."} onConfirm={deleted} okText="Si, eliminar." cancelText="No">
                                <li className={`h-logout`} >
                                    <LoadingOrComponent loading={changingStatusDeleted} text={<ClearOutlined />} loadingText="" showWhileLoading={false}/>
                                    <small className="h-section-name">Marcar eliminada</small>
                                </li>
                            </Popconfirm>
                            
                        </Tooltip> : 
                        ''

                }
                
                
                <Tooltip placement="left" title="Ocultar menú">
                    <li className="h-logout" onClick={hideMenu}>
                        <LoginOutlined />
                        <small className="h-section-name" >Ocultar</small>
                    </li>
                </Tooltip>
            </ul>
        </>
    )
}

export default InvoicesHelperComponent;