import { Col, Divider, Form, Input, Radio, Row } from 'antd';
import React from 'react'

const OtherData = () : JSX.Element => {

    return(
        <Row gutter={16}>
          <Col xs={24}>
            <Divider orientation="left">Otros datos</Divider>
          </Col>
          <Col xs={24}>
            <Form.Item name="applyReducedPrice"label="Aplicar descuento" initialValue={0}>
                <Radio.Group >
                        <Radio value={1}>Si</Radio>
                        <Radio value={0}>No</Radio>
                </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="specialty" help="Especialidad del usuario" label="Especialidad" >
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="collegiateNumber" help="Número de colegiado (en caso de ser un profesional)" label="Número de colegiado" >
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="observations" help="Las observaciones solo son visibles por un administrador. El usuario no podrá verlas en su perfíl" label="Observaciones" >
                <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
          
          
          
        </Row>
    )
}

export default OtherData;