import React from 'react';
import * as Components from '../../components/index'
import { Typography } from 'antd';

import './AdvancedSearchPage.scss'

interface AdvancedSearchPageProps {}

const AdvancedSearchPage = (props: AdvancedSearchPageProps) : JSX.Element => {

    return (
        <div className="advanced-search-page">
            <Components.AdvancedSearch />
        </div>
    )
}


export default AdvancedSearchPage;
