import React from 'react'

import './KeywordsPage.scss'
import * as Components from '../../components/index'

const KeywordsPage = () : JSX.Element => {

    return (
        <div className='keyword-page'>
           <Components.Keywords />
        </div>
    )
}

export default KeywordsPage;