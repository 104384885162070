import { Modal } from 'antd';
import React, { useContext, useEffect } from 'react'
import { LoginContext } from '../../hooks/context/userContext'
import ParamsApi from '../../models/api/ParamsApi';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';

interface Props{}

const RGPDComponent = (props: Props) : JSX.Element => {
    let loginContext = useContext(LoginContext);
    const restApiClient : RestApiClient = new RestApiClient();


    const [showRGPD, setShowRGPD] = React.useState(false);
    const [acceptingRGPD, setAcceptingRGPD] = React.useState(false);
    
    const acceptRGPD = () : void => {
        setAcceptingRGPD(true);
        restApiClient.fetch("AcceptRGPD", {})
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setShowRGPD(false);
                    window.sessionStorage.setItem("accepted-rgpd", "true");
                }
            })
            .finally(()=>{
                setAcceptingRGPD(false);
            })
    }

    useEffect(()=>{
        var isInSessionStorage = window.sessionStorage.getItem("accepted-rgpd");

        setShowRGPD(!loginContext.acceptGDPR && !isInSessionStorage);
    },[]);

    
    return(
        <Modal width={"80vw"} centered title="Condiciones y políticas de uso de CDA" visible={showRGPD} onOk={acceptRGPD} onCancel={()=>{setShowRGPD(false);}}>
            <div style={{maxHeight: "55vh", overflow:"scroll"}}>
                <h3>
                    RECOGIDA DE MUESTRAS / ENTREGA DE RESULTADOS
                </h3>

                <h4>
                    INFORMACIÓN BÁSICA DE PROTECCIÓN DE DATOS
                    Consentimiento Informado [G.D.P.R. (UE) 2016/679]
                </h4>
                <p>
                    <b>RESPONSABLE</b>: V&R WOMENS CARE S.L.P.
                    <br/><b>FINALIDAD</b>: PRESTACIÓN DE LOS  SERVICIOS DE ANATOMÍA PATOLÓGICA, MICROBIOLOGÍA, ANÁLISIS CLÍNICOS, GENÉTICA MOLECULAR, SERVICIOS ESTADÍSTICOS, CIENTÍFICOS, DE FACTURACIÓN Y COMUNICACIÓN.
                    <br/><b>LEGITIMACIÓN</b>: CONSENTIMIENTO DEL INTERESADO.
                    <br/><b>DESTINATARIOS</b>: NO SE CEDERÁN DATOS A TERCEROS, SALVO OBLIGACIÓN LEGAL O CONTRACTUAL.
                    <br/><b>DERECHOS</b>: ACCEDER, RECTIFICAR, SUPRIMIR DATOS. LIMITACIÓN DEL TRATAMIENTO Y PORTABILIDAD.
                    <br/><b>INFORMACIÓN ADICIONAL</b>: PUEDE CONSULTAR LA INFORMACIÓN ADICIONAL EN LA INTRANET DE SU ÁREA PERSONAL: www.centrodiagnosticocda.com
                </p>

                <p>
                    De conformidad con el G.D.P.R. (UE) 2016/679, la L.O.P.D., y la Ley 41/2002 Básica Reguladora de la Autonomía del Paciente y Profesionales, le informamos de que sus datos de carácter personal, identificativos y de salud, serán incorporados a un fichero titularidad de V&R WOMENS CARE S.L.P. y serán utilizados únicamente para la finalidad indicada.
                </p>
                <p>
                    - Toda la información que nos facilite u obtengamos, es necesaria para prestarle el servicio y será tratada de forma absolutamente confidencial, estando todos los facultativos y personal del Centro, obligados a guardar el debido secreto sobre su contenido.
                    <br/>- Si la asistencia sanitaria se realiza al amparo de pólizas o coberturas de las que Ud. sea beneficiario como paciente, el Centro podrá facilitar la información sobre sus datos identificativos, y el tipo de prestación que le hemos realizado, de conformidad con la póliza suscrita con su Compañía Aseguradora, a la aseguradora o entidad bajo cuya cobertura se presta la asistencia, al resultar imprescindible para la cobertura y la facturación de los servicios prestados. En caso de oposición, habrá de comunicárnoslo previamente antes de recibir la asistencia sanitaria, siendo de su cargo el pago de los servicios prestados.
                </p>
                <br/>

                <h4>
                    ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE SUS DATOS?
                </h4>
                <p>
                    <b>IDENTIDAD</b>: V&R WOMENS CARE S.L.P.
                    <br/><b>CIF N.º</b>: B-93263861
                    <br/><b>DIRECCIÓN POSTAL</b>: C/ Alonso de Higueras, Nº15 (29010) Málaga (Málaga)
                    <br/><b>TELÉFONO</b>: (+34) 679193834
                    <br/><b>D.P.O/CONTACTO LOPD</b>: <a href="mailto:info@centrodiagnosticocda.com">Info@centrodiagnosticocda.com</a>
                </p>
                <br/>
                <h4>
                    ¿CON QUÉ FINALIDAD TRATAMOS SUS DATOS PERSONALES?
                </h4>
                <p>
                    En CENTRO DE DIAGNÓSTICOS CDA tratamos la información que nos facilitan nuestros pacientes con el fin de gestionar sus datos demográficos, de salud, realización de estudios anatomopatológicos, microbiológicos, análisis clínicos, genetica molecular, servicios estadísticos, científicos (previa anonimización), así como facturación y contacto con el paciente y profesionales para información de los servicios de Grupo CDA.
                </p>
                <br/>
                <h4>
                    ¿POR CUÁNTO TIEMPO CONSERVAREMOS SUS DATOS?
                </h4>
                <p>
                    Los datos personales proporcionados se conservarán únicamente mientras se mantenga la relación contractual (y no se solicite la supresión por parte del interesado), o durante el plazo de obligación legal.
                </p>
                <br/>
                <h4>
                    ¿CUÁL ES LA LEGITIMACIÓN PARA EL TRATAMIENTO DE SUS DATOS?
                </h4>
                <p>
                    La base legal para el tratamiento de sus datos es la relación contractual.
                </p>
                <br/>
                <h4>
                    ¿A QUÉ DESTINATARIOS SE COMUNICARÁN SUS DATOS?
                </h4>
                <p>
                    Sus datos no serán cedidos a terceros sin su previo consentimiento.
                </p>
                <br/>
                <h4>
                    ¿CUÁLES SON SUS DERECHOS CUANDO NOS FACILITA SUS DATOS?
                </h4>
                <p>
                    Cualquier persona tiene derecho a obtener confirmación sobre si en CENTRO DE DIAGNÓSTICOS CDA estamos tratando datos personales que les conciernan, o no.
                    Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.
                    En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos.
                    CENTRO DE DIAGNÓSTICOS CDA dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
                </p>
                <br/>
                <h4>
                    ¿CÓMO HEMOS OBTENIDO SUS DATOS?
                </h4>
                <p>
                    Los datos los hemos obtenido del propio interesado o representante legal autorizado.
                </p>
                <br/><br/>
            </div>
        </Modal>
    )
}

export default RGPDComponent;