import { Row } from 'antd';
import React from 'react'
import Company from '../../../models/Company';
import { useHistory } from "react-router-dom";

import './CompaniesListComponent.scss'
import { PlusOutlined } from '@ant-design/icons';
import { TablePagination } from '../..';

interface CompaniesProps{}


const CompaniesListComponent = (props : CompaniesProps) : JSX.Element => {
    
    const history = useHistory();

    const columnsTable = [
        {
            title: "Nombre",
            dataIndex: "businessName",
            key: "businessName"
        },
        {
            title: "CIF",
            dataIndex: "cif",
            key: "cif"
        },
        {
            title: "Teléfono principal",
            dataIndex: "phone1",
            key: "phone1"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Contacto",
            dataIndex: "contactPersonName",
            key: "contactPersonName"
        }
    ];



    const getResume = (company: Company) : JSX.Element => {
        var result : JSX.Element = <></>;
        
        result = <div className="company-resume">
                    <table>
                        <tbody>
                        <tr className="resume-title">
                                <td>Web:</td>
                                <td>{company.web}</td>
                            </tr>
                            <tr className="resume-title">
                                <td>Dirección:</td>
                                <td>{company.address}</td>
                            </tr>
                            <tr className="resume-title">
                                <td>Teléfono secundario:</td>
                                <td>{company.phone2}</td>
                            </tr>
                            
                            <tr className="resume-title">
                                <td colSpan={2}>Observaciones:</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{company.observations}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

        return result;
    }

    const goAddCompany = () : void => {
        history.push("/add-company");
    }
 
    return (
            <div className="companies-list">
                <Row >
                    <TablePagination  
                        contextualMenu
                        title="Listado de compañías"
                        entityName="company"
                        aliasEndPoint="GetFilteredCompanies"
                        columns={columnsTable}
                        expandableInfo={{
                            expandedRowRender: (record: any) => getResume(record),
                            rowExpandable: (record: any) => record.businessName !== 'Not Expandable',
                        }}
                        actionButtons={
                                        [
                                            { 
                                                label:"Añadir",
                                                action:goAddCompany,
                                                icon:<PlusOutlined />
                                            }
                                        ]
                                    }
                    />
                </Row>
            </div>
    )
}

export default CompaniesListComponent;