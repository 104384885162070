import { Alert } from 'antd'
import React from 'react'

import * as Components from '../../components/index'

import './Archived.scss'

const ArchivedPage = () : JSX.Element => {


    return (<div className="archived-page">
        <Alert
        message="Atención"
        description="A continuación aparece el listado de casos de estudios que han sido archivados. Puede volver a restaurarlos desde la edición rápida.."
        type="warning"
        showIcon
        />
        <Components.CaseReportList onlyArchived={true} showHeaderButtons={false}/>

    </div>)
}


export default ArchivedPage;