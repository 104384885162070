import React, { forwardRef, useImperativeHandle } from 'react'
import * as Templates from './index'
import { Form, Input } from 'antd'
import BiopsiaDiagnosisFields from '../../models/templates/BiopsiaDiagnosis'

interface TemplateProps{
    type: string | "biopsia-diagnosis" | "biopsia-recepcion",
    reset: boolean,
    form: any,
    values: any,
    useForm?:boolean
}

const TemplateComponent = forwardRef((props: TemplateProps, ref: any)=>{
    const [form] = Form.useForm(props.form)

    const { type } = props;

    const getTemplate = () : JSX.Element => {

        let result : JSX.Element = <>Error, template {type} no reconocido.</>

        switch(type){
            case "biopsia-diagnosis":
            case "biopsia-diagnostico":
                result = <Templates.BiopsiaDiagnosis reset={props.reset} form={props.form} values={props.values}/>
                break;
            case "citologia-diagnosis":
            case "citologia-diagnostico":
                result = <Templates.CitologiaDiagnosis reset={props.reset} form={props.form} values={props.values}/>
                break;
            case "microbiologia-diagnosis":
            case "microbiologia-diagnostico":
                result = <Templates.MicrobiologiaDiagnosis  reset={props.reset} form={props.form} values={props.values}/>
                break;
            case "ihq-molecular-diagnosis":
            case "ihq-molecular-diagnostico":
                result = <Templates.IHQMolecularDiagnosis reset={props.reset} form={props.form} values={props.values}/> 
                break;
            case "biopsia-recepcion":
                result = <Templates.BiopsiaReception reset={props.reset} form={props.form} values={props.values}/>
                break;
            case "citologia-recepcion":
                result = <Templates.CitologiaReception reset={props.reset} form={props.form} values={props.values}/>
                break;
            case "microbiologia-recepcion":
                result = <Templates.MicrobiologiaReception reset={props.reset} form={props.form} values={props.values}/>
                break;
            case "ihq-molecular-recepcion":
                result = <Templates.IHQMolecularReception reset={props.reset} form={props.form} values={props.values}/>
                break;
            default:
                result = <>Error, template {type} no reconocido.</>;
                console.warn(`ERROR: El template ${type} no se ha reconocido.`);
                break;
        }

        return result;
    }

    const getBiopsiaDiagnosisData = () : any => {
        const data : BiopsiaDiagnosisFields = {
            name: "biopsia",
            serie: "A",
            title: "Informe anatomopatológico de biopsias",
            ...form.getFieldsValue()
        }

        return data;
    }

    const getBiopsiaRecepcionData = () : any => {
        const data : BiopsiaDiagnosisFields = {
            name: "biopsia",
            serie: "A",
            title: "Informe anatomopatológico de biopsias",
            ...form.getFieldsValue()
        }

        return data;
    }   


    useImperativeHandle(ref, () => ({

        /**
    const childRef = useRef();
         * 
         * const push2 = () : void => {
        if (childRef2 && childRef2.current){
            const data : string = (childRef2.current as any).getData();

            const validate : any = (childRef2.current as any).getIsValidData();

            (childRef2.current as any).clearItems();
        }
    }
        */

        getIsValidData() {
            return form.getFieldsError();
        },
        getData() {
            let result : any = {};

            switch(type){
                case "biopsia-diagnosis":
                    result = getBiopsiaDiagnosisData();
                    break;
                case "biopsia-recepcion":
                    result = getBiopsiaRecepcionData();
                    break;
                default:
                    console.warn(`ERROR: El template ${type} no se ha reconocido.`);
                    break;
            }
          
            return JSON.stringify(result);
        },
        clearItems(){
            form.resetFields();
        }
    
      }));

      return (<div className="template-biopsia-diagnosis">
                {
                    props.useForm!== undefined && props.useForm === false ?
                        getTemplate()
                    :
                        <Form form={form} name="frm-add-company" layout="vertical">
                            {getTemplate()}
                        </Form>
                }
                
            </div>)
});


export default TemplateComponent;