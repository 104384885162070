import React, { useState, useContext, useEffect } from 'react'
import { Form, Input, Button} from 'antd'
import { useHistory } from "react-router-dom";

import { LoginContext } from '../../hooks/context/userContext'

import RestApiClient from '../../api/restApiClient'

import './login.scss';
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';
import User from '../../models/Users';

interface FieldsForm{
    username: string,
    password: string
}

const  LoginFormComponentInner = (props : any) => {
    const [form] = Form.useForm();

    const [errorLoginMessage, setErrorLoginMessage] = useState<string>("");
    let loginContext = useContext(LoginContext);

    const restApiClient : RestApiClient = new RestApiClient();

    const history = useHistory();

    const checkApiStatus = () : void => {
    }

    const onFinish = (values : FieldsForm) : void => {
        const paramsApi : ParamsApi = {
            body: {
                username: values.username,
                password: values.password
            }
        }

        restApiClient.fetch("DoLogin", paramsApi).then((r : ApiResponse | null)=> {
            if (r !== null && r.code === 500){
                setErrorLoginMessage(r.message);
            }else if (r !== null && r.code === 200){
                const data : User = r.data as User;
                data.isLoged = true;
                loginContext = data;
                
                sessionStorage.setItem("user-data", JSON.stringify(loginContext));


                var pinPaged = localStorage.getItem("pin-page");
                if (!pinPaged){
                    history.push("/dashboard");
                }else{
                    history.push(pinPaged);
                }
            }
        })
    }

    const resetErrorLoginMessage = () : void => {
        setErrorLoginMessage('');
    }

    useEffect(()=>{
        checkApiStatus();
        if (window.sessionStorage.getItem("session-expired")){
            setErrorLoginMessage("Su sesión ha expirado, por favor ingrese nuevamente");
            window.sessionStorage.removeItem("session-expired");
        }else if (window.sessionStorage.getItem("no-authorized")){
            setErrorLoginMessage("Su sesión cerró al intentar realizar una operación no permitida. Contacte con soporte técnico si cree que es un error.");
            window.sessionStorage.removeItem("no-authorized");
        }
    }, []);


    return(
           <Form form={form} className="frm-login" onFinish={onFinish} layout="vertical">
                <Form.Item
                    label="Usuario"
                    name="username"
                    className="form-item"
                    rules={[{required: true, message: 'Por favor, introduce tu DNI como nombre de usuario...'}]}
                >
                    <Input placeholder="Introduce tu DNI" onChange={resetErrorLoginMessage} />
                </Form.Item>
                <Form.Item
                    style={{marginBottom: "0 !important"}}
                    label="Contraseña"
                    name="password"
                    className="form-item no-bottom-space"
                    rules={[{required: true, message: 'Por favor, introduce tu contraseña...'}]}
                >
                    <Input.Password type="password" placeholder="Introduce tu password" onChange={resetErrorLoginMessage} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="btn-login">
                        Acceder
                    </Button>
                </Form.Item>

                <p className="error-login"><a >{errorLoginMessage}</a></p>
            </Form>
    )
}

export default LoginFormComponentInner;