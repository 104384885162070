import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import ApiResponse from '../../../../models/api/ApiResponse';
import RestApiClient from '../../../../api/restApiClient';
import ParamsApi from '../../../../models/api/ParamsApi';

import { Divider, Select, DatePicker } from 'antd';

import "../Components.scss"
import RequestTypes from '../../../../models/RequestType';
import moment from 'moment';
import { LoadingOrText } from '../../..';

const { Option } = Select;

interface Props{
    onSelectEvents: (events: any[]) => void,
    onSelectDate: (date: any) => void
}

const EventSelectorComponent = forwardRef((props: Props, ref) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [events, setEvents] = useState<RequestTypes[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [selectedDate, setSelectedDate] = useState<any>()
    const [selectedEvents, setSelectedEvents] = useState<any[]>([])

    useImperativeHandle(ref, () => ({
        
        clearFields(){
            setSelectedDate(undefined);
            setSelectedEvents([]);
            setLoading(false);
        }
    
    }));

    const getEventList = () : void => {
        const paramsApi : ParamsApi = {}

        setLoading(true);
        restApiClient.fetch("GetRequestTypes", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setEvents(r.data);
                }
            })
            .finally(()=>{
                setLoading(false);
            })
    }

    const handleChangeSelector = (items: any[]) : void => {
        setSelectedEvents(items);
        props.onSelectEvents(items);
    }   

    const handleChangeDatePicker = (v: any) : void => {
        setSelectedDate(v);
        props.onSelectDate(v);
    }

    const disabledDate = (current: any) => {
        
        return moment(current) <= moment().add(0, "day");
      };

    useEffect(()=>{
        getEventList();
    }, []);

   

    return (
        <div className="event-selector box-component">
            <Divider orientation="left">Fecha y motivo de la petición</Divider>
            <div className="elements">
                <DatePicker value={selectedDate} disabledDate={disabledDate} style={{width:"40%"}} placeholder="Seleccione una fecha" onChange={handleChangeDatePicker}/>
                <Select
                    mode="multiple"
                    style={{ width: '60%', marginLeft: "1%" }}
                    placeholder={<LoadingOrText loading={loading} text="Seleccione motivo"/>}
                    defaultValue={[]}
                    value={selectedEvents}
                    onChange={handleChangeSelector}
                >
                    {
                        events.map((event: RequestTypes) =>{
                            return <Option key={event.shortDescription} value={`${event.id}#${event.description}`}>{event.description}</Option>
                        })
                    }
                </Select>
            </div>
        </div>
    )
})


export default EventSelectorComponent;