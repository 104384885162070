import React from 'react'
import * as Templates from '../../components/templates/'

import './preformatteds.scss'

const PreformattedPage = () : JSX.Element => {

    return (
        <div className='preformatted-page'>
            <Templates.PreformattedList />
        </div>
    )
}

export default PreformattedPage;