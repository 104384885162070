import React from 'react'

import * as Components from '../../components'

import './MigrationPatientsPage.scss'

const MigrationPatientsPage = () : JSX.Element => {

    return(
        <div className="migration-patients-page">
            <Components.MigrationPatients />
        </div>
    )

}

export default MigrationPatientsPage;