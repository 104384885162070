import CalendarComponent from "./Calendar/CalendarComponent";
import EventSelectorComponent from "./EventSelector/EventSelectorComponent";
import ProfessionalListComponent from "./Professionals/ProfessionalsListComponent";
import RequestListComponent from "./ListTable/RequestListComponent";
import RequestTableComponent from "./ListTable/RequestTableComponent";
import OrderRequestComponent from "./Order/OrderRequestComponent";


export const Calendar = CalendarComponent;
export const EventSelector = EventSelectorComponent;
export const ProfessionalList = ProfessionalListComponent;
export const RequestList = RequestListComponent;
export const RequestTable = RequestTableComponent;
export const OrderRequest = OrderRequestComponent;