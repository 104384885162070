import React from 'react'
import Patient from '../../../models/Patient'
import { Col, DatePicker, Divider, Form, Input, Radio, Row } from 'antd';
import moment from 'moment';


interface Props{
    patient?: Patient
}

const PatientResultComponent = (props: Props) : JSX.Element => {
    const [form] = Form.useForm();

    const {patient} = props;

    return (
        <Form form={form} layout="vertical" className="frm-register">
                            <Divider orientation="left">Datos personales</Divider>
                            <Row gutter={16}>
                                <Col xs={24}>
                                    <Form.Item name="name" label="Nombre" 
                                        initialValue={patient?.name}
                                        rules={[{ required: true, message: "Debe indicar el nombre del paciente."}]}>
                                        <Input />
                                    </Form.Item>
                                    
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                    initialValue={patient?.surname} name="surname" label="Primer apellido" rules={[{ required: true, message: "Debe indicar al menos el primer apellido."}]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item initialValue={patient?.secondSurname} name="secondSurname" label="Segundo apellido">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                
                                <Col xs={24} md={12}>
                                    <Form.Item name="nif" initialValue={patient?.patientNif} label="DNI/NIE">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item name="birthDate" label="Fecha Nacimiento" initialValue={patient?.birthDate ? moment(patient?.birthDate).format("DD/MM/YYYY") : "N/D"}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item name="sex" initialValue={patient?.sex}  label="Sexo" rules={[{ required: true, message: "Debe indicar el sexo del paciente."}]}>
                                        <Radio.Group>
                                            <Radio value={'varón'}>Varón</Radio>
                                            <Radio value={'mujer'}>Mujer</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider orientation="left">Observaciones</Divider>
                            <Row gutter={16}>
                                <Col xs={24}>
                                    <Form.Item name="observations" label="Observaciones" initialValue={patient?.observations}>
                                        <Input.TextArea />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
    )
}

export default PatientResultComponent;