import React, { useEffect, useState } from 'react'

import ApiResponse from '../../../../models/api/ApiResponse';
import RestApiClient from '../../../../api/restApiClient';
import ParamsApi from '../../../../models/api/ParamsApi';

import "../Components.scss"
import Doctor from "../../../../models/Doctor";
import { Divider, Select } from 'antd';
import { LoadingOrText } from '../../..';

const { Option } = Select;

interface Props{
    onSelect: (doctor: Doctor) => void
}

const ProfessionalListComponent = (props: Props) : JSX.Element => {

    const { onSelect } = props;

    const restApiClient : RestApiClient = new RestApiClient();
    const [loading, setLoading] = useState<boolean>(false);
    const [doctors, setDoctors] = useState<Doctor[]>();
    //GetDoctors

    const GetDoctors = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                filter: ''
            }
        }
        setLoading(true);
        restApiClient.fetch("GetDoctors", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setDoctors(r.data);
                }
            })
            .finally(()=>{
                setLoading(false);
            })
    }

    const onChange = (id: number) : void => {
        if (doctors){
            const doc : Doctor | undefined = doctors.find((doctor: Doctor)=> doctor.id == id);

            if (doc){
                onSelect(doc);
            }
        }
    }

    useEffect(()=>{
        GetDoctors();
    },[]);
    
    //https://doar-react.netlify.app/apps/calendar
    //http://minia-h-light.react.themesbrand.com/apps-calendar
    return (<div className="box-component">
        <Divider orientation="left">Listado de profesionales</Divider>
        <Select
            style={{ width: '100%' }}
            onChange={onChange} 
            placeholder={<LoadingOrText loading={loading} text="Seleccione..."/>}
        >
            {
                doctors?.map((doctor: Doctor) => {
                    return <Option key={doctor.id} value={doctor.id}>{doctor.name} {doctor.surname} {doctor.secondSurname}</Option>
                })
            }
        </Select>
    </div>)
}

export default ProfessionalListComponent;