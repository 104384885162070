import { FileDoneOutlined, LoadingOutlined, SendOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Result } from 'antd'
import React, { useEffect, useState } from 'react'

import RestApiClient from '../../../../api/restApiClient';
import ParamsApi from '../../../../models/api/ParamsApi';

interface Props{
    selected: any,
    visible: boolean,
    onClose: any,
    pdfData: any,
    loadingData?: boolean,
}

const ModalInvoicePDFComponent = (props: Props) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [selected, setSelected] = useState<string>();
    const [visible, setVisible] = useState<boolean>(false);
    const [pdfData, setPdfData] = useState<any>();

    const [sendingEmail, setSendingEmail] = useState<boolean>(false);

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');


    const sendEmail = (myEmail: string = "", myPassword: string = "") => {
        const paramsApi : ParamsApi = {
            body:{
                invoiceNumber: selected,
                emailTo: myEmail.length > 0 ? myEmail : email,
                password: myPassword.length > 0 ? myPassword : password,
                mySelf: myEmail.length > 0 ? true : false
            }
        }
        
        setSendingEmail(true);
        restApiClient.fetch("SendInvoiceTo", paramsApi) 
            .finally(()=>{
                setSendingEmail(false);
                setEmail("");
                setPassword("");
            })
    }

    useEffect(()=>{
        if (props.selected){
            setSelected(props.selected);
        }
    },[props.selected]);

    useEffect(()=>{
        if (props.pdfData){
            setPdfData(props.pdfData);
        }
    },[props.pdfData]);

    useEffect(()=>{
        setVisible(props.visible);
    },[props.visible])



    return(
        <Modal
            title={<><FileDoneOutlined /> Previsualización documento</>}
            centered
            visible={visible}
            onOk={() => props.onClose()}
            onCancel={() => props.onClose()}
            width={"90vw"}
            footer={
                <>
                        <Button type="primary" onClick={() => props.onClose()}>Cerrar</Button>
                </>
            }
        >
            
            {
                props.loadingData && props.loadingData === true ? <Result
                className="empty"
                status="warning"
                title={<>Obteniendo datos</>}
                subTitle={<>Espere, estamos generando el PDF... <LoadingOutlined spin /></>}
            /> : (pdfData ? 
                    <iframe src={pdfData} style={{width:"100%", height:"70vh", border: "none", marginTop:"10px"}}></iframe>
                :
                '')
            }
            <>
            
                <Input type="email" placeholder='Escribe un email al que desees enviar la factura...' style={{width: "70%"}}
                    onChange={(e)=>{setEmail(e.target.value)}}
                    value={email}
                    size='small' 
                />
                <Input onChange={(e)=>{setPassword(e.target.value)}} value={password} size="small" placeholder='Contraseña (Opcional)' style={{width:"20%"}}/>
                <Button style={{borderRadius:"0", width: "10%"}} size="small" disabled={!email || email.length === 0} danger 
                onClick={()=>{sendEmail()}}>
                    <SendOutlined /> Enviar por email
                </Button>
                </>
        </Modal>
    )
}

export default ModalInvoicePDFComponent;