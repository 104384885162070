import { Col, Row } from 'antd'
import React from 'react'
import * as Components from '../../components/index'

import './Requests.scss'

interface RequestsProps{}

const RequestPage = (props: RequestsProps) : JSX.Element => {
    return (
        <div  className="request-page"><Components.Requests /></div>
        
    )
}


export default RequestPage;
