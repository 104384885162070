import { PlusOutlined } from '@ant-design/icons';
import { Row, Switch } from 'antd';
import React from 'react'
import Preformatted from '../../../models/Preformatted';
import * as Components from '../../index';
import { useHistory } from "react-router-dom";
import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';

interface Props{}

const PreformattedListComponent = (props: Props) : JSX.Element => {
    const history = useHistory();
    const restApiClient : RestApiClient = new RestApiClient();

    const columns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Nombre',
                dataIndex: 'name',
                key: 'name',
                render(i: number, element: Preformatted) {
                    return <><Switch size='small' defaultChecked={element.active} onChange={(checked: boolean)=>{ onChangePreformattedStatus(checked, element.id)}} /> {element.description}</>;
                }
            },
            {
                title: 'Tipo',
                dataIndex: 'name',
                key: 'name',
                render(i: number, element: Preformatted) {
                    var type = "";
                    switch(element.type){
                        case "ihq-molecular": 
                            type = "IHQ Molecular"; 
                            break;
                        case "citologia": 
                            type = "Citología"; 
                            break;
                        case "microbiologia": 
                            type = "Microbiología";
                            break;
                        case "biopsia": 
                            type = "Biopsia"; 
                            break;
                        default: 
                            type = element.type;
                            break;
                    }
                    return type;
                }
            },
            {
                title: 'Subtipo',
                dataIndex: 'name',
                key: 'name',
                render(i: number, element: Preformatted) {
                    var subtype  = "";
                    switch(element.subType){
                        case "diagnostico": subtype = "Diagnóstico"; break;
                        case "recepcion": subtype = "Recepción"; break;
                        default: subtype = element.subType;
                    }
                    return subtype;
                }
            },
        ];

    const onChangePreformattedStatus = (checked: boolean, id: number) => {

        const paramsApi : ParamsApi = {
            body:{
                id: id,
                active: checked
            }
        }

        restApiClient.fetch("ChangePreformattedStatus", paramsApi);

    }

    return (
        <div className='preformatted-list'>
            <Row gutter={16}>
                <Components.TablePagination 
                    contextualMenu
                    entityName="preformatted"
                    aliasEndPoint="GetPreformattedList"
                    columns={columns}
                    title="Listado de preformateados"
                    filterButtons={["Citología", "Biopsia", "IHQ Molecular", "Microbiología", "separator", "Recepción", "Diagnóstico"]}
                    actionButtons={[{label:"Añadir", action: ()=>{history.push("add-preformatted")}, icon: <PlusOutlined />}]}
                />
            </Row>
        </div>
    )
}

export default PreformattedListComponent;