import { Button, Col, Collapse, DatePicker, Divider, Result, Row, Select, Table, Typography} from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { Pie, measureTextWidth } from '@ant-design/plots';
import ParamsApi from '../../models/api/ParamsApi';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import './DetailedStatisticsComponent.scss'
import { FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import LoadingOrComponent from '../core/utils/LoadingOrComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Doctor from '../../models/Doctor';
import CaseReports from '../../models/CaseReports';
import { useHistory } from 'react-router-dom';
import { LoginContext } from '../../hooks/context/userContext'
import { ADMIN_ROLE } from '../../utils/Constants';
import { CaseReportStatus } from '../../models/Constants';
import { processFile } from '../../utils/ProcessFile';
import * as Components from '../index';
import { getAgeInfo } from '../../utils/AgeUtils';

const { Title } = Typography;

interface Props{

}
//https://charts.ant.design/en/examples/pie/basic#basic
const DetailedStatisticsComponent = (props: Props) : JSX.Element =>{
    const history = useHistory();
    const loginContext = useContext(LoginContext);
    
    const restApiClient : RestApiClient = new RestApiClient();
    
    const [data, setData] = React.useState<any[]>([]);
    const [totalSample, setTotalSample] = React.useState<number>(0);
    const [caseReports, setCaseReports] = React.useState<CaseReports[]>([]);
    const [linearData, setLinearData] = React.useState<any[]>([]);
    const [filter, setFilter] = React.useState<string>('');
    const [title, setTitle] = React.useState<string>('');
    const [loadingData, setLoadingData] = React.useState<boolean>(true);
    const [loadingDoctors, setLoadingDoctors] = React.useState<boolean>(false);
    const [doctors, setDoctors] = React.useState<Doctor[]>();

    const [selectedDoctor, setSelectedDoctor] = React.useState<number>();
    const [selectedDates, setSelectedDates] = React.useState<any>();

    const getDoctors = () : void => {
        if (loginContext.idRole === ADMIN_ROLE){
            const paramsApi : ParamsApi = {
                query:{
                    filter: ''
                }
            }
    
            setLoadingDoctors(true);
            restApiClient.fetch("GetDoctors", paramsApi)
                .then((r : ApiResponse | null)=> {
                    if (r && r.code === 200){
                        setDoctors(r.data);
                    }
                })
                .finally(()=>{
                    setLoadingDoctors(false);
                })
        }
        
    }
    

    const getStatistics = () : void => {

        const paramsApi : ParamsApi = {
            query:{
                "typeFilter": filter,
                "doctorId": loginContext.idRole === ADMIN_ROLE ? (selectedDoctor || -1) : loginContext.id,
                "dateFrom": selectedDates ? moment(selectedDates[0]).format("YYYY-MM-DD") : "",
                "dateTo": selectedDates ? moment(selectedDates[1]).format("YYYY-MM-DD") : ""
            }
        }

        setLoadingData(true);
        restApiClient.fetch("GetWordStatistics", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setTotalSample(r.data.totalRecordsType);
                    setData(r.data.statistics);
                    setCaseReports(r.data.caseReports);
                   
                }
            }).finally(()=>{
                setLoadingData(false);
            })
    }

    const prepareLineData = () : void => {
        var arrayResult : any[] = [];

        data.forEach((d: any, index: number)=> {
            arrayResult.push({
                index: index,
                range: "Menos de 21 años",
                value: d.less21,
                keyword:d.usedKeywords
            });

            arrayResult.push({
                index: index,
                range: "21-30",
                value: d.between21And30,
                keyword:d.usedKeywords
            });

            arrayResult.push({
                index: index,
                range: "31-40",
                value: d.between31And40,
                keyword:d.usedKeywords
            });

            arrayResult.push({
                index: index,
                range: "41-50",
                value: d.between41And50,
                keyword:d.usedKeywords
            });

            arrayResult.push({
                index: index,
                range: "51-60",
                value: d.between51And60,
                keyword:d.usedKeywords
            });

            arrayResult.push({
                index: index,
                range: "61-70",
                value: d.between61And70,
                keyword:d.usedKeywords
            });

            arrayResult.push({
                index: index,
                range: "71-80",
                value: d.between71And80,
                keyword:d.usedKeywords
            });

            arrayResult.push({
                index: index,
                range: "81-90",
                value: d.between81And90,
                keyword:d.usedKeywords
            });

            arrayResult.push({
                index: index,
                range:"Mayores de 90",
                value: d.over90,
                keyword:d.usedKeywords
            });
        });

        setLinearData(arrayResult);
    }

    useEffect(()=>{
        prepareLineData();
    }, [data]);

    useEffect(()=>{
        if (filter.length > 0){
            getStatistics();
        }
    }, [filter]);

    useEffect(()=>{
        getDoctors();
    },[])


    const renderStatistic = (containerWidth: any , text: any , style: any ) : any => {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2
    
        let scale = 1;
    
        if (containerWidth < textWidth) {
          scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }
    
        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
      }

    const configDonut = {
        appendPadding: 10,
        data,
        angleField: 'absolute',
        colorField: 'usedKeywords',
        radius: 1,
        innerRadius: 0.64,
        label: {
          type: 'inner', 
          style: {
            fontSize: 12,
          },
          content: '{value}',
        },
        statistic: {
          title: {
            customHtml: (container: any, view: any, datum: any) => {
                // Aquí pinta la parte superior que aparece dentro del donut
                const { width, height } = container.getBoundingClientRect();
                const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                const text = datum ? datum.usedKeywords : title;
                return renderStatistic(d, text, {
                    fontSize: 20,
                });
            },
          },
          content: {
            style: {
              fontSize: '20px',
            },
            customHtml: (container: any, view: any, datum: any, data: any) => {
                // Aquí pinta la parte inferior del texto que aparece dentro del donut
                const { width } = container.getBoundingClientRect();
                const text = datum ? `${datum.absolute} casos (${getAbsolutePercent(datum)}%)` : `${data.reduce((a:any, b:any) => a + (b["absolute"] || 0), 0)} casos (100%)`;
                return renderStatistic(width, text, {
                    fontSize: 20,
                });
            },
          },
        },
        interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
          {
            type: 'pie-statistic-active',
          },
        ],
      };

      
      const getTotalPercent = (d: any) => {
        var total =totalSample;
        if (total > 0){
            return Math.round(((d["absolute"] * 100) / total) * 100) / 100;
        }else{
            return 0;
        }
      }

      const getAbsolutePercent = (d : any) => {
        var total = data.reduce((a, b) => a + (b["absolute"] || 0), 0);
        if (total > 0){
            return Math.round(((d["absolute"] * 100) / total) * 100) / 100;
        }else{
            return 0;
        }
      }



      const columns : any = [
        {
            title: 'Caso/estudio',
            dataIndex: 'caseReportNum',
            key: 'caseReportNum',
        },
        {
            title: 'Paciente',
            dataIndex: 'patient',
            key: 'patient.name',
            render: (text: string, element: CaseReports) => renderColumn(element, <>
                <span>{element.patient.name} {element.patient.surname} {element.patient.secondSurname}</span>
                <small style={{display:"block"}}>
                    {
                        getAgeInfo(element.reception.request.start, element.patient.birthDate)
                    }
                </small>
            </>)
        },
        {
            title: 'Tipo de muestra',
            dataIndex: 'type',
            key: 'type',
            render: (i: number, element: CaseReports) => renderColumn(element, <>{element.product.description}
                <small style={{display:"block"}}>
                    {element.product.type}
                </small>
                
                </>)
        },
        {
            title: 'Palabras clave',
            dataIndex: 'type',
            key: 'type',
            render: (i: number, element: CaseReports) => element.keywords
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (element: CaseReports) => <Button size='small' danger disabled={(element?.status !== CaseReportStatus.Finished || element.reception.status > 0 )} onClick={()=>{getPdfData([element.id]); setSelected(element)}}>
                                                    <FilePdfOutlined />
                                                    <small className="h-section-name" >PDF</small>
                                                </Button>
        }
    ];

    const [selected, setSelected] = useState<CaseReports>();
    const [loadingMassivePrint, setLoadingMassivePrint] = useState<boolean>(false);
    const [showPdf, setShowPdf] = useState<boolean>(false);
    const [pdfData, setPdfData] = useState<any>();
    const getPdfData = (caseReportIds: number[]) => {
        const paramsApi : ParamsApi = {
            body:caseReportIds
        }

        setLoadingMassivePrint(true);
        restApiClient.generatePdf(paramsApi, "GenerateCaseReportsPDF")
            .then((data)=> {
                processFile(data, setPdfData);
                setShowPdf(true);
            })
            .finally(()=>{
                setLoadingMassivePrint(false);
            });
    }

    interface CaseReportsByCountry{
        Country: string,
        Cases: number,
        Keywords: string
    }

    const [caseReportsByCountryList, setCaseReportByCountryList] = React.useState<CaseReportsByCountry[]>([]);
    const getDataByCountry = () : void => {
        let dataByCountry : CaseReportsByCountry[] = [];
        caseReports.forEach((d:CaseReports, index:any) => {
            let idx = dataByCountry.findIndex((c:CaseReportsByCountry) => c.Country === d.reception.request.user.country && c.Keywords === d.keywords?.sort().toString());

            if (idx === -1){
                dataByCountry.push({
                    Country: d.reception.request.user.country,
                    Cases: 1,
                    Keywords: d.keywords ? d.keywords.sort().toString() : ''
                });
            }else{
                dataByCountry[idx].Cases += 1;
            }
        })
        
        setCaseReportByCountryList(dataByCountry.sort((a:CaseReportsByCountry, b:CaseReportsByCountry) => b.Cases - a.Cases));
    }

    useEffect(() => {
        getDataByCountry();
    },[caseReports]);

    const viewDetail = (element: CaseReports) : void => {
        history.push(`/case-report/${element.id}`);
    }

    const renderColumn = (element: CaseReports, children: any, addIcon: boolean = false) : any => {

        const finalChildren = children; 

        return {
            props: {
                className:'',
            },
            children: finalChildren,
        };
    }

    return(
        <Row gutter={16} className="detailed-statistics-component">
            <Components.ModalPDF isMassivePdf={false} selected={selected} onClose={()=>{setShowPdf(false); setPdfData(null); }} visible={showPdf} pdfData={pdfData}/> 

            <Col xs={{offset:3, span: 21}} className="filters">
                {
                    loginContext.idRole === ADMIN_ROLE ?
                        <Select defaultValue={-1} showSearch filterOption={(input:any, option:any) =>{
                                const name = `${option.children}`;
                                return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }} value={selectedDoctor} className="select-doctor" placeholder="Seleccione Médico" style={{ width: 250 }} onChange={setSelectedDoctor}>
                            <Select.Option key={-1} value={-1}>Todos</Select.Option>
                            {
                                doctors?.map((d: Doctor, index: number)=>{
                                    return(
                                        <Select.Option key={index} value={d.id}>{d.name} {d.surname} {d.secondSurname ? d.secondSurname : ''}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    :
                    ''
                }
                
                <DatePicker.RangePicker
                    value={selectedDates}
                    onChange={setSelectedDates}
                    className="dp-filter-dates" defaultValue={[moment(`01/01/` + (new Date().getFullYear())), moment(`12/31/` + (new Date().getFullYear()))]}
                    format="DD/MM/YYYY"
                    />
                <Button type="primary" className="btn-secondary" onClick={getStatistics}>Filtrar</Button>
            </Col>
            <Col xs={3} className="bordered-col right">
                {
                    // Iconos: https://fontawesome.com/icons/bacteria?s=solid
                }
                <Button disabled={loadingData && filter.length != 0} type="primary" size='small' className="btn-statistics-option full-width btn-secondary" onClick={()=>{ setFilter('citologia'); setTitle("Citología");}}>
                    <FontAwesomeIcon className="icon" icon={Icons.faBacteria} title="Paciente"/>&nbsp;Citologías
                </Button>
                <Button disabled={loadingData && filter.length != 0} type="primary" size='small' className="btn-statistics-option full-width btn-secondary" onClick={()=>{ setFilter('ihq-molecular'); setTitle("IHQ Molecular");}}>
                    <FontAwesomeIcon className="icon" icon={Icons.faBacterium} title="Paciente"/>&nbsp;IHQ Molecular
                </Button>
                <Button disabled={loadingData && filter.length != 0} type="primary" size='small' className="btn-statistics-option full-width btn-secondary" onClick={()=>{ setFilter('biopsia'); setTitle("Biopsias");}}>
                    <FontAwesomeIcon className="icon" icon={Icons.faDisease} title="Paciente"/>&nbsp;Biopsias
                </Button>
            </Col>

                {
                    filter.length === 0 ?
                        <Col xs={21}>
                            <Result
                                className="empty"
                                status="success"
                                title="Pulsar opción"
                                subTitle="Utiliza el menú izquierdo para mostrar las estadísticas."
                            />
                        </Col>
                    :
                    <>
                            <LoadingOrComponent showSpinner={false} loading={loadingData} 
                                text={
                                    <>
                                        <Col xs={9} className="bordered-col right">
                                            
                                                <Pie {...configDonut} />
                                        </Col>
                                        <Col xs={12} className="bordered-col none">
                                            <LoadingOrComponent showSpinner={false} loading={loadingData} 
                                                                text={
                                                                    <Collapse defaultActiveKey={['1']}>
                                                                        <Collapse.Panel header="Valores porcentuales (Muestra / Total)" key="1">
                                                                            {
                                                                                data.map((d:any) => {
                                                                                    return <div className="statistics-info">
                                                                                                <span className="statistics-label">{d.usedKeywords}</span>
                                                                                                <span className="statistics-value">{getAbsolutePercent(d)}% / {getTotalPercent(d)}%</span>
                                                                                                <Divider className="statistics-info-divider"/>
                                                                                        </div>
                                                                                })
                                                                            }
                                                                            <div className="statistics-info">
                                                                                <span className="statistics-label"></span>
                                                                                <span className="statistics-value">TOTAL: 100.00% / {Math.round((data.reduce((a, b) => a + (getTotalPercent(b) || 0), 0))* 10000) /10000}%</span>
                                                                                <Divider className="statistics-info-divider"/>
                                                                            </div>
                                                                        </Collapse.Panel>
                                                                        <Collapse.Panel header={<span>Valores absolutos (sobre <strong>{totalSample}</strong> casos de estudio muestreados)</span>} key="2">
                                                                            {
                                                                                data.map((d:any) => {
                                                                                    return <div className="statistics-info">
                                                                                                <span className="statistics-label">{d.usedKeywords}</span>
                                                                                                <span className="statistics-value">{d.absolute} casos</span>
                                                                                                <Divider className="statistics-info-divider"/>
                                                                                        </div>
                                                                                })
                                                                            }
                                                                            <div className="statistics-info">
                                                                                <span className="statistics-label"></span>
                                                                                <span className="statistics-value">TOTAL: {data.reduce((a, b) => a + (b["absolute"] || 0), 0)} casos</span>
                                                                                <Divider className="statistics-info-divider"/>
                                                                            </div>
                                                                        </Collapse.Panel>
                                                                        <Collapse.Panel header="Rango de edades" key="3" style={{padding:0}}>
                                                                            <table className="table-age-range" style={{width:"100%"}}>
                                                                                <tr className="header">
                                                                                    <td className="table-bold">Edad</td>
                                                                                    <td className="table-bold text-centered">&#60; 21</td>
                                                                                    <td className="table-bold text-centered">21-30</td>
                                                                                    <td className="table-bold text-centered">31-40</td>
                                                                                    <td className="table-bold text-centered">41-50</td>
                                                                                    <td className="table-bold text-centered">51-60</td>
                                                                                    <td className="table-bold text-centered">61-70</td>
                                                                                    <td className="table-bold text-centered">71-80</td>
                                                                                    <td className="table-bold text-centered">81-90</td>
                                                                                    <td className="table-bold text-centered">&#62; 90</td>
                                                                                </tr>
                                                                                {
                                                                                    //Pintamos una fila por cada keyword
                                                                                    data.map((d:any, index:any) => {
                                                                                        //R: 255, G: 0,   B: 0 => Rojo
                                                                                        //R: 255, G: 255, B: 0 => Amarillo
                                                                                        //R: 0,   G: 255, B: 0 => Verde
                                                                                        return(
                                                                                            <tr key={index}>
                                                                                                <td style={{width:"150px"}} className="table-bold">{d.usedKeywords}</td>
                                                                                                <td className={`text-centered ${d.less21 > 0 ? 'has-data' : ''}`}>{d.less21}</td>
                                                                                                <td className={`text-centered ${d.between21And30 > 0 ? 'has-data' : ''}`}>{d.between21And30}</td>
                                                                                                <td className={`text-centered ${d.between31And40 > 0 ? 'has-data' : ''}`}>{d.between31And40}</td>
                                                                                                <td className={`text-centered ${d.between41And50 > 0 ? 'has-data' : ''}`}>{d.between41And50}</td>
                                                                                                <td className={`text-centered ${d.between51And60 > 0 ? 'has-data' : ''}`}>{d.between51And60}</td>
                                                                                                <td className={`text-centered ${d.between61And70 > 0 ? 'has-data' : ''}`}>{d.between61And70}</td>
                                                                                                <td className={`text-centered ${d.between71And80 > 0 ? 'has-data' : ''}`}>{d.between71And80}</td>
                                                                                                <td className={`text-centered ${d.between81And90 > 0 ? 'has-data' : ''}`}>{d.between81And90}</td>
                                                                                                <td className={`text-centered ${d.over90 > 0 ? 'has-data' : ''}`}>{d.over90}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <tr>
                                                                                    <td className="table-bold">TOTALES</td>
                                                                                    <td className="text-centered">{data.reduce((a:any, b:any) => a + (b["less21"] || 0), 0)}</td>
                                                                                    <td className="text-centered">{data.reduce((a:any, b:any) => a + (b["between21And30"] || 0), 0)}</td>
                                                                                    <td className="text-centered">{data.reduce((a:any, b:any) => a + (b["between31And40"] || 0), 0)}</td>
                                                                                    <td className="text-centered">{data.reduce((a:any, b:any) => a + (b["between41And50"] || 0), 0)}</td>
                                                                                    <td className="text-centered">{data.reduce((a:any, b:any) => a + (b["between51And60"] || 0), 0)}</td>
                                                                                    <td className="text-centered">{data.reduce((a:any, b:any) => a + (b["between61And70"] || 0), 0)}</td>
                                                                                    <td className="text-centered">{data.reduce((a:any, b:any) => a + (b["between71And80"] || 0), 0)}</td>
                                                                                    <td className="text-centered">{data.reduce((a:any, b:any) => a + (b["between81And90"] || 0), 0)}</td>
                                                                                    <td className="text-centered">{data.reduce((a:any, b:any) => a + (b["over90"] || 0), 0)}</td>
                                                                                </tr>
                                                                                <tr className="header sup">
                                                                                    <td className="table-bold">Edad</td>
                                                                                    <td className="table-bold text-centered">&#60; 21</td>
                                                                                    <td className="table-bold text-centered">21-30</td>
                                                                                    <td className="table-bold text-centered">31-40</td>
                                                                                    <td className="table-bold text-centered">41-50</td>
                                                                                    <td className="table-bold text-centered">51-60</td>
                                                                                    <td className="table-bold text-centered">61-70</td>
                                                                                    <td className="table-bold text-centered">71-80</td>
                                                                                    <td className="table-bold text-centered">81-90</td>
                                                                                    <td className="table-bold text-centered">&#62; 90</td>
                                                                                </tr>
                                                                            </table>
                                                                        </Collapse.Panel>
                                                                        
                                                                        
                                                                        <Collapse.Panel header="Sexo (Mujer (%) vs Hombre (%))" key="4">
                                                                            {
                                                                                data.map((d:any) => {
                                                                                    return <>
                                                                                                <div className="statistics-info">
                                                                                                    <span className="statistics-label">{d.usedKeywords}</span>
                                                                                                    <span className="statistics-value">{d.women} ({d.womenPercent}%) / {d.men} ({d.menPercent}%) </span>
                                                                                                    <Divider className="statistics-info-divider"/>
                                                                                                </div>
                                                                                        </>
                                                                                })
                                                                            }
                                                                        </Collapse.Panel>
                                                                        {
                                                                            loginContext.idRole == ADMIN_ROLE ? 
                                                                                <Collapse.Panel header="Ciudades (Ordenado de mayor a menor incidencia)" key="5">
                                                                                    <table className="table-age-range" style={{width:"100%"}}>
                                                                                        <tbody>
                                                                                            <tr className="header">
                                                                                                <td className="table-bold">Ciudad</td>
                                                                                                <td className="table-bold">Palabra clave</td>
                                                                                                <td className="table-bold">Cantidad</td>
                                                                                            </tr>
                                                                                            {
                                                                                                //Pintamos una fila por cada keyword
                                                                                                caseReportsByCountryList.map((e:CaseReportsByCountry, index: number)=>{
                                                                                                    return(
                                                                                                        <tr key={index}>
                                                                                                            <td>{e.Country}</td>
                                                                                                            <td>{e.Keywords}</td>
                                                                                                            <td>{e.Cases}</td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                                
                                                                                            }
                                                                                            <tr className="header">
                                                                                                <td className="table-bold">Ciudad</td>
                                                                                                <td className="table-bold">Palabra clave</td>
                                                                                                <td className="table-bold">Cantidad</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                        
                                                                                    </table>
                                                                                </Collapse.Panel>:
                                                                                ''
                                                                        }
                                                                    </Collapse>
                                                                } 
                                                                loadingText={
                                                                    <Result
                                                                        className="empty"
                                                                        status="warning"
                                                                        title={<>Obteniendo datos</>}
                                                                        subTitle={<>Espere, consultando datos... <LoadingOutlined spin /></>}
                                                                    />
                                                                } 
                                            />  
                                        </Col>
                                        <Col xs={24}>
                                            <Title level={5}>
                                                Casos de estudio
                                            </Title>
                                            <Table rowKey="id" 
                                                size="small"
                                                dataSource={caseReports} 
                                                columns={columns} 
                                            />    
                                        </Col>
                                    </>
                                } loadingText={
                                    <Col xs={21}>
                                        <Result
                                            className="empty"
                                            status="warning"
                                            title={<>Obteniendo datos</>}
                                            subTitle={<>Espere, consultando datos... <LoadingOutlined spin /></>}
                                        />
                                    </Col>
                                } 
                            />
                    </>  
                }
            
        </Row>
    )
}


export default DetailedStatisticsComponent;