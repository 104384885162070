import React from 'react';

import * as Components from '../../../components'

import './AddUser.scss'

const AddUser = () : JSX.Element => {
    return (
        <div className="add-user-page">
        <Components.UsersManage updateMode={false} />
    </div>
    );
}

export default AddUser;