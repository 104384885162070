import React from "react";
import * as Templates from '../components/templates'
import Preformatted from "../models/Preformatted";

export const getTemplateName = (id: string) : string =>{
    let result : string = "";

    switch(id){
        case "biopsia":
            result = "Biopsias";
            break;
        case "citologia":
            result = "Citologías";
            break;
        case "ihq-molecular":
            result = "IHQ y Molecular";
            break;
        case "microbiologia":
            result = "Microbiología";
            break;
        default:
            result = id;
            break;
    }

    return result;
}

export const extractMetaDataTemplate = (preformatted? : Preformatted) : any =>{
    let result : any = null;

    if(!preformatted){
        return result;
    }

    switch(preformatted.type){
        case "biopsia":
            if (preformatted.subType === "diagnostico"){
                result = preformatted.biopsiaDiagnosisData;
            }else{
                result = preformatted.biopsiaReceptionData;
            }
            break;
        case "citologia":
            if (preformatted.subType === "diagnostico"){
                result = preformatted.citologiaDiagnosisData;
            }else{
                result = preformatted.citologiaReceptionData;
            }
            break;
        case "ihq-molecular":
            if (preformatted.subType === "diagnostico"){
                result = preformatted.ihqMolecularDiagnosisData;
            }else{
                result = preformatted.ihqMolecularReceptionData;
            }
            break;
        case "microbiologia":
            if (preformatted.subType === "diagnostico"){
                result = preformatted.microbiologiaDiagnosisData;
            }else{
                result = preformatted.microbiologiaReceptionData;
            }
            break;
        default:
            break;
    }


    return result;
}

export const loadTemplate = (id: string, form: any, defaultValues: any, reset: boolean = true, useForm: boolean = true) : JSX.Element =>{
    return <Templates.Main type={id} reset={reset} form={form} values={defaultValues} useForm={useForm}/>;
}