import { Tabs } from 'antd';
import React from 'react'

import * as Components from './../../components'

import './ConfigurationsPage.scss'


const ConfigurationsPage = () : JSX.Element => {
    return (
        <div className='configurations-page'>
            <h1>Configuraciones sistema</h1>

            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Palabras clave" key="1">
                    <Components.Keywords />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Modelo 347" key="2">
                    <Components.Model347 />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Atajos" key="3">
                    <Tabs defaultActiveKey="11">
                        <Tabs.TabPane tab="Información clínica" key="11">
                            <Components.Shortcuts type='ClinicInfo'/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Observaciones" key="12">
                            <Components.Shortcuts type='Observations'/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Etiquetas especiales" key="13">
                            <Components.Shortcuts type='SpecialTags'/>
                        </Tabs.TabPane>
                    </Tabs>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Configuraciones extra" disabled key="4">
                    Proximamente...
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

export default ConfigurationsPage;