import React, { forwardRef, useEffect, useState } from 'react'
import { Form, Select } from 'antd'

//https://github.com/zenoamaro/react-quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import User from '../../../models/Users';
import RestApiClient from '../../../api/restApiClient';
import ParamsApi from '../../../models/api/ParamsApi';
import ApiResponse from '../../../models/api/ApiResponse';


interface CitologiaProps {
    reset: boolean,
    form: any,
    values?:{
        muestra:string,
        estudio:string,
        informacionClinica:string
    }
}


// https://stackoverflow.com/questions/37949981/call-child-method-from-parent
const CitologiaReceptionComponent = forwardRef((props: CitologiaProps, ref: any)=>{
    const restApiClient : RestApiClient = new RestApiClient();
    
    const formItemHeight : string = "300px";
    const textAreaHeight : string = "250px";

    const [reset, setReset] = useState<boolean>(false);
    const [shortCuts, setShortCuts] = useState<any[]>([]);
    const [selectedShortCode, setSelectedShortCode] = useState<string[]>([]);

    const { form, values } = props;

    const GetShortcuts = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                page: 0,
                N: 0,
                filter: 'ClinicInfo', 
            }
        }
        restApiClient.fetch("GetOptions", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setShortCuts(r.data.elements);
                }
            })
    }

    useEffect(()=>{
        if(props.reset){
            GetShortcuts();
            setReset(true);
        }
    },[props])

    
    useEffect(()=>{
        if(reset){
            form.setFieldsValue({"citologiaReceptionData":{ "muestra":"CITOLOGÍA VAGINAL TRIPLE TOMA", "estudio":"CITOLOGÍA EXFOLIATIVA GINECOLÓGICA", "informacionClinica":""}});
            setReset(false);
        }
    },[reset])

    useEffect(()=>{
        if(values){
            form.setFieldsValue({"citologiaReceptionData":{ "muestra":values.muestra ?? "", "estudio":values.estudio ?? "", "informacionClinica":values.informacionClinica ?? ""}});
        }else{
            form.setFieldsValue({"citologiaReceptionData":{ "muestra":"CITOLOGÍA VAGINAL TRIPLE TOMA", "estudio":"CITOLOGÍA EXFOLIATIVA GINECOLÓGICA", "informacionClinica":""}});
        }
    },[values])


    return (<>
                <small>Citología</small>
                <Form.Item 
                    name={["citologiaReceptionData","muestra"]}
                    rules={[{ required: true, message: "Debe seleccionar una muestra"}]}
                    label="Muestra">
                    <Select style={{ width: "100%" }} >
                        <option id="1489666217809" value="CITOLOGÍA VAGINAL TRIPLE TOMA">CITOLOGÍA VAGINAL TRIPLE TOMA</option>
                        <option id="1489666263089" value="CITOLOGÍA VAGINAL SIMPLE">CITOLOGÍA VAGINAL SIMPLE</option>
                        <option id="1489666292785" value="CITOLOGÍA ENDOCERVICAL">CITOLOGÍA ENDOCERVICAL</option>
                        <option id="1489666321059" value="CITOLOGÍA ENDOMETRIAL">CITOLOGÍA ENDOMETRIAL</option>
                        <option id="1489666346209" value="CITOLOGÍA VULVAR">CITOLOGÍA VULVAR</option>
                        <option id="1489666392209" value="CITOLOGÍA SECRECIÓN MAMARIA">CITOLOGÍA SECRECIÓN MAMARIA</option>
                        <option id="1502129773450" value="PAAF DE MAMA">PAAF DE MAMA</option>
                        <option id="1523306161339" value="LÍQUIDO PERITONEAL">LÍQUIDO PERITONEAL</option>
                        <option id="1526075240280" value="PAAF DE GANGLIO LINFÁTICO">PAAF DE GANGLIO LINFÁTICO</option>
                        <option id="1591514011694" value="CITOLOGÍA VAGINAL DOBLE TOMA">CITOLOGÍA VAGINAL DOBLE TOMA</option>
                        <option id="1592737379181" value="LIQUIDO ARTICULAR">LIQUIDO ARTICULAR</option>
                        <option id="1614502624169" value="LIQUIDO MAMARIO">LIQUIDO MAMARIO</option>
                        <option id="1614502624170" value="CITOLOGIA URINARIA">CITOLOGIA URINARIA</option>
                    </Select>
                </Form.Item>
                <Form.Item 
                    name={["citologiaReceptionData","estudio"]}
                    rules={[{ required: true, message: "Debe seleccionar un estudio"}]}
                    label="Estudio">
                    <Select style={{ width: "100%" }} >
                        <option id="1489666586175" value="CITOLOGÍA EXFOLIATIVA GINECOLÓGICA">CITOLOGÍA EXFOLIATIVA GINECOLÓGICA</option>
                        <option id="1489666629631" value="CITOLOGÍA EXFOLIATIVA NO GINECOLÓGICA">CITOLOGÍA EXFOLIATIVA NO GINECOLÓGICA</option>
                        <option id="1489666666111" value="CITOLOGÍA CONSULTA">CITOLOGÍA CONSULTA</option>
                        <option id="1489666723438" value="PUNCIÓN-ASPIRACIÓN CON AGUJA FINA ( PAAF )">PUNCIÓN-ASPIRACIÓN CON AGUJA FINA ( PAAF )</option>
                    </Select>
                </Form.Item>
                <Form.Item 
                    name={["citologiaReceptionData","informacionClinica"]}
                    label="Información clínica" style={{height: formItemHeight}} initialValue="">
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
                <Select 
                    mode="tags" 
                    value={selectedShortCode}
                    onChange={(v)=>{ setSelectedShortCode(v)}}
                    style={{ width: '100%', marginBottom: 5 }} 
                    placeholder="Atajos" 
                    placement='topLeft'
                    onDeselect={(v: string)=>{
                        try{
                            let ob : string = form.getFieldValue("citologiaReceptionData").informacionClinica;

                            ob = ob.replace(`${v}`, "");

                            form.setFieldsValue({"citologiaReceptionData":{ "informacionClinica":ob}});

                        }catch(exception){

                        }
                    }} 
                    onSelect={(v: string)=>{
                        let ob : string = form.getFieldValue("citologiaReceptionData").informacionClinica;

                        if (ob){
                            ob = `${ob} ${v} `;
                        }else{
                            ob = ` ${v} `;
                        }

                        form.setFieldsValue({"citologiaReceptionData":{ "informacionClinica":ob}});
                    }}
                >
                    {
                        shortCuts.map((s: any) => {
                            return <Select.Option key={s.key} value={s.value}>{s.value}</Select.Option>
                        })
                    }
                </Select>
            </>)
        

            
});


export default CitologiaReceptionComponent;
