import React, { forwardRef, useEffect, useState } from 'react'
import { Form, Select } from 'antd'

//https://github.com/zenoamaro/react-quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import User from '../../../models/Users';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';
import RestApiClient from '../../../api/restApiClient';


interface MicrobiologiaReceptionProps {
    reset: boolean,
    form: any,
    values?:{
        muestra:string,
        estudio:string,
        informacionClinica:string
    }
}


// https://stackoverflow.com/questions/37949981/call-child-method-from-parent
const MicrobiologiaReceptionComponent = forwardRef((props: MicrobiologiaReceptionProps, ref: any)=>{
    const restApiClient : RestApiClient = new RestApiClient();
    
    const formItemHeight : string = "300px";
    const textAreaHeight : string = "250px";

    const [reset, setReset] = useState<boolean>(false);
    const [shortCuts, setShortCuts] = useState<any[]>([]);
    const [selectedShortCode, setSelectedShortCode] = useState<string[]>([]);

    const { form, values } = props;

    const GetShortcuts = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                page: 0,
                N: 0,
                filter: 'ClinicInfo', 
            }
        }
        restApiClient.fetch("GetOptions", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setShortCuts(r.data.elements);
                }
            })
    }

    useEffect(()=>{
        if(props.reset){
            GetShortcuts();
            setReset(true);
        }
    },[props])

    
    useEffect(()=>{
        if(reset){
            form.setFieldsValue({"microbiologiaReceptionData":{ "muestra":"EXUDADO VAGINAL", "estudio":"CULTIVO MICROBIOLÓGICO", "informacionClinica":""}});
            setReset(false);
        }
    },[reset])

    useEffect(()=>{
        if(values){
            form.setFieldsValue({"microbiologiaReceptionData":{ "muestra":values.muestra ?? "", "estudio":values.estudio ?? "", "informacionClinica":values.informacionClinica ?? "" }});
        }else{
            form.setFieldsValue({"microbiologiaReceptionData":{ "muestra":"EXUDADO VAGINAL", "estudio":"CULTIVO MICROBIOLÓGICO", "informacionClinica":""}});
        }
    },[values])


    return (<>
                <small>Microbiología</small>
                <Form.Item 
                    name={["microbiologiaReceptionData","muestra"]}
                    rules={[{ required: true, message: "Debe seleccionar una muestra"}]}
                    label="Muestra">
                    <Select style={{ width: "100%" }} >
                        <option id="1520412040916" value="EXUDADO VAGINAL">EXUDADO VAGINAL</option>
                        <option id="1520412123792" value="EXUDADO CERVICAL">EXUDADO CERVICAL</option>
                        <option id="1547229952925" value="CONTENIDO / SECRECIÓN MAMARIA">CONTENIDO / SECRECIÓN MAMARIA</option>
                        <option id="1547229952926" value="CONTENIDO ENDOMETRIAL">CONTENIDO ENDOMETRIAL</option>
                        <option id="1560113031570" value="EXUDADO RECTAL">EXUDADO RECTAL</option>
                        <option id="1563430856155" value="EXUDADO VULVAR">EXUDADO VULVAR</option>
                        <option id="1573120833792" value="UROCULTIVO">UROCULTIVO</option>
                        <option id="1589367307007" value="EXUDADO FARINGEO">EXUDADO FARINGEO</option>
                        <option id="1607619981920" value="EXUDADO ANAL">EXUDADO ANAL</option>
                        <option id="1614793727848" value="EXUDADO PENEANO">EXUDADO PENEANO</option>
                        <option id="1614793727849" value="EXUDADO URETRAL">EXUDADO URETRAL</option>
                        <option id="1589544169999" value="UÑA">UÑA</option>
                    </Select>
                </Form.Item>
                <Form.Item 
                    name={["microbiologiaReceptionData","estudio"]}
                    initialValue="CULTIVO MICROBIOLÓGICO"
                    rules={[{ required: true, message: "Debe seleccionar un estudio"}]}
                    label="Estudio">
                    <Select style={{ width: "100%" }} >
                        <option id="1520412184035" value="CULTIVO MICROBIOLÓGICO">CULTIVO MICROBIOLÓGICO</option>
                    </Select>
                </Form.Item>
                <Form.Item 
                    name={["microbiologiaReceptionData","informacionClinica"]}
                    label="Información clínica" style={{height: formItemHeight}} initialValue="">
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
                <Select 
                    mode="tags" 
                    value={selectedShortCode}
                    onChange={(v)=>{ setSelectedShortCode(v)}}
                    style={{ width: '100%', marginBottom: 5 }} 
                    placeholder="Atajos" 
                    placement='topLeft'
                    onDeselect={(v: string)=>{
                        try{
                            let ob : string = form.getFieldValue("microbiologiaReceptionData").informacionClinica;

                            ob = ob.replace(`${v}`, "");

                            form.setFieldsValue({"microbiologiaReceptionData":{ "informacionClinica":ob}});

                        }catch(exception){

                        }
                    }} 
                    onSelect={(v: string)=>{
                        let ob : string = form.getFieldValue("microbiologiaReceptionData").informacionClinica;

                        if (ob){
                            ob = `${ob} ${v} `;
                        }else{
                            ob = ` ${v} `;
                        }

                        form.setFieldsValue({"microbiologiaReceptionData":{ "informacionClinica":ob}});
                    }}
                >
                    {
                        shortCuts.map((s: any) => {
                            return <Select.Option key={s.key} value={s.value}>{s.value}</Select.Option>
                        })
                    }
                </Select>
            </>)
        

            
});


export default MicrobiologiaReceptionComponent;
