import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Keywords'

export const KeywordsEP : EndPointConfig[] =[
        new EndPointConfig()
            .setMethod('GET')
            .setController(controller) 
            .setActionName("GetKeywords"),    
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller) 
            .setActionName("AddKeyword"),
        new EndPointConfig()
            .setMethod('DELETE')
            .setController(controller) 
            .setActionName("DeleteKeyword")
]