import React, { useEffect, useState } from 'react'
import Product from '../../../models/Product'
import { Button, Divider, Form, Input, InputNumber, Select } from 'antd'
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';


import './ProductCEComponent.scss'


const { Option } = Select;

interface ProductCEProps{
    title?: string,
    idCompany: number,
    product?: Product,
    onFinish: ()=>void
}

const ProductCEComponent = (props: ProductCEProps) : JSX.Element => {
    
    const restApiClient : RestApiClient = new RestApiClient();

    const { title, idCompany } = props;
    const [form] = Form.useForm();

    const [ product, setProduct ] = useState<Product | undefined>();
    const [ isUpdate, setIsUpdate ] = useState<boolean>(false);

    const onFinish = () : void => {
        if (isUpdate){
            editProduct();
        }else{
            addProduct();
        }
    }

    const addProduct = () : void => {
        
        var formsValues = form.getFieldsValue()

        formsValues.isActive = formsValues.isActive === "si" ? true : false;

        const paramsApi : ParamsApi = {
            body: {
                idCompany: idCompany,
                ...formsValues
            }
        }

        restApiClient.fetch("AddProduct", paramsApi).then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
                form.resetFields();
                props.onFinish();
            };
        });
    }

    const editProduct = () : void => {
        var formsValues = form.getFieldsValue()

        formsValues.isActive = formsValues.isActive === "si" ? true : false;

        const paramsApi : ParamsApi = {
            body: {
                id: product?.id,
                idCompany: idCompany,
                ...formsValues
            }
        }

        restApiClient.fetch("UpdateProduct", paramsApi).then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
                props.onFinish();
            };
        });
    }

    useEffect(()=>{
        if (props.product){
            setIsUpdate(true);
            setProduct(props.product);
            form.setFieldsValue(props.product);
            form.setFieldsValue({
                isActive: props.product.isActive ? "si" : "no"
                });
        }
    },[props.product])

    

    return (
        <div className="product-ce-component">
            {
                title ? <Divider orientation="left">{title}</Divider> : ''
            }
            
            <Form layout="vertical" form={form} name="frm-add-company" onFinish={onFinish} >
                <Form.Item name="description" label="Nombre producto" rules={[{ required: true, message: "Debe indicar un nombre para el producto."}]}>
                    <Input />
                </Form.Item>
                <Divider orientation="left">Precio</Divider>
                <Form.Item name="price" label="Precio (P.V.P)" rules={[{ required: true, message: "Debe indicar un precio para el producto. Indique 0 si es gratuito."},({ getFieldValue }) => ({
                        validator(rule, value) {
                            if(isNaN(value)){
                                return Promise.reject("El P.V.P debe ser un valor numérico.");
                            }

                            return Promise.resolve();
                        },
                        })]}>
                    <InputNumber style={{minWidth:"100%"}}/>
                </Form.Item>
                <Form.Item name="reducedPrice" label="Precio reducido (P.V.P.R)"
                    rules={[
                        ({ getFieldValue }) => ({
                        validator(rule, value) {
                            const priceValue = form.getFieldValue("price");
                            if(isNaN(value)){
                                return Promise.reject("El P.V.P.R debe ser un valor numérico.");
                            }else if(priceValue < value){
                                return Promise.reject("El P.V.P.R debe ser inferior o igual al P.V.P");
                            }

                            return Promise.resolve();
                        },
                        })]}
                >
                    <InputNumber style={{minWidth:"100%"}}/>
                </Form.Item>
                <Divider orientation="left">Plantillas</Divider>
                <Form.Item name="type" label="Tipología" rules={[{ required: true, message: "Debe indicar la tipología del producto."}]}>
                    <Select>
                        <Option value="biopsia">Biopsias</Option>
                        <Option value="citologia">Citología</Option>
                        <Option value="ihq-molecular">IHQ y Molecular</Option>
                        <Option value="microbiologia">Microbiología</Option>
                    </Select>
                </Form.Item>
                <Divider orientation="left">Activo</Divider>
                <Form.Item name="isActive" label="Activo" rules={[{ required: true, message: "Debe indicar si el producto está activo o no."}]}>
                    <Select>
                        <Option value="si">Si</Option>
                        <Option value="no">No</Option>
                    </Select>
                </Form.Item>
                <div className="form-buttons-container right">
                    <Button className="btn-save" type="primary" htmlType="submit">
                        {isUpdate ? "Actualizar": "Guardar"} 
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default ProductCEComponent;