import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Divider, Checkbox, InputNumber, Alert, Select } from 'antd'
import Preformatted from '../../../../models/Preformatted';
import { useHistory } from "react-router-dom";
import ParamsApi from '../../../../models/api/ParamsApi';
import RestApiClient from '../../../../api/restApiClient';
import ApiResponse from '../../../../models/api/ApiResponse';
import { extractMetaDataTemplate, getTemplateName, loadTemplate } from '../../../../utils/TemplateNames';
import LoadingOrComponent from '../../../core/utils/LoadingOrComponent';

interface Props{
    preformattedData?: Preformatted,
    updateMode?: boolean,
    data?: Preformatted
}

const PreformattedManagementComponent = (props: Props) : JSX.Element => {
    const [form] = Form.useForm();
    const history = useHistory();
    const restApiClient : RestApiClient = new RestApiClient();

    const { updateMode } = props;
    
    const [goExitOnFinish, setGoExitOnFinish] = useState<boolean>(true);
    const [template, setTemplate] = useState<any>()
    const [data, setData] = useState<Preformatted>();
    const [savingData, setSavingData] = useState<boolean>(false);
    
    const setExitOnFinish = (goExitOnFinish:boolean) : void => {
        setGoExitOnFinish(goExitOnFinish);
    }

    const onLoadTemplate = () : void => {
        var type = data?.type ?? form.getFieldValue("type");

        // Se pide por cliente quitar las plantillas de tipo recepción pero se comenta por si cambia de idea.
        var subType = "diagnostico";

        if (type && subType){
            setTemplate(loadTemplate(`${type}-${subType}`, form, extractMetaDataTemplate(data), true, false));
        }else{
            console.warn("No data to load template", type, subType);
        }
    }
    
    const onFinish = (values: any) : void => {
        values = form.getFieldsValue();
        if (updateMode){
            values.id = props.data?.id;
            values.active = props.data?.active;
        }

        // Se pide por cliente quitar las plantillas de tipo recepción pero se comenta por si cambia de idea.
        values.subType="diagnostico";

        const paramsApi : ParamsApi = {
            body:values
        }
        

        const ep : string = props.updateMode ? "UpdatePreformatted" : "AddPreformatted";

        setSavingData(true);
        restApiClient.fetch(ep, paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setTimeout(() => {
                        history.push("/preformatted")
                    }, 500);
                }
            }).finally(()=>{
                setSavingData(false);
            });
    }

    const saveAndExit = (values: any) : void => {
        if (updateMode){
            values.id = props.data?.id;
            values.active = props.data?.active;
        }

        // Se pide por cliente quitar las plantillas de tipo recepción pero se comenta por si cambia de idea.
        values.subType="diagnostico";

        const paramsApi : ParamsApi = {
            body:values
        }

        const ep : string = props.updateMode ? "UpdatePreformatted" : "AddPreformatted";

        setSavingData(true);
        restApiClient.fetch(ep, paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    if (!props.updateMode){
                        onReset();
                    }
                    history.push("/preformatted")
                }
            }).finally(()=>{
                setSavingData(false);
            });
    }

    const onReset = () : void => {
        form.resetFields();
    }


    useEffect(()=>{
        if (props.data){
            setData(props.data);
            form.setFieldsValue({
                "type": props.data.type,
                "subType":props.data.subType,
                "description":props.data.description
            }); 
            setTemplate(loadTemplate(`${props.data.type}-${props.data.subType}`, form, extractMetaDataTemplate(props.data), !updateMode));
        }
    },[props.data]);


    return (
        <div className='preformatted-management'>
            <Form form={form} layout='vertical' name="frm-add-preformatted" onFinish={onFinish}>
                <div className="b-component-actions">
                    <Button className="btn-save" type="default" onClick={()=>{history.goBack()}}>
                        Volver al listado
                    </Button>
                    {
                        updateMode ? 
                        '' 
                            : 
                            <Button className="btn-reset" htmlType="button" onClick={onReset}>
                                Limpiar campos
                            </Button>
                    }
                    
                    <Button className="btn-save" onClick={onFinish} type="primary" >
                        <LoadingOrComponent loading={savingData} text={`${props.updateMode ? "Actualizar": "Guardar"} y salir`}  showWhileLoading={false}/>
                    </Button>
                </div>
                <Divider orientation="left">Tipología</Divider>
                <Form.Item name="type" label="Tipo" 
                    help="Selecciona el tipo de preformateado." rules={[{ required: true, message: "Campo obligatorio"}]}>
                    <Select style={{ width: "100%" }} onChange={onLoadTemplate} disabled={updateMode}>
                        <Select.Option value="biopsia">Biopsia</Select.Option>
                        <Select.Option value="citologia">Citología</Select.Option>
                        <Select.Option value="ihq-molecular">IHQ Molecular</Select.Option>
                        <Select.Option value="microbiologia">Microbiología</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item initialValue={"diagnostico"} name="subType" label="Sub-tipo" style={{display:"none", visibility:"hidden"}}
                    help="Selecciona el sub-tipo de preformateado." >
                    <Select style={{ width: "100%" }} onChange={onLoadTemplate} disabled={updateMode}>
                        <Select.Option value="recepcion">Recepción</Select.Option>
                        <Select.Option value="diagnostico">Diagnóstico</Select.Option>
                    </Select>
                </Form.Item>
                
                <Divider orientation="left">Contenido</Divider>
                <Form.Item name="description" label="Descripción" 
                    help="Texto descriptivo de la plantilla" rules={[{ required: true, message: "Campo obligatorio"}]}>
                    <Input />
                </Form.Item>
                {template}
            </Form>
        </div>
    )
}

export default PreformattedManagementComponent;