import { Button, Card, Col, DatePicker, Divider, Form, Input, Radio, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { LoadingOrText } from '../..';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';
import Patient from '../../../models/Patient';
import { useHistory } from "react-router-dom";

import PatientCoincidencesComponent from '../coincidences/PatientCoincidencesComponent';

import './ManagePatientComponent.scss'
import { FileSearchOutlined } from '@ant-design/icons';

interface ManagePatientProps{
    onFinish? : (values : any) => void,
    clear: boolean
    patientData?: Patient,
    editMode: boolean
}

const ManagePatientComponent = (props : ManagePatientProps) : JSX.Element => {

    const history = useHistory();

    const [form] = Form.useForm();
    const {editMode} = props;
    const restApiClient : RestApiClient = new RestApiClient();

    const [selected, setSelected] = useState<Patient>();

    const [savingData, setSavingData] = useState<boolean>(false);
    const [coincidences, setCoincidences] = useState<Patient[]>([])
    const [ data, setData] = useState<Patient>();

    const [numberOrRecords, setNumberOfRecords] = useState<number>(10);
    
    const [numberOfCoincidences, setNumberOfCoincidences] = useState<number>(0);
    const searchCoincidences = () : void => {
        const name : string = `${form.getFieldValue('name') ?? ''} ${form.getFieldValue('surname') ?? ''} ${form.getFieldValue('secondSurname') ?? ''}`;

        if (name.length >= 3){
            const paramsApi : ParamsApi = {
                query:{
                    page:0,
                    N:numberOrRecords, 
                    filter: name
                }
            }
            
            restApiClient.fetch("GetPatientsBasic", paramsApi)
                            .then((r : ApiResponse | null)=> {
                                if (r && r.code === 200){
                                    setCoincidences(r.data.elements);
                                    setNumberOfCoincidences(r.data.totalRecords);
                                }
                            })
        }

        
    }

    const onFinish = () => {
        let params = form.getFieldsValue();
        
        setSavingData(true);

        let ep : string = "AddPatient";
        if (editMode){
            ep = "UpdatePatient";
            params.id = data?.id
        }

        const paramsApi : ParamsApi = {
            body : params
        }


        restApiClient.fetch(ep, paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200 && props.onFinish){
                    props.onFinish(r.data as Patient);
                }
            })
            .finally(()=>{
                setSavingData(false);
            })
    }

    const resetFields = () => {
        form.resetFields();
    }

    useEffect(()=>{
        searchCoincidences();
    },[numberOrRecords])

    useEffect(() => {
        if (selected && props.onFinish){
            props.onFinish(selected);
        }
    }, [selected])

    useEffect(() => {
        if (props.clear){
            form.resetFields();
        }
    }, [props.clear])

    useEffect(()=>{
        if (props.patientData && props.patientData.id){
            props.patientData.birthDate = moment(props.patientData.birthDate);
            setData(props.patientData);
            form.setFieldsValue(props.patientData);
        }
    },[props.patientData]);


    return (
        <Form form={form} onFinish={onFinish} layout="vertical" className="frm-register">
            <Divider orientation="left">Datos personales</Divider>
            <Row gutter={16}>
                <Col xs={24}>
                    <div className="b-component-actions">
                        {
                            editMode ? 
                            <Button className="btn-save" type="default" onClick={()=>{history.goBack()}}>
                                Volver al listado
                            </Button>
                            :
                            ''
                        }
                        <Button className="btn-save" onClick={resetFields} type="default">
                            Limpiar campos
                        </Button>
                        <Button disabled={savingData} className="btn-save" type="primary" htmlType="submit">
                            <LoadingOrText loading={savingData} text={editMode ? 'Actualizar' : 'Guardar'}/>
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24}>
                    <Form.Item name="name" label="Nombre" 
                        rules={[{ required: true, message: "Debe indicar el nombre del paciente."}]}>
                        <Input />
                    </Form.Item>
                    
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name="surname" label="Primer apellido" rules={[{ required: true, message: "Debe indicar al menos el primer apellido."}]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name="secondSurname" label="Segundo apellido">
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} style={{textAlign: "right"}}>
                    <Button type='primary' style={{marginBottom: "15px"}} onClick={(v:any)=>{searchCoincidences()}}><FileSearchOutlined /> Buscar coincidencias</Button>
                </Col>
                <Col xs={24}>
                        <PatientCoincidencesComponent coincidences={coincidences} onSelect={setSelected} numberOfRecords={numberOfCoincidences} onShowAll={setNumberOfRecords}/>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name="nif" label="DNI/NIE">
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name="birthDate" label="Fecha Nacimiento">
                        <DatePicker style={{width:"100%"}} format="DD/MM/YYYY" placeholder="N/D"/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name="sex" initialValue={"mujer"} label="Sexo" rules={[{ required: true, message: "Debe indicar el sexo del paciente."}]}>
                        <Radio.Group>
                            <Radio value={'varón'}>Varón</Radio>
                            <Radio value={'mujer'}>Mujer</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Divider orientation="left">Datos contacto</Divider>
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Form.Item name="email" label="Email">
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name="address" label="Dirección">
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name="phone1" label="Teléfono">
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item name="phone2" label="Teléfono">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Divider orientation="left">Observaciones</Divider>
            <Row gutter={16}>
                <Col xs={24}>
                    <Form.Item name="observations" label="Observaciones">
                        <Input.TextArea />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24}>
                    <div className="b-component-actions">
                        {
                            editMode ? 
                            <Button className="btn-save" type="default" onClick={()=>{history.goBack()}}>
                                Volver al listado
                            </Button>
                            :
                            ''
                        }
                        <Button className="btn-save" onClick={resetFields} type="default">
                            Limpiar campos
                        </Button>
                        <Button disabled={savingData} className="btn-save" type="primary" htmlType="submit">
                            <LoadingOrText loading={savingData} text={editMode ? 'Actualizar' : 'Guardar'}/>
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default ManagePatientComponent;