

export const processFile = (data: any, callBack: any) => {
    data.blob().then((blob: any) => {
        let reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        //reader.readAsDataURL(blob);
        reader.onloadend = () => {
            let blob = reader.result;
            if (!blob){
                console.error("ERROR al obtener informe");
            }
            let file = new Blob([blob!], { type: 'application/pdf'});
            let fileUrl = window.URL.createObjectURL(file);
            callBack(fileUrl);
        }
    })
}