import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Pdf'

export const PDFEP : EndPointConfig[] =[
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller) 
            .setActionName("SendReportTo"),
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller) 
            .setActionName("GenerateCaseReportsPDF")
]