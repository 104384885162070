import React, {useContext,  useEffect,  useState } from 'react'
import { useHistory } from "react-router-dom";

import { Badge, notification, Tooltip } from 'antd'
import { NotificationOutlined,
         MailOutlined,
         ProfileOutlined,
         CreditCardOutlined,
         RadarChartOutlined,
         LogoutOutlined,
         HeartOutlined 
} from '@ant-design/icons'
import * as Drawers from '../drawers/index'
import ApiResponse from '../../../models/api/ApiResponse'
import RestApiClient from '../../../api/restApiClient'

import { LoginContext } from '../../../hooks/context/userContext'

interface DashboardHelperProps{}

const DashboardHelperComponent = (props: DashboardHelperProps) : JSX.Element => {
    const loginContext = useContext(LoginContext);

    const restApiClient : RestApiClient = new RestApiClient();
    const history = useHistory();

    const [showContactDrawer, setShowContactDrawer] = useState<boolean>(false);
    const [showProfileDrawer, setShowProfileDrawer] = useState<boolean>(false);


    const doLogout = () : void => {

        restApiClient.fetch("DoLogout", {
            query:{
                sessionId: sessionStorage.getItem("token")
            }
        }).finally(()=>{
            restApiClient.closeSession();
            history.push("/");
        });
        
    }

    

    
    


    const goOmnicode = () : void => {
        window.open('https://www.omnicode.es','_blank')
    }

    return (
        <>
            <ul className="helper-balls dashboard-balls">
                <Tooltip placement="left" title="Contacta">
                <li className="h-contact" onClick={()=>{ setShowContactDrawer(true); }}>
                    <MailOutlined />
                    <small className="h-section-name">Contacto</small>
                </li>
                </Tooltip>
                <Tooltip placement="left" title="Tu perfil">
                <li className="h-enrollments" onClick={()=>{ setShowProfileDrawer(true); }}>
                    <ProfileOutlined />
                    <small className="h-section-name">Perfil</small>
                </li>
                </Tooltip>
                <Tooltip placement="left" title="Cerrar sesión">
                <li className="h-logout" onClick={doLogout}>
                    <small className="h-section-name" >Salir</small>
                <LogoutOutlined />
                </li>
                </Tooltip>
                <Tooltip placement="left" title="Hecho con amor por el equipo de Omnicode.es">
                    <li className="h-heart" onClick={goOmnicode}>
                        <HeartOutlined />
                        <small className="h-section-name" >Omnicode</small>
                    </li>
                </Tooltip>
                
                <li className="created-by-omnicode">
                    <small>Creado desde Málaga con <HeartOutlined /> por <a onClick={goOmnicode}>Omnicode</a></small>
                </li>
            </ul>

            {
                // Drawer for profile form
            }
            <Drawers.ProfileDrawer visible={showProfileDrawer} setShowDrawer={setShowProfileDrawer} />

           
        </>
    )
}

export default DashboardHelperComponent;