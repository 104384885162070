import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Invoices'

export const InvoicesEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("GenerateInvoice"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetInvoicePDF"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("Get347Model"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("GenerateResumePDF"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetInvoiceList"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetInvoiceNotifications"),     
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller) 
        .setActionName("DiscardInvoiceNotice"),   
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller) 
        .setActionName("SetPayedInvoice"),   
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller) 
        .setActionName("DeleteInvoice"),      
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("Send347To"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("SendInvoiceListTo"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("SendInvoiceTo"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GenerateInvoiceListPDF"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GenerateBill")


        
        
]