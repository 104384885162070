import { DeleteOutlined, FilePdfOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Select, Col, Row, Button, Tag, Table, Alert, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import CaseReports from '../../models/CaseReports';
import { CaseReportStatus } from '../../models/Constants';
import moment from 'moment';
import * as Components from '../index';

import './AdvancedSearchComponent.scss'
import ParamsApi from '../../models/api/ParamsApi';
import { processFile } from '../../utils/ProcessFile';
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import { LoadingOrText } from '..';
import { useHistory } from 'react-router-dom';
import { getAgeInfo } from '../../utils/AgeUtils';

interface AdvancedSearchComponentProps{}

const AdvancedSearchComponent = (props: AdvancedSearchComponentProps) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const history = useHistory();

    const [form] = Form.useForm();
    const [tags, setTags] = useState<string[]>([]);
    const [orderBy, setOrderby] = useState<string>('');
    const [orderByDirection, setOrderByDirection] = useState<string>('');
    const [limit, setLimit] = useState<number>(100);
    const [msgHelp, setMsgHelp] = useState<JSX.Element>(<></>);
    const [caseReports, setCaseReports] = React.useState<CaseReports[]>([]);

    const getSpecialWords = (element: CaseReports) : JSX.Element[] => {
        let specialWords : JSX.Element[] = [];

            if (element.keywords && element.keywords.length>0){
                let words : string = '';
                element.keywords.forEach((word: string, i: number)=>{
                    
                    words = words + word + (element.keywords && i < element.keywords.length-1 ? ', ' : '');
                    //specialWords.push(<Tag color="#f50">#{word}</Tag>);
                });

                specialWords.push(<Tooltip placement="bottom" title={`${words}`}>
                                        <Tag color="red" style={{cursor:"pointer", fontWeight:"700"}}>Caso Preferente <QuestionCircleOutlined /></Tag>
                                  </Tooltip>);
            };

        return specialWords;
    }
    
    const columns : any = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Caso/estudio',
            dataIndex: 'caseReportNum',
            key: 'caseReportNum',
            render: (text: string, element: CaseReports) => {
                return <><a onClick={(e)=>{
                    e.preventDefault();
                    history.push(`/case-report/${element.id}`);
                }}>{element.caseReportNum}</a><br/>
                {getSpecialWords(element)}
                </>;
            }
        },
        {
            title: 'Patólogo',
            dataIndex: 'pathologist',
            key: 'pathologist',
            render: (text: string, element: CaseReports) => renderColumn(element, <>
                <span>{element.pathologist.name} {element.pathologist.surname} {element.pathologist.secondSurname}</span>
            </>)
        },
        {
            title: 'Paciente',
            dataIndex: 'patient',
            key: 'patient.name',
            render: (text: string, element: CaseReports) => renderColumn(element, <>
                <span>{element.patient.name} {element.patient.surname} {element.patient.secondSurname}</span>
                <small style={{display:"block"}}>
                    {
                        getAgeInfo(element.reception.request.start, element.patient.birthDate)
                    }
                </small>
            </>)
        },
        {
            title: 'Tipo de muestra',
            dataIndex: 'type',
            key: 'type',
            render: (i: number, element: CaseReports) => renderColumn(element, <>{element.product.description}
                <small style={{display:"block"}}>
                    {element.product.type}
                </small>
                
                </>)
        },
        {
            title: 'Pagado',
            dataIndex: 'payed',
            key: 'payed'
        },
        {
            title: 'Fechas',
            dataIndex: 'type',
            key: 'type',
            render: (i: number, element: CaseReports) => {

                return  <ul style={{listStyleType:"none"}}>
                            <li>F. Recepcion: {element.receptionDate ? moment(element.receptionDate).format("DD/MM/YYYY") : "N/D"}</li>
                            <li>F. Procesado: {element.processingDate ? moment(element.processingDate).format("DD/MM/YYYY") : "N/D"}</li>
                            <li>F. Diagnóstico: {element.diagnosisDate ? moment(element.diagnosisDate).format("DD/MM/YYYY") : "N/D"}</li>
                        </ul>
            }
        },
        {
            title: 'Palabras clave',
            dataIndex: 'type',
            key: 'type',
            render: (i: number, element: CaseReports) => element.keywords
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (element: CaseReports) => <Button size='small' danger disabled={(element?.status !== CaseReportStatus.Finished || element.reception.status > 0 )} onClick={()=>{getPdfData([element.id]); setSelected(element)}}>
                                                    <FilePdfOutlined />
                                                    <small className="h-section-name" >PDF</small>
                                                </Button>
        }
    ];

    const [selected, setSelected] = useState<CaseReports>();
    const [loadingMassivePrint, setLoadingMassivePrint] = useState<boolean>(false);
    const [showPdf, setShowPdf] = useState<boolean>(false);
    const [pdfData, setPdfData] = useState<any>();
    const [freeWhere, setFreeWhere] = useState<string>('');

    const getPdfData = (caseReportIds: number[]) => {
        const paramsApi : ParamsApi = {
            body:caseReportIds
        }

        setLoadingMassivePrint(true);
        restApiClient.generatePdf(paramsApi, "GenerateCaseReportsPDF")
            .then((data: any)=> {
                processFile(data, setPdfData);
                setShowPdf(true);
            })
            .finally(()=>{
                setLoadingMassivePrint(false);
            });
    }

    const renderColumn = (element: CaseReports, children: any, addIcon: boolean = false) : any => {

        const finalChildren = children; 

        return {
            props: {
                className:'',
            },
            children: finalChildren,
        };
    }
    
    const onChangeField = (value: any) : void => {
        switch(value){
            case "case_report.ID":
                setMsgHelp(<>Formato válido: <strong>valor numérico</strong></>)
                break;
            case "case_report.ID_reception":
                setMsgHelp(<>Formato válido: <strong>valor numérico</strong></>)
                break;
            case "case_report.status":
                setMsgHelp(<>Formato válido: <strong>Recepcionado, Procesando, Finalizado</strong></>)
                break;
            case "case_report.case_report_num":
                setMsgHelp(<>Formato válido: <strong>Texto</strong></>)
                break;
            case "case_report.reception_date":
                setMsgHelp(<>Formato válido: <strong>Fecha en formato 'YYYY-MM-DD HH:mm:SS'</strong></>)
                break;
            case "case_report.processing_date":
                setMsgHelp(<>Formato válido: <strong>Fecha en formato 'YYYY-MM-DD HH:mm:SS'</strong></>)
                break;
            case "case_report.diagnosis_date":
                setMsgHelp(<>Formato válido: <strong>Fecha en formato 'YYYY-MM-DD HH:mm:SS'</strong></>)
                break;
            case "users.name":
                setMsgHelp(<>Formato válido: <strong>Texto</strong></>)
                break;
            case "case_report.payed":
                setMsgHelp(<>Formato válido: <strong>Si, No</strong></>)
                break;
            case "case_report.apply_invoice_number":
                setMsgHelp(<>Formato válido: <strong>Texto</strong></>)
                break;
            case "case_report_meta.value_diagnostico":
                setMsgHelp(<>Formato válido: <strong>Texto</strong></>)
                break;
            case "case_report_meta.value_recepcion":
                setMsgHelp(<>Formato válido: <strong>Texto</strong></>)
                break;
            case "patients.complete_name":
                setMsgHelp(<>Formato válido: <strong>Texto</strong></>)
                break;
            case "receptions.status":
                setMsgHelp(<>Formato válido: <strong>Autorizado, Pendiente autorización, Caso con incidencia, Rechazado por aseguradora</strong></>)
                break;
            default:
                setMsgHelp(<></>)
                break;
        }
    }

    const addSymbol = (symbol: string) : void => {
        let finalTags = tags;
        finalTags.push(symbol);

        setTags(finalTags);

        form.resetFields();
        setMsgHelp(<></>);
        setFreeWhere('');
    }

    const addCriteria = () : void => {

        const field : string = form.getFieldValue("field");
        const useQuotes : boolean = (!['case_report.ID', 'case_report.ID_reception'].includes(field));
        const criteria : string = form.getFieldValue("criteria");

        let value : string = form.getFieldValue("value");

        if (criteria == "like"){
            value = `%${value.replaceAll(" ", "%")}%`
        }

        const sql = `${field.trim()} ${criteria.trim()} ${useQuotes ? "'" : ''}${value.trim()}${useQuotes ? "'" : ''} ${form.getFieldValue("mode") ?? ''}`;

        let finalTags = tags;
        finalTags.push(sql);

        setTags(finalTags);

        form.resetFields();
        setMsgHelp(<></>);
    }

    const onClear = () : void => {
        form.resetFields();
        setMsgHelp(<></>);
        setTags([]);
        setCaseReports([]);
        setOrderby('');
        setOrderByDirection('');
        setLimit(100);
    }

    const [searching, setSearching] = useState<boolean>(false);
    const onSearch = (values: any) : void => {
        console.log("Final Info", tags, orderBy, orderByDirection, limit);

        let finalSQL = "";

        tags.forEach((tag: string)=>{
            finalSQL = `${finalSQL.trim()} ${tag}`
        });

        if (finalSQL.length == 0){
            finalSQL = "1=1"
        }

        if (orderBy && orderBy.length>0 && orderByDirection && orderByDirection.length > 0){
            finalSQL = `${finalSQL.trim()} ORDER BY ${orderBy} ${orderByDirection}`
        }

        if (limit){
            finalSQL = `${finalSQL.trim()} LIMIT ${limit}`
        }

        const paramsApi : ParamsApi = {
            query:{
                "sql": finalSQL.trim()
            }
        }

        setSearching(true);
        restApiClient.fetch("GetCaseReportsForAdvancedSearch", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setCaseReports(r.data);
                }
            }).finally(()=>{
                setSearching(false);
            })
    }

    const removeTag = (index: number) : void => {
        let finalTags = tags;
        console.log("Removing", index);
        finalTags.splice(index, 1);
        setTags(finalTags);

        form.resetFields();
        setMsgHelp(<></>);
    }

    return (
        <div className="advanced-search-component">
            <Components.ModalPDF isMassivePdf={false} selected={selected} onClose={()=>{setShowPdf(false); setPdfData(null); }} visible={showPdf} pdfData={pdfData}/> 

            <div className="form">
                <Divider orientation="left">Criterio de búsqueda</Divider>
                <Form form={form} className="frm-login" layout="vertical" onFinish={addCriteria}>
                    <Row gutter={16}>
                        <Col xs={12} sm={5}>
                            <Form.Item name="field" label="Campos de búsqueda" rules={[{ required: true, message: "Campo obligatorio"}]}>
                                <Select onChange={onChangeField}>
                                    <Select.Option value="case_report.ID">ID caso estudio</Select.Option>
                                    <Select.Option value="case_report.ID_reception">ID Recepción</Select.Option>
                                    <Select.Option value="case_report.status">Estado</Select.Option>
                                    <Select.Option value="case_report.case_report_num">Número de caso de estudio</Select.Option>
                                    <Select.Option value="case_report.reception_date">Fecha Recepción</Select.Option>
                                    <Select.Option value="case_report.processing_date">Fecha Procesando</Select.Option>
                                    <Select.Option value="case_report.diagnosis_date">Fecha Diagnosticado</Select.Option>
                                    <Select.Option value="case_report.payed">Estado pago</Select.Option>
                                    <Select.Option value="case_report.apply_invoice_number">Número de factura aplicada</Select.Option>
                                    <Select.Option value="case_report_meta.value">Texto en meta</Select.Option>
                                    <Select.Option value="patients.complete_name">Nombre paciente</Select.Option>
                                    <Select.Option value="receptions.status">Estado recepción</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={5}>
                            <Form.Item name="criteria" label="Criterio" rules={[{ required: true, message: "Campo obligatorio"}]}>
                                <Select>
                                    <Select.Option value=">">Mayor</Select.Option>
                                    <Select.Option value=">=">Mayor igual</Select.Option>
                                    <Select.Option value="<">Menor</Select.Option>
                                    <Select.Option value="<=">Menor igual</Select.Option>
                                    <Select.Option value="=">Igual</Select.Option>
                                    <Select.Option value="!=">Distinto</Select.Option>
                                    <Select.Option value="like">Cotiene</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={5}>
                            <Form.Item help={msgHelp} name="value" label="Valor" rules={[{ required: true, message: "Campo obligatorio"}]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={5}>
                            <Form.Item name="mode" label="Modo" >
                                <Select>
                                    <Select.Option value="AND">Modo AND</Select.Option>
                                    <Select.Option value="OR">Modo OR</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Form.Item name="btn-search" label={<>&nbsp;</>}>
                                <Button type="primary" className="btn-secondary btn-add-criteria" htmlType='submit'>Añadir criterio</Button>
                            </Form.Item>
                        </Col>
                        
                        <Col xs={12} sm={5}>
                            <Form.Item name="orderBy" label="Ordenado por" initialValue={""}>
                            <Select onChange={(e: any)=>{
                                    setOrderby(e);
                                }}>
                                    <Select.Option value="">Ninguno</Select.Option>
                                    <Select.Option value="case_report.ID">ID caso estudio</Select.Option>
                                    <Select.Option value="case_report.ID_reception">ID Recepción</Select.Option>
                                    <Select.Option value="case_report.status">Estado</Select.Option>
                                    <Select.Option value="case_report.case_report_num">Número de caso de estudio</Select.Option>
                                    <Select.Option value="case_report.reception_date">Fecha Recepción</Select.Option>
                                    <Select.Option value="case_report.processing_date">Fecha Procesando</Select.Option>
                                    <Select.Option value="case_report.diagnosis_date">Fecha Diagnosticado</Select.Option>
                                    <Select.Option value="case_report.payed">Estado pago</Select.Option>
                                    <Select.Option value="case_report.apply_invoice_number">Número de factura aplicada</Select.Option>
                                    <Select.Option value="patients.complete_name">Nombre paciente</Select.Option>
                                    <Select.Option value="receptions.status">Estado recepción</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={5}>
                            <Form.Item name="orderDirection" label="Dirección" initialValue={""}>
                                <Select onChange={(e: any)=>{
                                    setOrderByDirection(e);
                                }}>
                                    <Select.Option value="">Ninguno</Select.Option>
                                    <Select.Option value="ASC">Ascendente</Select.Option>
                                    <Select.Option value="DESC">Descendente</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={5}>
                            <Form.Item name="limit" label="Límite" initialValue={100}>
                                <Input type="number" min={6} max={600} onChange={(e:any)=>{setLimit(e.target.value)}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={5}>
                            <Form.Item name="btn-search" label={<>&nbsp;</>}>
                                <Button disabled={searching} type="primary" className="btn-secondary btn-add-criteria" onClick={onSearch}>
                                    <LoadingOrText loading={searching} text={"Buscar"} loadingText={"Buscando"}/>
                                </Button>
                                
                            </Form.Item>
                        </Col><Col xs={12} sm={4}>
                            <Form.Item name="btn-search" label={<>&nbsp;</>}>
                                <Button type="primary" className="btn-secondary btn-add-criteria" onClick={onClear}>Limpiar</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Divider orientation="left">Símbolos y texto libre</Divider>

                        <Col span={24} className="free-where">
                            <Button size='small' type="primary" className="btn-secondary symbol" onClick={()=>{addSymbol('(')}}>(</Button>
                            <Button size='small' type="primary" className="btn-secondary symbol" onClick={()=>{addSymbol(')')}}>)</Button>
                            <Button size='small' type="primary" className="btn-secondary symbol" onClick={()=>{addSymbol('AND')}}>AND</Button>
                            <Button size='small' type="primary" className="btn-secondary symbol" onClick={()=>{addSymbol('OR')}}>OR</Button>
                            <Input placeholder='Introduce cláusula where a mano' size="small" className="free-where-input" onChange={(e:any)=>{setFreeWhere(e.target.value)}}/> 
                            <Button type="primary" className="btn-secondary btn-free-where" size="small" onClick={()=>{addSymbol(freeWhere)}}>Aplicar</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="sql">
                <Divider orientation="left">SQL que se lanzará:</Divider>
                {
                    tags && tags.length > 0 ? tags.map((tag: string, index: number)=> <Tag key={index} className="sql-tag">{tag} <DeleteOutlined onClick={()=>{removeTag(index)}}/></Tag>) : <Alert message="Añada criterios para realizar búsqueda" type="info" showIcon />
                }
            </div>
            <div className="result">
                <Divider orientation="left">Datos obtenidos:</Divider>

                <Table rowKey="id" 
                    size="small"
                    dataSource={caseReports} 
                    columns={columns} 
                />   
            </div>
        </div>
    )
}


export default AdvancedSearchComponent;