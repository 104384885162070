import { FilePdfOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';
import { Invoice } from '../../../models/Invoice';
import { processFile } from '../../../utils/ProcessFile';
import * as Components from '../../index';


import './InvoiceNotificatorComponent.scss'


interface Props{}

const InvoiceNotificatorComponent = (props: Props) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const [invoiceNumbers, setInvoiceNumbers] = useState<string[]>([]);
    const history = useHistory();

    const [selected, setSelected] = useState<string>("");
    const [pdfData, setPdfData] = useState<any>(null);
    const [showPdf, setShowPdf] = useState<boolean>(false);
    
    const getInvoiceNotifications = () : void => {
        
        restApiClient.fetch("GetInvoiceNotifications", {}) 
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setInvoiceNumbers(r.data as string[]);
                }
            }).finally(()=>{
            });
    }

    const discardNotice = (invoiceNumber: string) : void => {

        const paramsApi : ParamsApi = {
            body:invoiceNumber
        }

        restApiClient.fetch("DiscardInvoiceNotice", paramsApi) 
            .then((r : ApiResponse | null)=> {
            }).finally(()=>{
            });

    }

    

    const view = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                invoiceNumber : selected
            }
        }

        restApiClient.generatePdf(paramsApi, "GetInvoicePDF")
            .then((data)=> {
                processFile(data, setPdfData);
                setShowPdf(true);
                setInvoiceNumbers(invoiceNumbers.filter(x=>x!==selected));
            });
    }

    const goInvoiceList = () : void => {
        history.push("/invoice-list")

    }

    useEffect(()=>{
        if(selected.length>0){
            view();
        }
    },[selected])

    useEffect(()=>{
        getInvoiceNotifications();
    },[])

    return(
        <div className="invoice-notificator">
            <Components.ModalInvoicePDF selected={selected} onClose={()=>{setSelected(""); setShowPdf(false); setPdfData(null);}} visible={showPdf} pdfData={pdfData}/> 

            {
                invoiceNumbers.map((invoiceNumber : string, i: number)=>{
                    return(
                        <Alert onClose={()=>{
                            discardNotice(invoiceNumber);
                        }} closable key={i} message={<span>Factura <strong>{invoiceNumber}</strong> pendiente de revisión. Visualizar <FilePdfOutlined onClick={()=>{setSelected(invoiceNumber)}}/> o ir al Listado <UnorderedListOutlined onClick={goInvoiceList}/></span>} type="warning" showIcon />
                    )
                })
            }
        </div>
    )
}

export default InvoiceNotificatorComponent;