import { AppstoreAddOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, Input, InputNumber } from 'antd';
import React, { useState } from 'react'
import { TablePagination } from '..'
import ParamsApi from '../../models/api/ParamsApi';
import ApiResponse from '../../models/api/ApiResponse';
import RestApiClient from '../../api/restApiClient';
import LoadingOrComponent from '../core/utils/LoadingOrComponent';


import './Model347Component.scss'
import moment from 'moment';


interface Props {}

const Model347Component = (props: Props) : JSX.Element => {
    const [form] = Form.useForm();
    const restApiClient : RestApiClient = new RestApiClient();

    const [counter, setCounter] = useState<number>(0);
    const [ showAddRecord, setShowAddRecord] = useState<boolean>(false);
    const [savingData, setSavingData] = useState<boolean>(false);
    
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Año',
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: 'Mínimo facturado',
            dataIndex: 'value',
            key: 'value'
        },
        {
            title:"Acciones",
            key: 'action',
            render: (text: any, record: any) => (
                <span>
                    <span className="ant-divider" />
                    <a href="#" onClick={()=>{
                        deleteRecord(record.id)
                    }}>Eliminar</a>
                </span>
            ),
        },
    ];

    const deleteRecord = (id: number) => {
       
        const paramsApi : ParamsApi = {
            query:{
                id: id
            }
        }

        restApiClient.fetch("DeleteOption", paramsApi)
            .then((r: ApiResponse | null) => {
                if (r && r.code === 200) {
                    setCounter(counter + 1);
                }
            })
    }

    const onFinish = () => {
        var formData = form.getFieldsValue();

        const paramsApi : ParamsApi = {
            body: {
                key: moment(formData["year"]).year().toString(),
                value: formData["minAmount"].toString(),
                type: "Model347"
            }
        }


        setSavingData(true);
        restApiClient.fetch("CreateOption", paramsApi).then((r : ApiResponse | null)=> {
            if (r !== null && r.code === 200){
                setCounter(counter + 1);
                setShowAddRecord(false);
                form.resetFields();
            }
        }).finally(()=>{
            setSavingData(false);
        })

    }

    const disabledDate = (current : any) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
      }

    return (
        <div className='model-347-component'>

            <Drawer title="Añadir registro 347" placement="right" onClose={(()=>{setShowAddRecord(false)})} visible={showAddRecord}>
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item name="year" label="Año de aplicación" rules={[{required: true, message: 'Por favor, introduce el dato...'}]}>
                        <DatePicker picker="year" style={{width:"100%"}} disabledDate={disabledDate}/>
                    </Form.Item>
                    <Form.Item name="minAmount" label="Cantidad mínima" rules={[{required: true, message: 'Por favor, introduce el dato...'}]}>                    
                        <InputNumber min={0} max={999999999} style={{width:"100%"}} />
                    </Form.Item>
                    <Button htmlType='submit' size="small" type='primary' className="btn-secondary" style={{float:"right"}}>
                        <LoadingOrComponent loading={savingData} text={`Guardar registro`} loadingText={`Guardando...`}/>
                    </Button>
                </Form>
            </Drawer>


            <TablePagination 
                    reloadCounter={counter}
                    aliasEndPoint="GetOptions"
                    showFilters={false}
                    entityName="option"
                    defaultFilter='Model347'
                    columns={columns}
                    title="Listado de registros modelo 347"
                    actionButtons={[{type: "ghost", className:"add-new-keyword", label:"Añadir nuevo registro", action: ()=>{setShowAddRecord(true)}, icon: <AppstoreAddOutlined />}]}
                />
        </div>
    )
}


export default Model347Component;