import React from 'react';
import User from '../../../models/Users';

import { useHistory } from "react-router-dom";

import './UserListComponent.scss'
import { Button, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { PlusOutlined } from '@ant-design/icons';
import { DOCTOR_ROLE } from '../../../utils/Constants';

import * as Components from '../../index';


interface IProps{

}

const UserListComponent = (props: IProps) : JSX.Element => {
    const history = useHistory();


    const columnsTable = [
        {
            title: "#",
            dataIndex: 'id',
            key: 'id',
            render: (i:number, element: User)=>{
                return <>{element.id} <div className={element.hasActiveSession ? 'connected' : 'no-connected'}></div></>
            }
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            render: (i: number, element : User) => {
                return <Button type="dashed" onClick={()=>{history.push(`/user/edit/${element.id}`)}}>{element.name} {element.surname} {element.secondSurname}</Button>
            }
        },
        {
            title: "Rol",
            dataIndex: "role",
            key: "role",
            render: (i: number, element : User) => {
                var result : JSX.Element = <></>;

                switch(element.idRole){
                    case 1: // Administrador
                        result = <div className={`role-tag role-${element.idRole}`}><FontAwesomeIcon className="icon" icon={Icons.faUserShield} />Admin.</div>
                        break;
                    case 2: // Médico
                        result = <div className={`role-tag role-${element.idRole}`}><FontAwesomeIcon className="icon" icon={Icons.faUserMd} />{element.rolName}</div>
                        break;
                    case 3: // Auxiliar
                        result = <div className={`role-tag role-${element.idRole}`}><FontAwesomeIcon className="icon" icon={Icons.faUserNurse} />{element.rolName}</div>
                        break;
                    case 4: // Supervisor
                        result = <div className={`role-tag role-${element.idRole}`}><FontAwesomeIcon className="icon" icon={Icons.faUserSecret} />{element.rolName}</div>
                        break;
                    case 5: // Customizado
                        result = <div className={`role-tag role-${element.idRole}`}><FontAwesomeIcon className="icon" icon={Icons.faCog} />{element.rolName}</div>
                        break;
                    case 7: // Patólogo 
                        result = <div className={`role-tag role-${element.idRole}`}><FontAwesomeIcon className="icon" icon={Icons.faNotesMedical} />{element.rolName}</div>
                        break;
                    default:
                        break;
                }

                return result;
            }
        },
        {
            title: "Teléfono",
            dataIndex: "phone",
            key: "phone",
            render: (i: number, element : User) => {
                return element.phone1;
            }
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (i: number, element : User) => {
                return element.email;
            }
        },
        {
            title: "Acepta GDPR",
            dataIndex: "gdpr",
            key: "gdpr",
            render: (i: number, element : User) => { 
                return element.acceptGDPR ? <div className="tag ok">Si</div> : <div className="tag koko">No</div>
            }
        },
        {
            title: "Baneado",
            dataIndex: "banned",
            key: "banned",
            render: (i: number, element : User) => {
                return element.banned ? <div className="tag ko">Si</div> : <div className="tag ok">No</div>
            }
        }
    ];

    const getResume = (user: User) : JSX.Element => {
        var result : JSX.Element = <></>;
        
        result = <div className="user-resume">
                    <table>
                        <tbody>
                            <tr className="resume-title">
                                <td>Teléfono secundario:</td>
                                <td>{user.phone2}</td>
                            </tr>
                            
                            {
                                user.idRole == DOCTOR_ROLE ? 
                                <>
                                    <tr className="resume-title">
                                        <td>Persona de contacto:</td>
                                        <td>{user.referencePersonName}</td>  
                                    </tr>
                                    <tr className="resume-title">
                                        <td>IBAN:</td>
                                        <td>{user.iban}</td>
                                    </tr>
                                    <tr className="resume-title">
                                        <td>Razón social:</td>
                                        <td>{user.bussinesName}</td>
                                    </tr>
                                    <tr className="resume-title">
                                        <td>Descuento aplicado:</td>
                                        <td>{user.applyReducedPrice ? 'Si' : 'No'}</td>
                                    </tr>
                                </>
                                : 
                                <></>
                            }
                            <tr className="resume-title">
                                <td colSpan={2}>Observaciones:</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{user.observations}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

        return result;
    }


    const goAddUser = () : void => {
        history.push("/add-user");
    }


    return (
        <Row gutter={16}className="user-list">
            <Components.TablePagination 
                    aliasEndPoint="GetUsers"
                    entityName="user"
                    columns={columnsTable}
                    title="Listado de usuarios"
                    filterButtons={["administrador", "patólogo", "médico"]}
                    expandableInfo={{
                        expandedRowRender: (record: any) => getResume(record),
                        rowExpandable: (record: any) => record.name !== 'Not Expandable',
                    }}
                    actionButtons={[
                                    { icon: <PlusOutlined />, label: "Añadir", action: goAddUser }, 
                                ]}
                />
        </Row>
    )
}

export default UserListComponent;