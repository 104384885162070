import { AppstoreOutlined, CheckCircleOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Drawer, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Company from '../../../models/Company';
import Product from '../../../models/Product';
import { getTemplateName } from '../../../utils/TemplateNames';
import * as Components from '../../index';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';
import { useHistory } from "react-router-dom";

import './ProductListComponent.scss';

interface ProductListComponent{
    company: Company,
    list?: Product[]
}

const ProductListComponent = (props: ProductListComponent) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();
    const history = useHistory();

    const { company } = props;

    const columnsTable = [
        {
            title: "#",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: "Nombre",
            dataIndex: "description",
            key: "description",
            render: (i: number, element : Product) => {
                return <Button type="dashed" onClick={()=>{setEditingProduct(element);}}>{element.isActive ? <CheckCircleOutlined /> : <StopOutlined />} {element.description}</Button>
            }
        },
        {
            title: "Precio",
            dataIndex: "price",
            key: "price",
            render: (i: number, element: Product) => {
                return <>{element.price}€ <small className="reduced-price">{element.reducedPrice}€ (P.V.P.R.)</small></>
            }
        },
        {
            title: "Tipología",
            dataIndex: "type",
            key: "type",
            render: (i: number, element: Product) => {
                return <>{getTemplateName(element.type)}</>
            }
        },
        {
            title: '',
            key: 'actions',
            render: (i: number, element: Product) => {
                return <>
                            <AppstoreOutlined className={`custom-table-actions ${element.id === editingProduct?.id ? 'record-selected' : 'record-unselected'}`} onClick={()=>{setEditingProduct(element);}}/>
                       </> 
    
            }
        }
    ];

    const [ list, setList ] = useState<Product[]>([]);

    const [showAddProduct, setShowAddProduct] = useState<boolean>(false);
    const [showEditProduct, setShowEditProduct] = useState<boolean>(false);
    const [editingProduct, setEditingProduct] = useState<Product>();
    const [reloadCounter, setReloadCounter] = useState<number>(0);

    

    const goBack = () : void => {
        history.goBack();
    }

    const goAddNewProduct = () : void => {
        setShowAddProduct(true);
    }

    useEffect(()=>{
        if (props.list){
            setList(props.list);
        }
    },[props.list]);

    useEffect(()=>{
        if (editingProduct){
            setShowEditProduct(true);
        }else{
            setShowEditProduct(false);
        }
    },[editingProduct])

    return (
        <div className="product-list">
            <Drawer width={"600px"} className={`drawer-responsive ${showAddProduct ? 'visible': ''}`} title="Añadir producto" visible={showAddProduct} onClose={()=>{setShowAddProduct(false);}}>
                <Components.ProductAdd title={`Compañía: ${company.businessName}`} idCompany={company.id} onFinish={()=>{setEditingProduct(undefined); setReloadCounter(reloadCounter +1);}}/>
            </Drawer>
            <Drawer 
                width={"600px"} 
                className={`drawer-responsive ${showEditProduct ? 'visible': ''}`}  
                title="Editar producto" 
                visible={showEditProduct} 
                onClose={()=>{ setEditingProduct(undefined); }}>
                <Components.ProductEdit 
                    title={`Compañía: ${company.businessName}`} 
                    idCompany={company.id} 
                    onFinish={()=>{ setEditingProduct(undefined); setReloadCounter(reloadCounter +1); }} 
                    product={editingProduct}/>
            </Drawer>
            
            <Components.TablePagination 
                        title="Listado de productos"
                        entityName="products"
                        aliasEndPoint="GetCompanyProducts"
                        reloadCounter={reloadCounter}
                        paramsEndPoint={{
                            companyId: company.id
                        }}
                        columns={columnsTable}
                        filterButtons={[ "microbiologia", "biopsia","citologia","ihq-molecular"]}
                        actionButtons={
                                        [
                                            { 
                                                label:"Volver al listado",
                                                action:goBack,
                                                type: "default",
                                                className: "btn-save btn-antd"
                                            },
                                            { 
                                                label:"Añadir producto",
                                                action:goAddNewProduct,
                                                icon:<PlusOutlined />
                                            }
                                        ]
                                    }
                    />
        </div>
    )
}

export default ProductListComponent;