import { AppstoreAddOutlined, ClearOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Input, Row } from 'antd';
import React, { useState } from 'react'
import RestApiClient from '../../api/restApiClient';
import ApiResponse from '../../models/api/ApiResponse';
import ParamsApi from '../../models/api/ParamsApi';

import TablePagination from '../core/utils/TablePaginationComponent'
import './KeywordsComponent.scss'

interface Props {}
const KeywordsComponent = (props: Props) : JSX.Element => {

    const [savingData, setSavingData] = useState<boolean>(false);
    const [showAddKeyword, setShowAddKeyword] = useState<boolean>(false);
    const [newKeyword, setNewKeyword] = useState<string>('');
    const [counter, setCounter] = useState<number>(0);

    const restApiClient : RestApiClient = new RestApiClient();

    const addKeyword = () => {
        const paramsApi : ParamsApi = {
            query:{
                keyword: newKeyword
            }
        }

        setSavingData(true);
        restApiClient.fetch("AddKeyword", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setCounter(counter + 1);
                }
            })
            .finally(()=>{
                setSavingData(false);
            });
    }

    const deleteKeyword = (id: number) => {


        const paramsApi : ParamsApi = {
            query:{
                keywordId: id
            }
        }

        setSavingData(true);
        restApiClient.fetch("DeleteKeyword", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setCounter(counter + 1);
                }
            })
            .finally(()=>{
                setSavingData(false);
            });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Descripción',
            dataIndex: 'keyword',
            key: 'keyword'
        },
        {
            title: 'Acciones',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Button onClick={()=>{deleteKeyword(record.id)}}>
                       <ClearOutlined /> Eliminar
                    </Button>
                );
            }
        }
    ];

    return (
        <div className='keyword-component'>
            <Drawer title="Añadir nueva palabra clave" placement="right" onClose={()=>{setNewKeyword(''); setShowAddKeyword(false)}} visible={showAddKeyword}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Input placeholder="Nueva palabra clave" value={newKeyword} onChange={(e)=>{setNewKeyword(e.target.value)}}/>
                    </Col>
                    <Col span={24} className="btn-add-keyword-container">
                        <Button type="primary" className="btn-secondary" onClick={()=>{addKeyword()}} loading={savingData}>
                            <AppstoreAddOutlined /> Añadir
                        </Button>
                    </Col>
                </Row>
            </Drawer>

           <TablePagination 
                    reloadCounter={counter}
                    aliasEndPoint="GetKeywords"
                    showFilters={false}
                    entityName="invoice"
                    columns={columns}
                    title="Listado de palabras clave"
                    actionButtons={[{type: "ghost", className:"add-new-keyword", label:"Añadir nueva", action: ()=>{setShowAddKeyword(true)}, icon: <AppstoreAddOutlined />}]}
                />
        </div>
    )
}


export default KeywordsComponent;

