import React, { useEffect } from 'react'

import { Tooltip } from 'antd'
import { 
         ToolOutlined,
         LoginOutlined,
} from '@ant-design/icons'


interface preformattedHelperProps{
    properties: any
} 

const preformattedHelperComponent = (props: preformattedHelperProps) : JSX.Element => {

    const {
        hideMenu,
        edition,
    } = props.properties;
    
    return (
        <>
            <ul className="helper-balls dashboard-balls">
                <Tooltip placement="left" title="Modificar preformateado">
                    <li className="h-generic" onClick={edition}>
                        <ToolOutlined />
                        <small className="h-section-name">Editar</small>
                    </li>
                </Tooltip>
                <Tooltip placement="left" title="Ocultar menú">
                    <li className="h-logout" onClick={hideMenu}>
                        <LoginOutlined />
                        <small className="h-section-name" >Ocultar</small>
                    </li>
                </Tooltip>
            </ul>
        </>
    )
}

export default preformattedHelperComponent;