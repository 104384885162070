import { InfoCircleOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"
import moment from "moment"
import React from "react"

export const getAgeInfo = (start: any, birthDate : any) : JSX.Element => {
    return (birthDate ? 
        <>
            Edad: {birthDate ? (moment(start).from(moment(birthDate), true)) : 'N/D'}
            <Tooltip title={`Actualmente el/la paciente tiene ${moment(birthDate).fromNow(true)}`}><InfoCircleOutlined style={{marginLeft:"5px"}} /></Tooltip>
        </> 
        : 
        <>Edad: N/D</>)
}