import TemplateComponent from './template';

// Reception
import BiopsiaReceptionComponent from './reception/biopsia'
import CitologiaReceptionComponent from './reception/citologia';
import IHQMolecularReceptionComponent from './reception/ihq_molecular';
import MicrobiologiaReceptionComponent from './reception/microbiologia';

// Diagnosis:
import BiopsiaDiagnosisComponent from './diagnosis/biopsia'
import CitologiaDiagnosisComponent from './diagnosis/citologia'
import MicrobiologiaDiagnosisComponent from './diagnosis/microbiologia'
import IHQMolecularDiagnosisComponent from './diagnosis/ihq_molecular'

// Preformateados:
import PreformattedListComponent from './preformatteds/PreformattedListComponent'
import PreformattedManagementComponent from './preformatteds/management/PreformattedManagement'





export const Main = TemplateComponent;

// Reception
export const BiopsiaReception = BiopsiaReceptionComponent;
export const CitologiaReception = CitologiaReceptionComponent;
export const IHQMolecularReception = IHQMolecularReceptionComponent;
export const MicrobiologiaReception = MicrobiologiaReceptionComponent;

// Diagnosis
export const BiopsiaDiagnosis = BiopsiaDiagnosisComponent;
export const CitologiaDiagnosis = CitologiaDiagnosisComponent;
export const MicrobiologiaDiagnosis = MicrobiologiaDiagnosisComponent;
export const IHQMolecularDiagnosis = IHQMolecularDiagnosisComponent;

//Preformateados
export const PreformattedList = PreformattedListComponent;
export const PreformattedManagement = PreformattedManagementComponent;

