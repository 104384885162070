import React from 'react'
import * as Templates from '../../../components/templates';

import './preformattedAdd.scss'

const PreformattedAddPage = () : JSX.Element => {
    return (
        <div className='preformatted-add-page'>
            <Templates.PreformattedManagement updateMode={false} />
        </div>
    )
}

export default PreformattedAddPage;