import ContactData from './ContactData';

export default class User extends ContactData{
    public id?: number;
    public name: string = '';
    public surname: string = '';
    public secondSurname: string = '';
    public dniNie: string = '';
    public idRole: number = -1;
    public rolName: string = '';
    public specialty: string = '';
    public isLoged: boolean = false;
    public collegiateNumber?: string = '';
    public sessionId?: string = ''
    public acceptGDPR: boolean = false;
    public acceptGdprDate: Date = new Date();
    public banned: boolean = false;
    public passwordCaducity: any;
    public country: string = '';
    public iban : string = '';
    public bussinesName : string = '';
    public applyReducedPrice : boolean = false;
    public observations : string = '';
    public cif : string = '';
    public hasActiveSession: boolean = false;

    public UpdateUserRGPDState = (acceptGDPR: boolean) => {
        this.acceptGDPR = acceptGDPR;
        this.acceptGdprDate = new Date();
    }

    public UpdateUserContext = (userData : User) => {
        this.id = userData.id;
        this.name = userData.name;
        this.surname = userData.surname;
        this.secondSurname = userData.secondSurname;
        this.dniNie = userData.dniNie;
        this.rolName = userData.rolName;
        this.idRole = userData.idRole;
        this.specialty = userData.specialty;
        this.isLoged = userData.isLoged;
        this.sessionId = userData.sessionId;
        this.collegiateNumber = userData.collegiateNumber;

        this.address = userData.address;
        this.phone1 = userData.phone1;
        this.phone2 = userData.phone2;
        this.referencePersonName = userData.referencePersonName;
        this.email = userData.email;

        this.acceptGDPR = userData.acceptGDPR;
        this.acceptGdprDate = userData.acceptGdprDate;
        this.banned = userData.banned;
        this.passwordCaducity = userData.passwordCaducity;

        this.iban = userData.iban;
        this.bussinesName = userData.bussinesName;
        this.applyReducedPrice = userData.applyReducedPrice;
        this.observations = userData.observations;
        this.cif = userData.cif;
    }
}
