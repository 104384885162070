import { UsersEP } from './UsersEP'
import { RequestsEP } from './RequestsEP'
import { CompaniesEP } from './CompaniesEP'
import { ProductsEP } from './ProductsEP'
import { PatientsEP } from './PatientsEP'
import { ReceptionsEP } from './ReceptionsEP'
import { CaseReportsEP } from './CaseReportsEP'
import { SearchEP } from './SearchEP'
import { PreformattedEP } from './PreformattedEP'
import { PDFEP } from './PDFEP'
import { InvoicesEP } from './InvoicesEP'
import { KeywordsEP } from './KeywordsEP'
import { StatisticsEP } from './StatisticsEP'
import { OptionsEP } from './OptionsEP'

export const RegisterEndPoints = []
    .concat(PDFEP)
    .concat(InvoicesEP)
    .concat(SearchEP)
    .concat(PatientsEP)
    .concat(ProductsEP)
    .concat(CompaniesEP)
    .concat(RequestsEP)
    .concat(ReceptionsEP)
    .concat(CaseReportsEP)
    .concat(PreformattedEP)
    .concat(KeywordsEP)
    .concat(UsersEP)
    .concat(OptionsEP)
    .concat(StatisticsEP);
