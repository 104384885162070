import React from 'react'

import * as Pages from '../../../pages/index'

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

export const PrivateRoutesComponent = (props : any) => {
    return(
        <Router forceRefresh={true}>
            
            <Switch>
                <Route exact path={["", "/dashboard"]} >
                    <Pages.Container component={<Pages.DashBoard />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["", "/dashboard/:paramsFilter"]} >
                    <Pages.Container component={<Pages.DashBoard />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/case-report/:id"]} >
                    <Pages.Container component={<Pages.CaseReportEdit />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/register"]} >
                    <Pages.Container component={<Pages.Register />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/requests-list"]} >
                    <Pages.Container component={<Pages.RequestList />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/requests"]} >
                    <Pages.Container component={<Pages.Requests />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/companies"]} >
                    <Pages.Container component={<Pages.Companies />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/add-company"]} >
                    <Pages.Container component={<Pages.AddCompany />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/add-user"]} >
                    <Pages.Container component={<Pages.AddUser />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/user/edit/:id"]} >
                    <Pages.Container component={<Pages.EditUser />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/company/edit/:id"]} >
                    <Pages.Container component={<Pages.EditCompany />} />
                </Route>
            </Switch>
            <Switch>
                <Route path={["/users"]} >
                    <Pages.Container component={<Pages.Users />} />
                </Route>
            </Switch>
            <Switch>
                <Route path={["/statistics"]} >
                    <Pages.Container component={<Pages.Statistics />} />
                </Route>
            </Switch>
            <Switch>
                <Route path={["/advanced-statistics"]} >
                    <Pages.Container component={<Pages.AdvancedStatistics />} />
                </Route>
            </Switch>
            <Switch>
                <Route path={["/patients"]} >
                    <Pages.Container component={<Pages.Patients />} />
                </Route>
            </Switch>
            <Switch>
                <Route path={["/invoice-generator"]} >
                    <Pages.Container component={<Pages.InvoicesGenerator />} />
                </Route>
            </Switch>
            <Switch>
                <Route path={["/invoice-list"]} >
                    <Pages.Container component={<Pages.Invoices />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/patient/edit/:id"]} >
                    <Pages.Container component={<Pages.EditPatient />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/add-patient"]} >
                    <Pages.Container component={<Pages.AddPatient />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/preformatted"]} >
                    <Pages.Container component={<Pages.Preformatted />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/keywords"]} >
                    <Pages.Container component={<Pages.Keywords />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/migration-patients"]} >
                    <Pages.Container component={<Pages.MigrationPatients />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/preformatted/edit/:id"]} >
                    <Pages.Container component={<Pages.PreformattedEdit />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/add-preformatted"]} >
                    <Pages.Container component={<Pages.PreformattedAdd />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/archived"]} >
                    <Pages.Container component={<Pages.Archived />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/configurations"]} >
                    <Pages.Container component={<Pages.Configurations />} />
                </Route>
            </Switch>
            <Switch>
                <Route exact path={["/advanced-search"]} >
                    <Pages.Container component={<Pages.AdvancedSearch />} />
                </Route>
            </Switch>
            <Switch>
                <Route path={["/error"]} >
                    <Pages.Container component={<Pages.Error500 />} />
                </Route>
            </Switch>
            <Switch>
                <Route>
                    <Pages.Container component={<Pages.Error404 />} />
                </Route>
            </Switch>
        </Router>
    )
}