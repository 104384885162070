import React from 'react'

import { Col, Divider, Form, Input, Row, Select } from 'antd';

const { Option } = Select;

const ContactData = () : JSX.Element => {

    return(
        <Row gutter={16}>
          <Col xs={24}>
            <Divider orientation="left">Rol de usuario</Divider>
          </Col>
          <Col xs={24}>
            <Form.Item name="idRole" help="Indica el tipo de cuenta del usuario" label="Rol del usuario" rules={[{ required: true, message: "Campo obligatorio"}]}>
              <Select style={{ width: "100%" }}>
                <Option key="doctor" value={1}>Administrador</Option>
                <Option key="doctor" value={2}>Médico</Option>
                <Option key="doctor" value={7}>Patólogo</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Divider orientation="left">Datos de contacto</Divider>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Form.Item name="name" help="Nombre de la persona que utilizará la cuenta." label="Nombre" rules={[{ required: true, message: "Campo oblitatorio"}]}>
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Form.Item name="surname" help="Primer apellido" label="Apellidos" rules={[{ required: true, message: "Campo oblitatorio"}]}>
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}  sm={24} md={12} lg={8}>
            <Form.Item name="secondSurname" help="Segundo apellido" label={<>&nbsp;</>}>
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}  sm={24} md={12} lg={8}>
            <Form.Item name="email" help="Dirección de correo electrónico." label="Email" rules={[{ required: true, message: "Campo oblitatorio"}]}>
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}  sm={24} md={12} lg={8}>
            <Form.Item name="phone1" help="Teléfono de contacto" label="Teléfono" rules={[{ required: true, message: "Campo oblitatorio"}]}>
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}  sm={24} md={12} lg={8}>
            <Form.Item name="phone2" help="Teléfono alternativo" label="Teléfono alternativo" >
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="dniNie" help="DNI/NIE del usuario que se está registrando. Su DNI será el usuario que tendrá que poner para iniciar sesión." label="CIF/NIF" rules={[{ required: true, message: "Campo oblitatorio"}]}>
                <Input />
            </Form.Item>
          </Col>
          <Col  sm={24} md={12} lg={16}>
            <Form.Item name="address" help="Dirección física o jurídica del usuario que será dado de alta" label="Dirección" rules={[{ required: true, message: "Campo oblitatorio"}]}>
                <Input />
            </Form.Item>
          </Col>
          <Col  sm={24} md={12} lg={8}>
            <Form.Item name="country" label="Ciudad" rules={[{ required: true, message: "Campo oblitatorio"}]}>
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="referencePersonName" help="En caso de ser una empresa, indique el nombre de la persona de contacto" label="Persona de contacto">
                <Input />
            </Form.Item>
          </Col>
        </Row>
    )
}

export default ContactData;