import React, { useContext, useEffect, useState } from 'react'

import { Tooltip } from 'antd'

import * as Components from '../../index';

import { 
         ToolOutlined,
         FilePdfOutlined,
         LoginOutlined,
} from '@ant-design/icons'
import CaseReports from '../../../models/CaseReports';
import { CaseReportStatus } from '../../../models/Constants';
import { LoginContext } from '../../../hooks/context/userContext'
import { ADMIN_ROLE, DOCTOR_ROLE } from '../../../utils/Constants';

import RestApiClient from '../../../api/restApiClient';
import { processFile } from '../../../utils/ProcessFile';
import ParamsApi from '../../../models/api/ParamsApi';

interface CaseReportsHelperProps{
    properties: any
}

const CaseReportsHelperComponent = (props: CaseReportsHelperProps) : JSX.Element => {
    let loginContext = useContext(LoginContext);
    const restApiClient : RestApiClient = new RestApiClient();

    const [showEdition, setShowEdition] = useState<boolean>(false);
    const [selected, setSelected] = useState<CaseReports>();
    const [pdfData, setPdfData] = useState<any>();
    const [showPdf, setShowPdf] = useState<boolean>(false);

    const {
        hideMenu,
        callBack
    } = props.properties;

    const drawerEdition = (visible : boolean) : void => {
        setShowEdition(visible);
    }

    const invoice = () : void => {}
    const pdf = () : void => {
        getPdf();
    }

    const handleShowQuickEdit = (value: boolean) : void => {
        hideMenu(value);
        setShowEdition(false);
    }

    const getPdf = () : void =>{

        const paramsApi : ParamsApi = {
            body:[selected?.id]
        }

        restApiClient.generatePdf(paramsApi, "GenerateCaseReportsPDF").then((data: any) => {
            processFile(data, setPdfData);
        });
    }

    useEffect(()=>{
        if (pdfData){
            setShowPdf(true);
        }
    },[pdfData]);



    const getButtons = () : JSX.Element => {

        let buttons : any[] = [];

        if (loginContext.idRole === ADMIN_ROLE){
            buttons.push(<Tooltip placement="left" title="Edición rápida">
                    <li className="h-generic" onClick={()=>{drawerEdition(true)}}>
                        <ToolOutlined />
                        <small className="h-section-name">Editar</small>
                    </li>
                </Tooltip>);
        }


        return <>
                
                {buttons}                
                <Tooltip placement="left" title="Ver PDF">
                    <li className={`h-generic ${selected?.status === CaseReportStatus.Finished  ? '' : 'disabled'}`} onClick={pdf}>
                        <FilePdfOutlined />
                        <small className="h-section-name" >PDF</small>
                    </li>
                </Tooltip>
                <Tooltip placement="left" title="Ocultar menú">
                    <li className="h-logout" onClick={hideMenu}>
                        <LoginOutlined />
                        <small className="h-section-name" >Ocultar</small>
                    </li>
                </Tooltip>
        </>
    }

    useEffect(()=>{
        if (props.properties.selected){
            setSelected(props.properties.selected as CaseReports);
        }
    },[props]);

    return (
        <>
            <Components.ModalPDF selected={selected} onClose={()=>{setShowPdf(false); setPdfData(null);}} visible={showPdf} pdfData={pdfData}/> 
            
            {
                loginContext.idRole === ADMIN_ROLE ? <Components.QuickEdit visible={showEdition} onclose={handleShowQuickEdit} casereport={selected} callback={callBack}/>  : ''
            }
            
            <ul className="helper-balls dashboard-balls">
                {getButtons()}
            </ul>
        </>
    )
}

export default CaseReportsHelperComponent;