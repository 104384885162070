import { Alert } from 'antd'
import React from 'react'

import * as Components from '../../../components/index'

import './AddCompany.scss'

interface AddCompanyProps {}

const AddCompanyPage = (props: AddCompanyProps) : JSX.Element => {
    return (
        <div className="add-company-page">
            <Alert
                message="Sobre productos"
                description="Podrá añadir y registrar productos a la empresa una vez la haya dado de alta. Para ello vaya al listado de empresas y pulse en ver detalle empresa."
                type="info"
                showIcon
            />
            <Components.AddCompany showBack={true}/>
        </div>
    )
}

export default AddCompanyPage;