import { Button, Result } from 'antd';
import React from 'react'
import { useHistory } from "react-router-dom";

interface Page404Props{}

const Page404 = (props : Page404Props) : JSX.Element => {

    const history = useHistory();

    const goHome = () : void => {
        window.location.href = "/dashboard";
    }
    const goBack = () : void => {
        history.goBack();
    }

    return <Result
            status="404"
            title="404"
            subTitle="Lo sentimos, la página que intenta visitar no existe."
            extra={<><Button type="primary" onClick={goBack}>Volver atras</Button><Button type="primary" onClick={goHome}>Inicio</Button></>}
        />
}

export default Page404;