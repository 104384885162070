import { ArrowUpOutlined, LikeOutlined } from '@ant-design/icons';
import { Alert, Col, Divider, Input, Row, Statistic } from 'antd';
import React, { useState } from 'react'

import * as Components from '../../components'

import './StatisticsPage.scss';

const StatisticsPage = () : JSX.Element => {

    const [fromYear, setFromYear] = useState<number>(new Date().getFullYear()-1);
    const [toYear, setToYear] = useState<number>(new Date().getFullYear());

    //Estadísticas de: https://charts.ant.design/en/examples/dual-axes/dual-line#dual-multi-line
    return(
        <div className="statistics-page">
            <h2 className="statistics-header">
                Estadísticas
                <small style={{display:"block", fontSize:"12px"}}>Basadas en las solicitudes recibidas y finalizadas del sistema.</small>
            </h2>
            <Row gutter={16}>
                <Col xs={24} sm={0}>
                    <Alert
                    message="Contenido no disponible"
                    description="El contenido estadístico no está disponible en versiones móviles."
                    type="info"
                    showIcon
                    />
                </Col>
                <Col xs={0} sm={24}>
                    <Row>
                        <Col xs={24} className="year-vs-container">
                            <span>Mostrar año</span>
                            <Input className="vs-year" type="number" placeholder='Desde' defaultValue={fromYear} onChange={(v:any)=>{setFromYear(v.target.value)}}/>
                            <span>Vs</span>
                            <Input className="vs-year" type="number" placeholder='Hasta' defaultValue={toYear} onChange={(v:any)=>{setToYear(v.target.value)}}/>
                        </Col>
                        <Col xs={24} md={24}>
                            <Components.StatisticsBilling fromYear={fromYear} toYear={toYear}/>
                        </Col>
                        <Divider />
                        <Col xs={24} md={24} lg={12}>
                            <Components.StatisticsDoctor year={fromYear}/>
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Components.StatisticsDoctor year={toYear}/>
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Components.StatisticsCompany year={fromYear}/>
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Components.StatisticsCompany year={toYear}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}


export default StatisticsPage;;