import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Search'

export const SearchEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName("SearchInApp"),
    
]