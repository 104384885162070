import React, { forwardRef, useEffect, useState } from 'react'
import { Form } from 'antd'

//https://github.com/zenoamaro/react-quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


interface CitologiaProps {
    reset: boolean,
    form: any,
    values?:{
        diagnosticoAnatomopatologico: string,
        evaluacionMuestra: string,
        nota: string
    }
}


// https://stackoverflow.com/questions/37949981/call-child-method-from-parent
const CitologiaDiagnosisComponent = forwardRef((props: CitologiaProps, ref: any)=>{
    const formItemHeight : string = "300px";
    const textAreaHeight : string = "250px";

    const [reset, setReset] = useState<boolean>(false);

    const {form, values} = props;

    useEffect(()=>{
        if(props.reset){
            setReset(true);
        }
    }, [props.reset])

    useEffect(()=>{
        if(reset){
            form.setFieldsValue({"citologiaDiagnosisData":{ "muestra":"", "estudio":"", "informacionClinica":""}});
            setReset(false);
        }
    },[reset])

    useEffect(()=>{
        if(values){
            form.setFieldsValue({"citologiaDiagnosisData":{ "diagnosticoAnatomopatologico":values.diagnosticoAnatomopatologico ?? "", "evaluacionMuestra":values.evaluacionMuestra ?? "", "nota":values.nota ?? "" }});
        }else{
            form.setFieldsValue({"citologiaDiagnosisData":{ "diagnosticoAnatomopatologico":"", "evaluacionMuestra":"", "nota":""}});
        }
    },[values])

    return (<>
                <Form.Item 
                    name={["citologiaDiagnosisData","evaluacionMuestra"]}
                    label="Evaluación de la muestra" style={{height: formItemHeight}}>
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
                <Form.Item 
                    name={["citologiaDiagnosisData","diagnosticoAnatomopatologico"]}
                    label="Diagnóstico Anatomopatológico" style={{height: formItemHeight}}>
                    <ReactQuill theme="snow" style={{height: textAreaHeight, width: "100%"}}/>
                </Form.Item>
                <Form.Item 
                    name={["citologiaDiagnosisData","nota"]}
                    label="Nota" style={{height: formItemHeight}}>
                    <ReactQuill theme="snow" style={{height: textAreaHeight}}/>
                </Form.Item>
            </>)
        
});


export default CitologiaDiagnosisComponent;