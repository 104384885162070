import { DollarOutlined, DotChartOutlined, FileSearchOutlined, HomeOutlined, ReconciliationOutlined, SettingOutlined, SolutionOutlined } from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginContext } from '../../hooks/context/userContext'
import { ADMIN_ROLE, DOCTOR_ROLE } from '../../utils/Constants';

import './MenuComponent.scss'

const { SubMenu } = Menu;

interface MenuComponentProps {}

const MenuComponent = (props: MenuComponentProps) : JSX.Element => {

    const history = useHistory();
    let loginContext = useContext(LoginContext);
    
    const [current, setCurrent] = useState<string>('home')
    const [actualCurrent, setActualCurrent] = useState<string>('')
    const [currentName, setCurrentName] = useState<string>('');


    const setSection = (section: string) : void => {
        const sectionSplit : string[] = section.split("/");
        setCurrent(section)
        setActualCurrent(`actual-${section}`)
        switch(sectionSplit[0]){
            case "requests":
                setCurrentName("Sección: Petición de recogidas (Admin)");
                break;
            case "register":
                setCurrentName("Sección: Registro");
                break;
            case "requests-list":
                setCurrentName("Sección: Petición de recogidas");
                break;
            case "documents":
                setCurrentName("Sección: Descarga PDFs");
                break;
            case "invoice-generator":
                setCurrentName("Sección: Generador");
                break;
            case "invoices":
                setCurrentName("Sección: Emitidas");
                break;
            case "add-company":
                setCurrentName("Sección: Añadir compañía");
                break;
            case "edit-company":
                setCurrentName("Sección: Editar compañía");
                break;
            case "companies":
                setCurrentName("Sección: Compañías");
                break;
            case "statistics":
                setCurrentName("Sección: Estadísticas");
                break;
            case "advanced-statistics":
                setCurrentName("Sección: Estadísticas avanzadas");
                break;
            case "migration-patients":
                setCurrentName("Sección: Migración Pacientes");
                break;
            case "preformatted":
                setCurrentName("Sección: Preformateados");
                break;
            case "add-preformatted":
                    setCurrentName("Sección: Añadir preformateado");
                break;
            case "configurations":
                    setCurrentName("Sección: Configuraciones");
                break;
            case "add-user":
                setCurrentName("Sección: Añadir usuario");
                break;
            case "users":
                setCurrentName("Sección: Usuarios");
                break;
            case "case-report":
                setCurrentName("Sección: Reporte de caso");
                break;
            case "advanced-search":
                setCurrentName("Sección: Buscador Avanzado CDE");
                break;
            default:
                setCurrentName("");
                break;
        }
    }

    const handleClick = (e: any) => {
        if (e.key.indexOf('pdf-') !== 0 && e.key !== `actual-${current}` && e.key !== current){
            history.push(`/${e.key}`);
        }
    }

    const goPdf = (pdfName: string) : any => {
        window.open(pdfName, '_blank');
    }
    
    
    useEffect(()=>{
        const pathName = history.location.pathname.replace("/","");
        setSection(pathName);
    },[]);

    return (
        <div className="app-menu">
            <Menu className="antd-menu" onClick={handleClick} selectedKeys={[current, actualCurrent]} mode="horizontal">
                <Menu.Item key="dashboard" icon={<HomeOutlined />}>
                    Inicio
                </Menu.Item>
            
                {
                    loginContext.idRole === ADMIN_ROLE || loginContext.idRole === DOCTOR_ROLE ? 
                        <SubMenu key="samples" icon={<ReconciliationOutlined />} title="Muestras">

                                {
                                    loginContext.idRole === DOCTOR_ROLE ?
                                        <Menu.Item key="requests-list">Petición de recogidas</Menu.Item>
                                    :
                                    <></>
                                }
                                
                                
                                {
                                    loginContext.idRole === ADMIN_ROLE ?
                                    <>
                                        <Menu.Item key="requests">Petición de recogidas (Admin)</Menu.Item>
                                        <Menu.Item key="register">Registro</Menu.Item>
                                    </>
                                    :
                                    <></>
                                }
                                <SubMenu title="PDFs" key="documets-pdf">
                                    <Menu.Item key="pdf-1" onClick={()=>{goPdf('/pdf/pdf_estudio_anatomia_patologica.pdf');}}>Estudio Anatomía Patológica</Menu.Item>
                                    <Menu.Item key="pdf-2" onClick={()=>{goPdf('/pdf/pdf_estudio_microbiologia.pdf');}}>Estudio Microbiología</Menu.Item>
                                    <Menu.Item key="pdf-3" onClick={()=>{goPdf('/pdf/pdf_neobona_cda.pdf');}}>Neobona CDA</Menu.Item>
                                    <Menu.Item key="pdf-4" onClick={()=>{goPdf('/pdf/pdf_estudio_microbiota_vaginal.pdf');}}>Estudio Microbiota Vaginal</Menu.Item>
                                </SubMenu>
                        </SubMenu>
                    :
                    <></>
                }

                {
                    loginContext.idRole === ADMIN_ROLE ? 
                    <Menu.Item key="patients" icon={<SolutionOutlined />}>
                        Pacientes
                    </Menu.Item>
                    :
                    <></>
                }
                
                {
                    loginContext.idRole === ADMIN_ROLE || loginContext.idRole === DOCTOR_ROLE ? 
                        <SubMenu key="invoices" icon={<DollarOutlined />} title="Facturación">
                            {
                                loginContext.idRole === ADMIN_ROLE ?
                                    <>
                                        <Menu.Item key="invoice-generator">Generador</Menu.Item>
                                        <Menu.Item key="invoice-list">Emitidas</Menu.Item>
                                    </>
                                :
                                <Menu.Item key="invoice-list">Mis facturas</Menu.Item>
                            }
                            
                        </SubMenu>
                    :
                    <></>
                }

                {
                    loginContext.idRole === ADMIN_ROLE 
                    ? 
                        <Menu.Item key="advanced-search" icon={<FileSearchOutlined />}>
                            Buscador Avanzado
                        </Menu.Item>
                    :
                        <></>
                }

                {
                    loginContext.idRole === ADMIN_ROLE ? 
                    <SubMenu key="admin" icon={<SettingOutlined />} title="Administración">
                        <Menu.Item key="companies">Compañias</Menu.Item>
                        <Menu.Item key="statistics">Estadística económica</Menu.Item>
                        <Menu.Item key="advanced-statistics">Estadística clínica</Menu.Item>
                        <Menu.Item key="migration-patients">Migración Pacientes</Menu.Item>
                        <Menu.Item key="preformatted">Preformateados</Menu.Item>
                        <Menu.Item key="users">Usuarios</Menu.Item>
                        <Menu.Item key="archived">Archivo</Menu.Item>
                        <Menu.Item key="configurations">Configuraciones</Menu.Item>
                    </SubMenu>
                    :
                    <></>
                }

                {
                    loginContext.idRole === DOCTOR_ROLE ? 
                        <Menu.Item key="advanced-statistics" icon={<DotChartOutlined /> }>
                            Estadísticas
                        </Menu.Item>
                    :
                    <></>
                }

                <Menu.Item className="current-page" key={`actual-${current}`}>
                    {currentName}
                </Menu.Item>
            </Menu>
            
        </div>
    )
}

export default MenuComponent;