import React from 'react'
import User from '../../models/Users'

export let userDefault : User = {
    collegiateNumber: '',
    dniNie: '',
    id: -1,
    idRole: -1,
    isLoged: false ,
    name: '',
    surname: '',
    secondSurname: '',
    specialty: '',
    sessionId:''
} as User

const sessionUserData = sessionStorage.getItem("user-data");

if (sessionUserData !== null && sessionUserData.length > 0){
    userDefault = JSON.parse(sessionUserData) as User;
}

export const LoginContext = React.createContext(userDefault);
