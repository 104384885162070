import React, { useEffect } from 'react'

import * as Pages from '../../../pages/index'

import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

export const PublicRoutesComponent = (props : any) => {

    return(
        <Router forceRefresh={true}>
            <Switch>
                <Route exact path={["/",
                                    "/login", 
                                    "/dashboard", 
                                    ]}>
                    <Pages.Login />
                </Route>
            </Switch>
            <Switch>
                <Route path={["/error"]} >
                    <Pages.Error500 />
                </Route>
            </Switch>
        </Router>
    )
}