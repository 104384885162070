import React from 'react'

import * as Components from '../../components/index'

import './AdvancedStatisticsPage.scss'

interface Props{}

const AdvancedStatisticsPage = (props: Props) : JSX.Element => {

    return(
        <div className="advanced-statistics-page">
            <Components.DetailedStatistics />
        </div>
    )
}

export default AdvancedStatisticsPage;