
import ContainerBase from './containerBase/containerBase'
import LoginComponent from './login/login'
import LockScreenComponent from './lockscreen/lockscreen'
import HelperComponent from './helper/helper'
import DrawerInsideComponent from './DrawerInside/DrawerInside'
import ProfileComponent from './profile/profileComponent'
import SearchComponent from './search/SearchComponent'
import MenuComponent from './menu/MenuComponent'
import HeaderComponent from './header/HeaderComponent'
import CaseReportDetailComponent from './caseReports/detail/CaseReportDetailComponent'
import CaseReportListComponent from './caseReports/list/CaseReportListComponent'
import FooterComponent from './footer/FooterComponent'
import SessionComponent from './session/SessionComponent'
import QuickEditComponent from './quickedit/QuickEditComponent'
import ManagePatientComponent from './patients/management/ManagePatientComponent'
import PatientListComponent from './patients/PatientListComponent'
import RequestsComponent from './requests/RequestsComponent'
import RequestsComponentDoctor from './requests/RequestsComponentDoctor'
import CompaniesListComponent from './companies/list/CompaniesListComponent'
import AddCompanyComponent from './companies/ce/CompanyCEComponent'
import ProductListComponent from './products/list/ProductListComponent'
import ProductCEComponent from './products/ce/ProductCEComponent'
import RegisterComponent from './register/RegisterComponent'
import LoadingOrComponent from './core/utils/LoadingOrComponent'
import UserListComponent from './users/List/UserListComponent'
import UsersManageComponent from './users/manage/UsersManageComponent'
import UserProfileComponent from './users/profile/UserProfileComponent'
import TablePaginationComponent from './core/utils/TablePaginationComponent'
import InvoiceListComponent from './invoices/list/InvoiceListcomponent'
import InvoiceGeneratorComponent from './invoices/generator/InvoiceGeneratorComponent'
import InvoiceNotificatorComponent from './invoices/notify/InvoiceNotificatorComponent'
import KeywordsComponent from './keywords/KewordsComponent'
import MigrationPatientsComponent from './patients/migrations/MigrationPatientsComponent'
import StatisticsCompanyComponent from './statistics/StatisticsCompanyComponent'
import StatisticsDoctorComponent from './statistics/StatisticsDoctorComponent'
import StatisticsBillingComponent from './statistics/StatisticsBillingComponent'
import DetailedStatisticsComponent from './statistics/DetailedStatisticsComponent'
import Model347Component from './model347/Model347Component'
import ShortcutsComponent from './shortcuts/ShortcutsComponent'
import RGPDComponent from './rgpd/RGPDComponent'
import AdvancedSearchComponent from './search/AdvancedSearchComponent'

//#region Drawers 
    import RequestDrawerComponent from './drawers/requests/RequestDrawerComponent'
//#endregion

//#region Modals
    import ModalPDFComponent from './core/modals/PDF/ModalPDF'
    import ModalInvoicePDFComponent from './core/modals/PDF/ModalInvoicePDF'
    import ModalReportPDFComponent from './core/modals/PDF/ModalReportPDF'
    import Modal347PDFComponent from './core/modals/PDF/Modal347PDF'
    import ModalListInvoicesPDFComponent from './core/modals/PDF/ModalListInvoicesPDF'
    import ModalCaseReportComponent from './core/modals/CaseReports/ModalCaseReportComponent'
    import ModalCaseReportValidateComponent from './core/modals/CaseReports/ModalCaseReportValidateComponent'
    
//#endregion

export const Container = ContainerBase  
export const Login = LoginComponent
export const LockScreen = LockScreenComponent
export const Helper = HelperComponent
export const DrawerInside = DrawerInsideComponent;
export const Profile = ProfileComponent;
export const Search = SearchComponent;
export const Menu = MenuComponent;
export const Header = HeaderComponent;
export const CaseReportDetail = CaseReportDetailComponent;
export const CaseReportList = CaseReportListComponent;
export const Footer = FooterComponent;
export const Session = SessionComponent;
export const QuickEdit = QuickEditComponent;
export const ManagePatient = ManagePatientComponent;
export const PatientList = PatientListComponent;
export const Requests = RequestsComponent;
export const RequestsDoctor = RequestsComponentDoctor;
export const CompaniesList = CompaniesListComponent;
export const AddCompany = AddCompanyComponent;
export const ProductList = ProductListComponent;
export const ProductAdd = ProductCEComponent;
export const ProductEdit = ProductCEComponent;
export const Register = RegisterComponent;
export const LoadingOrText = LoadingOrComponent;
export const UserList = UserListComponent;
export const UsersManage = UsersManageComponent;
export const UserProfile = UserProfileComponent;
export const TablePagination = TablePaginationComponent;
export const InvoiceList = InvoiceListComponent;
export const InvoiceGenerator = InvoiceGeneratorComponent;
export const InvoiceNotificator = InvoiceNotificatorComponent;
export const Keywords = KeywordsComponent;
export const MigrationPatients = MigrationPatientsComponent;
export const StatisticsCompany = StatisticsCompanyComponent;
export const StatisticsDoctor = StatisticsDoctorComponent;
export const StatisticsBilling = StatisticsBillingComponent;
export const DetailedStatistics = DetailedStatisticsComponent;
export const Model347 = Model347Component;
export const Shortcuts = ShortcutsComponent;
export const RGPD = RGPDComponent;
export const AdvancedSearch = AdvancedSearchComponent;

//#region Modals
    export const Modal347PDF = Modal347PDFComponent;
    export const ModalListInvoicesPDF = ModalListInvoicesPDFComponent;
    export const ModalPDF = ModalPDFComponent;
    export const ModalInvoicePDF = ModalInvoicePDFComponent;
    export const ModalReportPDF = ModalReportPDFComponent;
    export const ModalCaseReport = ModalCaseReportComponent; 
    export const ModalCaseReportValidate = ModalCaseReportValidateComponent; 
    


//#endregion

//#region Drawers 
    export const RequestsDrawer = RequestDrawerComponent;
//#endregion