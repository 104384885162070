import React, { useEffect, useState } from 'react'
import { Row, Col, Tabs, Button } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import * as Components from '../../../components/index'
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';
import ParamsApi from '../../../models/api/ParamsApi';

import '../add/AddCompany.scss'

import './EditCompany.scss'
import Company, { EmptyCompany } from '../../../models/Company';
import { RollbackOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

interface EditCompanyProps{}

const EditCompany = (props : EditCompanyProps) : JSX.Element => {
    const history = useHistory();

    
    const restApiClient : RestApiClient = new RestApiClient();

    const [company, setCompany] = useState<Company>(EmptyCompany);

    const { id } = useParams<any>();

    const getCompanyData = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                id: id
            }
        }

        restApiClient.fetch("GetCompany", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setCompany(r.data);
                }
            });
    }

    useEffect(()=>{
        if (!isNaN(id)){
            getCompanyData();
        }
    },[id]);


    return (
        <Row gutter={16} className="edit-company-page">
             <Col xs={24}>
                <Tabs>
                    <TabPane tab="Datos principales" key="1"  >
                        <Components.AddCompany showBack={false} companyData={company} updateMode={true}/>
                    </TabPane>
                    <TabPane tab="Productos" key="2">
                        <Components.ProductList list={company.products} company={company}/>
                    </TabPane>
                </Tabs>
             </Col>
        </Row>
    )
}

export default EditCompany;