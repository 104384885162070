import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

interface LoadingOrComponentProps {
    loading: boolean,
    text: any
    showWhileLoading?: boolean
    showSpinner?: boolean
    loadingText? : any
}

const LoadingOrComponent = (props: LoadingOrComponentProps) : JSX.Element => {

    const [ loading, setLoading ] = React.useState<boolean>(true);
    const [ text, setText] = React.useState<string>('');

    React.useEffect(() => {
        setLoading(props.loading);
    },[props.loading]);

    React.useEffect(() => {
        setText(props.text);
    },[props.text]);

    return (
        <>
        {
            loading ? 
                        <>
                            {
                                props.showSpinner === undefined || props.showSpinner === true ? (<LoadingOutlined spin style={{marginRight: "5px"}}/>) : ''
                            }
                            {props.loadingText ? props.loadingText : (props.showWhileLoading === false ? '' : text)}
                            </>
                    : 
                        <>{text}</>
        } 
        </>
    )
}

export default LoadingOrComponent;