import React, { useEffect, useState } from 'react';

import * as Components from '../../../components';
import RestApiClient from '../../../api/restApiClient';
import ApiResponse from '../../../models/api/ApiResponse';

import './EditPatient.scss';
import { useParams } from 'react-router-dom';
import Patient from '../../../models/Patient';

const EditPatientPage = () : JSX.Element => {   
    const restApiClient : RestApiClient = new RestApiClient();
    
    const { id } = useParams<any>();

    const [patient, setPatient] = useState<Patient>();


    useEffect(()=>{
        restApiClient.fetch("GetPatient", {
            query:{
                id: id
            }
        })
        .then((r : ApiResponse | null)=> {
            if (r && r.code === 200){
                setPatient(r.data);
            }
        })
    },[]);

    return (
        <div className="edit-patient-page">
            <Components.ManagePatient clear={false} patientData={patient} editMode/>
        </div>
    );
}

export default EditPatientPage;