import React, { useEffect, useState, useContext } from 'react'
import { Row, Col } from 'antd'
import { useHistory, useParams } from "react-router-dom"


import { LoginContext } from '../../../hooks/context/userContext'



import { Typography } from 'antd';

import * as Components from '../../../components/index'

import './loading.scss';
import './login.scss';
import './waves.scss';
import ParamsApi from '../../../models/api/ParamsApi';
import ApiResponse from '../../../models/api/ApiResponse';
import RestApiClient from '../../../api/restApiClient';
import AppCfg from '../../../models/AppCfg';

const { Title } = Typography;


const LoginPage = (props : any) => {
    let loginContext = useContext(LoginContext);

    let { validateAccountHash } = useParams<{validateAccountHash : string}>();
    let { changePasswordHash } = useParams<{changePasswordHash : string}>();

    
    const restApiClient : RestApiClient = new RestApiClient();
    
    const [loadingConfig, setLoadingConfig] = useState<boolean>(false);
    const [errorInConfig, setErrorInConfig] = useState<boolean>(false);
    const [messageLoading, setMessageLoading] = useState<any>(<p>Por favor, espere, cargando la aplicación...</p>);
    const [app, setApp] = useState<AppCfg>();

    

    const checkAppConfig = () : boolean => {
        let found : boolean = false;
        const actualConfig : string | null = sessionStorage.getItem("app-config");
        if (actualConfig){
            found = true;
            setApp(JSON.parse(actualConfig) as AppCfg);
            setErrorInConfig(false);
            setLoadingConfig(false);
        }

        return found;
    }


    useEffect(()=>{
        checkAppConfig()
    },[]);

    

    useEffect(()=>{
        //http://localhost:3000/validate-account/4770b345-a089-4b77-afa5-fd3ccf4212cd
        if (validateAccountHash !== null && validateAccountHash !== undefined){
            const paramsApi : ParamsApi = {
                path: {
                    hash: validateAccountHash
                }
            }
    
            restApiClient.fetch("ValidateAccount", paramsApi)
                .then((r : ApiResponse | null)=> {})
                .finally(()=>{})
                
        }
    }, [validateAccountHash])

    


    useEffect(()=>{
        //http://localhost:3000/change-my-password/4770b345-a089-4b77-afa5-fd3ccf4212cd
        if (changePasswordHash !== null && changePasswordHash !== undefined){
            const paramsApi : ParamsApi = {
                path: {
                    hash: changePasswordHash
                }
            }
    
            restApiClient.fetch("ChangeMyPasswordStep2", paramsApi)
                .then((r : ApiResponse | null)=> {
                    
                })
                .finally(()=>{})
                
        }
    }, [changePasswordHash])

    
    // Diseño: https://miro.medium.com/max/700/0*RSKAVlzjKB2GwPVR.jpg
    return(
        <>
            {
                loadingConfig || errorInConfig || app === undefined? 
                    <Row gutter={16} className="loading">
                        <Col xs={24} className="loading-container">
                        { loadingConfig ? <div className="lds-ripple"><div></div><div></div></div> : '' }
                        <Title level={3} className="message">
                            {messageLoading}
                        </Title>
                        </Col>
                            <div className="my-waves">
                                <div className="waves wave-1">
                                    <svg width="100%" height="400px" fill="none" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg">
                                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stop-color="#93dbe9" />
                                            <stop offset="50%" stop-color="#689cc5" />
                                            <stop offset="100%" stop-color="#5e6fa3" />
                                        </linearGradient>
                                        <path 
                                        fill="url(#grad1)" 
                                        d="
                                        M0 67
                                        C 273,183
                                            822,-40
                                            1920.00,106 
                                        
                                        V 400 
                                        H 0 
                                        V 67
                                        Z">
                                        <animate 
                                        repeatCount="indefinite" 
                                        fill="url(#grad1)" 
                                        attributeName="d" 
                                        dur="15s"
                                        attributeType="XML"
                                        values="
                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,-40
                                            1222,283
                                            1920,136 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 973,260
                                            1722,-53
                                            1920,120 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z
                                            ">
                                        </animate>
                                    </path>
                                    </svg>
                                </div>
                                <div className="waves wave-2">
                                    <svg width="100%" height="400px" fill="none" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg">
                                        <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                                            <stop offset="0%" stop-color="#5e6fa3" />
                                            <stop offset="50%" stop-color="#3b4368" />
                                            <stop offset="100%" stop-color="#191d3a" />
                                        </linearGradient>
                                        <path 
                                        fill="url(#grad2)" 
                                        d="
                                        M0 67
                                        C 273,183
                                            822,-40
                                            1920.00,106 
                                        
                                        V 400 
                                        H 0 
                                        V 67
                                        Z">
                                        <animate 
                                        repeatCount="indefinite" 
                                        fill="url(#grad2)" 
                                        attributeName="d" 
                                        dur="4s"
                                        attributeType="XML"
                                        values="
                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,-40
                                            1222,283
                                            1920,136 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 973,260
                                            1722,-53
                                            1920,120 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z; 

                                            M0 77 
                                            C 473,283
                                            822,-40
                                            1920,116 
                                            
                                            V 400 
                                            H 0 
                                            V 67 
                                            Z
                                            ">
                                        </animate>
                                    </path>
                                    </svg>
                                </div>
                                <div className="waves wave-3">
                                <svg width="100%" height="400px" fill="none" version="1.1"
                                xmlns="http://www.w3.org/2000/svg">
                                    <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
                                        <stop offset="0%" stop-color="#191d3a" />
                                        <stop offset="50%" stop-color="#d9dbee" />
                                        <stop offset="100%" stop-color="#b3b7e2" />
                                    </linearGradient>
                                    <path 
                                    fill="url(#grad3)" 
                                    d="
                                    M0 67
                                    c 273,183
                                        822,-40
                                        1920.00,106 
                                    
                                    V 400 
                                    H 0 
                                    V 67
                                    Z">
                                    <animate 
                                    repeatCount="indefinite" 
                                    fill="url(#grad3)" 
                                    attributeName="d" 
                                    dur="3s"
                                    attributeType="XML"
                                    values="
                                        M0 77 
                                        C 473,283
                                        822,-40
                                        1920,116 
                                        
                                        V 400 
                                        H 0 
                                        V 67 
                                        Z; 

                                        M0 77 
                                        C 473,-40
                                        1222,283
                                        1920,136 
                                        
                                        V 400 
                                        H 0 
                                        V 67 
                                        Z; 

                                        M0 77 
                                        C 973,260
                                        1722,-53
                                        1920,120 
                                        
                                        V 400 
                                        H 0 
                                        V 67 
                                        Z; 

                                        M0 77 
                                        C 473,283
                                        822,-40
                                        1920,116 
                                        
                                        V 400
                                        H 0 
                                        V 67 
                                        Z
                                        ">
                                    </animate>
                                </path>
                                </svg>
                            </div>
                            </div>
                    </Row>
                :
                    <Row className="login" justify="center" align="middle" style={{backgroundColor: `#aa60f0b3`}}>
                        <Col className="container" xs={24} sm={24} md={15} lg={12} xl={9} xxl={6}>
                            <Row className="container-row">
                                <Col xs={24} className="header" style={{backgroundColor: `#ebdafc`}}>
                                    
                                </Col>
                                <Col xs={24} className="form">
                                    <div className="circle-title">
                                    </div>
                                    <Components.Login />
                                </Col>
                                <Col xs={24} className="create-account">
                                    <small style={{fontSize:"14px", display:"block"}}>Todos los derechos reservados <a href='https://www.centrodiagnosticocda.com/' target="_blank"> CDA® </a></small>
                                    <small style={{fontSize:"10px", display:"block"}}>Software realizado por <a href='https://www.omnicode.es' target="_blank"> Omnicode®</a></small>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }
            <div style={{padding: "10px", color: "white", position: "absolute", right: 0, bottom:0, zIndex:"999"}}>
                {process.env.NODE_ENV}
            </div>
        </>
        
    )
}

export default LoginPage;