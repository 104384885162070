import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Users'

export const UsersEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("DoLogin"),
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)        
        .setActionName("DoLogout"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetDoctors"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)        
        .setActionName("GetPathologist"),
    new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetUsers"),
    new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetUserById"),
    new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("UpdateUser"),
    new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("AddUser"),
    new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("AcceptRGPD"),
    new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("PrepareWelcomePlatformEmail")
]