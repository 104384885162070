import { Row, Col } from "antd";
import React from "react";

import * as Components from '../../components/index'

import './Users.scss'


const Users = () : JSX.Element => {
    return (
        <Row gutter={16} className="users-page">
            <Col xs={24}>
                <Components.UserList />
            </Col>
        </Row>
    )
}

export default Users;