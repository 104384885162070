import { CarOutlined, EditOutlined, LikeOutlined, LoadingOutlined, PhoneOutlined, StopOutlined } from "@ant-design/icons";
import React from "react";

export const getItemStatus = (status: 'pendiente' | 'ordenado' | 'recepcionado' | 'procesando' | 'finalizado' | 'cancelado', subClassStyle : string = "") : JSX.Element => {

    let icon : JSX.Element =  <StopOutlined className={`status-icon`}/>;

    switch(status){
        case "pendiente":
            icon =  <PhoneOutlined className={`status-icon`}/>;
            break;
        case "ordenado":
            icon =  <CarOutlined className={`status-icon`}/>;
            break;
        case "recepcionado":
            icon =  <EditOutlined className={`status-icon`}/>;
            break;
        case "procesando":
            icon =  <LoadingOutlined className={`status-icon`}/>;
            break;
        case "finalizado":
            icon =  <LikeOutlined className={`status-icon`}/>;
            break;
    }

    return <div className={`request-status ${status}  ${subClassStyle}`} title={status}>{icon}</div>
}