import React from 'react'

import { Col, Divider, Form, Input, Radio, Row, Select } from 'antd';


const BillingData = () : JSX.Element => {

    return(
        <Row gutter={16}>
          <Col xs={24}>
            <Divider orientation="left">Datos de facturación</Divider>
          </Col>
          
          <Col xs={24}>
            <Form.Item name="bussinesName" help="En caso de ser necesario indicar el nombre de la sociedad. Si no viene indicado se facturará a nombre de la persona." label="Nombre/Sociedad" >
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="cif" help="Indique el CIF de la empresa. En caso de dejarlo en blanco se usará el DNI del usuario." label="DNI/CIF Facturación" >
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="billingAddress" help="Indique la dirección de facturación. Si la deja en blanco será la dirección del usuario." label="Dirección de facturación" >
                <Input />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="iban" help="IBAN de la cuenta corriente del usuario" label="IBAN">
                <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Divider orientation="left">Auto Factura</Divider>
          </Col>
          <Col xs={24}>
            <Form.Item name="autoBillingEnable" initialValue={0} label="Auto factura activa" rules={[{ required: true, message: "Campo obligatorio"}]}>
                <Radio.Group >
                    <Radio value={0}>No</Radio>
                    <Radio value={1}>Si</Radio>
                </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="autoBillingNotifyOnCreate" initialValue={1} label="Notificar al crear" rules={[{ required: true, message: "Campo obligatorio"}]}>
                <Radio.Group >
                    <Radio value={0}>No</Radio>
                    <Radio value={1}>Si</Radio>
                </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="autoBillingAttachCopy" initialValue={0} label="Incluir copia en notificación" rules={[{ required: true, message: "Campo obligatorio"}]}>
                <Radio.Group >
                    <Radio value={0}>No</Radio>
                    <Radio value={1}>Si</Radio>
                </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="autoBillingIncludeDetail" initialValue={1} label="Incluir detalle" rules={[{ required: true, message: "Campo obligatorio"}]}>
                <Radio.Group >
                    <Radio value={0}>No</Radio>
                    <Radio value={1}>Si</Radio>
                </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="autoBillingPaymentType" initialValue={'transfer'} label="Tipo de pago" rules={[{ required: true, message: "Campo obligatorio"}]}>
                <Radio.Group >
                    <Radio value='transfer'>Transferencia Bancaria</Radio>
                    <Radio value='cash'>Efectivo</Radio>
                    <Radio value='sepa'>SEPA</Radio>
                </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="autoBillingDescription" initialValue={''} label="Descripción">
              <Input />
            </Form.Item>
          </Col>
        </Row>
    )
}

export default BillingData;