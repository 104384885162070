import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Preformatted'

export const PreformattedEP : EndPointConfig[] =[
        new EndPointConfig()
            .setMethod('GET')
            .setController(controller) 
            .setActionName("GetPreformattedList"),    
        new EndPointConfig()
            .setMethod('GET')
            .setController(controller) 
            .setActionName("GetPreformatted"),
        new EndPointConfig()
            .setMethod('PATCH')
            .setController(controller)
            .setActionName("ChangePreformattedStatus"),
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller)
            .setActionName("AddPreformatted"),
        new EndPointConfig()
            .setMethod('POST')
            .setController(controller)
            .setActionName("UpdatePreformatted"),
]