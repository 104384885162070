import { Button, Divider, Form, Select, Typography, Drawer, Modal, Input, Alert, Checkbox, notification} from 'antd'
import React, { useEffect, useState } from 'react'
import Doctor from '../../models/Doctor';

import ApiResponse from '../../models/api/ApiResponse';
import RestApiClient from '../../api/restApiClient';
import ParamsApi from '../../models/api/ParamsApi';

import './RegisterComponent.scss'
import { CarOutlined, ClearOutlined, ClockCircleOutlined, LoadingOutlined, ReloadOutlined, RotateLeftOutlined, SaveOutlined } from '@ant-design/icons';
import Request from '../../models/Request';
import moment from 'moment';
import Pathologist from '../../models/Pathologist';
import Company from '../../models/Company';
import Product from '../../models/Product';
import { getTemplateName, loadTemplate } from '../../utils/TemplateNames';
import Patient from '../../models/Patient';
import 'react-quill/dist/quill.snow.css';
import { NO_REDIRECT_ON_ERROR } from '../../utils/Constants';
import { ManagePatient, LoadingOrText } from '..';
import { getItemStatus } from '../../utils/RequestsUtils';

import * as SubComponents from '../requests/components';

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;

interface RegisterComponentProps{}

const RegisterComponent = (props: RegisterComponentProps): JSX.Element => {
    const [form] = Form.useForm();
    const restApiClient : RestApiClient = new RestApiClient();

    const [doctors, setDoctors] = useState<Doctor[]>([]);
    const [selectedDoctor, setSelectedDoctor] = useState<Doctor>();
    const [requests, setRequests] = useState<Request[]>([]);
    const [pathologists, setPathologists] = useState<Pathologist[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<Company>();
    const [products, setProducts] = useState<Product[]>([]);
    const [patients, setPatients] = useState<Patient[]>([]);
    const [selectedRequest, setSelectedRequest] = useState<Request>();
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [shortCuts, setShortCuts] = useState<any[]>([]);
    const [specialTags, setSpecialTags] = useState<any[]>([]);
    const [selectedShortCode, setSelectedShortCode] = useState<string[]>([]);

    const [template, setTemplate] = useState<JSX.Element>();

    const [loadingDoctors, setLoadingDoctors] = useState<boolean>(false);
    const [loadingRequests, setLoadingRequests] = useState<boolean>(false);
    const [loadingPathologists, setLoadingPathologists] = useState<boolean>(false);
    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
    const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
    const [loadingPatients, setLoadingPatients] = useState<boolean>(false);
    const [savingData, setSavingData] = useState<boolean>(false);
    const [showOnlyRequesStatus, setShowOnlyRequesStatus] = useState<string>("");

    const [showAddPatient, setShowAddPatient] = useState<boolean>(false);
    const [showMoreInfoModal, setShowMoreInfoModal] = useState<boolean>(false);
    const [showAddOrder, setShowAddOrder] = useState<boolean>(false);


    const resetFields = () : void => {
        form.resetFields();
        setSelectedShortCode([]);
        setSelectedCompany(undefined);
        setSelectedDoctor(undefined);
        setSelectedProduct(undefined);
        setSelectedRequest(undefined);
        setPatients([]);
        setTemplate(undefined)
        form.setFieldsValue([{specialTags: " "}])

    }

    const [filterNamePatiente, setFilterNamePatient] = useState<string>('');
    const filterPatients = (v : string) : void => {
        setFilterNamePatient(v);
    }

    const GetShortcuts = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                page: 0,
                N: 0,
                filter: 'Observations', 
            }
        }
        restApiClient.fetch("GetOptions", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setShortCuts(r.data.elements);
                }
            })
    }

    const GetSpecialTags = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                page: 0,
                N: 0,
                filter: 'SpecialTags', 
            }
        }
        restApiClient.fetch("GetOptions", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setSpecialTags(r.data.elements);
                }
            })
    }

    const GetPatients = () : void => {
        if (filterNamePatiente.length > 0) {
            const paramsApi : ParamsApi = {
                query:{
                    page:0,
                    N:999999,
                    filter: filterNamePatiente
                }
            }
            setLoadingPatients(true);
            restApiClient.fetch("GetPatientsBasic", paramsApi)
                .then((r : ApiResponse | null)=> {
                    if (r && r.code === 200){
                        setPatients(r.data.elements);
                    }
                })
                .finally(()=>{
                    setLoadingPatients(false);
                })
        }else{
            notification.warn({
                message: 'Atención',
                description:"Debe ingresar un nombre para filtrar los pacientes"
            });
        }
    }

    const GetDoctors = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                filter: '', 
                counters: true,
                billingInfo: false
            }
        }
        setLoadingDoctors(true);
        restApiClient.fetch("GetDoctors", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setDoctors(r.data);
                }
            })
            .finally(()=>{
                setLoadingDoctors(false);
            })
    }

    const GetPathologists = () : void => {
        const paramsApi : ParamsApi = {
            query:{
                filter: ''
            }
        }
        setLoadingPathologists(true);
        restApiClient.fetch("GetPathologist", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setPathologists(r.data);
                }
            })
            .finally(()=>{
                setLoadingPathologists(false);
            })
    }

    const GetRequestsByDoctor = (doctorId: number) : void => {
        const paramsApi : ParamsApi = {
            query:{
                doctorId: doctorId
            }
        }
        setLoadingRequests(true);
        form.resetFields(["request"]);
        restApiClient.fetch("GetPendingDoctorRequests", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setRequests(r.data);
                }
            })
            .finally(()=>{
                setLoadingRequests(false);
            })
    }

    const GetCompanies = () : void => {
        const paramsApi : ParamsApi = {
            query:{}
        }
        setLoadingCompanies(true);
        restApiClient.fetch("GetAllCompanies", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setCompanies(r.data.elements);
                }
            })
            .finally(()=>{
                setLoadingCompanies(false);
            })
    }

    const GetCompanyProducts = (id: number) : void => {
        const paramsApi : ParamsApi = {
            query:{
                companyId: id
            }
        }
        setLoadingProducts(true);
        restApiClient.fetch("GetCompanyProducts", paramsApi)
            .then((r : ApiResponse | null)=> {
                if (r && r.code === 200){
                    setProducts(r.data.elements);
                }
            })
            .finally(()=>{
                setLoadingProducts(false);
            })
    }

 
    const save = (callback : any) : void => {
        const paramsApi : ParamsApi = {
            body:form.getFieldsValue()
        }

        setSavingData(true);
        restApiClient.fetch("AddReception", paramsApi, NO_REDIRECT_ON_ERROR)
            .then((r : ApiResponse | null)=> {
                if (r?.code === 200){
                    callback();
                    GetDoctors();
                }
            }).finally(()=>{
                setSavingData(false);
            })
            ;
    }

    const addAndReset = () : void => {
        form.validateFields().then(()=>{
            save(resetFields);
        });
    }

    const addAndKeep = () : void => {
        form.validateFields().then(()=>{
            save(clearSomeFields);
        });
    }



    const clearSomeFields = () : void => {
        form.setFieldsValue({patient:"", product:"", observations:"", status: "0"});
        setSelectedProduct(undefined);
        setPatients([]);
        setSelectedShortCode([]);
        setTemplate(undefined)
    }

    const changeRequestDoctor = (id:number)=>{
        setSelectedDoctor(doctors.find((d: Doctor) => d.id === id));
        form.setFieldsValue([{request:""}])
        setSelectedRequest(undefined);
    }

    const showAddNew = () : void => {
        setShowAddPatient(true);
    }

    const showMoreInfo = () : void => {
        const id : number = form.getFieldValue("request");

        if (id){
            const selected : Request = requests.find((rq : Request) => rq.id == form.getFieldValue("request"))!;

            setSelectedRequest(selected);

            setShowMoreInfoModal(true);
        }
    }


    useEffect(()=>{
        GetDoctors();
        GetPathologists();
        GetCompanies();
        GetShortcuts();
        GetSpecialTags();
    },[])

    useEffect(()=>{
        if (selectedDoctor){
            GetRequestsByDoctor(selectedDoctor.id);
        }
    },[selectedDoctor])

    useEffect(()=>{
        if (selectedCompany){
            GetCompanyProducts(selectedCompany.id);
        }
    },[selectedCompany])

    useEffect(()=>{
        if(selectedProduct){
            setTemplate(loadTemplate(`${selectedProduct.type}-recepcion`, form, {}));
        }
    },[selectedProduct]);

    const actionButtons = <div className="b-component-actions">
                                <Button className="btn-save" onClick={resetFields} type="default">
                                    <ClearOutlined /> Limpiar campos
                                </Button>
                                <Button type="primary" onClick={()=>{setShowAddOrder(true)}}><CarOutlined size={3}/> Ordenar&nbsp;&nbsp;</Button>
                                <Button disabled={savingData} className="btn-save" type="primary" onClick={addAndReset} >
                                     <LoadingOrText loading={savingData} text={<><SaveOutlined /> Guarda y límpia</>} showWhileLoading={false}/>
                                </Button>
                                <Button disabled={savingData} className="btn-save" type="primary" onClick={addAndKeep}>
                                     <LoadingOrText loading={savingData} text={<><RotateLeftOutlined /> Guarda y mantén</>} showWhileLoading={false}/>
                                </Button>
                            </div>

    const changeFilterRequestCheckBox = (v: any) : void => {
        v.target.value == showOnlyRequesStatus ? setShowOnlyRequesStatus("") :  setShowOnlyRequesStatus(v.target.value);
    }

const getFilterRequestCheckBoxs = () : JSX.Element => {

    return <>
                Solicitudes 
                <small>
                    &nbsp;&nbsp;
                    <Checkbox checked={showOnlyRequesStatus == "pendiente"} value={"pendiente"} onChange={changeFilterRequestCheckBox}>
                        Mostrar solo Pendientes
                    </Checkbox>
                    <Checkbox checked={showOnlyRequesStatus == "ordenado"} value={"ordenado"} onChange={changeFilterRequestCheckBox}>
                        Mostrar solo ordenados
                    </Checkbox>
                    <Checkbox checked={showOnlyRequesStatus == "recepcionado"} value={"recepcionado"} onChange={changeFilterRequestCheckBox}>
                        Mostrar solo Recepcionados
                    </Checkbox>
                    <Checkbox checked={showOnlyRequesStatus == "procesando"} value={"procesando"} onChange={changeFilterRequestCheckBox}>
                        Mostrar solo Procesando
                    </Checkbox>
                </small>
            </>
}

const onFinish = () => {}

const chargeNewPatient = (patient: Patient) : void => {
    setPatients([patient]);
    form.setFieldsValue({patient:patient.id});
    setShowAddPatient(false);
}

    return (
        <Form form={form} onFinish={onFinish} layout="vertical" className="frm-register">
            
            <Drawer title="Ordenar recogida" width="40%" placement="right" onClose={()=>{setShowAddOrder(false);}} visible={showAddOrder}>
                    <SubComponents.OrderRequest />
                </Drawer>
            <Drawer title="Registrar nuevo paciente" width="40%" placement="right" onClose={()=>{setShowAddPatient(false);}} visible={showAddPatient}>
                <ManagePatient onFinish={chargeNewPatient} clear={showAddPatient} editMode={false}/>
            </Drawer>
            
            <Modal className="more-info-rq" title={<div className="title"><ClockCircleOutlined className="clock" /><Title level={4}>Datos de la solicitud #{selectedRequest?.id} <small style={{display:"block"}}>Estos son los datos completos de la solicitud</small></Title> </div>} visible={showMoreInfoModal} onOk={()=>{setShowMoreInfoModal(false)}} onCancel={()=>{setShowMoreInfoModal(false)}}
            footer={[
                <Button key="back" type="default" onClick={()=>{setShowMoreInfoModal(false)}}>
                  Cerrar
                </Button>
              ]}>
                <Title level={5} className="resume">
                    Fecha de registro
                    <small>Día en que se registró la solicitud en la base de datos</small>
                </Title>
                <p><strong>{moment(selectedRequest?.requestDate).format("DD/MM/YYYY HH:mm:ss")}</strong></p>

                <Title level={5} className="resume">
                    Fecha de la solicitud
                    <small>Día en que se solicita la recogida</small>
                </Title>
                <p><strong>{moment(selectedRequest?.start).format("DD/MM/YYYY")}</strong></p>

                <Title level={5} className="resume">
                    Tipo de solicitud
                    <small>Texto que identifica a la solicitud</small>
                </Title>
                <p><strong>{selectedRequest?.requestType.description} ({selectedRequest?.requestType.shortDescription})</strong></p>

                <Title level={5} className="resume">
                    Estado ctual
                </Title>
                <p style={{textTransform:"capitalize"}}><strong>{selectedRequest?.status}</strong></p>
                
            </Modal>
            <Divider orientation="left">Recepción de muestras</Divider>
            {actionButtons}
            <Form.Item name="doctor" label={<>Médico solicitante <Button style={{marginLeft:"10px"}} size='small' type='primary' className='btn-secondary' onClick={GetDoctors}><ReloadOutlined /></Button></>} rules={[{ required: true, message: "Debe seleccionar un médico."}]}>
                <Select showSearch 
                    filterOption={(input:any, option:any) =>{
                        const name = `${option.children[14]} ${option.children[16]} ${option.children[18]}`;
                        return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}

                    style={{ width: "100%" }} placeholder={<LoadingOrText loading={loadingDoctors} text="Seleccione..."/>} 
                    
                    onChange={changeRequestDoctor}>
                    {
                        doctors.map((doctor: Doctor) => {
                            return <Option key={doctor.id} value={doctor.id}>{getItemStatus("pendiente", "inline")} {doctor.requestsPending ?? 0 } {getItemStatus("ordenado", "inline")} {doctor.requestsOrdered ?? 0 } {getItemStatus("recepcionado", "inline")} {doctor.requestsReceived ?? 0} {getItemStatus("procesando", "inline")} {doctor.requestsProcessing ?? 0} - {doctor.id} - {doctor.name} {doctor.surname} {doctor.secondSurname}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            
            <Form.Item name="request" 
                        help={selectedDoctor ? <>Indique una solicitud pendinente de {selectedDoctor?.name} {selectedDoctor?.surname} 
                                            {selectedDoctor?.secondSurname} 
                                                <Button size="small" style={{marginTop: "5px", marginLeft: "5px"}} type="primary" onClick={showMoreInfo}>Saber más</Button>
                                                <Button size="small" style={{marginTop: "5px", marginLeft: "5px"}} type="primary" onClick={()=>{if (selectedDoctor) GetRequestsByDoctor(selectedDoctor.id)}}>Refrescar</Button></>
                                             : null} 
                        label={getFilterRequestCheckBoxs()}
                        rules={[{ required: true, message: "Debe seleccionar un médico."}]}>
            
                <Select showSearch={true} style={{ width: "100%" }} disabled={loadingRequests || selectedDoctor === undefined} placeholder={selectedDoctor ? (<LoadingOrText loading={loadingRequests} text="Seleccione..."/>) : 'Debe seleccionar primero un profesional'}>
                    {
                        requests.filter((request: Request) => showOnlyRequesStatus.length > 0 ? request.status == showOnlyRequesStatus : true).map((request: Request) => {
                            return <Option key={request.id} value={request.id}>{request.id} - {request.requestType.description} - F. {moment(request.start).format("DD/MM/YYYY")} ({request.status})</Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name="pathologist" label="Patólogo" help="Selecciona un patólogo para asignar el caso de estudio." rules={[{ required: true, message: "Debe seleccionar un médico."}]}>
                <Select style={{ width: "100%" }} placeholder={<LoadingOrText loading={loadingPathologists} text="Seleccione..."/>}>
                    {
                        pathologists.map((pathologist: Pathologist) => {
                            return <Option key={pathologist.id} value={pathologist.id}>{pathologist.name} {pathologist.surname} {pathologist.secondSurname}</Option>
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item initialValue={" "} name="specialTag" label="Etiqueta especial" help="Selecciona una etiqueta especial para asociarla al caso de estudio">
                <Select style={{ width: "100%" }} showSearch> 
                    {
                        specialTags?.map((data: any)=>{
                            return <Option value={data.value} key={data.id}>{data.key}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            
            <Form.Item name="patient" label={`Paciente (${patients.length})`} help="Selecciona un paciente." rules={[{ required: true, message: "Debe seleccionar un médico."}]}>
                <Select
                    onSearch={filterPatients}
                    onClick={showAddNew}
                    showSearch
                    style={{ width: "100%" }}
                    placeholder={<LoadingOrText loading={loadingPatients} text="Pulsa buscar tras escribir el nombre..."/>}
                    filterOption={false}
                    dropdownRender={(menu:any) => (
                        <div>
                            {menu ? menu : ''}
                            <Divider style={{ margin: '4px 0' }} />
                            {
                                <>
                                    <div className="footer-search-patient">
                                        <Title level={5}><Button type="primary" size="small" onClick={()=>{GetPatients();}}>Realizar búsqueda</Button></Title>
                                    </div>
                                    <div className="footer-add-patient">
                                        <Title level={5}>¿No encuentra el paciente?, puedes añadirlo desde aquí <Button type="primary" size="small" onClick={showAddNew}>Crear nuevo</Button></Title>
                                    </div>
                                </>
                            }
                        </div>
                    )}
                >
                    {
                        patients.map((p: Patient) => {
                            return <Option key={p.id} value={p.id}>{p.name} {p.surname} {p.secondSurname} - {p.birthDate ? `${moment(p.birthDate).format("DD/MM/YYYY")} (${moment(p.birthDate).fromNow(true)})` : 'N/D'} {p.patientNif ? `- ${p.patientNif}` : ''}</Option>
                        })
                    }
                </Select>
                    
                    
                </Form.Item>
            
            <Form.Item initialValue={"no"} name="paymentStatus" label="Estado pago" help="Selecciona un patólogo para asignar el caso de estudio." rules={[{ required: true, message: "Debe seleccionar un médico."}]}>
                <Select style={{ width: "100%" }}>
                    <Option value="si">Pagado</Option>
                    <Option value="no">No pagado</Option>
                </Select>
            </Form.Item>
            <Form.Item name="company" 
                       label="Compañía" 
                       help="Indica la compañía a la que se asociará el caso de estudio." 
                       rules={[{ required: true, message: "Debe seleccionar una compañía"}]}>
                <Select showSearch filterOption={(input:any, option:any) =>{
                        const name = `${option.children}`;
                        return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }} style={{ width: "100%" }} onChange={(v)=>{
                    setSelectedCompany(companies.find((d: Company) => d.id === v));
                }} placeholder={<LoadingOrText loading={loadingCompanies} text="Seleccione..."/>}>
                    {
                        companies.map((company: Company) => {
                            return <Option key={company.id} value={company.id}>{company.businessName}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name="product" 
                help={selectedCompany ? 'Seleccione el producto asociado' : 'Seleccione una compañia primero'} 
                label="Producto" 
                rules={[{ required: true, message: "Debe seleccionar un médico."}]}
            >
                <Select style={{ width: "100%" }} disabled={selectedCompany === undefined} 
               
                onChange={(v)=>{
                    const e : Product = products.find((p:Product)=>p.id == v)!;
                    setSelectedProduct(e);
                }}
                
                placeholder={
                                selectedCompany 
                                    ? 
                                        (<LoadingOrText loading={loadingProducts} text="Seleccione..." />) 
                                    : 
                                        "Debe seleccionar primero una compañía" 
                            }
                >
                    {
                        products.map((product: Product) => {
                            return <Option key={product.id} value={product.id}>{product.description} ({getTemplateName(product.type)})</Option>
                        })
                    }
                </Select>
            </Form.Item>
            {
                template && selectedProduct ? template : <Alert
                message="Atención"
                description="Debe seleccionar un producto para cargar la plantilla."
                type="info"
                showIcon
            />
            }
            
            <Form.Item 
                name="observations" 
                style={{ marginBottom: 5 }}
                label="Observaciones">
                    <TextArea rows={4} />
            </Form.Item>
            <Select 
                mode="tags" 
                value={selectedShortCode}
                onChange={(v)=>{ setSelectedShortCode(v)}}
                style={{ width: '100%', marginBottom: 5 }} 
                placeholder="Atajos" 
                onDeselect={(v: string)=>{
                    try{
                        let ob : string = form.getFieldValue("observations");

                        ob = ob.replace(` ${v} `, "");

                        form.setFieldsValue({observations:ob})
                    }catch(exception){

                    }
                }} 
                onSelect={(v: string)=>{
                    let ob : string = form.getFieldValue("observations");

                    if (ob){
                        ob = `${ob} ${v} `;
                    }else{
                        ob = ` ${v} `;
                    }

                    form.setFieldsValue({observations:ob})
                }}
            >
                {
                    shortCuts.map((s: any) => {
                        return <Select.Option key={s.key} value={s.value}>{s.value}</Select.Option>
                    })
                }
            </Select>
            <Form.Item initialValue={"0"} name="status" label="Estado" help="Selecciona el estado de esta recepción." rules={[{ required: true, message: "Campo obligatorio"}]}>
                <Select style={{ width: "100%" }}>
                    <Option value="0">Autorizado</Option>
                    <Option value="1">Pendiente de autorización</Option>
                    <Option value="2">Caso con incidencia</Option>
                    <Option value="3">Rechazado por aseguradora</Option>
                </Select>
            </Form.Item>

            
            {actionButtons}

        </Form>
    )
}


export default RegisterComponent;