import { DownloadOutlined, FileDoneOutlined, SendOutlined } from '@ant-design/icons'
import { Button, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import CaseReports from '../../../../models/CaseReports'

import RestApiClient from '../../../../api/restApiClient';
import ParamsApi from '../../../../models/api/ParamsApi';

interface Props{
    selected: any,
    visible: boolean,
    onClose: any,
    pdfData: any,
    isMassivePdf?: boolean
}

const ModalPDFComponent = (props: Props) : JSX.Element => {
    const restApiClient : RestApiClient = new RestApiClient();

    const [selected, setSelected] = useState<CaseReports>();
    const [visible, setVisible] = useState<boolean>(false);
    const [pdfData, setPdfData] = useState<any>();

    const [sendingEmail, setSendingEmail] = useState<boolean>(false);

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [isMassivePdf, setIsMassivePdf] = useState<boolean>(false);


    const sendEmail = (myEmail: string = "", myPassword: string = "") => {
        const paramsApi : ParamsApi = {
            body:{
                caseReportId: selected?.id,
                emailTo: myEmail.length > 0 ? myEmail : email,
                password: myPassword.length > 0 ? myPassword : password,
                mySelf: myEmail.length > 0 ? true : false
            }
        }
        setSendingEmail(true);
        restApiClient.fetch("SendReportTo", paramsApi) 
            .finally(()=>{
                setSendingEmail(false);
                setEmail("");
                setPassword("");
            })
    }


    useEffect(()=>{
        if (props.selected){
            setSelected(props.selected);
        }
    },[props.selected]);

    useEffect(()=>{
        if (props.pdfData){
            setPdfData(props.pdfData);
        }
    },[props.pdfData]);

    useEffect(()=>{
        setVisible(props.visible);
    },[props.visible])

    useEffect(()=>{
        setIsMassivePdf(props.isMassivePdf != undefined && props.isMassivePdf != null && props.isMassivePdf === true);
    },[props.isMassivePdf])



    return(
        <Modal
            title={<><FileDoneOutlined /> Previsualización Informe {selected?.caseReportNum}</>}
            centered
            visible={visible}
            onOk={() => props.onClose()}
            onCancel={() => props.onClose()}
            width={"90vw"}
            footer={
                <>
                        {
                            !isMassivePdf ?
                                <Button danger onClick={()=>{sendEmail(selected?.reception?.request?.user?.email, selected?.reception.request.user.dniNie)}}><SendOutlined /> Enviar a {selected?.reception?.request?.user?.email} Médico</Button>
                            : <></>
                        }
                        <Button type="primary" onClick={() => props.onClose()}>Cerrar</Button>

                        
                </>
            }
        >
            {
                pdfData ? 
                    <iframe src={pdfData} style={{width:"100%", height:"70vh", border: "none", marginTop:"10px"}}></iframe>
                :
                ''
            }
            {
                            !isMassivePdf ?
                            <>
                                <Input type="email" placeholder='Escribe un email al que desees enviar el informe.' style={{width: "70%"}} 
                                onChange={(e)=>{setEmail(e.target.value)}}
                                value={email}
                                size='small' 
                                />
                                <Input onChange={(e)=>{setPassword(e.target.value)}} value={password} size="small" placeholder='Contraseña (Opcional)' style={{width:"20%"}}/>
                                <Button style={{borderRadius:"0", width: "10%"}} size="small" disabled={!email || email.length === 0} danger 
                                    onClick={()=>{sendEmail()}}>
                                    <SendOutlined /> Enviar por email
                                </Button>
                            </>: <></>
                        }
        </Modal>
    )
}

export default ModalPDFComponent;