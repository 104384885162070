import { Drawer } from 'antd'
import React from 'react'


interface RequestDrawerProps{
    title: string,
    visible: boolean,
    onClose: any,
}

const RequestDrawerComponent = (props: RequestDrawerProps):JSX.Element => {
    return(
        <Drawer title={props.title} 
                width="600px" 
                closable={true} 
                onClose={props.onClose} 
                visible={props.visible} 
                style={{ position: 'absolute' }}>
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Drawer>
    )
}

export default RequestDrawerComponent;