import React from 'react'

import * as Components from '../../components'

import './patients.scss'

const PatientsPage = () : JSX.Element => {

    return (
        <div className="patients-page">
            <Components.PatientList />
        </div>
    )
}

export default  PatientsPage;