import React from 'react'
import { Badge, Button, Calendar } from 'antd'
import esEs from 'antd/lib/calendar/locale/es_ES'

import Request from '../../../../models/Request';
import "../Components.scss"
import moment from 'moment';

interface Props{
    requests: Request[],
    onChange: any,
    onSelect: any,
    onSelectDay: any,
    filterTitle: string
}

interface ListData{
    type: string,
    content: any
}

const CalendarComponent = (props: Props) : JSX.Element => {

    const { requests } = props;

    const onChange = (value: any) : void => {
        props.onChange(value);
    }

    const onSelect = (value: any) : void => {
        props.onSelect(value);
    }

    const getListData = (value: any) => {
        let listData : ListData[] = [];

        var _requests : Request[] = requests.filter((rq: Request) => moment(rq.start).format("DD/MM/YYYY") == value.format("DD/MM/YYYY"));

        if (_requests.length > 0){
            listData.push({ type: "", content: <Button size='small' style={{minWidth:"100%", marginBottom:"10px"}} type="default" onClick={()=>{props.onSelectDay(moment(_requests[0].start).format("YYYY-MM-DD"))}}>Ver día</Button>})
        }

        _requests.map((rq : Request) => {
            listData.push({ type: rq.status, content: `(Estado: ${rq.status} - ${rq.id}) - ${rq.requestType.description} - ${rq.user.name} ${rq.user.surname} ${(rq.user.secondSurname ? rq.user.secondSurname : '')}`})
        });
        
        return listData || [];
      }

    const dateCellRender = (value: any) : JSX.Element => {
        const listData = getListData(value);
        return (
            <ul className="events">
            {listData.map((item:any) => (
                <li key={item.content}>
                    <Badge status={item.type} text={item.content} />
                </li>
            ))}
            </ul>
        );
    }

    return (
            <div className="calendar box-component">
                <Calendar 
                onSelect={onSelect} 
                onChange={onChange} 
                locale={esEs} 
                dateCellRender={dateCellRender}/>
            </div>
        )
}

export default CalendarComponent;