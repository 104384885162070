import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Options'

export const OptionsEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller) 
        .setActionName("GetOptions"),  
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller) 
        .setActionName("CreateOption"),  
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller) 
        .setActionName("UpdateOption"),  
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller) 
        .setActionName("DeleteOption"),    
        
]