import { Col, Row } from 'antd';
import React from 'react'
import * as Components from '../index'

import './HeaderComponent.scss'


interface HeaderComponentProps {}

const HeaderComponent = (props: HeaderComponentProps) : JSX.Element => {
    return (
        <Row className="app-header">
            <Col xs={24} sm={24} md={3} className="logo-container">
                <img  className="logo" src="https://www.centrodiagnosticocda.com/img/cda/cda.png" />
            </Col>
            <Col xs={24} sm={24} md={17} className="search-container">
                <Components.Search />
            </Col>
            <Col xs={24} sm={24} md={4} className="search-container">
                <Components.Session />
            </Col>
        </Row>
    )
}

export default HeaderComponent;