import React, {useContext, useState} from 'react'
import { Row, Col } from 'antd'
import { AppCfgContext } from '../../hooks/context/appConfigContext'

interface ContainerBaseProps{
    children: React.ReactNode
}

const ContainerBase = (props : ContainerBaseProps) : JSX.Element => {
    const appConfigContext = useContext(AppCfgContext);

    return(
        <Row className="theme" justify="center" align="middle">
            <Col id="container" className="container" xs={24}>
                {props.children}
            </Col>
        </Row>
    )
}

export default ContainerBase;