import { Row, Col } from 'antd'
import React from 'react'
import * as Components from '../../components/index'

import './Register.scss'

interface RegisterProps{}

const RegisterPage = (props: RegisterProps) : JSX.Element => {
    return (<Row gutter={16} className="register-page request-page">
                <Col xs={24}>
                    <Components.Register />
                </Col>
                
            </Row>)
}

export default RegisterPage;