import React, { useState } from 'react'


import './App.css';
import './styles/theme.scss'
import './styles/custom.scss'

import { PrivateRoutesComponent } from './components/core/routes/PrivateRoutes'
import { PublicRoutesComponent } from './components/core/routes/PublicRoutes'

import { LoginContext, userDefault } from './hooks/context/userContext'

import User from './models/Users';
 
// App
function App() {

  const [ user ] = useState<User>(userDefault)

  return (
        <LoginContext.Provider value={user}>
            {
                user && user.isLoged ? 
                  <PrivateRoutesComponent />
                :
                  <PublicRoutesComponent />
            }
             
        </LoginContext.Provider>
  );
}

export default App;
